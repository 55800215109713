import { Box, Grid, TablePagination } from "@mui/material";
import { BoxContainer } from "../../components";
import { useForm } from "react-hook-form";
import RegexOf from "../../config/regex";
import { Button, TextField } from "../../components/FormFields";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useNotifier } from "../../hooks";
import { cleanUpMask } from "../../config/mask";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { LibraryAdd } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import TableFilter from "../../components/Table/TableFilter";
import Yup from "../../config/yup";
import { validateCNS789, validateCPF } from "../../config/validations";
import { useDomiciliaries } from "../../service";

function Territorial() {
  const navigate = useNavigate();
  const notify = useNotifier();
  const { getDomiciliaries } = useDomiciliaries();
  const [enableQuery, setEnableQuery] = useState(false);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const initialValues = {
    physic_national: "",
    sus_card: "",
    street: "",
    number: "",
    complement: "",
    county: "",
  };

  const searchScheme = Yup.object()
    .shape({
      physic_national: Yup.string().nullable(),
      sus_card: Yup.string().nullable(),
      street: Yup.string().nullable(),
      number: Yup.string().nullable(),
      complement: Yup.string().nullable(),
      county: Yup.string().nullable(),
    })
    .test("physicNationalAndSusCode", "", function (values) {
      const { sus_card, physic_national } = values;
      if (physic_national !== "" && !validateCPF(physic_national)) {
        return this.createError({
          path: "physic_national",
          message: "CPF inválido.",
        });
      } else if (sus_card !== "" && !validateCNS789(sus_card)) {
        return this.createError({
          path: "sus_card",
          message: "CNS inválido.",
        });
      } else return true;
    });

  const { watch, control, reset, handleSubmit } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(searchScheme),
  });

  const domiciliaries = useQuery(
    ["domiciliaries", [page, limit]],
    () =>
      getDomiciliaries({
        page: page,
        limit: limit,
        physic_national: cleanUpMask(watch("physic_national"), "", ["_", "-", "."]),
        sus_card: cleanUpMask(watch("sus_card"), "", ["_"]),
        street: watch("street"),
        number: watch("number"),
        complement: watch("complement"),
        county: watch("county"),
      }),
    {
      enabled: enableQuery,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (response) => {
        if (response.items.length) {
          notify(`Domicilio encontrado`, "success");
        } else {
          notify("Nenhum domilicio foi encontrado com este fitro", "warning");
        }
        setEnableQuery(!enableQuery);
      },
      onError: (error) => {
        setEnableQuery(!enableQuery);
        notify(error.message, "error");
      },
    }
  );

  useEffect(() => {
    domiciliaries.refetch();
  }, [page, limit]);

  const onSubmit = () => {
    setEnableQuery(!enableQuery);
  };

  const columns = [
    {
      field: "address_location_stay.address.street",
      name: "Logradouro",
      width: 250,
      type: "string",
    },
    {
      field: "physic_national",
      name: "CPF do responsável",
      width: 150,
      type: "string",
      use: (value) => !!value && value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
    },
    {
      field: "sus_card",
      name: "CNS do responsável",
      width: 150,
      type: "string",
    },
    {
      field: "address_location_stay.number",
      name: "Número",
      width: 100,
      type: "string",
    },
    {
      field: "address_location_stay.complement",
      name: "Complemento",
      width: 250,
      type: "string",
      flex: 0.3,
    },
    {
      field: "address_location_stay.address.county",
      name: "Municipio",
      width: 250,
      type: "string",
      flex: 0.3,
    },
  ];

  return (
    <BoxContainer title={"Consulta de Ficha Territorial"}>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField name="street" label="NOME DO LOGRADOURO" control={control} />
            </Grid>

            <Grid item xs={2}>
              <TextField name="number" label="NÚMERO" control={control} />
            </Grid>

            <Grid item xs={4}>
              <TextField name="complement" label="COMPLEMENTO" control={control} />
            </Grid>

            <Grid item xs={4}>
              <TextField
                name="county"
                label="MUNICIPIO"
                control={control}
                value="São Paulo"
                disabled
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                name="physic_national"
                label="CPF DO RESPONSÁVEL"
                control={control}
                mask={RegexOf.cpf}
              />
            </Grid>

            <Grid item xs={4}>
              <TextField
                name="sus_card"
                label="CNS DO RESPONSÁVEL"
                control={control}
                mask={RegexOf.cns}
              />
            </Grid>

            <Grid item xs={12} alignItems="stretch">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  pt: 2,
                }}
              >
                <Link to="/cds/territorial/register">
                  <Button sx={{ textDecoration: "none" }}>
                    <LibraryAdd /> Adicionar ficha
                  </Button>
                </Link>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    pt: 2,
                  }}
                >
                  <Button onClick={reset} sx={{ mr: 1 }} variant="text">
                    Limpar os Dados
                  </Button>
                  <Button
                    type="submit"
                    loading={domiciliaries.isFetching}
                    loadingMessage="Buscando"
                  >
                    <SearchOutlinedIcon /> Buscar
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>

      <Grid item xs={12} sx={{ paddingTop: 3 }}>
        <TableFilter
          noFilter
          data={domiciliaries.data?.items || []}
          columns={columns}
          loading={domiciliaries.isFetching && domiciliaries.isLoading}
          actions
          actionsTypes={["view", "edit"]}
          actionHandleView={(i) => {
            navigate(`register/${domiciliaries.data.items[i].id}`);
          }}
          actionHandleEdit={(i) => {
            navigate(`register/${domiciliaries.data.items[i].id}?isEditing=true`);
          }}
        />
      </Grid>

      <Box>
        <Grid item xs={12} sx={{ paddingTop: 3 }}>
          <TablePagination
            count={domiciliaries.data?.totalItems || 0}
            component="div"
            page={page}
            onPageChange={(_, newPage) => {
              setPage(newPage);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 40, 75]}
            onRowsPerPageChange={(event) => {
              setLimit(parseInt(event.target.value, 10));
              setPage(0);
            }}
          />
        </Grid>
      </Box>
    </BoxContainer>
  );
}

export default Territorial;
