import React, { createContext, useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useLocalStorage } from "../hooks";
import { format, isValid } from "date-fns";

export const AppContext = createContext();

export const AppStorage = ({ children }) => {
  const soapPreContent = {
    subjective: { reason: "", id_ciap: [], procedure_id: null },
    admeasurement_objective: {},
    objective: {
      complementary_exams: "",
      allergy_status: "",
      allergy_substances: [],
      allergy_description: "",
      objective_notes: "",
      vaccine_up_to_date: null,
    },
    PreExistIllness: [],
    assessment: {
      query_assessment: "",
      id_ciap: [],
      cid: [],
    },
    plan: {
      query_plan: "",
      id_ciap: [],
    },
    prescription: [],
    against_reference: [],
  };

  const [soapInvalidFields, setSoapInvalidFields] = useState([]);
  const [soapInvalidStep, setSoapInvalidStep] = useState([]);
  const [diabeticFeetEvaluation, setDiabeticFeetEvaluation] = useState();
  const [interventions, setInterventions] = useState([]);
  const [anesthesia, setAnesthesia] = useState({
    types: [],
    sterilization: [],
  });
  const [prosthesisCommand, setProsthesisCommand] = useState(0);
  const [preData, setPreData] = useState(soapPreContent);
  const [patientData, setPatientData] = useState([]);
  const [employeeData, setEmployeeData] = useState([]);
  const [dataSOAP, setDataSOAP] = useState({});
  const [company, setCompany] = useState(null);
  const [disableFields, setDisableFields] = useState(false);
  const [disabledFieldNames, setDisabledFieldNames] = useState([]);
  const [isRenderInterventions, setIsRenderInterventions] = useState(false);
  const [soapToEdit, setSoapToEdit] = useState(null);
  const [drugPrescriptions, setDrugPrescriptions] = useState([]);
  const [examPrescriptions, setExamPrescriptions] = useState([]);

  const [planAssistedMedicationAdministration, setPlanAssistedMedicationAdministration] = useState(
    []
  );
  const [activeProblemsToInactive, setActiveProblemsToInactive] = useState([]);
  const [attendancesHistory] = useLocalStorage("attendance");
  const location = useLocation();
  const clearAppContext = useCallback(() => {
    setSoapToEdit(null);
    setCompany(null);
    setInterventions([]);
    setAnesthesia([]);
    setProsthesisCommand(0);
    setDisableFields(false);
    setDisabledFieldNames([]);
    setActiveProblemsToInactive([]);
    setDiabeticFeetEvaluation(null);
  }, []);

  useEffect(() => {
    if (planAssistedMedicationAdministration.length && !location.pathname.includes("soap")) {
      setPlanAssistedMedicationAdministration([]);
    }
  }, [location.pathname]);

  useEffect(() => {
    if (attendancesHistory) {
      const attendanceToVerifyDate = Object.entries(attendancesHistory)[0];
      const attendanceSavedDate = new Date(attendanceToVerifyDate.soap_saved_date);
      if (isValid(attendanceSavedDate)) {
        const today = format(new Date(), "dd/MM/yyyy");
        const formattedAttendanceSavedDate = format(attendanceSavedDate, "dd/MM/yyyy");
        if (today !== formattedAttendanceSavedDate) {
          localStorage.removeItem("attendance");
        }
      }
    }
  });

  return (
    <AppContext.Provider
      value={{
        soapToEdit,
        setSoapToEdit,
        preData,
        setPreData,
        soapInvalidFields,
        setSoapInvalidFields,
        drugPrescriptions,
        setDrugPrescriptions,
        examPrescriptions,
        setExamPrescriptions,
        soapInvalidStep,
        setSoapInvalidStep,
        patientData,
        setPatientData,
        employeeData,
        setEmployeeData,
        dataSOAP,
        setDataSOAP,
        disableFields,
        setDisableFields,
        disabledFieldNames,
        setDisabledFieldNames,
        activeProblemsToInactive,
        setActiveProblemsToInactive,
        company,
        setCompany,
        clearAppContext,
        interventions,
        setInterventions,
        anesthesia,
        setAnesthesia,
        planAssistedMedicationAdministration,
        setPlanAssistedMedicationAdministration,
        prosthesisCommand,
        setProsthesisCommand,
        isRenderInterventions,
        setIsRenderInterventions,
        diabeticFeetEvaluation,
        setDiabeticFeetEvaluation,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
