const { useLocation } = require("react-router-dom");

function usePermissions(path) {
  const location = useLocation();

  let permissionsObject = [];
  if (localStorage.getItem("user-data")) {
    permissionsObject = JSON.parse(localStorage.getItem("user-data"))?.accessList || [];
  }

  permissionsObject["/full-document-printing"] = {
    create: true,
    update: true,
    delete: true,
    read: true,
  };

  if (path === "all") return permissionsObject;

  const parentPattern = new RegExp(
    "(?<parent>/.*)/(?<actual>new|[0-9A-Fa-f]{8}-[0-9A-Fa-f]{4}-[4][0-9A-Fa-f]{3}-[89ABab][0-9A-Fa-f]{3}-[0-9A-Fa-f]{12})$"
  );

  const pathMatches = parentPattern.exec(path || location.pathname);

  if (parentPattern.test(pathMatches?.groups.parent)) {
    pathMatches.groups.parent = parentPattern.exec(pathMatches?.groups.parent)?.groups.parent;
  }

  return {
    ...permissionsObject[path || location.pathname],
    parent: {
      from: pathMatches?.groups.actual,
      ...permissionsObject[pathMatches?.groups.parent],
    },
  };
}

export default usePermissions;
