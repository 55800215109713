import React from "react";
import TableFilter from "../../../components/Table/TableFilter/Table";
import { groupPrescriptionCollection, openURL } from "../../../utils";
import { Box } from "@mui/material";
import { PrintRounded } from "@mui/icons-material";
import { sadtPDF } from "../../../pdfModels";

export default function ExamTable({ prescription, check, activeActions, disableActions }) {
  function handlePrint(data) {
    const firstItem = data[0];
    const isXray = firstItem.procedure.type?.includes("xray");
    let type = "lab";

    if (isXray) {
      type = "xray";
    } else if (firstItem.type === "Imagem") {
      type = "image";
    }

    const signedDocument = prescription.signed_documents.find((signedDocument) => {
      return signedDocument.type === type && signedDocument.tab_id === firstItem.tab_id;
    });

    if (signedDocument?.result) {
      return openURL(signedDocument.result);
    }

    const documentData = {
      createdAt: prescription.createdAt,
      employee: {
        ...prescription.employee_specialty?.employee,
        specialty: prescription.employee_specialty?.specialty,
      },
      company: prescription.attendance.company,
    };

    const pdf = sadtPDF(type, data, prescription.attendance.patient, documentData);
    pdf.open();
  }

  const columns = [
    { name: "Exame", field: "describe" },
    { name: "Nota", field: "notes" },
    { name: "tipo", field: "type" },
    {
      name: "Profissional",
      field: "employee_specialty",
      use: (value) => (value?.employee?.name ? value?.employee?.name : "-"),
    },
  ];

  const groupedExams = [];

  prescription.exams.forEach((exam) => {
    if (exam.tab_id) {
      groupPrescriptionCollection(groupedExams, exam);
    } else {
      groupedExams.push([exam]);
    }
  });

  return (
    <Box display="flex" flexDirection="column" gap="12px">
      {groupedExams.map((group) => {
        const headerActions = [
          {
            title: "Imprimir",
            icon: <PrintRounded />,
            handler: () => handlePrint(group),
          },
        ];

        return (
          <TableFilter
            key={group[0].id}
            data={group}
            columns={columns}
            actions={activeActions}
            actionsTypes={["finish", "delete"]}
            headerActions={headerActions}
            customizeActions={{
              finish: {
                title: "Coletado",
                verb: "Marcar como Coletado",
              },
              delete: {
                title: "Não Coletado",
                verb: "Marcar como Não Coletado",
              },
            }}
            rowStatus={(item) => (item.done ? "active" : item.done === false ? "disabled" : "")}
            disableActions={(item, action) => {
              if (disableActions instanceof Function) {
                const result = disableActions(item, action);

                if (result) return result;
              }

              return !(item.done === null);
            }}
            actionHandleFinish={check}
            actionHandleDelete={(_, i, v) => check(_, i, v, false)}
          />
        );
      })}
    </Box>
  );
}
