import React, { useContext, useState } from "react";
import { Grid, Box, Typography, Divider } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import Yup from "../../../config/yup";
import { endOfDay, format, formatISO, isValid, parseISO, startOfDay, subHours } from "date-fns";
import {
  attendanceStatementPDF,
  followUpStatementPDF,
  maternityLeavePDF,
  medicalOpinionPDF,
  medicalReportPDF,
  medicalStatementPDF,
} from "../../../pdfModels";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  DateField,
  PaginatedAutocompleteField,
  SelectField,
  TextField,
} from "../../../components/FormFields";
import DeclarationCard from "./DeclarationCard";
import { useCids, usePatient } from "../../../service";
import useSignaturePassword from "../../../hooks/useSignaturePassword";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNotifier } from "../../../hooks";
import { AuthContext } from "../../../contexts/AuthContext";
import { AppContext } from "../../../contexts/AppContext";
import { openURL, pdfToBase64 } from "../../../utils";

export default function DeclarationForm({
  attendance,
  isPregnancy,
  isExternal,
  defaultCids,
  odonto,
  ceo,
}) {
  const notify = useNotifier();
  const validateSignature = useSignaturePassword();
  const [declarations, setDeclarations] = useState([]);
  const disabledInternalFields = !attendance.has_soap && isExternal;
  const { userData } = useContext(AuthContext);
  const { soapToEdit } = useContext(AppContext);

  const queryClient = useQueryClient();
  const { getCids } = useCids();
  const { postDeclarations, getDeclarations } = usePatient();

  const postDeclarationsMutation = useMutation(postDeclarations);

  const initialValues = {
    declaration_type: "Atestado",
    arrival_time: null,
    departure_time: null,
    days: "",
    cid: defaultCids?.[0] || null,
    companion_name: "",
    observations: "",
    ctps: "",
    ctps_series: "",
    weeks_of_pregnancy: "",
    maternity_leave: "",
    maternity_license_date: null,
  };

  const validations = Yup.object().shape(
    {
      declaration_type: Yup.string().required("Selecione o tipo de declaração"),
      arrival_time: Yup.date().when(["declaration_type", "days"], {
        is: (declaration_type, days) => {
          return (
            ["Comparecimento", "Acompanhamento"].includes(declaration_type) ||
            (declaration_type === "Atestado" && days <= 0)
          );
        },
        then: Yup.date().typeError("Horário inválido").required("É requerido").nullable(),
        otherwise: Yup.date().typeError("Horário inválido").nullable(),
      }),
      departure_time: Yup.date().when(["declaration_type", "days"], {
        is: (declaration_type, days) => {
          return (
            ["Comparecimento", "Acompanhamento"].includes(declaration_type) ||
            (declaration_type === "Atestado" && days <= 0)
          );
        },
        then: Yup.date()
          .typeError("Horário inválido")
          .required("É requerido")
          .nullable()
          .when("arrival_time", (arrival_time, schema) => {
            return schema.test({
              test: (departure_time) => !!arrival_time && departure_time > arrival_time,
              message: "Escolha um horário final posterior ao horário inicial",
            });
          }),
        otherwise: Yup.date().typeError("Horário inválido").nullable(),
      }),
      days: Yup.string().when(["arrival_time", "departure_time", "declaration_type"], {
        is: (arrival_time, departure_time, declaration_type) => {
          return declaration_type === "Atestado" && (!arrival_time || !departure_time);
        },
        then: (schema) =>
          schema
            .min(1, "A quantidade deve ser maior que 0")
            .required("É requerido")
            .typeError("O valor do campo deve ser um número"),
      }),
      cid: Yup.object().nullable(),
      companion_name: Yup.string().when("declaration_type", {
        is: "Acompanhamento",
        then: (schema) => schema.required("É requerido"),
      }),
      observations: Yup.string().when("declaration_type", {
        is: (declaration_type) => ["Relatorio", "Laudo"].includes(declaration_type),
        then: (schema) => schema.required("É requerido"),
      }),
      ctps: Yup.string(),
      ctps_series: Yup.string(),
      weeks_of_pregnancy: Yup.string().when("declaration_type", {
        is: "AtestadoMaternidade",
        then: (schema) => schema.required("É requerido"),
      }),
      maternity_leave: Yup.string().when("declaration_type", {
        is: "AtestadoMaternidade",
        then: (schema) => schema.required("É requerido"),
      }),
      maternity_license_date: Yup.date()
        .nullable()
        .when("declaration_type", {
          is: "AtestadoMaternidade",
          then: (schema) => schema.required("É Requerido").typeError("Data Inválida"),
        }),
    },
    [
      ["arrival_time", "days"],
      ["departure_time", "days"],
      ["departure_time", "arrival_time"],
      ["days", "arrival_time"],
      ["days", "departure_time"],
    ]
  );

  const { handleSubmit, control, reset, watch } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const [selectedDeclarationType, arrivalTime, departureTime, days] = watch([
    "declaration_type",
    "arrival_time",
    "departure_time",
    "days",
  ]);

  const currentDeclarationQuery = useQuery(
    ["current-declarations"],
    () => {
      const params = {
        page: 0,
        limit: 75,
        created_from_soap: !isExternal,
        attendance_id: attendance.id,
      };

      if (soapToEdit) {
        params.pendency_type = "soap_complement";
      } else if (!isExternal) {
        params.pendency_type = "soap";
      }

      const date = new Date();

      if (userData.company.type.TWENTY_FOUR_HOURS) {
        params.initial_date = formatISO(subHours(date, 24));
        params.final_date = formatISO(date);
      } else {
        params.initial_date = formatISO(startOfDay(date));
        params.final_date = formatISO(endOfDay(date));
      }

      return getDeclarations(attendance.patient.id, params);
    },
    {
      enabled: attendance.has_soap || !isExternal,
      onSuccess(response) {
        setDeclarations(response.items);
      },
    }
  );

  const declarationTypes = {
    Atestado: {
      label: odonto || ceo ? "Atestado" : "Atestado Médico",
      descriptions: [
        {
          labelFormatter: (data) => {
            return data.days ? "Dias de atestado" : "Hora de chegada";
          },
          valueFormatter: (data) => (data.days ? data.days : data.arrival_time),
        },
        {
          label: "Hora de saída",
          valueKey: "departure_time",
          visible: (data) => !data.days,
        },
        {
          label: "CID 10",
          valueKey: "cid_id",
          visible: (data) => data.cid_id,
        },
        {
          label: "Observação",
          valueKey: "observations",
        },
      ],
      handler: (data) => {
        const declaration = {
          cid_id: data.cid?.id || data.cid_id,
          observations: data.observations,
        };

        if (!!data.days) {
          declaration.days = data.days;
        } else {
          declaration.arrival_time = isValid(data.arrival_time)
            ? format(data.arrival_time, "HH:mm")
            : data.arrival_time;
          declaration.departure_time = isValid(data.departure_time)
            ? format(data.departure_time, "HH:mm")
            : data.departure_time;
        }

        const pdf = medicalStatementPDF(declaration, attendance.patient);
        return { pdf, ...declaration };
      },
    },
    Comparecimento: {
      label: "Declaração de Comparecimento",
      descriptions: [
        {
          label: "Hora de chegada",
          valueKey: "arrival_time",
        },
        {
          label: "Hora de saída",
          valueKey: "departure_time",
        },
        {
          label: "Observação",
          valueKey: "observations",
        },
      ],
      handler: (data) => {
        const declaration = {
          arrival_time: isValid(data.arrival_time)
            ? format(data.arrival_time, "HH:mm")
            : data.arrival_time,
          departure_time: isValid(data.departure_time)
            ? format(data.departure_time, "HH:mm")
            : data.departure_time,
          observations: data.observations,
        };

        const pdf = attendanceStatementPDF(declaration, attendance.patient);

        return { pdf, ...declaration };
      },
    },
    Acompanhamento: {
      label: "Declaração de Acompanhante",
      descriptions: [
        {
          label: "Hora de chegada",
          valueKey: "arrival_time",
        },
        {
          label: "Hora de saída",
          valueKey: "departure_time",
        },
        {
          label: "Acompanhante",
          valueKey: "companion_name",
        },
        {
          label: "Observação",
          valueKey: "observations",
        },
      ],
      handler: (data) => {
        const declaration = {
          arrival_time: isValid(data.arrival_time)
            ? format(data.arrival_time, "HH:mm")
            : data.arrival_time,
          departure_time: isValid(data.departure_time)
            ? format(data.departure_time, "HH:mm")
            : data.departure_time,
          companion_name: data.companion_name,
          observations: data.observations,
        };

        const pdf = followUpStatementPDF(declaration, attendance.patient);

        return { pdf, ...declaration };
      },
    },
    Relatorio: {
      label: "Relatório Médico",
      descriptions: [
        {
          label: "Observação",
          valueKey: "observations",
        },
      ],
      handler: (data) => {
        const declaration = { observations: data.observations };

        const pdf = medicalReportPDF(declaration, attendance.patient);

        return { pdf, ...declaration };
      },
    },
    Laudo: {
      label: "Laudo Médico",
      descriptions: [
        {
          label: "CID 10",
          valueKey: "cid_id",
        },
        {
          label: "Observação",
          valueKey: "observations",
        },
      ],
      handler: (data) => {
        const declaration = {
          observations: data.observations,
          cid_id: data.cid?.id || data.cid_id,
        };

        const pdf = medicalOpinionPDF(declaration, attendance.patient);
        return { pdf, ...declaration };
      },
    },
  };

  if (isPregnancy) {
    declarationTypes["AtestadoMaternidade"] = {
      label: "Licença Maternidade",
      descriptions: [
        {
          label: "Número da carteiro profissional",
          valueKey: "ctps",
          grid: 12,
          visible: (data) => !!data.ctps,
        },
        {
          label: "Número Carteira Serie",
          valueKey: "ctps_series",
          grid: 12,
          visible: (data) => !!data.ctps_series,
        },
        {
          label: "Tempo de Gestação",
          valueKey: "weeks_of_pregnancy",
          grid: 12,
        },
        {
          label: "Duração da Licença",
          valueKey: "maternity_leave",
          grid: 12,
        },
        {
          label: "Início da lincença",
          valueFormatter: (data) => format(parseISO(data.maternity_license_date), "dd/MM/yyyy"),
          grid: 12,
        },
        {
          label: "Observação",
          valueKey: "observations",
          grid: 12,
        },
      ],
      handler: (data) => {
        const declaration = {
          ctps: data.ctps,
          ctps_series: data.ctps_series,
          weeks_of_pregnancy: data.weeks_of_pregnancy,
          maternity_leave: data.maternity_leave,
          maternity_license_date: format(data.maternity_license_date, "yyyy-MM-dd"),
          observations: data.observations,
        };

        const pdf = maternityLeavePDF(declaration, attendance.patient);

        return { pdf, ...declaration };
      },
    };
  }

  const declarationOptions = Object.entries(declarationTypes).map(([key, value]) => ({
    label: value.label,
    value: key,
  }));

  const handleAddDeclaration = handleSubmit(async (values) => {
    const declarationType = declarationTypes[values.declaration_type];
    const { pdf, ...newDeclaration } = declarationType.handler(values);

    newDeclaration.type = values.declaration_type;
    newDeclaration.created_from_soap = !isExternal;
    newDeclaration.id = pdf.name.replace(".pdf", "");
    newDeclaration.original_file_name = pdf.name;
    newDeclaration.data = await pdfToBase64(pdf);
    newDeclaration.signature_settings = {
      visible_sign_page: "*",
      visible_sign_x: 170,
      visible_sign_y: 595,
    };

    setDeclarations((declarations) => [...declarations, newDeclaration]);
    reset({ declaration_type: values.declaration_type }, { keepDefaultValues: true });
  });

  function handleRemoveDeclaration(key) {
    const newDeclarations = declarations.filter((_, index) => key !== index);
    setDeclarations(newDeclarations);
  }

  function handlePrint(data) {
    if (data.signed_document.result) {
      return openURL(data.signed_document.result);
    }

    const { pdf } = declarationTypes[data.type].handler(data);
    pdf.open();
  }

  function verifyPendencies() {
    if (isExternal) return;

    const hasPendencies = queryClient.getQueryData("verify-has-pendencies");

    if (!hasPendencies) {
      queryClient.invalidateQueries("verify-has-pendencies");
    }
  }

  async function onSubmit() {
    await validateSignature(() => {
      const formattedDeclarations = [];

      declarations.forEach((declaration) => {
        if (!declaration.signed_document) {
          formattedDeclarations.push(declaration);
        }
      });

      const data = {
        attendance_id: attendance.id,
        id_soap: soapToEdit ? soapToEdit.id : null,
        documents: formattedDeclarations,
      };

      postDeclarationsMutation.mutate(data, {
        onSuccess(response) {
          verifyPendencies();
          currentDeclarationQuery.refetch();
          notify(response.message, "success");
        },
        onError(error) {
          notify(error.message, "error");
        },
      });
    });
  }

  function verifyDisabledSave() {
    const hasUnSavedDeclarations = declarations.some((declaration) => !declaration.signed_document);
    return disabledInternalFields || !hasUnSavedDeclarations;
  }

  const attendanceStatementDeclarations = [];

  declarations.forEach((declaration, index) => {
    if (declaration.type === selectedDeclarationType) {
      attendanceStatementDeclarations.push({ ...declaration, key: index });
    }
  });

  const numberMask = Array.from({ length: 7 }).fill(/\d/);

  return (
    <>
      <Box component="form">
        <Grid container spacing={2} marginTop={1} alignItems="center">
          <Grid container item xs={12} marginBottom={2} spacing={2}>
            <Grid item xs={4}>
              <SelectField
                control={control}
                label="Tipo de Declaração"
                name="declaration_type"
                options={declarationOptions}
                optionLabelKey="label"
                optionValueKey="value"
                onChange={(value) => {
                  if (["Comparecimento", "Acompanhamento"].includes(value)) {
                    reset(
                      { declaration_type: value, arrival_time: parseISO(attendance.createdAt) },
                      { keepDefaultValues: true }
                    );
                  } else {
                    reset({ declaration_type: value }, { keepDefaultValues: true });
                  }
                }}
                disabled={disabledInternalFields}
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </Grid>
          {["Atestado", "Acompanhamento", "Comparecimento"].includes(selectedDeclarationType) && (
            <>
              {" "}
              <Grid item xs={4}>
                <DateField
                  control={control}
                  name="arrival_time"
                  label="Horário de Chegada"
                  type="time"
                  required={!days}
                  disabled={disabledInternalFields}
                />
              </Grid>
              <Grid item xs={4}>
                <DateField
                  control={control}
                  name="departure_time"
                  label="Horário de Saída"
                  type="time"
                  required={!days}
                  disabled={disabledInternalFields}
                />
              </Grid>
            </>
          )}
          {selectedDeclarationType === "Atestado" && (
            <>
              <Grid item xs={1}>
                <Typography>ou</Typography>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  type="number"
                  control={control}
                  name="days"
                  label="Dias"
                  required={!arrivalTime && !departureTime}
                  disabled={!!arrivalTime || !!departureTime || disabledInternalFields}
                />
              </Grid>
            </>
          )}
          {["Atestado", "Laudo"].includes(selectedDeclarationType) && (
            <Grid item xs={12}>
              <PaginatedAutocompleteField
                control={control}
                label="CID"
                name="cid"
                service={(params) => getCids({ ...params, patient_id: attendance.patient.id })}
                autoCompleteProps={{
                  getOptionLabel: (option) => `${option.id ? option.id : ""} ${option.description}`,
                }}
                optionCompareKey="id"
                filterKey="fieldValue"
                queryKey="cids"
                disabled={disabledInternalFields}
              />
            </Grid>
          )}
          {selectedDeclarationType === "Acompanhamento" && (
            <Grid item xs={4}>
              <TextField
                control={control}
                name="companion_name"
                type="text"
                label="Nome do Acompanhante"
                required
              />
            </Grid>
          )}
          {selectedDeclarationType === "AtestadoMaternidade" && (
            <>
              <Grid item xs={8}>
                <TextField
                  control={control}
                  name="ctps"
                  type="number"
                  label="Número da Carteira de Trabalho"
                  mask={numberMask}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  control={control}
                  name="ctps_series"
                  type="number"
                  label="Série da Carteira de Trabalho"
                  mask={numberMask.slice(0, 4)}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  control={control}
                  name="weeks_of_pregnancy"
                  type="number"
                  label="Tempo de Gestação"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  control={control}
                  name="maternity_leave"
                  type="number"
                  label="Duração da Licença"
                  required
                />
              </Grid>
              <Grid item xs={4}>
                <DateField
                  control={control}
                  name="maternity_license_date"
                  label="Início da Licença"
                  required
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              control={control}
              name="observations"
              type="text"
              label="Observação"
              multiline
              minRows={4}
              maxRows={4}
              required
              disabled={disabledInternalFields}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end" margin="1.2rem auto">
            <Button
              variant="text"
              onClick={handleAddDeclaration}
              startIcon={<AddCircleIcon />}
              disabled={disabledInternalFields}
            >
              Adicionar declaração
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        {attendanceStatementDeclarations?.map((data) => (
          <DeclarationCard
            key={`${data.type}-${data.key}`}
            data={data}
            descriptions={declarationTypes[selectedDeclarationType].descriptions}
            handleRemove={() => handleRemoveDeclaration(data.key)}
            handlePrint={(data) => handlePrint(data)}
          />
        ))}
      </Grid>
      <Box display="flex" justifyContent="center" margin="1.5rem auto">
        <Button
          loadingMessage={currentDeclarationQuery.isFetching ? "Carregando..." : "Enviando..."}
          loading={postDeclarationsMutation.isLoading || currentDeclarationQuery.isFetching}
          disabled={verifyDisabledSave()}
          onClick={onSubmit}
        >
          Salvar
        </Button>
      </Box>
    </>
  );
}
