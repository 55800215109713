import { headerHorizontalStyle, footer, title, patientInformations } from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";

function medicalReportPDF(data, patient) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const description = {
    alignment: "center",
    text: [{ text: `\n${data.observations}`, bold: true }, { text: "." }],
  };

  const urlType = pdfName + "/types/declaration";

  let employee;

  if (data.soap?.employee_specialty) {
    employee = {
      ...data.soap.employee_specialty.employee,
      specialty: data.soap.employee_specialty.specialty,
    };
  }

  const definitions = {
    info: { title: pdfName },
    pageMargins: [40, 105, 40, 120],
    defaultStyle: {
      fontSize: 10,
    },
    ...headerHorizontalStyle(data?.attendance?.company),
    footer: footer(data.createdAt, true, employee, data?.attendance?.company, urlType),
    content: [title("RELATÓRIO MÉDICO"), patientInformations(patient), description],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;

  return documentPDF;
}

export default medicalReportPDF;
