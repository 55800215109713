import { useContext } from "react";
import {
  SHOW_SIGNATURE_DIALOG,
  SignaturePasswordContext,
} from "../contexts/SignaturePasswordContext";
import Cookie from "js-cookie";
import { useAuth } from "../service";
import { useMutation } from "react-query";
import { isBefore } from "date-fns";

function useSignaturePassword(handleLoading = () => null) {
  const [, dispatch] = useContext(SignaturePasswordContext);
  const signerAuthToken = Cookie.get("signer-auth-token");
  const { signatureTokenIsValid } = useAuth();
  const validateMutation = useMutation(signatureTokenIsValid);
  const delaySignUntil = localStorage.getItem("delay-sign-until");
  const actualDate = new Date();
  function open() {
    dispatch({ type: SHOW_SIGNATURE_DIALOG });
  }

  async function signatureStatus(onSuccess, force = false, onError) {
    handleLoading(true);
    if (isBefore(actualDate, parseInt(delaySignUntil))) {
      handleLoading(false);
      if (onSuccess instanceof Function) onSuccess();
    } else if (!!signerAuthToken) {
      validateMutation.mutate(undefined, {
        onSuccess: () => {
          handleLoading(false);
          if (onSuccess instanceof Function) onSuccess();
        },
        onError: (error) => {
          Cookie.remove("signer-auth-token");
          if (onError instanceof Function) onError(error);
          handleLoading(false);
          open();
        },
      });
    } else {
      const error = new Error("Token de assinatura não identificado.");
      if (onError instanceof Function) onError(error);
      handleLoading(false);
      open();
    }

    if (force) {
      handleLoading(false);
      open();
    }
  }

  return signatureStatus;
}

export default useSignaturePassword;
