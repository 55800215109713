import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { TextField } from "../../../components/FormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import { useNotifier } from "../../../hooks";
import { useMutation } from "react-query";
import { useSOAP } from "../../../service";

export default function SoapSuspendForm({ soapId, handleClose }) {
  const notify = useNotifier();

  const { patchSoap } = useSOAP();

  const patchSoapMutation = useMutation(patchSoap);

  const form = useForm({
    defaultValues: {
      cancellation_notes: "",
    },
    resolver: yupResolver(
      Yup.object().shape({
        cancellation_notes: Yup.string().required("Informe o motivo da suspensão."),
      })
    ),
  });

  const suspend = form.handleSubmit((values) => {
    patchSoapMutation.mutate(
      { id: soapId, data: values },
      {
        onSuccess() {
          notify("Soap suspenso com sucesso", "success");
          handleClose();
        },
        onError(data) {
          notify(data.message, "error");
        },
      }
    );
  });

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <TextField
          control={form.control}
          name={"cancellation_notes"}
          label={"Motivo da suspensão"}
          multiline
          minRows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={() => suspend()}>
          Suspender
        </Button>
      </Grid>
    </Grid>
  );
}
