import { Box, Grid, TablePagination, Tooltip, Typography } from "@mui/material";
import {
  TextField,
  DateField,
  SwitchField,
  PaginatedAutocompleteField,
} from "../../components/FormFields";
import { FormProvider, useForm } from "react-hook-form";
import { BoxContainer, PatientsCard } from "../../components";
import TableFilter from "../../components/Table/TableFilter";
import { PaginatedScrollList } from "../../components/PaginatedScrollList/index";
import { prioritiesIcon } from "../../config/config";
import { useContext, useEffect, useState } from "react";
import limitName from "../../utils/limitName";
import { format, parseISO } from "date-fns";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { ConfirmDialog, Dialog } from "../../helper";
import PatientServicesDialog from "../../components/PatientServicesDialog";
import { useLocation } from "react-router-dom";
import { AppContext } from "../../contexts/AppContext";
import { useCallSystem, useDebounceCallback, useLocalStorage } from "../../hooks";
import useNotifier from "../../hooks/useNotifier";
import { useAttendaces } from "../../service";
import { mountFilters } from "../../helper/mountInicialAttendanceFilters";
import TransferForm from "./TransferForm";
import useInitialAttendance from "../../service/useInitialAttendance";
import useService from "../../service/useServices";
// import CollectiveActivityForm from "./CollectiveActivityForm";
import { verifyTwentyFourHoursCompany } from "../../utils";

function InitialAttendance() {
  const { setEmployeeData, setPatientData, userData } = useContext(AppContext);
  const [openPatientServices, setOpenPatientServices] = useState(false);
  const handleCallSystem = useCallSystem("attendance");
  const location = useLocation();
  const notify = useNotifier();
  const [attendanceToTranferId, setAttendanceToTranferId] = useState(false);
  const [attendanceToFinishId, setAttendanceToFinishId] = useState(false);
  // const [attendancesToCollectiveActivity, setAttendancesToCollectiveActivity] = useState([]);
  // const [dialogCollectiveActivity, setDialogCollectiveActivity] = useState(false);
  const [initialAttendanceFilters, setInitialAttendanceFilters] = useLocalStorage(
    "initial-attendance-filters",
    {
      patient: undefined,
      employee: undefined,
      sector: undefined,
      employeeAndSector: undefined,
      token: undefined,
      services: [],
      notScheduled: undefined,
      regulated: undefined,
      finished: undefined,
      in_reevaluation: undefined,
      slimMode: undefined,
    }
  );
  const [totalItems, setTotalItems] = useState(0);
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(6);
  const [listData, setListData] = useState([]);
  const [filters, setFilters] = useState(
    mountFilters({ date: new Date(), ...initialAttendanceFilters })
  );
  // const [collectiveActivityValues, setCollectiveActivityValues] = useState(null);

  const debounceFilter = useDebounceCallback(({ date, ...values }) => {
    const newFilters = mountFilters({ date, ...values });

    setInitialAttendanceFilters(values);
    setFilters(newFilters);
    setPage(0);
  }, 300);

  const form = useForm({
    defaultValues: {
      ...initialAttendanceFilters,
      date: new Date(),
    },
  });

  const [notScheduledField, slimModeField] = form.watch(["notScheduled", "slimMode"]);
  const tableColumns = [
    {
      name: "Cod. do paciente",
      field: "patient?.number",
      use: (value) => value || "Não informado",
      type: "text",
    },
    {
      name: "Senha",
      field: "token_audit?.token_describe",
      type: "text",
    },
    {
      name: "Tipo de Agendamento",
      field: "scheduling",
      type: "text",
      use: (scheduling) => {
        if (scheduling?.regulated) {
          return "Agendamento Regulado";
        }

        if (scheduling) {
          return "Agendamento Local";
        }

        return "Não agendado";
      },
    },
    {
      name: "Prioridade",
      field: "token_audit?.priority?.describe",
      type: "text",
      use: (priority) => {
        return (
          <Tooltip
            title={priority}
            sx={{
              mx: "auto",
            }}
          >
            <Box>{prioritiesIcon[priority?.toLowerCase()]}</Box>
          </Tooltip>
        );
      },
    },
    {
      name: "Nome",
      field: "patient?.name",
      use: (value, row) =>
        limitName(
          row.patient?.social_prioritize ? row.patient.social_name : value,
          3,
          "Não informado"
        ),
      type: "text",
    },
    {
      name: "Data de Nascimento",
      field: "patient?.birth_date",
      use: (date) => (date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado"),
      type: "date",
    },
    {
      name: "Recepção",
      field: "token_audit?.createdAt",
      use: (date) => (date ? format(parseISO(date), "HH:mm") : "-"),
      type: "date",
    },
    {
      name: "Classificação",
      field: "classification",
      use: (classification) => {
        const classificationColors = {
          Vermelho: "#ef4444",
          Verde: "#22c55e",
          Azul: "#3b82f6",
          Amarelo: "#facc15",
          null: "#d1d5db",
        };

        return (
          <Box
            sx={{
              width: 24,
              height: 24,
              mx: "auto",
              backgroundColor: classificationColors[classification],
              borderRadius: "100%",
            }}
          />
        );
      },
      type: "text",
    },
    {
      name: "Profissional",
      field: "employee?.name",
      use: (value) => limitName(value, 3, "Não informado"),
      type: "text",
    },
  ];

  const queryClient = useQueryClient();
  const { getAttendances, finishAttendance, getAttendanceById, verifyHasPendencies } =
    useAttendaces();
  const { getSectorsAndEmployees } = useInitialAttendance();
  const { getServices } = useService();
  const finishAttendanceMutation = useMutation(finishAttendance);

  const attendanceQuery = useQuery(
    ["attendanceQuery", page, limit, filters],
    () =>
      getAttendances({
        page,
        limit,
        ...filters,
      }),
    {
      refetchOnWindowFocus: false,
      onSuccess({ items, totalItems }) {
        if (items) {
          setListData((query) => (page === 0 ? items : [...query, ...items]));
        }
        setTotalItems(totalItems);
      },
      onError(error) {
        notify(error.message, "error");
        setListData([]);
      },
    }
  );

  const pendenciesQuery = useQuery("verify-has-pendencies", () => verifyHasPendencies(), {
    onError() {
      queryClient.setQueryData("verify-has-pendencies", null);
    },
  });

  function handleFinishAttendance(id) {
    finishAttendanceMutation.mutate(id, {
      onSuccess: (response) => {
        notify(response.message, "success");
        if (page === 0) {
          attendanceQuery.refetch();
        }
        setPage(0);
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    });
  }

  useEffect(() => {
    (async () => {
      if (location.state?.menuServiceOpen) {
        try {
          const attendance = await getAttendanceById(location.state?.use);
          setOpenPatientServices({ isOpen: true, patient: attendance });
        } catch (err) {
          console.error(err);
        }
      }
    })();
  }, []);

  form.watch(debounceFilter);

  function isOldAttendance(attendance) {
    const isOld = verifyTwentyFourHoursCompany(attendance.createdAt);

    if (userData?.company.type.TWENTY_FOUR_HOURS) {
      return isOld && !attendance.id_scheduling;
    }

    return isOld;
  }

  return (
    <>
      <ConfirmDialog
        message={"Você realmente deseja *finalizar* esse atendimento ?"}
        hideBackDrop={false}
        open={!!attendanceToFinishId}
        maxWidth="sm"
        fullWidth={true}
        handleClose={() => setAttendanceToFinishId(false)}
        actions
        handleConfirm={() => handleFinishAttendance(attendanceToFinishId)}
      />
      <PatientServicesDialog
        title={"Atendimento Inicial"}
        pendencie={pendenciesQuery.data}
        handleClose={() => setOpenPatientServices(true)}
        open={openPatientServices}
      />
      <Dialog
        open={!!attendanceToTranferId}
        title="Transferência de atendimento"
        maxWidth="md"
        fullWidth={true}
        fullScreen={false}
        handleClose={() => setAttendanceToTranferId(false)}
      >
        <TransferForm
          attendanceId={attendanceToTranferId}
          handleClose={() => setAttendanceToTranferId(false)}
          refetch={() => {
            if (page === 0) {
              attendanceQuery.refetch();
            }
            setPage(0);
          }}
        />
      </Dialog>
      {/* <Dialog
        open={!!dialogCollectiveActivity}
        title="Atendimento em Grupo"
        handleClose={() => setDialogCollectiveActivity(false)}
      >
        <CollectiveActivityForm
          attendances={attendancesToCollectiveActivity}
          setAttendances={setAttendancesToCollectiveActivity}
          collectiveActivityValues={collectiveActivityValues}
          setCollectiveActivityValues={setCollectiveActivityValues}
          handleClose={() => {
            setDialogCollectiveActivity(false);
            setAttendancesToCollectiveActivity([]);
          }}
        />
      </Dialog> */}
      <BoxContainer title={"Atendimento Inicial"}>
        <FormProvider {...form}>
          <Grid container item xs={12}>
            <Grid xs={9} item display={"flex"} flexWrap={"wrap"} flexDirection={"row"} rowGap={2}>
              <Grid container xs={12} item spacing={2}>
                <Grid xs={6} item>
                  <TextField
                    name={"patient"}
                    label={"Buscar Paciente:"}
                    placeholder={"CPF, CNS ou Nome do paciente."}
                  />
                </Grid>
                <Grid xs={6} item>
                  <PaginatedAutocompleteField
                    name={"employeeOrSector"}
                    label={"Profissional / Setor :"}
                    service={getSectorsAndEmployees}
                    filterKey={"name"}
                    queryKey={"employeeAndSector"}
                    autoCompleteProps={{
                      getOptionLabel: (data) => data.name || data.describe,
                      groupBy: (option) => (option.name ? "Profissional" : "Setor"),
                    }}
                  />
                </Grid>
                <Grid xs={6} item>
                  <TextField
                    name={"token_describe"}
                    label={"Senha do totem:"}
                    placeholder={"Digite a senha."}
                  />
                </Grid>
                <Grid xs={6} item>
                  <PaginatedAutocompleteField
                    multiple
                    service={(params) => getServices({ ...params, active: true })}
                    queryKey={"servicesQuery"}
                    filterKey={"describe"}
                    optionLabelKey="describe"
                    name={"services"}
                    label={"Selecione um serviço"}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} display={"flex"} flexDirection={"row"}>
                <Grid item xs={2.5}>
                  <SwitchField
                    name={"notScheduled"}
                    label={"Filtrar Atend. Não Agendados"}
                    customOnChange={() => {
                      form.setValue("regulated", undefined);
                    }}
                  />
                </Grid>
                {!notScheduledField ? (
                  <Grid item xs={2.5}>
                    <SwitchField name={"regulated"} label={"Agendamentos regulados"} />
                  </Grid>
                ) : null}
                <Grid item xs={2.5}>
                  <SwitchField name={"finished"} label={"Filtrar Atend. Não Finalizados"} />
                </Grid>
                <Grid item xs={2.8}>
                  <SwitchField name={"in_reevaluation"} label={"Filtrar Atend. para Reavaliação"} />
                </Grid>
                <Grid item xs={2.2}>
                  <SwitchField
                    customOnChange={() => {
                      setPage(0);
                    }}
                    name={"slimMode"}
                    label={"Modo Slim"}
                  />
                </Grid>
                {/* <Grid item xs={2}>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setDialogCollectiveActivity(true)}
                    disabled={!attendancesToCollectiveActivity.length}
                  >
                    Grupo
                  </Button>
                </Grid> */}
              </Grid>
              <Grid item xs={12}>
                {slimModeField ? (
                  <>
                    <TableFilter
                      data={attendanceQuery.data?.items || []}
                      columns={tableColumns}
                      loading={attendanceQuery.isLoading}
                      noFilter
                      emptyMessage="Nenhum atendimento encontrado."
                      actions
                      actionsTypes={["view", "finish", "call", "transfer"]}
                      callsCountField={"token_audit.calls_count"}
                      disableActions={(row, action) => {
                        if (isOldAttendance(row)) return true;

                        if (!row.finished && ["finish", "call"].includes(action)) {
                          return false;
                        }

                        if (
                          (!row?.id_scheduling ||
                            row?.service.describe === "Acolhimento" ||
                            !!row?.risk_classification_id) &&
                          action === "finish"
                        ) {
                          return false;
                        }

                        if (row.has_soap && !row.id_scheduling && action === "transfer") {
                          return false;
                        }

                        if (action === "view") return false;

                        return true;
                      }}
                      confirmMessage="Você realmente deseja *action* esse atendimento?"
                      actionHandleView={(_, attendance) => {
                        setEmployeeData(attendance?.employee);
                        setPatientData(attendance?.patient);
                        setOpenPatientServices({
                          isOpen: true,
                          patient: attendance,
                        });
                      }}
                      actionHandleCall={(_, attendance) => {
                        handleCallSystem(attendance.token_audit.id);
                      }}
                      actionHandleTransfer={(_, attendance) => {
                        setAttendanceToTranferId(attendance.id);
                      }}
                      actionHandleFinish={(_, attendance) => {
                        handleFinishAttendance(attendance.id);
                      }}
                    />
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <TablePagination
                        count={attendanceQuery.data?.totalItems || 0}
                        component="div"
                        page={page}
                        onPageChange={(_, newPage) => {
                          setPage(newPage);
                        }}
                        rowsPerPage={limit}
                        rowsPerPageOptions={[5, 10, 20, 40, 75]}
                        onRowsPerPageChange={(event) => {
                          setLimit(parseInt(event.target.value, 10));
                          setPage(0);
                        }}
                      />
                    </Box>
                  </>
                ) : (
                  <PaginatedScrollList
                    component={(item, index) => (
                      <PatientsCard
                        patient={item}
                        key={index}
                        noTextSelection
                        pendencie={pendenciesQuery.data}
                        old={isOldAttendance(item)}
                        // isCollectiveActivity={() =>
                        //   attendancesToCollectiveActivity?.some(
                        //     (attendance) => attendance?.id === item?.id
                        //   )
                        // }
                        handleClick={handleCallSystem}
                        handleFinish={(id) => {
                          setAttendanceToFinishId(id);
                        }}
                        handleTransferAttendance={(id) => {
                          setAttendanceToTranferId(id);
                        }}
                        // handleCollectiveActivity={(patient, isActive) => {
                        //   setAttendancesToCollectiveActivity((previousPatients) => {
                        //     if (isActive()) {
                        //       const newAttendances = previousPatients?.filter(
                        //         (previous) => previous?.id !== patient?.id
                        //       );

                        //       if (!newAttendances.length) {
                        //         setCollectiveActivityValues(null);
                        //       }

                        //       return newAttendances;
                        //     }

                        //     return [...previousPatients, patient];
                        //   });
                        // }}
                      />
                    )}
                    handlePage={setPage}
                    loading={attendanceQuery.isLoading || attendanceQuery.isRefetching}
                    page={page}
                    totalPage={attendanceQuery.data?.total || 0}
                    data={listData}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item xs={3}>
              <DateField
                type="static-date"
                name={"date"}
                customOnChange={() => {
                  setPage(0);
                }}
                maxDate={new Date()}
                fullWidth
              />
            </Grid>

            {!initialAttendanceFilters.slim_mode && (
              <Grid item xs={9} display={"flex"} justifyContent={"flex-end"}>
                {totalItems > 0 ? (
                  <Typography color="primary.main" marginTop={"15px"}>
                    Quantidades de Atendimentos : {totalItems}
                  </Typography>
                ) : (
                  <Typography color="primary.main">Não há Atendimentos</Typography>
                )}
              </Grid>
            )}
          </Grid>
        </FormProvider>
      </BoxContainer>
    </>
  );
}

export default InitialAttendance;
