const states = [
  { describe: "Solteiro(A)" },
  { describe: "Casado(A)" },
  { describe: "Separado(A)" },
  { describe: "Divorciado(A)" },
  { describe: "Viúvo(A)" },
  { describe: "União Estável" },
];

const classificationColors = {
  Vermelho: "#ef4444",
  Verde: "#22c55e",
  Azul: "#3b82f6",
  Amarelo: "#facc15",
  null: "#d1d5db",
};

const drugAdmininstrations = [
  {
    procedure_id: 1170,
    tuss_code: "030110021",
    describe: "VO",
    title: "VIA ORAL",
  },
  {
    procedure_id: 5953,
    tuss_code: null,
    describe: "SL",
    title: "SUBLINGUAL",
  },
  {
    procedure_id: 5954,
    tuss_code: null,
    describe: "IV",
    title: "INTRAVENOSA",
  },
  {
    procedure_id: 1169,
    tuss_code: "030110020",
    describe: "IM",
    title: "INTRAMUSCULAR",
  },
  {
    procedure_id: 5955,
    tuss_code: null,
    describe: "VN",
    title: "VIA NASAL",
  },
  {
    procedure_id: 5956,
    tuss_code: null,
    describe: "OC",
    title: "VIA OCULAR",
  },
  {
    procedure_id: 5957,
    tuss_code: null,
    describe: "VA",
    title: "VIA RETAL",
  },
  {
    procedure_id: 1171,
    tuss_code: "030110022",
    describe: "SC",
    title: "SUBCUTANEA",
  },
  {
    procedure_id: 5958,
    tuss_code: null,
    describe: "UT",
    title: "TOPICO",
  },
  {
    procedure_id: 5959,
    tuss_code: null,
    describe: "VI",
    title: "VIA INALATORIA",
  },
  {
    procedure_id: 5960,
    tuss_code: null,
    describe: "VV",
    title: "VIA VAGINAL",
  },
  {
    procedure_id: 2545,
    tuss_code: "0301100233",
    describe: "VT",
    title: "VIA TÓPICA",
  },
  {
    procedure_id: 7570,
    tuss_code: null,
    describe: "VG",
    title: "VIA OTOLÓGICA",
  },
];

const bloodTypes = [
  { describe: "A+" },
  { describe: "A-" },
  { describe: "B+" },
  { describe: "B-" },
  { describe: "AB+" },
  { describe: "AB-" },
  { describe: "O+" },
  { describe: "O-" },
];

const kinships = [
  { describe: "O Mesmo" },
  { describe: "Mãe" },
  { describe: "Pai" },
  { describe: "Irmã" },
  { describe: "Irmão" },
  { describe: "Esposo(a)" },
  { describe: "Filho(a)" },
  { describe: "Enteado(a)" },
  { describe: "Tio(a)" },
  { describe: "Avô(ó)" },
  { describe: "Neto(a)" },
  { describe: "Sobrinho(a)" },
  { describe: "Sogro(a)" },
  { describe: "Namorado(a)" },
  { describe: "Companheiro(a)" },
  { describe: "Amigo(a)" },
  { describe: "Vizinho(a)" },
  { describe: "Funcionario(a)" },
  { describe: "SAMU / Resgate" },
  { describe: "Policia" },
  { describe: "Ambulancia" },
  { describe: "Outros" },
];

const kinship_sus_codes = [
  {
    describe: "Cônjuge/Companheiro(a)",
  },
  {
    describe: "Filho(a)",
  },
  {
    describe: "Enteado(a)",
  },
  {
    describe: "Neto(a)",
  },
  {
    describe: "Bisneto(a)",
  },
  {
    describe: "Pai",
  },
  {
    describe: "Mãe",
  },
  {
    describe: "Sogro(a)",
  },
  {
    describe: "Irmão/Irmã",
  },
  {
    describe: "Genro/Nora",
  },
  {
    describe: "Outro parente",
  },
  {
    describe: "Avô",
  },
  {
    describe: "Avó",
  },
  {
    describe: "Tio(a)",
  },
  {
    describe: "Não parente",
  },
];
const organDonor = [{ describe: "Sim" }, { describe: "Não" }];
const race = [
  { describe: "Amarela" },
  { describe: "Branca" },
  { describe: "Indígena" },
  { describe: "Outros" },
  { describe: "Parda" },
  { describe: "Preta" },
  { describe: "Sem informação" },
];
const genders = [{ describe: "Masculino" }, { describe: "Feminino" }, { describe: "Indefinido" }];
const countries = [
  {
    describe: "Brasileiro",
  },
  {
    describe: "Estrangeiro",
  },
  {
    describe: "Naturalizado",
  },
];
const active = {
  true: "Sim",
  false: "Não",
};
const days = [
  { describe: "Domingo", value: 0 },
  { describe: "Segunda", value: 1 },
  { describe: "Terça", value: 2 },
  { describe: "Quarta", value: 3 },
  { describe: "Quinta", value: 4 },
  { describe: "Sexta", value: 5 },
  { describe: "Sabado", value: 6 },
];
const daysFilter = {
  sunday: "domingo",
  monday: "segunda",
  tuesday: "terça",
  wednesday: "quarta",
  thursday: "quinta",
  friday: "sexta",
  saturday: "Sabado",
};

const hourtype = [{ describe: "Hora Marcada" }, { describe: "Horario de Chegada" }];
const sector = {
  setorA: "Setor A",
  setorB: "Setor B",
  setorC: "Setor C",
  setorD: "Setor D",
  setorE: "Setor E",
};
const employee = {
  jhonatas: "Jhonatas",
  marcos: "Marcos",
  matheus: "Matheus",
  felipe: "Felipe",
  augusto: "Augusto",
};

const ufs = [
  "AC",
  "AL",
  "AP",
  "AM",
  "BA",
  "CE",
  "DF",
  "ES",
  "GO",
  "MA",
  "MT",
  "MS",
  "MG",
  "PA",
  "PB",
  "PR",
  "PE",
  "PI",
  "RJ",
  "RN",
  "RS",
  "RO",
  "RR",
  "SC",
  "SP",
  "SE",
  "TO",
];

const ufs2 = [
  { describe: "AC" },
  { describe: "AL" },
  { describe: "AP" },
  { describe: "AM" },
  { describe: "BA" },
  { describe: "CE" },
  { describe: "DF" },
  { describe: "ES" },
  { describe: "GO" },
  { describe: "MA" },
  { describe: "MT" },
  { describe: "MS" },
  { describe: "MG" },
  { describe: "PA" },
  { describe: "PB" },
  { describe: "PR" },
  { describe: "PE" },
  { describe: "PI" },
  { describe: "RJ" },
  { describe: "RN" },
  { describe: "RS" },
  { describe: "RO" },
  { describe: "RR" },
  { describe: "SC" },
  { describe: "SP" },
  { describe: "SE" },
  { describe: "TO" },
];

const procedures = [
  "Domicílio",
  "Consultório Médico",
  "Via Urbana",
  "Outro Hospital",
  "Ambulatório",
  "Radioterapia",
  "Banco de Sangue",
  "Quimioterapia",
  "Hemodiálise",
  "Laboratório",
  "Trabalho",
  "Escola",
  "Centro de Diagnóstico Imagem",
  "Delegacias",
  "Unidade Carcerária",
  "Posto de Saúde",
  "Não Especificada/Declarada",
  "Outras Procedências",
];
const councils = ["CRM"];

const service_location = [
  "UBS",
  "Unidade Móvel",
  "Rua",
  "Domicílio",
  "Escola/Creche",
  "Outros",
  "Polo",
  "Instituição/Abrigo",
  "Unidade prisional ou congêneres",
  "Unidade socioeducativa",
  "Hospital",
  "Unidade de pronto atendimento",
  "CACON / UNACON",
];

const NonAttendanceReason = [
  "Ausência / Falta do Paciente",
  "Evasão do Paciente",
  "Inpossibilidade do Estabelecimento",
  "Paciente sem condições de atendimento",
];

const dental_supplies = ["Escova dental", "Creme dental", "Fio dental"];

const open_bite = {
  posterior: "Posterior",
  anterior: "Anterior",
};

const cross_bite = {
  bilateral: "Bilateral",
  unilateral: "Unilateral",
  before: "Anterior",
};

const deep_bite_class_1 = {
  note: "treeeeee",
  others: "Outras anomalias de crescimento crânio facial",
  crowding: "Apinhamento",
  mouth_breathing: "Respiração Bucal",
  maxillary_atresia: "Atresia de maxila",
  mandibular_atresia: "Atresia de mandíbula",
};

const deep_bite_class_2 = {
  division_1: {
    excessive_overjet: "Sobressalência acentuada",
    mandibular_retrusion: "Retrusão mandibular",
    maxillary_protrusion: "Protrusão maxilar",
    labial_movement_incisors: "Vestibularização de incisivos",
  },
  division_2: {
    incisor_extrusion: "Retrusão mandibular",
    excessive_overbite: "Sobremordida acentuada",
    mandibular_retrusion: "Verticalização de incisivos",
  },
};

const deep_bite_class_3 = {
  mandibular_prognathism: "Prognatismo mandibular",
  pre_maxillary_hypoplasia: "Pouco crescimento da pré-maxila",
};

const ampiOptions = {
  fragile: "FRÁGIL",
  "pre-fragile": "PRÉ-FRÁGIL",
  healthy: "SAUDÁVEL",
};

const footQuestions = {
  ucle_foot_hitoric: "Histórico de ulceração no pé?",
  abnormal_shape: "O pé apresenta forma anormal?",
  fingers_deformation: "Existe deformação nos dedos?",
  nails_ingrown: "As unhas são grossas ou encravadas?",
  has_calluses: "Apresenta calos?",
  has_edema: "Apresenta edema?",
  has_increase_temperature: "Apresenta elevação na temperatura?",
  has_muscle_weakness: "Apresenta fraqueza muscular?",
  can_examine_soles_feet: "Consegue examinar a planta dos seus pés?",
  appropriate_footwear: "Utiliza calçados adequados?",
};

const footRegions = [
  {
    name: "Hálux",
    path: "hallux",
  },
  {
    name: "Antepé médio",
    path: "metatarsal_heads",
    subPath: "med_metatarsal",
  },
  {
    name: "Antepé meio",
    path: "metatarsal_heads",
    subPath: "mid_metatarsal",
  },
  {
    name: "Antepé lateral",
    path: "metatarsal_heads",
    subPath: "lat_metatarsal",
  },
  {
    name: "Mediopé médio",
    path: "arches",
    subPath: "med_arches",
  },
  {
    name: "Mediopé lateral",
    path: "arches",
    subPath: "lat_arches",
  },
  {
    name: "Calcâneo",
    path: "heel",
  },
];

const risk_stratification_labels = {
  risk_factors: {
    cvd_family_history: "Histórico de DCV prematura em parentes de primeiro grau",
    smoking: "Tabagismo",
    diabetes: "Diabetes",
    dyslipidemia: "Dislipidemia",
    obesity: "Obesidade",
  },
  loa: {
    left_ventricular_hypertrophy: "Hipertrofia Ventricular Esquerda (HVE)",
    albuminuria: "Albuminúria",
    glomerular_filtration_rate: "Taxa de Filtração Glomerular",
  },
  cd_advanced: {
    ischemic_stroke: "AVE Isquêmico",
    cerebral_hemorrhage: "Hemorragia Cerebral",
    transient_ischemic_attack: "Ataque Isquêmico Transitório (AIT)",
    stable_or_unstable_angina: "Angina Estável ou Instável",
    myocardial_infarction: "Infarto do Miocárdio",
    myocardial_revascularization: "Revascularização do Miocárdio (Angioplastia ou Cirúrgica)",
    heart_failure_or_reduced:
      "Clínica de Insuficiência Cardíaca e/ou Fração de Ejeção Reduzida FEVE <40",
    artery_disease_lower_limbs: "Doença Arterial Periférica Sintomática dos Membros Inferiores",
    stage_four_chronic_kidney_disease: "Doença Renal Crônica Estágio 4",
    advanced_retinopathy: "Retinopatia avançada: hemorragias, exsudatos, papiledema",
  },
};

const salary_income = [
  {
    describe: "1/4 de salário mínimo",
    sus_code_id: 1,
  },
  {
    describe: "Meio salário mínimo",
    sus_code_id: 2,
  },
  {
    describe: "Um salário mínimo",
    sus_code_id: 3,
  },
  {
    describe: "Dois salários mínimos",
    sus_code_id: 4,
  },
  {
    describe: "Quatro salários mínimos",
    sus_code_id: 5,
  },

  {
    describe: "Acima de quatro salários mínimos",
    sus_code_id: 6,
  },
  {
    describe: "Três salários mínimos",
    sus_code_id: 7,
  },
];

const excluded_reasons = [
  {
    label: "Óbito",
  },
  {
    label: "Mudança de território",
  },
  {
    label: "Outros",
  },
];

const attendanceAlerts = {
  inAttendance: "O paciente já se encontra em atendimento.",
  soapPendency:
    "Você possui pendências de documentos para esse atendimento, para resolvê-las finalize um prontuário SOAP.",
  complementPendency:
    "Você possui pendências de documentos para esse atendimento, para resolvê-las finalize o processo de edição do SOAP.",
};

export {
  states,
  bloodTypes,
  organDonor,
  race,
  genders,
  countries,
  ufs,
  ufs2,
  councils,
  active,
  hourtype,
  procedures,
  sector,
  employee,
  kinships,
  days,
  daysFilter,
  drugAdmininstrations,
  service_location,
  classificationColors,
  NonAttendanceReason,
  dental_supplies,
  open_bite,
  cross_bite,
  deep_bite_class_1,
  deep_bite_class_2,
  deep_bite_class_3,
  ampiOptions,
  footQuestions,
  footRegions,
  risk_stratification_labels,
  kinship_sus_codes,
  salary_income,
  excluded_reasons,
  attendanceAlerts,
};
