import { format, parseISO } from "date-fns";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";

function tokenCEMPDF(patient) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const patient_name = patient.name;
  const birth_date = format(parseISO(patient.birth_date), "dd/MM/yyyy");
  const key = birth_date.replaceAll("/", "");
  const prontuary = patient.number;

  const titlePDF = {
    table: {
      widths: [290],
      body: [
        [
          {
            text: "HOSPITAL SANTA MARCELINA",
            fontSize: 10,
            alignment: "center",
            bold: true,
          },
        ],
        [{ text: patient_name, alignment: "center", bold: true, fontSize: 8 }],
        [{ text: `Nasc : ${birth_date}`, alignment: "right", fontSize: 8 }],
      ],
    },
    layout: {
      defaultBorder: false,
    },
  };

  const margins = [0, -5, 0, 0];

  const credentialsPatient = {
    table: {
      widths: [40, 240],
      body: [
        [
          { text: "Usuário :", bold: true, fontSize: 8 },
          { text: `${prontuary}APS`, bold: true, color: "red", fontSize: 8 },
        ],
        [
          { text: "Senha :", bold: true, fontSize: 8, margin: margins },
          { text: key, fontSize: 8, margin: margins },
        ],
        [
          { text: "Site :", bold: true, fontSize: 8, margin: margins },
          {
            text: window.CEM_EXAMS_RESULTS_URL,
            fontSize: 8,
            margin: margins,
          },
        ],
      ],
    },
    layout: {
      defaultBorder: false,
    },
  };

  const warningText = {
    columns: [
      {
        width: 300,
        alignment: "center",
        text: "PARA O PRIMEIRO ACESSO UTILIZAR USUÁRIO E SENHA ABAIXO, CASO JÁ TENHA CADASTRO UMA SENHA PESSOAL O ACESSO SERA SEU USUÁRIO E A SENHA CADASTRADA.",
        fontSize: 8,
      },
    ],
  };

  const definitions = {
    pageSize: "A4",
    pageMargins: [5, 5, 5, 5],
    defaultStyle: {
      fontSize: 10,
    },
    content: [titlePDF, warningText, credentialsPatient],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;

  return documentPDF;
}

export default tokenCEMPDF;
