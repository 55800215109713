import { Button, Grid } from "@mui/material";
import { FormTextField } from "../../../components";
import { FormSelectWithPaginate } from "../../../components/Form";
import { AppContext } from "../../../contexts/AppContext";
import { useContext, useState } from "react";
import TableFilter from "../../../components/Table/TableFilter";
import { Formik, useFormikContext } from "formik";
import { DialogMedium } from "../../../helper";
import { useProcedures } from "../../../service";
import Yup from "../../../config/yup";
import { verifyAttendanceFlow } from "../../../utils";

export default function OthersTab({ attendance }) {
  const { disableFields, patientData } = useContext(AppContext);
  const [proceduresToview, setProceduresToView] = useState([]);
  const { getProcedures } = useProcedures();

  const tableColumns = [
    { name: "Local", field: "local" },
    { name: "Observação", field: "notes" },
  ];

  const validations = Yup.object().shape({
    local: Yup.string().required("O local deve ser informado."),
    procedures: Yup.array().min(1, "Informe ao menos um procedimento."),
  });

  const { setFieldValue, values: soapValues } = useFormikContext();

  const initialValues = {
    local: "",
    procedures: [],
    notes: "",
  };

  function handleAdd(value, { resetForm }) {
    const oldValue = soapValues.others || [];
    oldValue.push(value);
    setFieldValue("others", oldValue);
    resetForm();
  }

  function handleRemove(index) {
    const oldValue = soapValues.others?.filter((_, i) => i !== index);
    setFieldValue("others", oldValue);
  }

  return (
    <Grid item xs={12}>
      <DialogMedium
        fullWidth
        maxWidth={"md"}
        open={!!proceduresToview?.length}
        handleClose={() => setProceduresToView()}
      >
        <TableFilter
          data={proceduresToview}
          noHeaderOptions
          columns={[
            { name: "SIGTAP", field: "tuss_code" },
            { name: "Procedimento", field: "describe" },
          ]}
        />
      </DialogMedium>
      <Formik onSubmit={handleAdd} validationSchema={validations} initialValues={initialValues}>
        {({ handleSubmit }) => (
          <Grid item container xs={12} spacing={2} marginTop={1}>
            <Grid item xs={12} display={"flex"} flexDirection={"column"} rowGap={2}>
              <FormTextField
                disabled={disableFields}
                name={"local"}
                label={"Local"}
                required={true}
              />
              <FormSelectWithPaginate
                disabled={disableFields}
                name={"procedures"}
                label={"Procedimentos"}
                service={(params) =>
                  getProcedures({
                    ...params,
                    type: "odonto",
                    patient_id: patientData?.id,
                    attendance_flow: verifyAttendanceFlow(attendance.risk_classification_id),
                  })
                }
                getOptionLabel={(data) => {
                  let label = data?.name;

                  if (data?.tuss_code) {
                    label = `${data?.tuss_code} - ${label}`;
                  }

                  return label;
                }}
                searchBy="filters"
                multiple
                fields={"describe"}
              />
              <FormTextField
                disabled={disableFields}
                name={"notes"}
                label={"Observações"}
                multiline
                minRows={3}
                maxRows={3}
              />
            </Grid>
            <Grid xs={12} item display={"flex"} justifyContent={"end"}>
              <Button onClick={() => handleSubmit()} variant="contained">
                Adicionar
              </Button>
            </Grid>
          </Grid>
        )}
      </Formik>
      <Grid item xs={12} marginTop={2} maxHeight={"326px"} sx={{ overflowY: "auto" }}>
        <TableFilter
          noHeaderOptions
          columns={tableColumns}
          data={soapValues.others || []}
          customizeActions={{
            delete: {
              verb: "remove",
              title: "Remover",
            },
          }}
          actionsTypes={["view", "delete"]}
          disableActions={(row, action) => {
            return (
              (action === "view" && !row.procedures.length) ||
              (action === "delete" && disableFields)
            );
          }}
          actionHandleDelete={(index) => {
            handleRemove(index);
          }}
          actionHandleView={(_, item) => {
            setProceduresToView(item.procedures);
          }}
          actions
        />
      </Grid>
    </Grid>
  );
}
