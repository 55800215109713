import instance from "../config/api";
import urlQuery from "../helper/urlQuery";
const path = "/individual-registers";

function useIndividualRegister() {
  const getPatientIndividualHistoric = async (id, params) => {
    try {
      const response = await instance.get(`${path}/patients/${id}`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const getIndividualHistoric = async (id, params) => {
    try {
      const response = await instance.get(`${path}/${id}`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  const postIndividual = async (data) => {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      const error = new Error(err.response.data.message);
      error.data = err.response.data;
      throw error;
    }
  };

  const updateIndividual = async (individualId, data) => {
    try {
      const response = await instance.patch(`${path}/${individualId}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  };

  return {
    getIndividualHistoric,
    getPatientIndividualHistoric,
    postIndividual,
    updateIndividual,
  };
}

export default useIndividualRegister;
