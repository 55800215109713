import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button, Divider, Grid, Typography } from "@mui/material";
import { AddCircleRounded, SaveRounded } from "@mui/icons-material";
import {
  AutocompleteField,
  DateField,
  PaginatedAutocompleteField,
  RadioField,
  TextField,
} from "../../../components/FormFields";
import { useFamily, useMicroArea, usePatient } from "../../../service";
import RegexOf from "../../../config/regex";
import Yup from "../../../config/yup";
import { PatientRenderCell } from "../../../components";
import { useEffect, useState } from "react";
import { searchByPatientIdentifiers } from "../../../utils";
import TableFilter from "../../../components/Table/TableFilter";
import validationMessages from "../../../config/validationMessages";
import { useNotifier } from "../../../hooks";
import {
  excluded_reasons,
  kinship_sus_codes,
  salary_income,
} from "../../../config/standardSelects";
import { DialogMedium } from "../../../helper";
import { useMutation } from "react-query";
import { v4 as uuidv4 } from "uuid";
const membersTableColumns = [
  {
    name: "Paciente",
    use: (_, row) => {
      return <PatientRenderCell row={row} />;
    },
  },
  {
    name: "Responsável",
    field: "responsible",
    use: (value) => (value ? "Sim" : "Não"),
  },
  {
    name: "Parentesco",
    field: "kinship",
    use: (kinship) => kinship?.describe || "Não Informado",
  },
];

const verifyFamilyNamePattern = (familyName) => {
  return RegexOf.familyName.test(familyName);
};

export default function FamilyForm({ familyData }) {
  const { getMicroArea } = useMicroArea();
  const { getPatients } = usePatient();
  const { postFamily } = useFamily();

  const notify = useNotifier();

  const [members, setMembers] = useState(familyData?.members || []);
  const [removedMembers, setRemovedMembers] = useState([]);
  const [memberToRemoveIndex, setMemberToRemoveIndex] = useState(null);
  const [openRemoveForm, setOpenRemoveForm] = useState(false);
  const [isEditingMember, setIsEditingMember] = useState(false);

  const upInsertFamilyMutation = useMutation(postFamily);

  const familyValidations = Yup.object().shape({
    microarea: Yup.object().required(validationMessages.required).nullable(),
    family_prontuary_number: Yup.string().test({
      test: (family_prontuary_number, context) => {
        if (!family_prontuary_number && context.parent.microarea) {
          return false;
        }
        return true;
      },
      message: validationMessages.required,
    }),
  });

  const membersValidation = Yup.object().shape({
    member: Yup.object().required(validationMessages.required).nullable(),
    responsible: Yup.boolean(),
    kinship: Yup.object()
      .test({
        test: (kinship, context) => {
          if (context.parent.responsible) {
            return true;
          }

          if (!kinship) return false;

          return true;
        },
        message: validationMessages.required,
      })
      .nullable(),
    do_number: Yup.string().nullable(),
    date_deceased: null,
    excluded_reason: null,
  });

  const removalValidations = Yup.object().shape({
    excluded_reason: Yup.string().required(validationMessages.required).nullable(),
    do_number: Yup.string().when("excluded_reason", {
      is: "Óbito",
      then: Yup.string().required(validationMessages.required).nullable(),
      otherwise: Yup.string().nullable(),
    }),
    date_deceased: Yup.date().when("excluded_reason", {
      is: "Óbito",
      then: Yup.date().required(validationMessages.required).nullable(),
      otherwise: Yup.date().nullable(),
    }),
    description: Yup.string().when("excluded_reason", {
      is: "Outros",
      then: Yup.string().required(validationMessages.required).nullable(),
      otherwise: Yup.string().nullable(),
    }),
  });

  const familyForm = useForm({
    defaultValues: {
      family_prontuary_number: verifyFamilyNamePattern(familyData?.name)
        ? familyData?.name.split(".")[2]
        : "",
      microarea: familyData?.domiciliary?.microarea || null,
      team: familyData?.team || null,
      domiciliary_id: familyData?.domiciliary_id || null,
      resides_since: familyData?.resides_since || null,
      family_income:
        salary_income.find(
          (salary_income) => salary_income.sus_code_id === +familyData?.family_income_id
        ) || null,
    },
    resolver: yupResolver(familyValidations),
  });

  const memberForm = useForm({
    defaultValues: {
      kinship: null,
      member: null,
      responsible: false,
    },
    resolver: yupResolver(membersValidation),
  });

  const removalForm = useForm({
    defaultValues: {
      do_number: null,
      date_deceased: null,
      excluded_reason: null,
      description: "",
    },
    resolver: yupResolver(removalValidations),
  });

  const prontuary_number = familyForm.watch("family_prontuary_number");
  const microarea = familyForm.watch("microarea");
  const memberToAdd = memberForm.watch("member");
  const excluded_reason = memberForm.watch("excluded_reason");

  const familyName = `${microarea?.team?.name}.${microarea?.name}.${prontuary_number}`;

  const formatMember = (member, locallyAdded = true) => {
    return {
      number: member.number,
      id: member.id,
      name: member.name,
      social_name: member.social_name,
      birth_date: member.birth_date,
      physic_national: member.physic_national,
      sus_card: member.sus_card,
      kinship: member.kinship,
      responsible: member.responsible,
      locallyAdded,
    };
  };
  const addMember = () => {
    const memberInTheListIndex = members.findIndex(
      (member) => member.number === memberToAdd.number
    );

    if (memberInTheListIndex > -1 && !isEditingMember) {
      return notify(
        `O membro '${memberToAdd.name.toUpperCase()}' já foi adicionado na família.`,
        "warning"
      );
    }

    const memberObject = formatMember({
      ...memberToAdd,
      kinship: memberForm.getValues("kinship"),
      responsible: memberForm.getValues("responsible"),
    });

    if (isEditingMember) {
      setMembers((currentMembers) => {
        const members = [...currentMembers];
        members[memberInTheListIndex] = memberObject;

        return members;
      });
    } else {
      setMembers((currentMembers) => [...currentMembers, memberObject]);
    }

    setIsEditingMember(false);
    memberForm.reset({
      kinship: null,
      member: null,
      responsible: false,
    });
  };
  const editMember = (memberIndex) => {
    setIsEditingMember(true);
    const member = members[memberIndex];
    memberForm.reset({
      member: member,
      kinship: member.kinship,
      responsible: member.responsible,
    });
  };
  const removeMember = (memberIndex) => {
    setMembers((currentMembers) => currentMembers.filter((_, index) => index !== memberIndex));
  };
  const verifyMemberRemoval = (memberIndex) => {
    const member = members[memberIndex];

    if (member?.locallyAdded) {
      return removeMember(memberIndex);
    }

    setMemberToRemoveIndex(memberIndex);
    setOpenRemoveForm(true);
  };

  const doesReponsibleIsAlreadyInTheList = () => members.some((member) => member.responsible);

  const handleRemovalSubmit = (removalFormData) => {
    let memberToRemove = members[memberToRemoveIndex];
    memberToRemove = { ...memberToRemove, ...removalFormData };

    setRemovedMembers((removedMembers) => [...removedMembers, memberToRemove]);
    removeMember(memberToRemoveIndex);
    removalForm.reset();
    setOpenRemoveForm(false);
    setMemberToRemoveIndex(null);
  };

  useEffect(() => {
    if (familyData) {
      if (!verifyFamilyNamePattern(familyData.name)) {
        notify(
          "O número de prontuário da família está fora do padrão, por favor, adicione um número de prontuário válido",
          "error"
        );
      }
      const formatedMembers = familyData.members.map((member) => {
        const kinship = kinship_sus_codes.find((kinship) => kinship.describe === member.kinship_id);
        return formatMember({ ...member, kinship, ...member.patient }, false);
      });
      setMembers(formatedMembers);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [familyData]);

  const handleFamilySubmit = (familyFormData) => {
    if (members.length && !doesReponsibleIsAlreadyInTheList()) {
      return notify("Adicione ao menos um responsável pelo(s) membro(s)", "warning");
    }

    const familyPayload = {
      id: familyData?.id || uuidv4(),
      domiciliary_id: familyFormData.domiciliary_id || null,
      name: familyName,
      resides_since: familyFormData.resides_since,
      family_income_id: familyFormData.family_income?.sus_code_id,
      members: members.map((member) => {
        return {
          id_patient: member.id,
          responsible: member.responsible,
          kinship_id: member.kinship?.describe,
        };
      }),
      remove_members: removedMembers,
    };

    upInsertFamilyMutation
      .mutateAsync(familyPayload)
      .then(() => notify("Familia salva com sucesso", "success"))
      .catch((err) => notify(`Erro ao salvar a familia: ${err.message}`, "error"));
  };

  return (
    <>
      <DialogMedium
        title={"Motivo da remoção"}
        open={openRemoveForm}
        handleClose={() => {
          removalForm.reset();
          setOpenRemoveForm(false);
          setMemberToRemoveIndex(null);
        }}
      >
        <Grid container spacing={2} xs={12}>
          <Grid item xs={12}>
            <RadioField
              control={removalForm.control}
              required
              label={"Motivo da remoção"}
              name={"excluded_reason"}
              options={excluded_reasons}
              optionValueKey={"label"}
              optionCompareKey={"label"}
            />
          </Grid>
          <Divider />
          {excluded_reason === "Óbito" && (
            <>
              <Grid item xs={12}>
                <DateField
                  control={removalForm.control}
                  name="date_deceased"
                  label="Data do óbito"
                  maxDate={new Date()}
                  required
                  placeholder="DD/MM/AAAA"
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  control={removalForm.control}
                  name="do_number"
                  label="Número da DO"
                  required
                  placeholder="Número"
                  keyboardType="numeric"
                />
              </Grid>
            </>
          )}
          <Grid item xs={12}>
            <TextField
              control={removalForm.control}
              name="description"
              label="Anotação"
              textArea
              multiline
              minRows={4}
              required={excluded_reason === "Outros"}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              onClick={removalForm.handleSubmit(handleRemovalSubmit)}
              variant="contained"
              color="error"
            >
              Confirmar Remoção
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <Grid container spacing={2} xs={12}>
        <Grid item container xs={12} lg={8} spacing={2}>
          <Grid item xs={12}>
            <Typography fontSize={15} fontWeight="bold" color="secondary.main">
              Nome da Família:{" "}
              <Typography component="span">{microarea ? familyName : "00.00.0000"}</Typography>
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <PaginatedAutocompleteField
              control={familyForm.control}
              name={"microarea"}
              label={"Microárea"}
              service={(params) => getMicroArea({ ...params, team_id: "not_null" })}
              customOnChange={(microarea) => {
                if (!microarea) {
                  familyForm.setValue("family_prontuary_number", "");
                  return;
                }
                familyForm.setValue("team", microarea?.team?.name);
              }}
              optionLabelKey="name"
              filterKey={"name"}
              optionCompareKey={"id"}
              queryKey={"microareas"}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              control={familyForm.control}
              name={"family_prontuary_number"}
              label={"Número do Prontuário"}
              required
              placeholder={microarea ? "Exemplo: 2222" : "Selecione uma microaérea"}
              mask={RegexOf.familyProntuary}
              disabled={!microarea}
            />
          </Grid>
          <Grid item xs={6}>
            <DateField
              control={familyForm.control}
              name={"resides_since"}
              maxDate={new Date()}
              label={"Reside desde"}
            />
          </Grid>
          <Grid item xs={6}>
            <AutocompleteField
              control={familyForm.control}
              options={salary_income}
              optionLabelKey="describe"
              optionCompareKey={"sus_code_id"}
              name={"family_income"}
              label={"Renda Familiar"}
            />
          </Grid>
        </Grid>
        <Grid container item spacing={2} xs={12}>
          <Grid item xs={12}>
            <Typography fontSize={15} fontWeight="bold" color="secondary.main">
              Membros
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <RadioField
              control={memberForm.control}
              name={"responsible"}
              label="É Responsável Familiar?"
              optionValueKey={"value"}
              options={[
                {
                  label: "Sim",
                  value: true,
                },
                { label: "Não", value: false },
              ]}
              disabled={doesReponsibleIsAlreadyInTheList()}
            />
          </Grid>
          <Grid
            item
            xs={12}
            lg={8}
            sx={{
              display: "flex",
              gap: 2,
            }}
          >
            <Grid xs={12} item container spacing={2}>
              <Grid xs={6} item>
                <PaginatedAutocompleteField
                  control={memberForm.control}
                  service={({ name, ...params }) => {
                    searchByPatientIdentifiers(name, params, [
                      "physic_national",
                      "sus_card",
                      "name",
                    ]);

                    return getPatients({
                      ...params,
                      extraFilters: {
                        filter: "hasNoFamily",
                      },
                    });
                  }}
                  queryKey={"patientsQuery"}
                  filterKey={"name"}
                  optionLabelKey={"name"}
                  name={"member"}
                  placeholder={"Selecione um membro da família"}
                  label="Buscar por: CPF ou CNS ou nome do paciente.."
                />
              </Grid>
              <Grid xs={6} item>
                <AutocompleteField
                  control={memberForm.control}
                  optionLabelKey={"describe"}
                  optionCompareKey={"describe"}
                  options={kinship_sus_codes}
                  name={"kinship"}
                  placeholder={"Selecione um parentesco"}
                  label="Parentesco"
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              disabled={!memberToAdd}
              onClick={memberForm.handleSubmit(addMember)}
            >
              {isEditingMember ? <SaveRounded /> : <AddCircleRounded />}
            </Button>
          </Grid>
          <Grid item container xs={12} lg={8}>
            <TableFilter
              columns={membersTableColumns}
              data={members}
              actions
              noFilter
              customizeActions={{
                delete: {
                  title: "Remover Membro",
                  verb: "remover",
                },
                edit: {
                  title: "Editar Membro",
                  verb: "remover",
                },
              }}
              actionsTypes={["edit", "delete"]}
              actionHandleEdit={editMember}
              actionHandleDelete={verifyMemberRemoval}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              variant="contained"
              startIcon={<SaveRounded />}
              type="submit"
              disabled={upInsertFamilyMutation.isLoading}
              onClick={familyForm.handleSubmit(handleFamilySubmit)}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
