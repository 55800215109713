function formDataObject(object) {
  const entries = Object.entries(object);
  const formData = new FormData();

  entries.forEach(([key, value]) => {
    if ([undefined, null].includes(value)) return;

    if (Array.isArray(value)) {
      value.forEach((insideValue) => {
        formData.append(key, insideValue);
      });
    }

    formData.append(key, value);
  });

  return formData;
}

export default formDataObject;
