import React from "react";
import { Grid, Typography, Box, TablePagination, Button } from "@mui/material";
import { useTheme } from "@emotion/react";
import { DialogMedium} from "../../../helper";
import { useMutation, useQuery } from "react-query";
import { format, parseISO } from "date-fns";
import Yup from "../../../config/yup";
import useTotem from "../../../service/useTotem";
import TableFilter from "../../../components/Table/TableFilter";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { usePagination } from "../../../service";
import { TextField } from "../../../components/FormFields";
import { useNotifier } from "../../../hooks";

export default function TabPrinter() {
  const theme = useTheme();
  const notify = useNotifier();
  const [edit, setEdit] = React.useState(false);
  const [limit, setLimit] = React.useState(5);
  const [selectedTotem, setSelectedTotem] = React.useState(null);

  const { getTotems, updateTotem, postTotem, deleteTotem } = useTotem();

  const totemMutation = useMutation(postTotem);
  const updateTotemMutation = useMutation(updateTotem);
  const deleteTotemMutation = useMutation(deleteTotem);

  const totemQuery = useQuery(["totems"], () => getTotems(), {
    retry: false,
    initialData: [],
  });

  const { paginationInfo, handlePageChange } = usePagination(
    totemQuery.data,
    limit
  );

  function handleClose() {
    setEdit(false);
    setSelectedTotem(null);
  }

  const columns = [
    {
      field: "name",
      name: "Nome",
      type: "string",
    },
    {
      field: "createdAt",
      name: "Criado em",
      type: "string",
      use: (value) => format(parseISO(value), "dd/MM/yyyy"),
    },
    {
      field: "updatedAt",
      name: "Atualizado em",
      type: "string",
      use: (value) => format(parseISO(value), "dd/MM/yyyy"),
    },
  ];

  const validations = Yup.object().shape({
    name: Yup.string().required("É requerido"),
  });

  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      name: "",
    },
    resolver: yupResolver(validations),
  });

  const editTotemForm = useForm({
    defaultValues: {
      name: selectedTotem?.describe,
    },
    resolver: yupResolver(validations),
  });

  const onSubmit = handleSubmit((values) => {
    totemMutation.mutate(values, {
      onSuccess: (res) => {
        notify(res.message, "success");
        reset();
        totemQuery.refetch();
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    });
  });

  const onEditSubmit = editTotemForm.handleSubmit((values) => {
    updateTotemMutation.mutate(
      { id: selectedTotem.id, data: values },
      {
        onSuccess: (res) => {
          notify(res.message, "success");
          totemQuery.refetch();
          handleClose();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  });

  
  const handleDelete = (id) => {
    deleteTotemMutation.mutate(id, {
      onSuccess: (success) => {
        notify(success.message, "success");
        totemQuery.refetch();
      },
      onError: (error) => {
        notify(error.message, "error");
      },
    });
  };


  return (
    <>
      <DialogMedium
        open={edit}
        fullWidth
        maxWidth="sm"
        title="Alteração do Totem"
        handleClose={handleClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={8}>
            <TextField
              control={editTotemForm.control}
              name="name"
              label="Nome"
              required
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              variant="contained"
              onClick={onEditSubmit}
              onLoad={updateTotemMutation.isLoading}
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </DialogMedium>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-around",
        }}
      >
        <Typography
          color="textSecondary"
          style={{
            fontFamily: "Montserrat",
            color: theme.palette.primary.light,
            fontSize: "16px",
          }}
        >
          Totem
        </Typography>
      </Box>
      <Box pt={3}>
        <TableFilter
          data={paginationInfo.items}
          columns={columns}
          loading={totemQuery.isLoading}
          actions
          actionUniqueIdentifier={"id"}
          actionsTypes={["edit", "delete"]}
          shouldDisableFields={["id"]}
          actionHandleEdit={(_, item) => {
            setSelectedTotem(item);
            editTotemForm.setValue("name", item.name);
            setEdit(true);
          }}
          actionHandleDelete={handleDelete}
        />
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <TablePagination
            count={paginationInfo.totalItems}
            component="div"
            page={paginationInfo.currentPage - 1}
            onPageChange={(_, newPage) => {
              handlePageChange(newPage + 1);
            }}
            rowsPerPage={limit}
            rowsPerPageOptions={[5, 10, 20, 75]}
            onRowsPerPageChange={({ target }) => {
              setLimit(parseInt(target.value, 10));
              handlePageChange(1);
            }}
          />
        </Box>
      </Box>

      <Grid container spacing={2} paddingX={1}>
        <Grid item xs={12}>
          <Typography
            sx={{
              color: theme.palette.primary.light,
              fontWeight: "bold",
            }}
          >
            Adicionar Totem
          </Typography>
        </Grid>
        <Grid item xs={8}>
          <TextField control={control} name="name" label="Nome" required />
        </Grid>
        <Grid item xs={2}>
          <Button
            variant="contained"
            onClick={onSubmit}
            onLoad={totemMutation.isLoading}
          >
            Cadastrar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
