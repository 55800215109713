import { Grid, Typography } from "@mui/material";
import { AutocompleteField, DateField, PaginatedAutocompleteField } from "../../FormFields";
import { addDays, isWeekend, startOfMonth, subDays } from "date-fns";
import { useProvider } from "../../../service";
import searchEmployeeByNameCpfCns from "../../../utils/searchEmployeeByNameCpfCns";
import { useContext } from "react";
import { AuthContext } from "../../../contexts/AuthContext";

function IndividualEmployeeForm({
  control,
  setValue,
  watch
}) {
  const { getProvidersHeader } = useProvider();
  const today = new Date();
  const { userData } = useContext(AuthContext);
  const employeeData = watch("employee");
  const disableCBOfield = !(employeeData?.employee_specialties?.length > 1);
  const disableINEfield = !(employeeData?.team_employees?.length > 1);

  const getMinDate = () => {
    const startOfCurrentMonth = startOfMonth(today);

    if (today.getDate() <= 7) {
      let secondBusinessDay = startOfCurrentMonth;
      let businessDaysCount = 0;

      while (businessDaysCount < 2) {
        secondBusinessDay = addDays(secondBusinessDay, 1);
        if (!isWeekend(secondBusinessDay)) {
          businessDaysCount += 1;
        }
      }

      return subDays(secondBusinessDay, 7);
    }

    return subDays(today, 7);
  };

  const handleEmployeeValues = (value) => {
    if (!value) return handleClearEmployeeFields();
    setValue("employee_cbo",
      value.employee_specialties?.length > 1 ? null : value.employee_specialties[0]
    );
    setValue("employee_cnes", value.employee_specialties[0]);
    setValue("employee_ine",
      value.team_employees?.length > 1 ? null : value.team_employees[0]
    );
  };

  const handleClearEmployeeFields = () => {
    setValue("employee_cbo", null);
    setValue("employee_cnes", null);
    setValue("employee_ine", null);
    setValue("createdAt", null);
  };

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="secondary">Dados do Profissional</Typography>
      </Grid>

      <Grid item xs={3}>
        <PaginatedAutocompleteField
          name="employee"
          label="Nome, CPF ou CNS do profissional"
          control={control}
          service={
            (params) => getProvidersHeader(searchEmployeeByNameCpfCns(
              { ...params, company_id: userData.company.id })
            )}
          filterKey="name"
          queryKey="employee"
          optionLabelKey="name"
          required
          customOnChange={handleEmployeeValues}
        />
      </Grid>

      <Grid item xs={2}>
        <AutocompleteField
          name="employee_cbo"
          label="CBO"
          control={control}
          required
          disabled={disableCBOfield}
          options={employeeData?.employee_specialties || []}
          optionLabelKey="specialty.cbo_code"
        />
      </Grid>

      <Grid item xs={2}>
        <AutocompleteField
          name="employee_cnes"
          label="CNES"
          control={control}
          required
          disabled
          options={employeeData?.employee_specialties || []}
          optionLabelKey="company.cmes"
        />
      </Grid>

      <Grid item xs={2}>
        <AutocompleteField
          name="employee_ine"
          label="INE"
          control={control}
          disabled={disableINEfield}
          options={
            employeeData?.team_employees || []
          }
          optionLabelKey={"team.ine"}
        />
      </Grid>

      <Grid item xs={3}>
        <DateField
          name="createdAt"
          label="DATA"
          control={control}
          placeholder="DD/MM/AAAA"
          required
          minDate={getMinDate()}
          maxDate={today}
        />
      </Grid>
    </>
  )
}

export default IndividualEmployeeForm;