import React, { useContext, useMemo, useRef, useState } from "react";
import { Dialog } from "../../../helper";
import {
  Box,
  Button,
  Grid,
  IconButton,
  TablePagination,
  Typography,
  useTheme,
} from "@mui/material";
import OutlinedContainer from "../../../components/OutlinedContainer/OutlinedContainer";
import { AppContext } from "../../../contexts/AppContext";
import calcIMC from "../../../utils/calcIMC";
import PrintIcon from "@mui/icons-material/Print";
import { soapPDF } from "../../../pdfModels";
import { parseISO, format, addDays } from "date-fns";
import { MedicationRounded } from "@mui/icons-material";
import { useQuery } from "react-query";
import { useClinicalRecords } from "../../../service";
import TAccordion from "../../../components/Accordion";
import MedicationTable from "../../Monitoring/Medications/MedicationTable";
import { ColorInfoLabels } from "../../../components";
import SoapRegularData from "./SoapRegularData";
import { formatOdontoProcedures, formatOdontoRps, openURL } from "../../../utils";

const SoapProntuary = ({ data, open, handleClose, isOdonto }) => {
  const { patientData } = useContext(AppContext);
  const theme = useTheme();
  const imcValues = useRef({ PESO: false, ALTURA: false });
  const [openAssistedHistory, setOpenAssistedHistory] = useState(false);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const { getClinicalRecordsByPatientId } = useClinicalRecords();
  const [accordionConf, setAccordionConf] = useState([]);

  const rps = useMemo(() => formatOdontoRps(data?.plan?.sextant), [data]);
  const odontoProcedures = useMemo(() => formatOdontoProcedures(data?.plan), [data]);

  function handlePrint() {
    const document = data.signed_documents[data.signed_documents.length - 1];

    if (document.result) {
      openURL(document.result);
    } else {
      const pdf = soapPDF(
        data,
        patientData,
        {
          createdAt: data?.createdAt,
          employee: {
            name: data?.employee_specialty?.employee?.name,
            specialty: data?.employee_specialty?.specialty,
            employee_Type: data?.employee_specialty?.employee.employee_Type,
            council_registration_number:
              data?.employee_specialty?.employee?.council_registration_number,
          },
        },
        odontoProcedures,
        rps
      );

      pdf.open();
    }
  }
  const patientClinicalRecords = useQuery(
    ["patient-clinical-records", patientData.id, page, limit, openAssistedHistory],
    () =>
      getClinicalRecordsByPatientId(patientData.id, {
        page,
        limit,
      }),
    {
      enabled: !!patientData.id && openAssistedHistory,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        const conf = data?.items.map((record) => {
          const lastDosage = record.clinical_records.find((clinicalRecord) => {
            return clinicalRecord.administration === true;
          });
          const firstDosage = record.clinical_records.toReversed().find((clinicalRecord) => {
            return clinicalRecord.administration === true;
          });
          return {
            title: (
              <Box>
                <Typography fontWeight={600}>{record.name}</Typography>
                <Box
                  sx={{
                    display: "flex",
                    gap: "20px",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography>
                      Última Administração:{" "}
                      <Typography component={"span"} fontWeight={600}>
                        {lastDosage?.date
                          ? format(parseISO(lastDosage.date), "dd/MM/yyyy 'às' HH:mm")
                          : "Nenhuma até o momento."}
                      </Typography>
                    </Typography>
                    <Typography>Posologia: {record.posology}</Typography>
                    <Typography>Observações: {record.notes}</Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <Typography>
                      Prazo:{" "}
                      <Typography component={"span"} fontWeight={600}>
                        {record.treatment_cycle
                          ? `${record.treatment_cycle ?? 0} Dias`
                          : "Nenhuma até o momento."}
                      </Typography>
                    </Typography>
                    <Typography>
                      Data 1° aplicação:{" "}
                      {firstDosage?.date
                        ? format(parseISO(firstDosage.date), "dd/MM/yyyy 'às' HH:mm")
                        : "Nenhuma até o momento."}
                    </Typography>
                    <Typography>
                      Data prevista ultima aplicação:{" "}
                      {firstDosage?.date
                        ? format(
                            addDays(parseISO(firstDosage.date), record.treatment_cycle),
                            "dd/MM/yyyy "
                          )
                        : "Nenhuma até o momento."}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            ),
            bgColor: record.suspended ? theme.palette.error.light : "",
            body: <MedicationTable medications={record.clinical_records} type={"assisted"} />,
          };
        });
        setAccordionConf(conf);
      },
    }
  );

  function admeasurementListValues(item, index, array) {
    if (["PESO", "ALTURA"].includes(item.admeasurement.describe.toUpperCase())) {
      imcValues.current[item.admeasurement.describe.toUpperCase()] = item.value;
    }

    const { PESO, ALTURA } = imcValues.current;

    return item?.value ? (
      <>
        <Grid container xs={12} my={1}>
          <Grid item xs={8}>
            <Typography fontSize={10} color={theme.palette.primary.main}>
              {" "}
              {item?.admeasurement?.describe}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            {" "}
            {item?.value}
          </Grid>
        </Grid>
        {PESO && ALTURA && array.length - 1 === index && (
          <Grid container xs={12} my={1}>
            <Grid item xs={12}>
              <Typography component="p" fontSize={10} color={theme.palette.primary.main}>
                IMC:{" "}
                <Typography component="span">
                  {calcIMC(
                    imcValues.current.PESO,
                    imcValues.current.ALTURA,
                    "Peso e Altura registrados incorretamente"
                  )}
                </Typography>
              </Typography>
            </Grid>
          </Grid>
        )}
      </>
    ) : (
      ""
    );
  }

  return (
    <Dialog open={open} title="Histórico" handleClose={handleClose}>
      {!data?.deletedAt && (
        <>
          <IconButton
            sx={{
              position: "fixed",
              right: 30,
              bottom: 25,
              color: theme.palette.primary.light,
              cursor: "pointer",
            }}
            onClick={handlePrint}
          >
            <PrintIcon fontSize="large" />
          </IconButton>
        </>
      )}
      <Box
        sx={{
          marginBottom: "20px",
        }}
      >
        <Button
          variant="outlined"
          sx={{
            display: "flex",
          }}
          onClick={() => {
            setOpenAssistedHistory(true);
          }}
        >
          <MedicationRounded
            sx={{
              fill: (theme) => theme.palette.primary.main,
            }}
          />
          Histórico de Medicações Assistidas
        </Button>
      </Box>
      <Dialog
        open={openAssistedHistory}
        handleClose={() => {
          setOpenAssistedHistory(false);
        }}
        title={"Histórico de Medicações Assistidas"}
      >
        {patientClinicalRecords?.data?.items?.length ? (
          <>
            <ColorInfoLabels
              labels={[
                {
                  text: "Suspenso",
                  color: theme.palette.error.light,
                },
              ]}
            />
            <TAccordion configs={accordionConf} kerning={false} />
            <TablePagination
              component="div"
              count={patientClinicalRecords.data?.totalItems || 1}
              page={page}
              onPageChange={(_, newPage) => {
                setPage(newPage);
              }}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 20, 75]}
              onRowsPerPageChange={({ target }) => {
                setLimit(parseInt(target.value, 10));
                setPage(0);
              }}
            />
          </>
        ) : (
          <Box
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            <MedicationRounded
              sx={{
                fill: theme.palette.primary.main,
              }}
            />
            <Typography fontSize={16} color={"primary.main"}>
              Nenhuma medicação assistida prescrita para este paciente.
            </Typography>
          </Box>
        )}
      </Dialog>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <OutlinedContainer label="Atendimento">
            <Grid item xs={12} style={{ display: "flex", flexDirection: "row" }}>
              <Grid item container xs={7}>
                <Grid item xs={6}>
                  <Typography color={theme.palette.primary.main}>
                    <Typography
                      variant="h7"
                      color={theme.palette.primary.light}
                      fontWeight="500"
                      sx={{ marginTop: "20px" }}
                    >
                      {`Paciente : `}
                    </Typography>
                    {patientData?.social_prioritize
                      ? patientData?.social_name
                        ? patientData.social_name.toUpperCase()
                        : "Não informado"
                      : patientData?.name
                      ? patientData.name.toUpperCase()
                      : "Não informado"}
                  </Typography>
                  <Typography color={theme.palette.primary.main}>
                    <Typography
                      variant="h7"
                      color={theme.palette.primary.light}
                      fontWeight="500"
                      sx={{ marginTop: "20px" }}
                    >
                      {`Data de nascimento : `}
                    </Typography>
                    {!patientData?.birth_date
                      ? "Não Informado"
                      : format(parseISO(patientData.birth_date), "dd/MM/yyyy")}
                  </Typography>
                  <Typography color={theme.palette.primary.main}>
                    <Typography
                      variant="h7"
                      color={theme.palette.primary.light}
                      fontWeight="500"
                      sx={{ marginTop: "20px" }}
                    >
                      {`CNS : `}
                    </Typography>
                    {!patientData?.sus_card ? "Não Informado" : patientData.sus_card}
                  </Typography>
                  <Typography color={theme.palette.primary.main}>
                    <Typography
                      variant="h7"
                      color={theme.palette.primary.light}
                      fontWeight="500"
                      sx={{ marginTop: "20px" }}
                    >
                      {`Nome da mãe : `}
                    </Typography>
                    {!patientData?.mother_name ? "Não Informado" : patientData.mother_name}
                  </Typography>
                </Grid>
                <Grid item xs={6}>
                  <Typography color={theme.palette.primary.main}>
                    <Typography
                      variant="h7"
                      color={theme.palette.primary.light}
                      fontWeight="500"
                      sx={{ marginTop: "20px" }}
                    >
                      {`Serviço: `}
                    </Typography>
                    {data?.attendance?.service?.describe}
                  </Typography>
                  <Typography color={theme.palette.primary.main}>
                    <Typography
                      variant="h7"
                      color={theme.palette.primary.light}
                      fontWeight="500"
                      sx={{ marginTop: "20px" }}
                    >
                      {`Atendimento : `}
                    </Typography>
                    {data?.attendance?.number}
                  </Typography>
                </Grid>
              </Grid>
              <Grid
                item
                xs={5}
                style={{
                  borderLeft: "0.1rem solid #D3E3ED",
                  paddingLeft: "15px",
                }}
              >
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    {`Profissional : `}
                  </Typography>
                  {data?.employee_specialty?.employee?.name}
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    {`Cargo : `}
                  </Typography>
                  {data?.employee_specialty?.specialty?.describe}
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    variant="h7"
                    color={theme.palette.primary.light}
                    fontWeight="500"
                    sx={{ marginTop: "20px" }}
                  >
                    {`Registro : `}
                  </Typography>
                  {data?.employee_specialty?.employee?.council_registration_number}
                </Typography>
              </Grid>
            </Grid>
          </OutlinedContainer>
        </Grid>

        <Grid item xs={9}>
          {data?.cancellation_notes && (
            <OutlinedContainer label="Justificativa da Suspensão" color="#ef4444">
              {data.cancellation_notes}
            </OutlinedContainer>
          )}
        </Grid>
        <Grid item xs={9}>
          <SoapRegularData
            data={data}
            patientData={patientData}
            prontuaryOdonto={isOdonto}
            dataOdontoProcedures={odontoProcedures}
            dataRps={rps}
          />
        </Grid>
        <Grid item container xs={3} spacing={1} height={450}>
          <Grid item xs={12}>
            <OutlinedContainer label="Aferições">
              {data?.objective?.admeasurement_objectives?.length ? (
                <>{data?.objective?.admeasurement_objectives.map(admeasurementListValues)}</>
              ) : (
                "Não há aferições registradas."
              )}
            </OutlinedContainer>
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default SoapProntuary;
