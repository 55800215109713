import { Button, Grid, Paper, Popover, Typography } from "@mui/material";
import { useFormikContext } from "formik";
import { Check } from "@mui/icons-material";
import { useProcedures } from "../../../service";
import { useContext, useEffect } from "react";
import { AppContext } from "../../../contexts/AppContext";
import { verifyAttendanceFlow } from "../../../utils";
import { useForm } from "react-hook-form";
import {
  AutocompleteField,
  CheckboxField,
  PaginatedAutocompleteField,
  TextField,
} from "../../FormFields";
import teethArrayObject from "../Teeth/TeethArrayObject";

export default function CrownOptions({
  anchorEl,
  open,
  handleClose,
  handleSave,
  id = "",
  problems,
  disabled,
  attendance,
  type,
}) {
  const { getProcedures } = useProcedures();
  const {
    values: { teeth },
  } = useFormikContext();
  const { patientData } = useContext(AppContext);
  const initialValues = {
    procedures: [],
    notes: "",
    problems: [],
    replicate_to: [],
  };
  const form = useForm({
    defaultValues: initialValues,
  });

  const handleOnSubmit = form.handleSubmit((values) => {
    if (!values.notes && !values.problems?.length && !values.procedures?.length) {
      handleSave({ teeth_id: id, replicate_to: values.replicate_to }, "crown");
    } else {
      handleSave(
        {
          teeth_id: id,
          crown: {
            notes: values.notes,
            problems: values.problems,
            procedures: values.procedures,
          },
          replicate_to: values.replicate_to,
        },
        "crown",
      );
    }
    form.reset(initialValues);
    handleClose();
  });

  useEffect(() => {
    if (!anchorEl) return;
    const toothValues = teeth?.find((tooth) => tooth.teeth_id === id);
    if (toothValues && toothValues.crown) {
      form.reset({ ...toothValues.crown, replicate_to: [] });
    } else {
      form.reset(initialValues);
    }
  }, [anchorEl]);

  return (
    <Popover
      anchorEl={anchorEl}
      open={open}
      onClose={() => {
        handleClose();
      }}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <Paper sx={{ width: 440, padding: 2 }}>
        <Grid item container xs={12} spacing={2} component={"form"}>
          <Grid item xs={12}>
            <Typography color={"secondary"} sx={{ fontWeight: "bolder", padding: 2 }}>
              {`Coroa do dente ${id}`}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <CheckboxField
              name={"problems"}
              multiple
              disabled={disabled}
              options={problems}
              control={form.control}
              optionCompareKey={"id"}
              optionValueKey={"id"}
              optionLabelKey="describe"
              orientation="column"
              maxHeight={"385px"}
            />
          </Grid>
          <Grid item xs={12}>
            <AutocompleteField
              name={"replicate_to"}
              label={"Replicar problema/condição para:"}
              multiple
              disabled={disabled}
              options={teethArrayObject[type].top.concat(teethArrayObject[type].bottom)}
              control={form.control}
              optionCompareKey={"id"}
              autoCompleteProps={{
                getOptionLabel: (data) => {
                  return `Dente ${data.id}`;
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <PaginatedAutocompleteField
              control={form.control}
              name={"procedures"}
              optionCompareKey="id"
              service={(params) =>
                getProcedures({
                  ...params,
                  type: "teeth",
                  patient_id: patientData?.id,
                  attendance_flow: verifyAttendanceFlow(attendance.risk_classification_id),
                })
              }
              label={"Procedimentos"}
              filterKey="filters"
              multiple
              disabled={disabled}
              autoCompleteProps={{
                getOptionLabel: (data) => {
                  let label = data?.name;

                  if (data?.tuss_code) {
                    label = `${data?.tuss_code} - ${label}`;
                  }

                  return label;
                },
              }}
              queryKey={"odonto-procedures"}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              control={form.control}
              name={"notes"}
              label={"Observações"}
              multiline
              minRows={3}
              disabled={disabled}
              maxRows={3}
            />
          </Grid>
          {!disabled && (
            <Grid item xs={12} justifyContent={"end"} display={"flex"}>
              <Button onClick={() => handleOnSubmit()} variant="contained">
                <Check />
              </Button>
            </Grid>
          )}
        </Grid>
      </Paper>
    </Popover>
  );
}
