import React, { useContext, useEffect, useRef, useState } from "react";
import { Typography, Divider } from "@mui/material";
import { useTheme } from "@emotion/react";
import { isValid, parseISO } from "date-fns";
import { AppContext } from "../../../contexts/AppContext";
import { useConfirm } from "../../../helper/BlockNavigation";
import SoapProntuary from "../SoapProntuary/SoapProntuary";
import { PaginatedScrollList } from "../../../components/PaginatedScrollList";
import { useSOAP } from "../../../service";
import { useFormikContext } from "formik";
import useNotifier from "../../../hooks/useNotifier";
import { Tabs } from "../../../components";
import TabClassificationHistory from "../ClassificationHistory/TabClassificationHistory";
import SoapSuspendForm from "./SoapSuspendForm";
import { DialogMedium } from "../../../helper";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import SoapCard from "./SoapCard";
import Yup from "../../../config/yup";

function SoapHistory({
  setLastSoapId,
  setIdAttendance,
  previousPregnancies,
  patientDischarge,
  setPreviousPregnancies,
  setHasConducts,
  setTouchedValues,
  validations,
  setValidations,
  odonto,
  ceo,
  pendencies,
}) {
  const theme = useTheme();
  const { confirm } = useConfirm();
  const notify = useNotifier();
  const params = useParams();
  const [soapId, setSoapId] = useState();
  const [selectedHistoryId, setSelectedHistoryId] = useState(null);
  const [prontuary, setProntuary] = useState(false);
  const [previousValues, setPreviousValues] = useState(null);
  const [previousValidations, setPreviousValidations] = useState(null);
  const [previousDateFieldsToPregnant, setPreviousDateFieldsToPregnant] = useState(1);
  const [page, setPage] = useState(0);
  const [soapHistories, setSoapHistories] = useState([]);
  const [historyType, setHistoryType] = useState("attendance");
  const [toSuspend, setToSuspend] = useState(null);
  const history = localStorage.getItem("history");
  const { values, setValues } = useFormikContext();
  const { patientData, setDataSOAP, setDisableFields, soapToEdit, setSoapToEdit } =
    useContext(AppContext);
  const firstRender = useRef(true);

  const { getSoapsByPatient, getSOAP } = useSOAP();

  const soapHistoryQuery = useQuery(
    ["soap-history", page, patientData, historyType],
    () => {
      const type = [];

      if (odonto || ceo) {
        type.push("odonto", "ceo");
      } else {
        type.push("normal");
      }

      return getSoapsByPatient(patientData.id, {
        page,
        limit: 4,
        type,
      });
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled: !!patientData?.id && historyType === "attendance",
      initialData: {
        total: 0,
        items: [],
      },
      onSuccess(response) {
        if (response?.items.length) {
          const soap = response.items[0];
          if (soap) {
            setLastSoapId((lastSoap) => {
              if (lastSoap) {
                return lastSoap;
              }
              return soap.id;
            });
          }

          if (page === 0) setSoapHistories(response.items);
          else setSoapHistories((items) => [...items, ...response.items]);
        }
      },
    }
  );

  function mountInitialValues(item) {
    const admeasurement_objective = {};

    const currentAdmeasurements = Object.keys(values.admeasurement_objective);

    currentAdmeasurements.forEach((id) => {
      admeasurement_objective[id] = "";
    });

    if (item) {
      const newInitialValues = {
        reason: item.subjective?.reason || "",
        ciapSubjective: item.subjective?.ciaps || [],
        check: [],
        doencasPreexistentes: item.objective?.preExistIllnesses || [],
        radioVacina: item.objective?.vaccine_up_to_date,
        radioStatusAlergia: item.objective?.allergy_status,
        allergy_substances: item.objective?.allergy_substances
          ? Object.entries(item.objective.allergy_substances)
              .filter(([, value]) => value)
              .map(([key]) => key)
          : [],
        physical_exams: item.objective?.physical_exams || "",
        allergy_description: item.objective?.allergy_description || "",
        complementary_exams: item.objective?.complementary_exams || "",
        cid: item.assessment?.cids || [],
        ciapEvaluation: item.assessment?.ciaps || [],
        consult: item.assessment?.query_assessment || "",
        prescriptions: item.plan?.prescription,
        plan: item.plan?.query_plan,
        ciapPlan: item.plan?.ciaps || [],
        programs: item.plan?.programs || [],
        admeasurement_objective,
        conducts: item.conducts?.map(({ id }) => id) || [],
        pregnant: !!item.objective?.pregnancy_record,
        last_menstrual_period:
          item.objective?.pregnancy_record?.last_menstrual_period &&
          isValid(new Date(item.objective?.pregnancy_record?.last_menstrual_period))
            ? parseISO(item.objective?.pregnancy_record?.last_menstrual_period)
            : item.objective?.pregnancy_record?.last_menstrual_period,
        obstetric_usg_date:
          item.objective?.pregnancy_record?.obstetric_usg_date &&
          isValid(new Date(item.objective?.pregnancy_record?.obstetric_usg_date))
            ? parseISO(item.objective?.pregnancy_record?.obstetric_usg_date)
            : item.objective?.pregnancy_record?.obstetric_usg_date,
        obstetric_usg_weeks: item.objective?.pregnancy_record?.obstetric_usg_weeks,
        obstetric_usg_days: item.objective?.pregnancy_record?.obstetric_usg_days,
        gravidity: item.objective?.pregnancy_record?.gravidity,
        parity: item.objective?.pregnancy_record?.parity,
        vaginal_deliveries_number: item.objective?.pregnancy_record?.vaginal_deliveries_number,
        cesarean_deliveries_number: item.objective?.pregnancy_record?.cesarean_deliveries_number,
        abortions_number: item.objective?.pregnancy_record?.abortions_number,
        planned_pregnancy: item.objective?.pregnancy_record?.planned_pregnancy,
        fetal_movements: item.objective?.pregnancy_record?.fetal_movements,
        folic_acid_supplementation: item.objective?.pregnancy_record?.folic_acid_supplementation,
        calcium_carbonate_supplementation:
          item.objective?.pregnancy_record?.calcium_carbonate_supplementation,
        edema:
          item?.objective?.pregnancy_record?.edema === null
            ? "Sem edema"
            : item?.objective?.pregnancy_record?.edema || "Não informado",
        gestational_risk: item.objective?.pregnancy_record?.gestational_risk,
        fetal_presentation: item.objective?.pregnancy_record?.fetal_presentation,
        normal_clinical_exam: item.objective?.pregnancy_record?.normal_clinical_exam,
        normal_gynecological_exam: item.objective?.pregnancy_record?.normal_gynecological_exam,
        normal_breast_exam: item.objective?.pregnancy_record?.normal_breast_exam,
        ferrous_sulfate_supplementation:
          item.objective?.pregnancy_record?.ferrous_sulfate_supplementation,
        breastfeeding: item.objective?.child_record?.breastfeeding,
        ortolani: item.objective?.child_record?.ortolani,
        neuropsychomotor_development: item.objective?.child_record?.neuropsychomotor_development,
        red_reflex_exam: item.objective?.child_record?.red_reflex_exam,
        teeth: item.plan.teeth,
        arch: item.plan.arch,
        lower_denture: item.plan.lower_denture,
        upper_denture: item.plan.upper_denture,
        retainer: item.plan.retainer,
        braces: item.plan.braces,
        dental_appointment_type: item?.dental_appointment_type?.id,
        attendance_type: item.attendance_type?.id
          ? item.attendance_type?.id
          : item.attendance_type + "",
        oral_health_surveillance: item?.assessment?.oral_health_surveillances?.map(
          (surveillances) => surveillances.id
        ),
        dental_supplies: item.plan?.dental_supplies,
        orthodontic: item.plan?.orthodontic,
        endodontics: item.plan?.endodontics,
        sextant: item.plan?.sextant,
        others: item.plan?.others,
        types_attendance_urgency: item.types_attendance_urgency,
        treatment_type_conclusion_procedure_id: item?.plan?.treatment_type_conclusion_procedure_id,
        diabetic_foot: item.objective?.diabetic_foot,
        date_diabetic_foot_assessment: item.objective?.date_diabetic_foot_assessment,
        new_evaluation_scheduled_date: item.objective?.new_evaluation_scheduled_date,
      };
      item.objective?.admeasurement_objectives.forEach((item) => {
        newInitialValues.admeasurement_objective[item?.admeasurement.id] = item?.value;
      });

      const previousAdmeasurements = Object.entries(newInitialValues.admeasurement_objective);
      if (previousAdmeasurements.length) {
        for (const [previousKey, previousValue] of previousAdmeasurements) {
          newInitialValues.admeasurement_objective[previousKey] = previousValue;
        }
      }

      item.objective?.pregnancy_record?.previous_pregnancies?.forEach((value, index) => {
        newInitialValues["previous_pregnancies-" + index] = parseISO(value);
      });

      if (!previousValues) {
        setPreviousDateFieldsToPregnant(previousPregnancies);
        setPreviousValues(values);
      }

      if (!previousValidations) {
        setPreviousValidations(validations);
      }

      setPreviousPregnancies(item.objective?.pregnancy_record?.previous_pregnancies?.length || 1);
      setValidations({
        complement: soapToEdit ? Yup.string().required("É requerido") : null,
      });
      setValues((values) => ({
        ...newInitialValues,
        plan_procedure: values.plan_procedure,
        confirm_procedure: values.confirm_procedure,
      }));
    } else {
      const newInitialValues = {
        reason: previousValues?.reason || "",
        ciapSubjective: previousValues?.ciapSubjective || [],
        check: previousValues?.check || [],
        doencasPreexistentes: previousValues?.doencasPreexistentes || [],
        radioVacina: previousValues?.radioVacina,
        radioStatusAlergia: previousValues.radioStatusAlergia,
        allergy_substances: previousValues.allergy_substances,
        physical_exams: previousValues.physical_exams || "",
        allergy_description: previousValues.allergy_description || "",
        complementary_exams: previousValues.complementary_exams || "",
        cid: previousValues.cid || [],
        ciapEvaluation: previousValues.ciapEvaluation || [],
        consult: previousValues.consult || "",
        prescriptions: previousValues.prescriptions,
        plan: previousValues.plan,
        ciapPlan: previousValues.ciapPlan || [],
        programs: previousValues.programs || [],
        admeasurement_objective,
        conducts: previousValues.conducts,
        pregnant: previousValues.pregnant,
        last_menstrual_period: previousValues.last_menstrual_period,
        obstetric_usg_date: previousValues.obstetric_usg_date,
        obstetric_usg_weeks: previousValues.obstetric_usg_weeks,
        obstetric_usg_days: previousValues.obstetric_usg_days,
        gravidity: previousValues.gravidity,
        parity: previousValues.parity,
        vaginal_deliveries_number: previousValues.vaginal_deliveries_number,
        cesarean_deliveries_number: previousValues.cesarean_deliveries_number,
        abortions_number: previousValues.abortions_number,
        planned_pregnancy: previousValues.planned_pregnancy,
        fetal_movements: previousValues.fetal_movements,
        folic_acid_supplementation: previousValues.folic_acid_supplementation,
        calcium_carbonate_supplementation: previousValues.calcium_carbonate_supplementation,
        edema: previousValues.edema,
        gestational_risk: previousValues.gestational_risk,
        fetal_presentation: previousValues.fetal_presentation,
        normal_clinical_exam: previousValues.normal_clinical_exam,
        normal_gynecological_exam: previousValues.normal_gynecological_exam,
        normal_breast_exam: previousValues.normal_breast_exam,
        ferrous_sulfate_supplementation: previousValues.ferrous_sulfate_supplementation,
        breastfeeding: previousValues.breastfeeding,
        ortolani: previousValues.ortolani,
        neuropsychomotor_development: previousValues.neuropsychomotor_development,
        red_reflex_exam: previousValues.red_reflex_exam,
        confirm_procedure: previousValues.confirm_procedure,
        plan_procedure: previousValues.plan_procedure,
        teeth: previousValues.teeth || [],
        arch: previousValues.arch,
        lower_denture: previousValues.lower_denture,
        upper_denture: previousValues.upper_denture,
        retainer: previousValues.retainer,
        braces: previousValues.braces,
        dental_appointment_type: previousValues.dental_appointment_type || "",
        attendance_type: previousValues.attendance_type || "",
        oral_health_surveillance: previousValues.oral_health_surveillances,
        dental_supplies: previousValues.dental_supplies,
        orthodontic: previousValues.orthodontic,
        others: previousValues.others,
        sextant: previousValues.sextant,
        endodontics: previousValues.endodontics,
        types_attendance_urgency: previousValues.types_attendance_urgency,
        treatment_type_conclusion_procedure_id:
          previousValues.treatment_type_conclusion_procedure_id,
        diabetic_foot: previousValues.diabetic_foot,
        date_diabetic_foot_assessment: previousValues.date_diabetic_foot_assessment,
        new_evaluation_scheduled_date: previousValues.new_evaluation_scheduled_date,
      };

      const currentAdmeasurements = Object.keys(values.admeasurement_objective);
      const previousAdmeasurements = Object.entries(previousValues.admeasurement_objective);

      if (!previousAdmeasurements.length) {
        newInitialValues.admeasurement_objective = currentAdmeasurements.reduce(
          (acc, currentKey) => {
            for (const [previousKey, previousValue] of previousAdmeasurements) {
              if (currentKey === previousKey) {
                return { ...acc, [previousKey]: previousValue || "" };
              }
            }

            return { ...acc, [currentKey]: "" };
          },
          {}
        );
      } else {
        newInitialValues.admeasurement_objective = previousValues.admeasurement_objective;
      }

      setValidations(previousValidations);
      setValues(newInitialValues);
      setPreviousPregnancies(previousDateFieldsToPregnant);
      setPreviousValues(null);
      setPreviousValidations(null);
    }
  }

  function dataHistoryInToSoap(item) {
    const formattedSoapData = mountInitialValues(item);
    setDataSOAP(formattedSoapData);
  }

  const soapQuery = useQuery(
    ["soap-by-id", soapId, selectedHistoryId, soapToEdit?.id],
    () => getSOAP(soapToEdit?.id || selectedHistoryId || soapId),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!soapId || !!selectedHistoryId || !!soapToEdit,
      onSuccess(response) {
        if (selectedHistoryId || soapToEdit) {
          if (response.conducts.length) {
            setHasConducts(true);
          }

          dataHistoryInToSoap(response);
          setDisableFields(true);
        }
      },
      onError(error) {
        notify(error.message, "error");
      },
    }
  );

  useEffect(() => {
    if (!soapToEdit && !firstRender.current) {
      setIdAttendance(params.attendance);
      setHasConducts(false);
      setTouchedValues(true);
      setDisableFields(!!pendencies?.soap_id);
      dataHistoryInToSoap(null);
    }

    firstRender.current = false;
  }, [soapToEdit]);

  const tabs = [
    {
      label: "Histórico de atendimentos",
      id: "attendance",
      content: (
        <>
          <DialogMedium
            title={`Suspender SOAP`}
            fullWidth
            maxWidth={"md"}
            open={!!toSuspend}
            handleClose={() => setToSuspend(null)}
          >
            <SoapSuspendForm
              soapId={toSuspend}
              handleClose={() => {
                setToSuspend(null);
                setSoapHistories([]);
                setPage(0);
                if (page === 0) soapHistoryQuery.refetch();
              }}
            />
          </DialogMedium>
          <Typography variant="h6" color="secondary" fontWeight="700">
            Histórico de Atendimentos
          </Typography>
          <PaginatedScrollList
            handlePage={setPage}
            page={page}
            totalPage={soapHistoryQuery.data?.total || 0}
            data={soapHistories}
            maxHeight="200px"
            minHeight="auto"
            hasDivider={false}
            emptyMessage="O paciente não possui SOAP anteriores."
            endMessage="Não há mais nenhum SOAP para este paciente."
            sx={{
              padding: "12px 12px 12px 0px",
            }}
            component={(item) => (
              <SoapCard
                data={item}
                key={item.id}
                isSelected={selectedHistoryId === item.id}
                pendencyId={pendencies?.soap_id}
                isEditing={!!soapToEdit}
                handleClick={() => {
                  if (selectedHistoryId === item.id) {
                    setSelectedHistoryId(null);
                    dataHistoryInToSoap(null);
                    setIdAttendance(params.attendance);
                    setHasConducts(false);
                    setTouchedValues(true);

                    if (history !== "true" && !patientDischarge) {
                      setDisableFields(false);
                    }
                  } else {
                    setTouchedValues(false);
                    setSelectedHistoryId(item.id);
                    setIdAttendance(item.attendance.id);
                  }
                }}
                handleView={() => {
                  setSoapId(item.id);
                  setProntuary(true);
                }}
                handleEdit={() => {
                  confirm("Deseja *editar* este *SOAP* ?", () => {
                    setTouchedValues(false);
                    setIdAttendance(item.attendance.id);
                    setSoapToEdit(item);
                  });
                }}
                handleSuspend={() => {
                  confirm("Deseja *suspender* este *SOAP* ?", () => setToSuspend(item.id));
                }}
              />
            )}
          />
        </>
      ),
    },
    {
      label: "Histórico de Classificação",
      id: "classification",
      content: <TabClassificationHistory />,
    },
  ];

  return (
    <>
      <SoapProntuary
        data={soapQuery.data}
        open={prontuary}
        handleClose={() => setProntuary(false)}
        isOdonto={odonto || ceo}
      />
      <Divider sx={{ backgroundColor: theme.palette.primary.medium, mt: 1, mb: 1 }} />
      <Tabs
        tabs={tabs}
        content
        handleClick={(tab) => {
          setPage(0);
          setHistoryType(tab.id);
        }}
      />
    </>
  );
}

export default SoapHistory;
