import { useCallback } from "react";
import urlQuery from "../helper/urlQuery";
import instance from "../config/api";

const path = "/signed-documents";

export default function useSignedDocuments() {
  const getSignedDocuments = useCallback(async (params) => {
    try {
      const response = await instance.get(path, { params: urlQuery(params) });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const getSignedDocumentsStatistics = useCallback(async (params) => {
    try {
      const response = await instance.get(`${path}/statistics`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const signDocuments = useCallback(async (data) => {
    try {
      const response = await instance.post(`${path}/sign`, data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const postSignedDocument = useCallback(async (data) => {
    try {
      const response = await instance.post(path, data, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  return {
    getSignedDocuments,
    signDocuments,
    getSignedDocumentsStatistics,
    postSignedDocument,
  };
}
