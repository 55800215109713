import React, { useState } from "react";
import {
  Box,
  Card,
  Divider,
  CardContent,
  CardHeader,
  Typography,
  Grid,
  Button,
} from "@mui/material";
import ProgramHistory from "../../pages/SOAP/ProgramHistory/ProgramHistory";
import SoapPatientCard from "../PatientsCard/SoapPatientCard";
import { Dialog, DialogMedium } from "../../helper";
import Monitoring from "../../pages/Monitoring";
import { Summarize, Home, Notes } from "@mui/icons-material";
import ClassificationHistory from "../../pages/SOAP/ClassificationHistory/ClassificationHistory";
import VisitHystory from "../../pages/SOAP/History/VisitHystory";
import { ReactComponent as Exames } from "../../assets/svg/exames_sm.svg";
import Exams from "../../pages/Exams";
import { useTheme } from "@mui/styles";
import GeneralAnnotationsList from "../../pages/Attendance/GeneralAnnotations/List";
import Forms from "@mui/icons-material/InsertDriveFileOutlined";
import EditorForms from "../../pages/Attendance/EditorForms/EditorForms";

function SoapContainer({
  title,
  history,
  children,
  maxWidth,
  width,
  patient,
  attendance,
  programHistory = true,
  classificationHistory = true,
  vaccine,
  allergies,
}) {
  const [openMonitoring, setOpenMonitoring] = useState(false);
  const [openVisits, setOpenVisits] = useState(false);
  const [openAnnotations, setOpenAnnotations] = useState(false);
  const [examsModal, setExamsModal] = useState(false);
  const [openForms, setOpenForms] = useState(false);
  const theme = useTheme();

  return (
    <>
      <Dialog open={examsModal} handleClose={() => setExamsModal(false)}>
        <Exams attendanceId={attendance?.id} hideBackButton />
      </Dialog>
      <DialogMedium
        open={openMonitoring}
        title={"Histórico de monitoramentos"}
        maxWidth="xl"
        fullWidth={true}
        handleClose={() => setOpenMonitoring(false)}
      >
        <Monitoring patientId={patient?.id} />
      </DialogMedium>
      <DialogMedium
        open={openVisits}
        title={"Histórico de Visitas"}
        maxWidth="xl"
        fullWidth={true}
        handleClose={() => setOpenVisits(false)}
      >
        <VisitHystory attendance={attendance} patient={patient} />
      </DialogMedium>
      <DialogMedium
        open={openAnnotations}
        title={"Histórico de Anotações gerais"}
        maxWidth="xl"
        fullWidth={true}
        handleClose={() => setOpenAnnotations(false)}
      >
        <GeneralAnnotationsList patient={patient} />
      </DialogMedium>
      <Dialog
        open={openForms}
        title="Histórico de fichas"
        maxWidth="md"
        fullWidth={true}
        handleClose={() => setOpenForms(false)}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 5,
          }}
        >
          {patient.name}
        </Typography>
        <EditorForms
          staticPatientId={patient.id}
          attendanceId={attendance?.id}
          showFormsWithResponse
          staticType={["pregnant", "pregnant_first_evaluation", "ampi", "child"]}
          showTabs
          showOnlyTable
          disableResponse={() => true}
          disableActions={(action) => {
            return ["edit", "delete", "finish"].includes(action);
          }}
        />
      </Dialog>
      <Box maxWidth={maxWidth} width={width} sx={{ marginTop: "24px", height: "100%" }}>
        <Card sx={{ borderRadius: 3 }}>
          <CardHeader
            sx={{ textAlign: `left` }}
            title={
              <Typography
                variant="h7"
                color="primary"
                fontWeight="500"
                textAlign="center"
                fontSize={30}
              >
                <Typography component="span" color="secondary" fontWeight="500" fontSize={30}>
                  {title?.[0]}
                </Typography>
                {title?.substring(1)}
              </Typography>
            }
          />
          <Divider variant="middle" sx={{ marginTop: "-10px", backgroundColor: "#D3E3ED" }} />
          <CardContent>
            <Grid container spacing={2} sx={{ pt: 2 }}>
              <Grid item xs={history ? 8 : 12} sx={{ position: "relative" }}>
                {children}
              </Grid>
              {!vaccine && (
                <Grid item xs={4} display="flex" flexDirection="column" gap={1}>
                  <SoapPatientCard attendance={attendance} allergies={allergies} />
                  <Divider />
                  <Box
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexWrap: "wrap",
                      justifyContent: "center",
                      button: {
                        display: "flex",
                        columnGap: 1,
                        lineHeight: 0.5,
                      },
                    }}
                  >
                    <Button variant="outlined" onClick={() => setOpenMonitoring(true)}>
                      <Summarize sx={{ mb: 0.1 }} /> Monitoramentos
                    </Button>
                    <Button variant="outlined" onClick={() => setOpenVisits(true)}>
                      <Home sx={{ mb: 0.1 }} /> ESF
                    </Button>
                    <Button variant="outlined" onClick={() => setOpenForms(true)}>
                      <Forms sx={{ mb: 0.1 }} /> Fichas
                    </Button>
                    <Button variant="outlined" onClick={() => setOpenAnnotations(true)}>
                      <Notes sx={{ mb: 0.1 }} /> Anotações Gerais
                    </Button>
                    <Button variant="outlined" onClick={() => setExamsModal(true)}>
                      <Exames
                        style={{
                          fill: theme.palette.primary.main,
                          width: 20,
                          height: 20,
                          marginRight: 10,
                        }}
                      />{" "}
                      Prontuário Integrado
                    </Button>
                  </Box>
                  {history ? history : null}
                  {programHistory ? <ProgramHistory /> : null}
                  {classificationHistory ? <ClassificationHistory /> : null}
                </Grid>
              )}
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}

export default SoapContainer;
