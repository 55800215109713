import React from "react";
import { Typography, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import * as Yup from "yup";
import { Box } from "@mui/material";
import { useMutation, useQueryClient } from "react-query";
import useServices from "../../../service/useServices";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { SwitchField, TextField } from "../../../components/FormFields";
import AutocompleteField from "../../../components/FormFields/AutocompleteField";
import { useNotifier } from "../../../hooks";

function SettingsEditService({ service, employeeTypes, handleClose }) {
  const notify = useNotifier();
  const { updateService } = useServices();
  const queryClient = useQueryClient();
  const updateServiceMutation = useMutation(updateService);

  const InitialValuesService = {
    describe: service?.describe,
    active: service?.active,
    employee_types: service?.employee_Types,
    info: service?.info,
    prefix: service?.prefix,
    classification_risk: service?.classification_risk || false,
    unscheduled: service?.unscheduled || false,
    work_list: service?.work_list || false,
    has_priority: service?.has_priority,
    mab: service?.mab || false,
    ceo: service?.ceo || false,
    odontology: service?.odontology || false,
    hide_on_caller: service?.hide_on_caller || false,
  };

  const validator = Yup.object().shape({
    describe: Yup.string().required("É requerido"),
    subtitle: Yup.string().nullable(),
    prefix: Yup.string()
      .max(4, "O prefixo deve ter no máximo 4 caracteres.")
      .test("not-number", "Prefixo inválido.", (value) => !value || value?.match(/^[A-Za-z]+$/)),
    classification_risk: Yup.boolean(),
    unschedule: Yup.boolean(),
    work_list: Yup.boolean(),
    has_priority: Yup.boolean(),
    mab: Yup.boolean(),
    hide_on_caller: Yup.boolean(),
  });

  const { control, handleSubmit, watch } = useForm({
    defaultValues: InitialValuesService,
    resolver: yupResolver(validator),
  });

  function findAddedAndRemovedItems(initialArray, newArray) {
    const addedItems = [];
    const removedItems = [];

    // Encontrar itens adicionados
    newArray.forEach((newItem) => {
      const existsInInitialArray = initialArray.some(
        (initialItem) => initialItem.id === newItem.id
      );
      if (!existsInInitialArray) {
        addedItems.push(newItem.id);
      }
    });

    // Encontrar itens removidos
    initialArray.forEach((initialItem) => {
      const existsInNewArray = newArray.some((newItem) => newItem.id === initialItem.id);
      if (!existsInNewArray) {
        removedItems.push(initialItem.id);
      }
    });

    return { addedItems, removedItems };
  }

  function handleSubmitUpdateService(values) {
    const { addedItems, removedItems } = findAddedAndRemovedItems(
      service.employee_Types,
      values.employee_types
    );
    updateServiceMutation.mutate(
      {
        id: service.id,
        data: {
          ...values,
          employee_types_to_add: addedItems,
          employee_types_to_remove: removedItems,
        },
      },
      {
        onSuccess: (response) => {
          notify(response.message, "success");
          queryClient.invalidateQueries("services");
          handleClose();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  }

  const [unscheduled, work_list, classification_risk] = watch([
    "unscheduled",
    "work_list",
    "classification_risk",
  ]);

  return (
    <>
      <Box>
        <Typography color="secondary">Item a ser atualizado:</Typography>
      </Box>
      <Grid container sx={{ marginTop: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              control={control}
              name="describe"
              label="Nome do serviço"
              disabled={!!service?.system_id}
            />
          </Grid>
          <Grid item xs={12} marginTop={1}>
            <Grid item container xs={12}>
              <Grid item xs={5}>
                <SwitchField
                  control={control}
                  name="classification_risk"
                  label="Classificação de Risco"
                  disabled={!!unscheduled || !!work_list}
                />
              </Grid>
              <Grid item xs={4}>
                <SwitchField
                  control={control}
                  name="unscheduled"
                  label="Não Agendado"
                  disabled={!!classification_risk || !!work_list}
                />
              </Grid>
              <Grid item xs={3}>
                <SwitchField
                  control={control}
                  name="work_list"
                  label="Work-List"
                  disabled={!!unscheduled || !!classification_risk}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={7}>
            <AutocompleteField
              control={control}
              name="employee_types"
              label="Adicionar tipo de profissional"
              options={employeeTypes}
              optionLabelKey="description"
              multiple
            />
          </Grid>
          <Grid item xs={5}>
            <TextField control={control} label="Prefixo" name="prefix" />
          </Grid>
          <Grid item xs={12}>
            <TextField control={control} label="Descrição" name="info" multiline rows={8} />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Grid xs={4}>
              <SwitchField control={control} name="active" label="Alterar Status" />
            </Grid>

            <Grid xs={4}>
              <SwitchField control={control} name="ceo" label="CEO" />
            </Grid>
            <Grid xs={4}>
              <SwitchField control={control} name="odontology" label="Odontologico" />
            </Grid>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              mt: 1,
            }}
          >
            <Grid xs={4}>
              <SwitchField control={control} name="has_priority" label="Prioridade no totem" />
            </Grid>
            <Grid xs={4}>
              <SwitchField control={control} name="mab" label="Urgência MAB" />
            </Grid>
            <Grid xs={4}>
              <SwitchField control={control} name="hide_on_caller" label="Esconder no chamador" />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={3} mt={2}>
          <Button
            variant="contained"
            onClick={handleSubmit(handleSubmitUpdateService)}
            onLoad={updateServiceMutation.isLoading}
          >
            Atualizar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}

export default SettingsEditService;
