import {
  Box,
  Button,
  Divider,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import { AvatarGender } from "../../../components";
import RecentActorsIcon from "@mui/icons-material/RecentActors";
import EventIcon from "@mui/icons-material/Event";
import { format, parseISO } from "date-fns";
import { tokenCEMPDF } from "../../../pdfModels";
import { useContext, useState } from "react";
import { ConfirmDialog } from "../../../helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { useAttendaces } from "../../../service";
import { useMutation } from "react-query";
import { useNotifier } from "../../../hooks";

export default function FinishStep({ confirm, handleReset, handleBack }) {
  const theme = useTheme();
  const notify = useNotifier();
  const [openCEMPDFDialog, setOpenCEMPDFDialog] = useState(false);
  const { userData } = useContext(AuthContext);

  const { postAttendaces } = useAttendaces();
  const confirmMutation = useMutation(postAttendaces);

  const textStyle = {
    fontFamily: "Montserrat",
    fontSize: "16px",
  };

  const infoStyle = {
    ...textStyle,
    color: theme.palette.primary.font,
  };

  const labelStyle = {
    ...textStyle,
    color: theme.palette.primary.light,
  };

  function handlePrinter() {
    const pdf = tokenCEMPDF(confirm.patient);
    pdf.open();
    handleReset();
  }

  function handleFinishConfirmation() {
    const data = confirm.data;
    const type = confirm?.scheduling?.attendanceType;

    const confirmation = {
      id_health_insurance: data?.insurance?.id,
      insurancePlan_id: data?.insurancePlan_id?.id,
      card_number: data?.card_number,
      expiration_date: data?.expiration_date,
      id_scheduling: confirm?.scheduling?.id,
      id_patient: confirm?.patient?.id,
      id_employee: data?.employee?.id,
      provenance_id: data?.provenance.id,
      procedure_id: data?.procedure?.id,
      id_specialty: data?.specialty?.id,
      type,
      id_sector: data?.sector?.id,
      id_service: data?.service.id,
      date_late: type === "Demanda Espontanea" ? confirm?.date_late : null,
      justification_note:
        type === "Demanda Espontanea" ? confirm?.justification_note : null,
      homecare_modality_id: data.modalities?.id,
      location_id: data.locations.id,
      preload: confirm.preload ?? null,
    };

    confirmMutation.mutate(confirmation, {
      onSuccess() {
        const proceduresTussCode = ["0211049000", "0211049018", "0205029912", "0205010040", "0204030030", "0204030188"];

        if (
          userData.company.type.CEM &&
          proceduresTussCode.includes(data.procedure.tuss_code)
        ) {
          setOpenCEMPDFDialog(true);
        } else {
          handleReset();
        }

        notify("Atendimento Confirmado com Sucesso!", "success");
      },
      onError(error) {
        notify(error.message, "error");
      },
    });
  }

  return (
    <>
      <ConfirmDialog
        message="Você deseja imprimir a *ficha de atendimento* do paciente?"
        open={openCEMPDFDialog}
        handleClose={() => {
          setOpenCEMPDFDialog(false);
          handleReset();
        }}
        handleConfirm={handlePrinter}
        actions
      />
      <Grid container spacing={4}>
        <Grid item xs={12}>
          <Stack direction="row" spacing={0.5}>
            <AvatarGender gender={confirm.patient.gender || ""} size={60} />
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{ ...labelStyle, fontSize: "18px" }}
              >
                {confirm.patient.name}
              </Typography>
              <Typography style={infoStyle}>
                Data de nascimento:{" "}
                {confirm.patient.birth_date
                  ? format(parseISO(confirm.patient.birth_date), "dd/MM/yyyy")
                  : "Não informado"}
              </Typography>
              <Typography style={infoStyle}>
                CPF: {confirm.patient.physic_national ?? "Não informado"}
              </Typography>
              <Typography style={infoStyle}>
                Mãe: {confirm.patient.mother_name ?? "Não informado"}
              </Typography>
            </Box>
          </Stack>
          <Box sx={{ mt: 2 }}>
            <Divider />
          </Box>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={0.5}>
            <EventIcon
              style={{
                fontSize: "40px",
                color: theme.palette.primary.light,
              }}
            />
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{ ...labelStyle, fontSize: "18px" }}
              >
                Dados do atendimento
              </Typography>
              {confirm.scheduling?.date && (
                <Stack direction="row" spacing={0.5}>
                  <Typography style={labelStyle}>Data:</Typography>
                  <Typography style={infoStyle}>
                    {format(new Date(confirm.scheduling.date), "dd/MM/yyyy")}
                    {confirm.scheduling.hour
                      ? ` às ${confirm.scheduling.hour}`
                      : ""}
                  </Typography>
                </Stack>
              )}
              <Stack direction="row" spacing={0.5}>
                <Typography style={labelStyle}>Procedimento:</Typography>
                <Typography
                  style={infoStyle}
                >{`${confirm.data.procedure?.tuss_code} - ${confirm.data.procedure?.name}`}</Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography style={labelStyle}>Procedencia:</Typography>
                <Typography style={infoStyle}>
                  {confirm.data.provenance?.describe}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography style={labelStyle}>
                  Local de Atendimento:
                </Typography>
                <Typography style={infoStyle}>
                  {confirm.data.locations?.description}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography style={labelStyle}>Modalidade:</Typography>
                <Typography style={infoStyle}>
                  {confirm.data.modalities?.siga_code || "Não informado"}
                </Typography>
              </Stack>
              {confirm.date_late && (
                <Stack direction="row" spacing={0.5}>
                  <Typography style={labelStyle}>Dt. de referência:</Typography>
                  <Typography style={infoStyle}>
                    {format(new Date(confirm.date_late), "dd/MM/yyyy")}
                  </Typography>
                </Stack>
              )}
              {confirm.justification_note && (
                <Stack direction="row" spacing={0.5}>
                  <Typography style={labelStyle}>Justificativa:</Typography>
                  <Typography style={infoStyle}>
                    {confirm.justification_note}
                  </Typography>
                </Stack>
              )}
              <Stack direction="row" spacing={0.5}>
                <Typography style={labelStyle}>Tipo de Atendimento:</Typography>
                <Typography style={infoStyle}>
                  {confirm.scheduling?.attendanceType}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography style={labelStyle}>Serviço:</Typography>
                <Typography style={infoStyle}>
                  {confirm.data.service?.describe}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography style={labelStyle}>Especialidade:</Typography>
                <Typography style={infoStyle}>
                  {confirm.data.specialty?.describe}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography style={labelStyle}>Profissional:</Typography>
                <Typography style={infoStyle}>
                  {confirm.data.employee?.name}
                </Typography>
              </Stack>
              {confirm.data.sector && (
                <Stack direction="row" spacing={0.5}>
                  <Typography style={labelStyle}>Setor:</Typography>
                  <Typography style={infoStyle}>
                    {confirm.data.sector.describe}
                  </Typography>
                </Stack>
              )}
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={6}>
          <Stack direction="row" spacing={0.5}>
            <RecentActorsIcon
              style={{
                fontSize: "40px",
                color: theme.palette.primary.light,
              }}
            />
            <Box
              sx={{
                width: "100%",
              }}
            >
              <Typography
                variant="subtitle2"
                style={{ ...labelStyle, fontSize: "18px" }}
              >
                Dados do Plano de Saúde
              </Typography>
              <Stack direction="row" spacing={0.5}>
                <Typography style={labelStyle}>Convênio:</Typography>
                <Typography style={infoStyle}>
                  {confirm.data.insurance?.describe}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={0.5}>
                <Typography style={labelStyle}>Número da carteira:</Typography>
                <Typography style={infoStyle}>
                  {confirm.data.insurance?.describe === "SUS"
                    ? confirm.data.sus_number
                    : confirm.data.card_number}
                </Typography>
              </Stack>
              {confirm.data.expiration_date && (
                <Stack direction="row" spacing={0.5}>
                  <Typography style={labelStyle}>
                    Validade da carteira:
                  </Typography>
                  <Typography style={infoStyle}>
                    {format(
                      new Date(confirm.data.expiration_date),
                      "dd/MM/yyyy"
                    )}
                  </Typography>
                </Stack>
              )}
              {confirm.data.insurancePlan_id && (
                <Stack direction="row" spacing={0.5}>
                  <Typography style={labelStyle}>Plano:</Typography>
                  <Typography style={infoStyle}>
                    {confirm.data.insurancePlan_id.describe}
                  </Typography>
                </Stack>
              )}
            </Box>
          </Stack>
        </Grid>

        <Grid
          md={12}
          sx={{ display: "flex", justifyContent: "space-between" }}
          item
        >
          <Button onClick={handleBack}>Voltar</Button>
          <Button
            color="secondary"
            variant="contained"
            onClick={handleFinishConfirmation}
            disabled={confirmMutation.isLoading}
          >
            Confirmar
          </Button>
        </Grid>
      </Grid>
    </>
  );
}
