import { Box, Divider, Grid, Typography, useTheme } from "@mui/material";
import OutlinedContainer from "../../../components/OutlinedContainer/OutlinedContainer";
import { format, parseISO } from "date-fns";
import { convertDateToBirthday, mapObject, calcByDUM, calcByUSG } from "../../../utils";
import {
  ampiOptions,
  cross_bite,
  deep_bite_class_1,
  deep_bite_class_2,
  deep_bite_class_3,
  dental_supplies,
  open_bite,
  risk_stratification_labels,
} from "../../../config/standardSelects";
import FootInfo from "./FootInfo";

export default function SoapRegularData({
  data,
  patientData,
  prontuaryOdonto,
  dataOdontoProcedures,
  dataRps,
}) {
  const theme = useTheme();
  const convertedBirthDate = convertDateToBirthday(
    patientData.birth_date,
    ["years", "months"],
    false
  );

  const prescriptionWithoutComplement = data?.plan?.prescriptions?.find(
    (prescription) => !prescription.soap_complement_id
  );

  const againstReferencesWithoutComplement = data?.against_references?.filter(
    (reference) => !reference.soap_complement_id
  );

  const declarationsWithoutComplement = data?.plan?.documents.filter(
    (reference) => !reference.soap_complement_id
  );

  const RiskStratification = ({ data }) => {
    if (data?.objective?.risk_stratification) {
      const { id, cardiovascular_risk, therapeutic_goal, ...responses } =
        data.objective.risk_stratification;

      const riskFactors = Object.entries(risk_stratification_labels.risk_factors);
      const loa = Object.entries(risk_stratification_labels.loa);
      const cdAdvanced = Object.entries(risk_stratification_labels.cd_advanced);

      return (
        <>
          <Typography fontWeight="500">Estratificação RCV</Typography>
          <Typography fontWeight="500" marginLeft={2} mt={1} color={"red"}>
            {`RISCO CARDIOVASCULAR: ${cardiovascular_risk}`}
          </Typography>
          <Typography fontWeight="500" marginLeft={2} mt={1} color="secondary.main">
            {therapeutic_goal}
          </Typography>
          <Typography fontWeight="500" marginLeft={2} mt={1}>
            Fatores de Risco
          </Typography>
          {!!riskFactors.length && (
            <Box component="ul">
              {riskFactors.map(([key, label]) => (
                <Typography color={theme.palette.primary.main} component="li" key={label}>
                  <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                    {label + ": "}
                  </Typography>
                  {responses[key] ? "Sim" : "Não"}
                </Typography>
              ))}
            </Box>
          )}
          <Typography fontWeight="500" marginLeft={2} mt={1}>
            Lesão de Órgão Alvo (LOA)
          </Typography>
          {!!loa.length && (
            <Box component="ul">
              {loa.map(([key, label]) => (
                <Typography color={theme.palette.primary.main} component="li" key={label}>
                  <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                    {label + ": "}
                  </Typography>
                  {responses[key] ? "Sim" : "Não"}
                </Typography>
              ))}
            </Box>
          )}
          <Typography fontWeight="500" marginLeft={2} mt={1}>
            Doença Cardiovascular Avançada
          </Typography>
          {!!cdAdvanced.length && (
            <Box component="ul">
              {cdAdvanced.map(([key, label]) => (
                <Typography color={theme.palette.primary.main} component="li" key={label}>
                  <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                    {label + ": "}
                  </Typography>
                  {responses[key] ? "Sim" : "Não"}
                </Typography>
              ))}
            </Box>
          )}
        </>
      );
    }

    return null;
  };

  function Exams({ data }) {
    return (
      <Grid item xs={12} my={1}>
        <Typography fontWeight="500" color={theme.palette.primary.light}>
          Exames:
        </Typography>
        <Box component="ul">
          {data.map((item) => (
            <Box component="li" color={theme.palette.primary.main}>
              <Typography>
                {item?.describe.firstCharToUp()} - {item?.type}
              </Typography>
              {item.notes && <Typography>{item.notes}</Typography>}
            </Box>
          ))}
        </Box>
      </Grid>
    );
  }

  function Medications({ data }) {
    return (
      <Grid item xs={12} my={1}>
        <Typography fontWeight="500" color={theme.palette.primary.light}>
          Receituários:
        </Typography>
        <Box component="ul">
          {data.map((item) => (
            <Box component="li" color={theme.palette.primary.main}>
              <Typography>
                {item?.name.firstCharToUp()}
                {` ( ${item.posology} ) `}
                {item?.quantity ? `- ${item?.quantity}` : ""}
                {item?.continuous_use ? " - Uso contínuo" : ` - ${item?.presentation}`}
                {item.special ? " - Medicamento Especial" : ""}
              </Typography>
              {item.notes && <Typography>{item.notes}</Typography>}
            </Box>
          ))}
        </Box>
      </Grid>
    );
  }

  function MedicationsOnSite({ data }) {
    return (
      <Grid item xs={12} my={1}>
        <Typography fontWeight="500" color={theme.palette.primary.light}>
          Medicação:
        </Typography>
        <Box component="ul">
          {data.map((item) => (
            <Box component="li" color={theme.palette.primary.main}>
              <Typography>
                {item?.name.firstCharToUp()}
                {` ( ${item.posology} ) `}
                {item?.quantity ? `- ${item?.quantity}` : ""}
                {item?.continuous_use ? " - Uso contínuo" : ` - ${item?.presentation}`}
              </Typography>
              {item.notes && <Typography>{item.notes}</Typography>}
            </Box>
          ))}
        </Box>
      </Grid>
    );
  }

  function AgainstReferences({ data }) {
    return (
      <Grid item xs={12} my={1}>
        <Typography fontWeight="500" color={theme.palette.primary.light}>
          Contrarreferências :
        </Typography>
        <Box component="ul">
          {data.map((item) => (
            <Box color={theme.palette.primary.main} component="li">
              <Typography>{item.requested_specialty?.describe}</Typography>
              <Typography sx={{ ml: 3 }}>Prioridade: {item.priority}</Typography>
              <Typography sx={{ ml: 3 }}>
                Hipótese diagnóstica: {item.details?.hyphotesis}
              </Typography>
              <Typography sx={{ ml: 3 }}>Exames realizados: {item.details?.exams}</Typography>
              <Typography sx={{ ml: 3 }}>Motivo: {item.justification}</Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    );
  }

  function Declarations({ data }) {
    return (
      <Grid item xs={12} my={1}>
        <Typography fontWeight="500" color={theme.palette.primary.light}>
          Declarações :
        </Typography>
        <Box component="ul">
          {data.map((item) => (
            <Box color={theme.palette.primary.main} component="li">
              <Typography>{item.type}</Typography>
              {item.arrival_time && (
                <Typography sx={{ ml: 3 }}>Chegada: {item.arrival_time}</Typography>
              )}
              {item.departure_time && (
                <Typography sx={{ ml: 3 }}>Saida: {item.departure_time}</Typography>
              )}
              {item.companion_name && (
                <Typography sx={{ ml: 3 }}>Nome do acompanhante : {item.companion_name}</Typography>
              )}
              {item.cid_id && <Typography sx={{ ml: 3 }}>CID : {item.cid_id}</Typography>}
              <Typography sx={{ ml: 3 }}>Observação: {item.observations}</Typography>
            </Box>
          ))}
        </Box>
      </Grid>
    );
  }

  return (
    <OutlinedContainer label="SOAP">
      <Grid item xs={12}>
        <Divider sx={{ mb: 2 }} textAlign="left">
          <Typography color={theme.palette.primary.font} fontWeight="500" fontSize={18}>
            <Typography color={theme.palette.primary.light} component="span" fontSize={18}>
              S
            </Typography>
            ubjetivo
          </Typography>
        </Divider>
      </Grid>
      <Grid item xs={12} my={1}>
        {data?.subjective ? (
          <Grid item xs={12} my={1}>
            {data.subjective.reason && (
              <Typography color={theme.palette.primary.main}>
                <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                  Razão:{" "}
                </Typography>
                {data.subjective.reason}
              </Typography>
            )}
            {!!data.subjective?.ciaps?.length && (
              <Grid container item>
                <Typography color={theme.palette.primary.main}>
                  <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                    Alega:{" "}
                  </Typography>
                  {data.subjective.ciaps.map((item) => item.description.firstCharToUp()).join(", ")}
                </Typography>
              </Grid>
            )}
          </Grid>
        ) : (
          <Typography color={theme.palette.primary.main}>Não há subjetivo registrado</Typography>
        )}
      </Grid>
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} textAlign="left">
          <Typography color={theme.palette.primary.font} fontWeight="500" fontSize={18}>
            <Typography color={theme.palette.primary.light} component="span" fontSize={18}>
              O
            </Typography>
            bjetivo
          </Typography>
        </Divider>
      </Grid>
      {convertedBirthDate?.years >= 60 &&
        !!data?.objective?.ampi_classification &&
        !!data?.objective?.ampi_last_classification && (
          <>
            <Grid item xs={12} my={1}>
              <Typography color={theme.palette.primary.main}>
                <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                  Classificação AMPI:{" "}
                </Typography>
                {ampiOptions[data?.objective?.ampi_classification]}
              </Typography>
            </Grid>
            <Grid item xs={12} my={1}>
              <Typography color={theme.palette.primary.main}>
                <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                  Data da última classificação:{" "}
                </Typography>
                {format(parseISO(data?.objective?.ampi_last_classification), "dd/MM/yyyy")}
              </Typography>
            </Grid>
          </>
        )}

      {data?.objective?.diabetic_foot_assessment && (
        <FootInfo
          footData={data?.objective?.diabetic_foot_assessment}
          dateAssessment={data?.objective?.date_diabetic_foot_assessment}
          newAssessmentDate={data?.objective?.new_evaluation_scheduled_date}
        />
      )}
      {!!data?.objective?.pregnancy_record && (
        <>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Gestante:{" "}
              </Typography>
              {patientData?.pregnant ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Gravidez Planejada ?{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.planned_pregnancy ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Número de Gestações (G):{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.gravidity || "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Número de Partos (P):{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.parity || "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Número de Partos Normais (N):{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.vaginal_deliveries_number || "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Número de Partos Cesários (C):{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.cesarean_deliveries_number || "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Número de Abortamentos Cesários (C):{" "}
              </Typography>
              {data?.objective?.pregnancy_record?.abortions_number || "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Data da última menstruação:{" "}
              </Typography>
              {data.objective?.pregnancy_record.last_menstrual_period
                ? format(
                    parseISO(data.objective.pregnancy_record.last_menstrual_period),
                    "dd/MM/yyyy"
                  )
                : "Não informado"}
            </Typography>
          </Grid>
          {data?.objective?.pregnancy_record.last_menstrual_period && (
            <>
              <Grid item xs={12} my={1}>
                <Typography color={theme.palette.primary.main}>
                  <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                    Idade gestacional (DUM):{" "}
                  </Typography>
                  {
                    calcByDUM(
                      data.attendance.createdAt,
                      data.objective.pregnancy_record.last_menstrual_period
                    ).gestationalAge
                  }
                </Typography>
              </Grid>
              <Grid item xs={12} my={1}>
                <Typography color={theme.palette.primary.main}>
                  <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                    Data prevista de parto (DUM):{" "}
                  </Typography>
                  {
                    calcByDUM(
                      data.attendance.createdAt,
                      data.objective.pregnancy_record.last_menstrual_period
                    ).probableDateOfBirth
                  }
                </Typography>
              </Grid>
            </>
          )}
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Data do USG obstétrico:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.obstetric_usg_date
                ? format(parseISO(data.objective.pregnancy_record.obstetric_usg_date), "dd/MM/yyyy")
                : "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Semanas no USG:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.obstetric_usg_weeks || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Dias no USG:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.obstetric_usg_days || "Não informado"}
            </Typography>
          </Grid>
          {data?.objective?.pregnancy_record?.obstetric_usg_date &&
            data?.objective?.pregnancy_record.obstetric_usg_weeks &&
            data?.objective?.pregnancy_record.obstetric_usg_days >= 0 && (
              <>
                <Grid item xs={12} my={1}>
                  <Typography color={theme.palette.primary.main}>
                    <Typography
                      color={theme.palette.primary.light}
                      fontWeight="500"
                      component="span"
                    >
                      Idade gestacional (USG):{" "}
                    </Typography>
                    {
                      calcByUSG(
                        data.attendance.createdAt,
                        data.objective.pregnancy_record.obstetric_usg_date,
                        data.objective.pregnancy_record.obstetric_usg_weeks,
                        data.objective.pregnancy_record.obstetric_usg_days
                      ).gestationalAge
                    }
                  </Typography>
                </Grid>
                <Grid item xs={12} my={1}>
                  <Typography color={theme.palette.primary.main}>
                    <Typography
                      color={theme.palette.primary.light}
                      fontWeight="500"
                      component="span"
                    >
                      Data prevista de parto (USG):{" "}
                    </Typography>
                    {
                      calcByUSG(
                        data.attendance.createdAt,
                        data.objective.pregnancy_record.obstetric_usg_date,
                        data.objective.pregnancy_record.obstetric_usg_weeks,
                        data.objective.pregnancy_record.obstetric_usg_days
                      ).probableDateOfBirth
                    }
                  </Typography>
                </Grid>
              </>
            )}
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Movimentos fetais:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.fetal_movements ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Suplementação com Ácido Fólico:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.folic_acid_supplementation ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Suplementação de Carbonato de Cálcio:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.calcium_carbonate_supplementation ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Edema:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.edema === null
                ? "Sem edema"
                : data?.objective?.pregnancy_record?.edema || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Risco gestacional:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.gestational_risk || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Apresentação fetal:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.fetal_presentation || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Exame clínico normal:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.normal_clinical_exam || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Exame ginecológico normal:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.normal_gynecological_exam || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Exame de mamas normal?:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.normal_breast_exam || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Últimas gestações:{" "}
              </Typography>
              {data?.objective?.pregnancy_record.previous_pregnancies?.length > 0
                ? data.objective.pregnancy_record.previous_pregnancies.reduce(
                    (current, value, index, array) => {
                      if (array.length - 1 === index) {
                        return current + format(parseISO(value), "dd/MM/yyyy");
                      } else {
                        return current + format(parseISO(value), "dd/MM/yyyy") + ", ";
                      }
                    },
                    ""
                  )
                : "Não informado"}
            </Typography>
          </Grid>
        </>
      )}
      {convertedBirthDate?.years <= 6 && (
        <>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Aleitamento materno:{" "}
              </Typography>
              {data?.objective?.child_record?.breastfeeding}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Ortolani:{" "}
              </Typography>
              {data?.objective?.child_record?.ortolani || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Desenvolvimento Neuropsicomotor(DNPM):{" "}
              </Typography>
              {data?.objective?.child_record?.neuropsychomotor_development || "Não informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Reflexo vermelho:{" "}
              </Typography>
              {data?.objective?.child_record?.red_reflex_exam || "Não informado"}
            </Typography>
          </Grid>
        </>
      )}
      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
            Exame físico/psíquico:{" "}
          </Typography>
          {data?.objective?.physical_exams || "Não informado"}
        </Typography>
      </Grid>
      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
            Possui alergia:{" "}
          </Typography>
          {data?.objective?.allergy_status || "Não informado"}
        </Typography>
      </Grid>
      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
            Exames complementares | Medicamentos em uso:{" "}
          </Typography>
          {data?.objective?.complementary_exams || "Não informado"}
        </Typography>
      </Grid>

      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
            Descrição:{" "}
          </Typography>
          {data?.objective?.allergy_description || "Não informado"}
        </Typography>
      </Grid>

      {!!data?.objective?.preExistIllnesses?.length && (
        <Grid item xs={12} my={1}>
          <Typography color={theme.palette.primary.main}>
            <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
              Comorbidades:{" "}
            </Typography>
            {data.objective.preExistIllnesses.map((item) => item.describe).join(", ")}
          </Typography>
        </Grid>
      )}
      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
            Vacina em dia:{" "}
          </Typography>

          {typeof data?.objective?.vaccine_up_to_date === "boolean"
            ? data?.objective?.vaccine_up_to_date
              ? " Sim"
              : " Não"
            : "Não informado"}
        </Typography>
      </Grid>
      <RiskStratification data={data} />
      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} textAlign="left">
          <Typography color={theme.palette.primary.font} fontWeight="500" fontSize={18}>
            <Typography color={theme.palette.primary.light} component="span" fontSize={18}>
              A
            </Typography>
            valiação
          </Typography>
        </Divider>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          mt: 1,
          mb: 1,
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Typography color={theme.palette.primary.main}>
          <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
            Avaliação da consulta:{" "}
          </Typography>
          {data?.assessment?.query_assessment || "Não informado"}
        </Typography>
        <Typography fontWeight="500" color={theme.palette.primary.light}>
          CIAPS:
        </Typography>
        {data?.assessment?.ciaps?.length ? (
          <Box component="ul">
            {data.assessment.ciaps.map((item) => (
              <Typography color={theme.palette.primary.main} component="li" key={item.id}>
                <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                  {item.description.firstCharToUp() + " - "}
                </Typography>
                {item.acronym}
              </Typography>
            ))}
          </Box>
        ) : (
          <Typography color={theme.palette.primary.main}>Nenhum ciap informado</Typography>
        )}
        {prontuaryOdonto && (
          <>
            <Typography fontWeight="500">Vigilância em saúde bucal</Typography>
            {!!data?.assessment?.oral_health_surveillances?.length ? (
              <Box component="ul">
                {data.assessment.oral_health_surveillances.map((item) => (
                  <Typography color={theme.palette.primary.main} component="li" key={item.id}>
                    <Typography color={theme.palette.primary.main} fontWeight="500">
                      {item?.description.firstCharToUp() + " - "}
                    </Typography>
                    {item?.acronym}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography color={theme.palette.primary.main}>
                Nenhuma vigilância informada
              </Typography>
            )}
          </>
        )}
        <Typography fontWeight="500" color={theme.palette.primary.light}>
          CID:
        </Typography>
        {data?.assessment?.cids?.length ? (
          <Box component="ul">
            {data.assessment.cids.map((item) => (
              <Typography color={theme.palette.primary.main} component="li" key={item.id}>
                <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                  {item?.description.firstCharToUp() + " - "}
                </Typography>
                {item.id}
              </Typography>
            ))}
          </Box>
        ) : (
          <Typography color={theme.palette.primary.main}>Nenhum cid informado</Typography>
        )}
        {!!data?.assessment?.oral_health_surveillance?.length && (
          <Typography color={theme.palette.primary.main}>
            <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
              Vigilância em saúde bucal:{" "}
            </Typography>
            {data.assessment.oral_health_surveillance
              .map(({ description }) => description)
              .join(", ")}
          </Typography>
        )}
      </Grid>

      <Grid item xs={12}>
        <Divider sx={{ my: 2 }} textAlign="left">
          <Typography color={theme.palette.primary.font} fontWeight="500" fontSize={18}>
            <Typography color={theme.palette.primary.light} component="span" fontSize={18}>
              P
            </Typography>
            lano
          </Typography>
        </Divider>
      </Grid>

      <Grid item xs={12} my={1}>
        <Typography color={theme.palette.primary.main}>
          <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
            Plano de consulta:{" "}
          </Typography>
          {data?.plan?.query_plan || "Não informado"}
        </Typography>
      </Grid>
      {prontuaryOdonto && (
        <>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Possui aparelho:{" "}
              </Typography>
              {data?.plan?.braces ? "Sim" : "Não"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Tipo de atendimento:{" "}
              </Typography>
              {data?.attendance_type?.describe || "Não Informada"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Tipo de consulta:{" "}
              </Typography>
              {data?.dental_appointment_type?.description || "Não Informada"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Tipo de tratamento:{" "}
              </Typography>
              {data?.plan?.treatment_type?.name
                ? `> ${data?.plan?.treatment_type?.name} - ${data?.plan?.treatment_type_conclusion?.name}`
                : data?.plan?.treatment_type_conclusion?.name
                ? data?.plan?.treatment_type_conclusion?.name
                : "Não Informado"}
            </Typography>
          </Grid>
          <Grid item xs={12} my={1}>
            <Typography color={theme.palette.primary.main}>
              <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                Possui contenção:{" "}
              </Typography>
              {data?.plan?.retainer ? "Sim" : "Não"}
            </Typography>
          </Grid>

          {data?.plan?.upper_denture || data?.plan?.upper_denture ? (
            <Grid item xs={12} my={1}>
              <Typography color={theme.palette.primary.main}>
                <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                  Prótese:{" "}
                </Typography>
                {data.plan.lower_denture ? "Total inferior" : ""}
                {data.plan.lower_denture && data.plan.upper_denture ? " | " : ""}
                {data.plan.upper_denture ? "Total inferior" : ""}
              </Typography>
            </Grid>
          ) : null}
          {!!data?.plan?.prosthesisCommand && (
            <Grid item xs={12} my={1}>
              <Typography color={theme.palette.primary.main}>
                <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                  Comanda Prótese:{" "}
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    component={"span"}
                    fontWeight="500"
                    color={theme.palette.primary.light}
                  >
                    - N° Caixa:{" "}
                  </Typography>
                  {data?.plan?.prosthesisCommand.box_number || "Não informado"}
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    component={"span"}
                    fontWeight="500"
                    color={theme.palette.primary.light}
                  >
                    - Tipo de Trabalho:{" "}
                  </Typography>
                  {data?.plan?.prosthesisCommand.work_type || "Não informado"}
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    component={"span"}
                    fontWeight="500"
                    color={theme.palette.primary.light}
                  >
                    - Cor do dente:{" "}
                  </Typography>
                  {data?.plan?.prosthesisCommand.tooth_color || "Não informado"}
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography
                    component={"span"}
                    fontWeight="500"
                    color={theme.palette.primary.light}
                  >
                    - Observações:{" "}
                  </Typography>
                  {data?.plan?.prosthesisCommand.notes || "Não informado"}
                </Typography>
              </Typography>
            </Grid>
          )}
          {!!dataOdontoProcedures.length && (
            <Grid item xs={12} my={1}>
              <Typography fontWeight="500" color={theme.palette.primary.light}>
                Procedimentos: (Local | Problema | Procedimento | Observação).
              </Typography>
              <Box component="ul">
                {dataOdontoProcedures.map((item, index) => (
                  <Typography
                    color={theme.palette.primary.main}
                    key={`odonto-procedures-${index}`}
                    component="li"
                  >
                    {item?.local}
                    {" | "}
                    {item?.problems}
                    {" | "}
                    {item?.procedures}
                    {" | "}
                    {item?.notes}
                  </Typography>
                ))}
              </Box>
            </Grid>
          )}
          {!!dataRps.length && (
            <Grid item xs={12} my={1}>
              <Typography color={theme.palette.primary.main}>
                <Typography component={"span"} fontWeight="500" color={theme.palette.primary.light}>
                  Registro periodontal simplificado:{" "}
                </Typography>
                {dataRps?.map((item, index, array) => {
                  if (array.length - 1 === index) {
                    return item;
                  }
                  return item + " | ";
                })}
              </Typography>
              <Typography color={theme.palette.primary.main}>
                <Typography component={"span"} fontWeight="500" color={theme.palette.primary.light}>
                  Observações:{" "}
                </Typography>
                {data.plan.sextant.notes}
              </Typography>
            </Grid>
          )}
          <Grid item xs={12} my={1}>
            {data?.plan?.orthodontic?.open_bite && (
              <Typography color={theme.palette.primary.main}>
                <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                  Mordida aberta (Ortodontia):{" "}
                </Typography>
                {mapObject(data.plan.orthodontic.open_bite, (value, label) => {
                  if (value) {
                    return open_bite[label];
                  }
                })
                  .filter(Boolean)
                  .join(", ")}
              </Typography>
            )}
            {data?.plan?.orthodontic?.crossbite && (
              <Typography color={theme.palette.primary.main}>
                <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                  Mordida cruzada (Ortodontia):{" "}
                </Typography>
                {mapObject(data.plan.orthodontic.crossbite, (value, label) => {
                  if (value) {
                    return cross_bite[label];
                  }
                })
                  .filter(Boolean)
                  .join(", ")}
              </Typography>
            )}
            {!!data?.plan?.orthodontic?.deep_bite && (
              <>
                <Typography color={theme.palette.primary.light} fontWeight="500">
                  Mordida profunda (Ortodontia):{" "}
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
                    Classe I com apinhamento:
                  </Typography>
                  {`${mapObject(data.plan.orthodontic.deep_bite.class_1, (value, label) => {
                    if (value && label !== "note") {
                      return deep_bite_class_1[label];
                    }
                  })
                    .filter(Boolean)
                    .join(", ")} - ${data.plan.orthodontic.deep_bite.class_1.note}`}
                </Typography>
                <Typography color={theme.palette.primary.light} fontWeight="500">
                  Classe II:
                </Typography>
                <Typography>
                  {`Divisão 1ª - ${mapObject(
                    data.plan.orthodontic.deep_bite.class_2?.division_1,
                    (value, label) => {
                      if (value) {
                        return deep_bite_class_2.division_1[label];
                      }
                    }
                  )
                    .filter(Boolean)
                    .join(", ")}`}
                </Typography>
                <Typography>
                  {`Divisão 2ª - ${mapObject(
                    data.plan.orthodontic.deep_bite.class_2?.division_2,
                    (value, label) => {
                      if (value) {
                        return deep_bite_class_2.division_2[label];
                      }
                    }
                  )
                    .filter(Boolean)
                    .join(", ")}`}
                </Typography>
                <Typography color={theme.palette.primary.main}>
                  <Typography color={theme.palette.primary.light} component="span" fontWeight="500">
                    Classe III:
                  </Typography>
                  {`${mapObject(data.plan.orthodontic.deep_bite.class_3, (value, label) => {
                    if (value) {
                      return deep_bite_class_3[label];
                    }
                  })
                    .filter(Boolean)
                    .join(", ")}`}
                </Typography>
              </>
            )}
            <Typography fontWeight="500" color={theme.palette.primary.light}>
              Técnica anestésica | Tipo de anestésico | Quantidade | Lote / Validade:
            </Typography>
            {data?.plan?.anesthesia?.[0]?.types?.length ? (
              <Box component="ul">
                {data.plan.anesthesia[0].types.map((item, index) => (
                  <Typography
                    color={theme.palette.primary.main}
                    component="li"
                    key={`anesthetic-types=${index}`}
                  >
                    {item.anesthetic_technique?.describe ?? "-"}
                    {" | "}
                    {item.typesDescribe?.describe ?? "-"}
                    {" | "}
                    {item.typesQuantity ?? "-"}
                    {" | "}
                    {item.typesBatch ?? "-"}
                    {" | "}
                    {item.typesExpirationDate
                      ? format(parseISO(item.typesExpirationDate), "dd/MM/yyyy")
                      : "-"}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography color={theme.palette.primary.main}>Nenhum tipo informado</Typography>
            )}
            <Typography fontWeight="500" color={theme.palette.primary.light}>
              Instrumentais | Lote | Validade:
            </Typography>
            {data?.plan?.anesthesia?.[0]?.sterilization?.length ? (
              <Box component="ul">
                {data.plan.anesthesia[0].sterilization.map((item, index) => (
                  <Typography
                    component="li"
                    color={theme.palette.primary.main}
                    key={`sterelizations-${index}`}
                  >
                    {index + 1}
                    {" - "}
                    {item?.sterilizationDescribe?.firstCharToUp()}
                    {" | "}
                    {item?.sterilizationBatch}
                    {" | "}
                    {item?.sterilizationExpirationDate
                      ? format(parseISO(item.sterilizationExpirationDate), "dd/MM/yyyy")
                      : ""}
                  </Typography>
                ))}
              </Box>
            ) : (
              <Typography color={theme.palette.primary.main}>
                Nenhum tipo instrumental informado
              </Typography>
            )}
          </Grid>
        </>
      )}
      {data?.plan?.reavaluation_comments && (
        <Typography color={theme.palette.primary.main}>
          <Typography color={theme.palette.primary.light} fontWeight="500" component="span">
            Reavaliação:{" "}
          </Typography>
          {data?.plan?.reavaluation_comments || "Não informado"}
        </Typography>
      )}
      {data?.plan?.ciaps && (
        <Grid item xs={12} my={1}>
          <Typography color={theme.palette.primary.main}>
            <Typography fontWeight="500" component="span" color={theme.palette.primary.light}>
              Ciaps:
            </Typography>
            {data.plan.ciaps.map((item) => item.description.firstCharToUp()).join(", ")}
          </Typography>
        </Grid>
      )}
      {data?.plan?.dental_supplies && prontuaryOdonto && (
        <Grid item xs={12} my={1}>
          <Typography color={theme.palette.primary.main}>
            <Typography component="span" fontWeight="500" color={theme.palette.primary.light}>
              Fornecimento:{" "}
            </Typography>
            {data.plan.dental_supplies.map((item, index, dentalSupplies) => {
              if (dentalSupplies.length - 1 === index) {
                return dental_supplies[item - 1];
              }
              return dental_supplies[item - 1] + ", ";
            })}
          </Typography>
        </Grid>
      )}
      {!!data?.conducts?.length && (
        <Grid item xs={12} my={1}>
          <Typography color={theme.palette.primary.main}>
            <Typography fontWeight="500" component="span" color={theme.palette.primary.light}>
              Condutas:
            </Typography>
            {data.conducts.map((item) => item?.description?.firstCharToUp()).join(", ")}
          </Typography>
        </Grid>
      )}
      {data?.plan?.administrative_procedure && (
        <Grid item xs={12} my={1}>
          <Typography color={theme.palette.primary.main}>
            <Typography fontWeight="500" color={theme.palette.primary.light} component="span">
              Procedimento Administrativo:{" "}
            </Typography>
            {`${
              data.plan.administrative_procedure.tuss_code
                ? data.plan.administrative_procedure.tuss_code + " - "
                : ""
            }${data.plan.administrative_procedure.name.firstCharToUp()}`}
          </Typography>
        </Grid>
      )}
      {!!prescriptionWithoutComplement?.exams.length && (
        <Exams data={prescriptionWithoutComplement.exams} />
      )}
      {!!prescriptionWithoutComplement?.medications.length && (
        <Medications data={prescriptionWithoutComplement.medications} />
      )}
      {!!prescriptionWithoutComplement?.medications_on_site.length && (
        <MedicationsOnSite data={prescriptionWithoutComplement.medications_on_site} />
      )}
      {!!data?.plan?.interventions?.length && (
        <Grid item xs={12} my={1}>
          <Typography fontWeight="500" color={theme.palette.primary.light}>
            Intervenções e/ou procedimentos clínicos :
          </Typography>
          <Box component="ul">
            {data.plan.interventions.map((item) => (
              <Box component="li" color={theme.palette.primary.main}>
                <Typography>
                  {item.procedure.name.firstCharToUp()}
                  {item.done ? "- Realizado" : "- Não Realizado"}
                </Typography>
                {item.notes && <Typography>{item.notes}</Typography>}
              </Box>
            ))}
          </Box>
        </Grid>
      )}
      {!!againstReferencesWithoutComplement?.length && (
        <AgainstReferences data={againstReferencesWithoutComplement} />
      )}
      {!!declarationsWithoutComplement?.length && (
        <Declarations data={declarationsWithoutComplement} />
      )}
      {!!data?.complements.length && (
        <Grid item xs={12}>
          <Divider sx={{ my: 2 }} textAlign="left">
            <Typography color={theme.palette.primary.font} fontWeight="500" fontSize={18}>
              Complementos
            </Typography>
          </Divider>
          <Box>
            {data.complements.map((complement, index, array) => {
              const isLastIndex = index === array.length - 1;

              const prescription = data?.plan?.prescriptions?.find(
                (prescription) => prescription.soap_complement_id === complement.id
              );

              const againstReferences = data?.against_references?.filter(
                (reference) => reference.soap_complement_id === complement.id
              );

              const declarations = data?.plan?.documents.filter(
                (reference) => reference.soap_complement_id === complement.id
              );

              return (
                <Grid container key={complement.id}>
                  <Grid item xs={12}>
                    <Typography fontWeight="500" color={theme.palette.primary.light}>
                      {`${index + 1}# - ${format(
                        parseISO(complement.createdAt),
                        "dd/MM/yyyy 'às' HH:mm"
                      )}`}
                    </Typography>
                    <Typography color={theme.palette.primary.main}>
                      {complement.complement}
                    </Typography>
                  </Grid>
                  {!!prescription?.exams?.length && <Exams data={prescription.exams} />}
                  {!!prescription?.medications?.length && (
                    <Medications data={prescription.medications} />
                  )}
                  {!!prescription?.medications_on_site?.length && (
                    <MedicationsOnSite data={prescription.medications_on_site} />
                  )}
                  {!!againstReferences?.length && <AgainstReferences data={againstReferences} />}
                  {!!declarations?.length && <Declarations data={declarations} />}
                  {!isLastIndex && (
                    <Grid xs={12} my={2}>
                      <Divider />
                    </Grid>
                  )}
                </Grid>
              );
            })}
          </Box>
        </Grid>
      )}
    </OutlinedContainer>
  );
}
