import { Box, Button, Grid, Typography } from "@mui/material";
import { ReactComponent as SextantSVG } from "../../../assets/svg/sextant.svg";
import { Info } from "@mui/icons-material";
import { FormCheckbox, FormTextField } from "../../../components";
import { FormSelectWithPaginate } from "../../../components/Form";
import { useContext, useEffect, useState } from "react";
import { AppContext } from "../../../contexts/AppContext";
import TableFilter from "../../../components/Table/TableFilter";
import { useProcedures } from "../../../service";
import { Formik, useFormikContext } from "formik";
import mapObject from "../../../utils/mapObject";
import { DialogMedium } from "../../../helper";
import { verifyAttendanceFlow } from "../../../utils";
import { useNotifier } from "../../../hooks";
import Yup from "../../../config/yup";

const checks = {};

for (let i = 1; i <= 6; i++) {
  checks[`s${i}`] = Yup.boolean().test({
    test: (_, context) => {
      const value = context.parent;
      return Object.values(value).some((v) => v);
    },
    message: "Requerido",
  });
}

const validations = Yup.object().shape({
  procedures: Yup.array().min(1, "O procedimento é obrigatório"),
  checked: Yup.object(checks),
  notes_procedures: Yup.string(),
});

const initialValues = {
  procedures: [],
  checked: {
    s1: false,
    s2: false,
    s3: false,
    s4: false,
    s5: false,
    s6: false,
  },
  notes_procedures: "",
};

export default function Sextant({ attendance }) {
  const { disableFields, patientData } = useContext(AppContext);
  const { getProcedures } = useProcedures();
  const [proceduresToView, setProceduresToView] = useState([]);
  const { setFieldValue, values: soapValues } = useFormikContext();
  const notify = useNotifier();
  const [dataToView, setDataToView] = useState([]);

  function handleAdd(value, { resetForm }) {
    let valuesToSet = soapValues.sextant || {};
    const notifications = [];
    Object.entries(value.checked).forEach(([i, v]) => {
      if (!v) return;
      if (soapValues.sextant?.[i]?.procedures?.length) {
        soapValues.sextant[i].procedures = soapValues.sextant?.[i]?.procedures?.filter(
          (procedure) => {
            if (procedure.tuss_code === "0307030059") {
              const remove = value.procedures?.some(
                (newProcedure) => newProcedure.tuss_code === "0307030059"
              );
              if (remove) {
                notifications.push(
                  <Typography>
                    O {i.toUpperCase()} já continha o procedimento 0307030059 e não é possível
                    adicioná-lo novamente.
                  </Typography>
                );
              }
              return !remove;
            }
            if (procedure.tuss_code === "0307030024") {
              const remove = value.procedures?.some(
                (newProcedure) => newProcedure.tuss_code === "0307030024"
              );
              if (remove) {
                notifications.push(
                  <Typography>
                    O {i.toUpperCase()} já continha o procedimento 0307030024 e não é possível
                    adicioná-lo novamente.
                  </Typography>
                );
              }
              return !remove;
            }
            return true;
          }
        );
      }
      valuesToSet[i] = {
        ...(soapValues.sextant?.[i]?.rps ? { rps: soapValues.sextant?.[i]?.rps } : {}),
        procedures: soapValues.sextant?.[i]?.procedures
          ? [...value.procedures, ...soapValues.sextant?.[i]?.procedures]
          : value.procedures,
        note: value.notes_procedures ? value.notes_procedures : soapValues.sextant?.[i]?.note,
      };
    });
    if (notifications.length) {
      notify(notifications, "warning");
    }
    resetForm();
    setFieldValue("sextant", { ...valuesToSet });
  }

  function handleDelete(local) {
    const values = { ...soapValues.sextant };
    delete values[local];

    setFieldValue("sextant", values);
  }

  useEffect(() => {
    const data = mapObject(soapValues.sextant, (value, label) => {
      if (typeof value !== "object" || (!value?.procedures && !value?.note)) return;
      return {
        local: label,
        note: value.note,
        procedures: value.procedures,
      };
    }).filter((i) => i);
    setDataToView(data);
  }, [soapValues.sextant]);

  const tableColumns = [
    {
      name: "Local",
      field: "local",
      use: (value) => `Sextante ${value.toUpperCase()}`,
    },
    { name: "Observação", field: "note" },
  ];

  return (
    <>
      <DialogMedium
        fullWidth
        maxWidth={"md"}
        open={!!proceduresToView?.length}
        handleClose={() => setProceduresToView()}
      >
        <TableFilter
          data={proceduresToView}
          noHeaderOptions
          columns={[
            { name: "SIGTAP", field: "tuss_code" },
            { name: "Procedimento", field: "describe" },
          ]}
        />
      </DialogMedium>
      <Grid container item xs={12}>
        <Grid xs={4.3} item>
          <SextantSVG />
        </Grid>
        <Grid xs={7.7} item display={"flex"} flexDirection={"column"} rowGap={"15px"}>
          <Box
            sx={{
              display: "flex",
              position: "relative",
              flexDirection: "column-reverse",
              cursor: "pointer",
              minHeight: "27px",
              backgroundColor: (t) => t.palette.secondary.main,
              "& > #info": {
                visibility: "hidden",
                opacity: 0,
                transition: "visibility 0s, opacity 0.5s linear",
                position: "absolute",
                bottom: 27,
                color: "white",
                display: "flex",
                flexDirection: "column",
                rowGap: "3px",
                padding: "6px",
                fontSize: "10px",
                fontWeight: "900",
                backgroundColor: (t) => t.palette.secondary.main,
                "& >p": {
                  margin: 0,
                },
              },
            }}
          >
            <Typography
              sx={{
                color: "white",
                display: "flex",
                alignItems: "center",
                padding: "3px",
                columnGap: "3px",
                fontSize: "12px",
                "&:hover + #info": {
                  visibility: "visible",
                  opacity: 1,
                },
              }}
            >
              <Info /> Como preencher o registro periodontal simplificado
            </Typography>
            <Typography id="info" component={"div"}>
              <p>
                0 - Faixa colorida da sonda visível, ausência de sangramento após sondagem, ausência
                de cálculo dentário e/ou margens de restaurações mal adaptadas.
              </p>
              <p>
                1 - Sangramento após a sondagem. Faixa colorida da sonda visível, ausência de
                cálculo dentário e/ou margens de restaurações mal adaptadas.
              </p>
              <p>
                2 - Presença de cálculo dentário e/ou margens de restaurações mal adaptadas. Pode
                sangrar à sondagem ou não. Faixa colorida da sonda visível.
              </p>
              <p>
                3 - Faixa colorida da sonda parcialmente visível (bolsa periodontal entre 4 e 5mm).
              </p>
              <p>
                {"4 - Faixa colorida da sonda completamente encoberta (bolsa periodontal > 5mm)."}
              </p>
              <p>
                {
                  "* - Anormalidade clínica (lesão de furca, recessão gengival › 3mm, lesão mucogengival ou mobitidade). É obrigatório estar em conjunto com algum dos valores anteriores."
                }
              </p>
              <p>X - Sextantes sem dentes (excluídos)</p>
            </Typography>
          </Box>

          <Box display={"flex"} columnGap={1}>
            <Grid item xs={5} display={"flex"} flexDirection={"column"} rowGap={2.9}>
              <Box display={"flex"} columnGap={1}>
                <FormTextField disabled={disableFields} name={"sextant.s1.rps"} label={"S1"} />
                <FormTextField disabled={disableFields} name={"sextant.s2.rps"} label={"S2"} />
                <FormTextField disabled={disableFields} name={"sextant.s3.rps"} label={"S3"} />
              </Box>
              <Box display={"flex"} columnGap={1}>
                <FormTextField disabled={disableFields} name={"sextant.s4.rps"} label={"S4"} />
                <FormTextField disabled={disableFields} name={"sextant.s5.rps"} label={"S5"} />
                <FormTextField disabled={disableFields} name={"sextant.s6.rps"} label={"S6"} />
              </Box>
            </Grid>
            <Grid item xs={7} display={"flex"} columnGap={1}>
              <FormTextField
                disabled={disableFields}
                name={"sextant.notes"}
                label={"Observação"}
                multiline
                minRows={4}
                maxRows={4}
              />
            </Grid>
          </Box>
          <Formik onSubmit={handleAdd} initialValues={initialValues} validationSchema={validations}>
            {({ handleSubmit }) => (
              <>
                <Box
                  display={"flex"}
                  sx={{ "& *": { flex: 1 } }}
                  justifyContent={"stretch"}
                  paddingTop={1}
                >
                  <FormCheckbox disabled={disableFields} name={"checked.s1"} label={"S1"} />
                  <FormCheckbox disabled={disableFields} name={"checked.s2"} label={"S2"} />
                  <FormCheckbox disabled={disableFields} name={"checked.s3"} label={"S3"} />
                  <FormCheckbox disabled={disableFields} name={"checked.s4"} label={"S4"} />
                  <FormCheckbox disabled={disableFields} name={"checked.s5"} label={"S5"} />
                  <FormCheckbox disabled={disableFields} name={"checked.s6"} label={"S6"} />
                </Box>
                <Box display={"flex"} flexDirection={"column"} rowGap={2}>
                  <FormSelectWithPaginate
                    disabled={disableFields}
                    name={"procedures"}
                    label={"Procedimentos"}
                    multiple
                    required
                    service={(params) =>
                      getProcedures({
                        ...params,
                        type: "sextant",
                        patient_id: patientData?.id,
                        attendance_flow: verifyAttendanceFlow(attendance.risk_classification_id),
                      })
                    }
                    getOptionLabel={(data) => {
                      let label = data?.name;

                      if (data?.tuss_code) {
                        label = `${data?.tuss_code} - ${label}`;
                      }

                      return label;
                    }}
                    searchBy="filters"
                    fields={"describe"}
                  />
                  <FormTextField
                    disabled={disableFields}
                    name={"notes_procedures"}
                    label={`Observações`}
                    multiline
                    minRows={3}
                    maxRows={3}
                  />
                </Box>
                <Box display={"flex"} justifyContent={"end"} paddingBottom={2}>
                  <Button
                    variant="contained"
                    disabled={disableFields}
                    onClick={() => handleSubmit()}
                  >
                    Adicionar
                  </Button>
                </Box>
              </>
            )}
          </Formik>
        </Grid>
      </Grid>
      <Grid item xs={12} maxHeight={"326px"} sx={{ overflowY: "auto" }}>
        <TableFilter
          columns={tableColumns}
          data={dataToView}
          actionsTypes={["view", "delete"]}
          noHeaderOptions
          disableActions={(row, action) => {
            return (
              (action === "view" && !row.procedures?.length) ||
              (action === "delete" && disableFields)
            );
          }}
          actionHandleView={(_, i) => {
            setProceduresToView(i.procedures);
          }}
          actionHandleDelete={(_, i) => {
            handleDelete(i.local);
          }}
          actions
        />
      </Grid>
    </>
  );
}
