import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

export default function useDiabeticFoot() {
  async function getDiabeticFootByPatient(id, params) {
    try {
      const response = await instance.get(
        `/patients/${id}/diabetic-feet-assessments`,
        {
          params: urlQuery(params),
        }
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response?.data?.message);
    }
  }

  return { getDiabeticFootByPatient };
}
