import { Box, CircularProgress, Typography } from "@mui/material";
import { useCallback } from "react";
import { ReactComponent as Logo } from "../../assets/svg/tascomBlue.svg";
import { useDebounceCallback } from "../../hooks";


export function PaginatedScrollList({
  maxHeight = "50vh",
  minHeight = "150px",
  hasDivider = true,
  loading = false,
  handlePage = () => null,
  page = 0,
  totalPage = 0,
  data = [],
  componentContainer,
  component,
  emptyMessage = "Nenhum item econtrado",
  endMessage = "Não há mais items encontrados",
  sx,
}) {
  const debouncedPage = useDebounceCallback(() => handlePage(page + 1), 500);

  const detectScroll = useCallback(
    (e) => {
      if (e.target.scrollTop + e.target.clientHeight >= e.target.scrollHeight - 2) {
        if (page < totalPage) {
          debouncedPage();
        }
      }
    },
    [page, totalPage]
  );

  return (
    <Box
      sx={{
        width: "100%",
        position: "relative",
      }}
    >
      <Box
        borderBottom={(theme) => (hasDivider ? `1px solid ${theme.palette.primary.light}` : "none")}
        sx={{
          maxHeight,
          minHeight,
          overflowY: "auto",
          display: "flex",
          flexDirection: "column",
          rowGap: 1,
          ...sx,
        }}
        onScroll={detectScroll}
      >
        {componentContainer instanceof Function
          ? componentContainer(data, component)
          : component instanceof Function && data?.map((value, index) => component(value, index))}
        <Box
          sx={{
            marginLeft: 1,
            marginTop: 1,
          }}
        >
          {!data.length && !loading && <Typography color="primary.main">{emptyMessage}</Typography>}
          {page === totalPage && !!data.length && !loading && (
            <Typography color="primary.main">{endMessage}</Typography>
          )}
        </Box>
      </Box>
      {loading ? (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            position: "absolute",
            zIndex: 3000,
            top: 0,
            display: "flex",
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            background: "blur(20px)",
            justifyContent: "center",
            alignItems: "center",
            backdropFilter: "blur(4px)",
          }}
        >
          <Box marginTop={1} position="absolute">
            <Logo />
          </Box>
          <CircularProgress size={80}></CircularProgress>
        </Box>
      ) : null}
    </Box>
  );
}
