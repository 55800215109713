import { Box, Grid, IconButton, Tooltip } from "@mui/material";
import {
  EditRounded,
  VisibilityRounded,
  AssignmentOutlined,
  DoneRounded,
  LocalPrintshopRounded,
} from "@mui/icons-material";
import { ConfirmDialog } from "../../helper";
import { useState } from "react";
import { format, parseISO } from "date-fns";

const infoActions = {
  view: {
    title: "Visualizar",
    icon: <VisibilityRounded />,
    confirmation: false,
  },
  edit: { title: "Editar", icon: <EditRounded />, confirmation: false },
  response: {
    title: "Responder",
    icon: <AssignmentOutlined />,
    confirmation: false,
  },
  finish: { title: "Finalizar", icon: <DoneRounded />, confirmation: true },
  print: {
    title: "Imprimir",
    icon: <LocalPrintshopRounded />,
    confirmation: false,
  },
};

export default function PrescriptionInfo({
  info,
  statusField,
  actions = [],
  confirmMessage = "Você realmente deseja *action* este item?",
  notIntervention,
}) {
  const [message, setMessage] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [confirmDialogConfig, setConfirmDialogConfig] = useState(null);

  const status = {
    ongoing: "Em aberto",
    "partially finished": "Parcialmente finalizado",
    finished: "Finalizado",
    unfinished: "Não Inicializado",
  };

  const togleDialog = () => {
    setOpenDialog((openDialog) => !openDialog);
  };

  const handleMessage = (row, action) => {
    const message = confirmMessage.replace("action", action);
    const splitedMessage = message.split(" ").map((str) => {
      if (str.match(/({\D+})/gi)) {
        const replacer = str.replace(/[()?]/g, "");
        const value = eval(replacer.replace(/[{}]/g, ""));
        const string = str.replace(replacer, value);
        return string;
      } else {
        return str;
      }
    });
    const completedMessage = splitedMessage.join(" ");
    setMessage(completedMessage);
  };

  return (
    <Box sx={{ flex: 1 }}>
      <Grid container xs={12} alignItems="center" justifyContent={"space-between"}>
        {notIntervention && (
          <Grid item xs={1}>
            Nº {info.number}
          </Grid>
        )}
        <Grid item xs={2}>
          Solicitado em: {format(parseISO(info.createdAt ?? info.soap.createdAt), "dd/MM/yyyy")}
        </Grid>
        <Grid item xs={3}>
          Profissional : {info.employee_specialty?.employee.name}
        </Grid>
        <Grid item xs={3}>
          Status : {status[info[statusField]]}
        </Grid>

        <Grid
          item
          xs={3}
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: 1,
            paddingRight: "1rem",
          }}
        >
          {actions.map(({ name, handler, cancelHandler, disableAction }, index) => (
            <Tooltip
              key={`${name}-${index}`}
              placement="top"
              title={infoActions[name].title}
              onClick={(event) => event.stopPropagation()}
            >
              <Box component="span">
                <IconButton
                  sx={(theme) => ({
                    color: theme.palette.primary.medium,
                  })}
                  disabled={disableAction instanceof Function ? disableAction(info) : false}
                  onClick={(event) => {
                    if (infoActions[name].confirmation) {
                      handleMessage(info, infoActions[name].title.toLowerCase());

                      setConfirmDialogConfig({
                        confirmAction: () => handler(info),
                        cancelAction: () => {
                          if (cancelHandler instanceof Function) {
                            cancelHandler(info);
                          }
                        },
                      });
                      togleDialog();
                    } else {
                      handler(info);
                    }
                  }}
                >
                  {infoActions[name].icon}
                </IconButton>
              </Box>
            </Tooltip>
          ))}
        </Grid>
      </Grid>
      <ConfirmDialog
        message={message}
        hideBackDrop={false}
        open={openDialog}
        maxWidth="sm"
        fullWidth={true}
        handleClose={togleDialog}
        actions
        handleConfirm={confirmDialogConfig?.confirmAction}
        handleCancel={confirmDialogConfig?.cancelAction}
      />
    </Box>
  );
}
