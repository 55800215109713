/* eslint-disable no-restricted-globals */
import { format, isDate, isValid, parseISO } from "date-fns";
import React, { useCallback, useState } from "react";
import Yup from "../config/yup";
import { cleanUpMask } from "../config/mask";
import { validateCNS12, validateCNS789, validateCPF } from "../config/validations";
import { decode } from "html-entities";

const requiredMessage = "É requerido";

const fieldTypes = {
  text(field, config) {
    const cpfCnsValidations = [
      "cpf-cns",
      `CNS ou CPF inválidos.`,
      (value) => {
        const isCpf = field.label?.toLowerCase()?.includes("cpf");
        const isCns = field.label?.toLowerCase()?.includes("cns");
        if (field.label && (isCns || isCpf)) {
          if (!value && !field.required) {
            return true;
          }

          if (!value) {
            return false;
          }
          const valueToTest = cleanUpMask(value);

          if (field.label) {
            if (isCpf && isCns) {
              return (
                validateCPF(valueToTest) ||
                validateCNS12(valueToTest) ||
                validateCNS789(valueToTest)
              );
            }

            if (isCpf) {
              return validateCPF(valueToTest);
            }

            if (isCns) {
              return validateCNS12(valueToTest) || validateCNS789(valueToTest);
            }
          }
        }
        return true;
      },
    ];
    config.validations[field._id] = field.required
      ? Yup.string()
          .nullable()
          .required(requiredMessage)
          .test(...cpfCnsValidations)
      : Yup.string()
          .nullable()
          .test(...cpfCnsValidations);

    config.defaultValues[field._id] = null;
  },
  textarea(field, config) {
    config.validations[field._id] = field.required
      ? Yup.string().nullable().required(requiredMessage)
      : Yup.string().nullable();

    config.defaultValues[field._id] = null;
  },
  select(field, config) {
    config.validations[field._id] = field.required
      ? Yup.object().nullable().required(requiredMessage)
      : Yup.object().nullable();

    config.defaultValues[field._id] = null;
  },
  number(field, config) {
    const testParams = [
      "is-number",
      "O valor deve ser numérico",
      (value) => {
        const formatedValue = value ? value.replace(",", ".") : value;
        return !value || !isNaN(cleanUpMask(formatedValue));
      },
    ];
    const cpfCnsValidations = [
      "cpf-cns",
      `CNS ou CPF inválidos.`,
      (value) => {
        const isCpf = field.label?.toLowerCase()?.includes("cpf");
        const isCns = field.label?.toLowerCase()?.includes("cns");
        if (field.label && (isCns || isCpf)) {
          if (!value && !field.required) {
            return true;
          }

          if (!value) {
            return false;
          }
          const valueToTest = cleanUpMask(value);

          if (field.label) {
            if (isCpf && isCns) {
              return (
                validateCPF(valueToTest) ||
                validateCNS12(valueToTest) ||
                validateCNS789(valueToTest)
              );
            }

            if (isCpf) {
              return validateCPF(valueToTest);
            }

            if (isCns) {
              return validateCNS12(valueToTest) || validateCNS789(valueToTest);
            }
          }
        }
        return true;
      },
    ];

    config.validations[field._id] = field.required
      ? Yup.string()
          .nullable()
          .required(requiredMessage)
          .test(...testParams)
          .test(...cpfCnsValidations)
      : Yup.string()
          .nullable()
          .test(...testParams)
          .test(...cpfCnsValidations);

    config.defaultValues[field._id] = null;
  },
  radio(field, config) {
    config.validations[field._id] = field.required
      ? Yup.string().nullable().required(requiredMessage)
      : Yup.string().nullable();

    config.defaultValues[field._id] = null;
  },
  checkbox: (field, config) => {
    config.validations[field._id] = field.required
      ? Yup.array().min(1, "É requerido").nullable()
      : Yup.array().nullable();

    config.defaultValues[field._id] = [];
  },
  yesOrNot(field, config) {
    config.validations[field._id] = field.required
      ? Yup.boolean().nullable().required(requiredMessage)
      : Yup.boolean().nullable();

    config.defaultValues[field._id] = false;
  },
  date(field, config) {
    config.validations[field._id] = field.required
      ? Yup.date("Data inválida").nullable().required(requiredMessage)
      : Yup.date("Data inválida").nullable();

    config.defaultValues[field._id] = null;
  },
  range(field, config) {
    config.validations[field._id] = field.required
      ? Yup.number().min(field.min).max(field.max).nullable().required(requiredMessage)
      : Yup.number().min(field.min).max(field.max).nullable();

    config.defaultValues[field._id] = field.min;
  },
};

export const FormMakerContext = React.createContext();

export function FormMakerProvider({ children }) {
  const [document, setDocument] = useState();
  const [recordDocument, setRecordDocument] = useState();
  const [formConfig, setFormConfig] = useState({});

  const documentFormatter = useCallback((document, changeValidations = true) => {
    if (changeValidations) {
      const formConfig = {
        validations: {},
        defaultValues: {},
      };

      document.versions[0].fields.forEach((field) => {
        if (fieldTypes[field.type] instanceof Function) {
          fieldTypes[field.type](field, formConfig);
        }
      });

      setFormConfig(formConfig);
    }

    setDocument(document);
  }, []);

  const valuesFormatter = useCallback((values, document) => {
    try {
      if (!Array.isArray(values)) {
        return setFormConfig((formConfig) => ({
          ...formConfig,
          defaultValues: values,
        }));
      }

      const formatedValues = values.reduce((object, { field_id, value }) => {
        const documentVersion = document?.versions?.length ? document?.versions[0] : document;
        const fieldData = documentVersion?.fields.find(({ _id }) => _id === field_id);

        if (!Array.isArray(value) && (typeof value === "number" || Number.isInteger(+value))) {
          if (fieldData?.type === "select" && typeof value !== "object") {
            const option = fieldData?.options?.find((option) => option.value === value);
            value = option;
          } else {
            if (value) {
              value = value.toString();
            }
          }
        } else if (typeof value === "string" && isValid(parseISO(value))) {
          value = parseISO(value);
        }

        return {
          ...object,
          [field_id]: value,
        };
      }, {});

      setFormConfig((formConfig) => ({
        ...formConfig,
        defaultValues: formatedValues,
      }));
    } catch (err) {
      console.log(err);
    }
  }, []);

  const submitFormatter = useCallback((values, fields) => {
    if (!!values) {
      const formatedValues = Object.entries(values).map(([name, value]) => {
        const matchedField = fields.find(({ _id }) => _id === name);
        const submitObject = {
          field_id: name,
        };

        if (isDate(value) && isValid(value)) {
          submitObject.value = format(value, "yyyy-MM-dd");
        } else if (matchedField?.masks?.length > 0) {
          submitObject.value = cleanUpMask(value);
        } else {
          submitObject.value = value;
        }

        return submitObject;
      });

      return formatedValues;
    }
    return [];
  }, []);

  const feedbackFormatter = useCallback((recordDocument) => {
    const regex = /(<([^>]+)>)/gi;
    const formatedFields = [];
    const version = recordDocument.document_id.versions.find(
      ({ number }) => number === recordDocument.version
    );

    version.fields.forEach((field) => {
      const matchedField = recordDocument.fields.find(({ field_id }) => field_id === field._id);

      if (matchedField || field.type === "label") {
        formatedFields.push({
          label: decode(field.label.replace(regex, "")),
          type: field.type,
          id: matchedField?.field_id || field.id,
          value: matchedField?.value || null,
          options: field.options,
        });
      }
    });

    return formatedFields;
  }, []);

  return (
    <FormMakerContext.Provider
      value={{
        document,
        setDocument: documentFormatter,
        recordDocument,
        setRecordDocument,
        formConfig,
        setFormConfig,
        setRecord: valuesFormatter,
        submitFormatter,
        feedbackFormatter,
      }}
    >
      {children}
    </FormMakerContext.Provider>
  );
}
