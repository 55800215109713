import { Button, TablePagination } from "@mui/material";
import { Box } from "@mui/system";
import { parseInt } from "lodash";
import React from "react";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Dialog } from "../../../helper";
import useMonitoring from "../../../service/useMonitoring";
import { PatientInfo } from "../PatientInfo";
import PatientListTable from "./PatientListTable";
import EvolutionList from "./Evolution/EvolutionList";
import useInterventions from "../../../service/useInterventions";
import useNotifier from "../../../hooks/useNotifier";

import TAccordion from "../../../components/Accordion";
import PrescriptionInfo from "../PrescriptionInfo";

export default function ProcedurePatientList({ patientId, table, statusField }) {
  const notify = useNotifier();
  const [limit, setLimit] = React.useState(10);
  const [page, setPage] = React.useState(0);
  const [selectedMonitoring, setSelectedMonitoring] = React.useState(0);
  const [limitOfProcedures, setLimitOfProcedures] = React.useState(10);
  const [openEvolutionWithAttendance, setOpenEvolutionWithAttendance] = React.useState(false);
  const [proceduresPage, setProceduresPage] = React.useState(0);
  const [searchFilter, setSearchFilter] = React.useState({});
  const [openDialog, setOpenDialog] = React.useState(false);

  const queryClient = useQueryClient();
  const { getMonitorings, updateWorkList } = useMonitoring();
  const { getInterventions, updateInterventions } = useInterventions();

  const updateWorkListStatusMutation = useMutation(updateWorkList);
  const updateInterventionsMutation = useMutation(updateInterventions);

  const [intervention, setIntervention] = React.useState(null);
  const [accordionConf, setAccordionConf] = React.useState([]);

  function handleUpdateIntervention(_, item, toSend, value = true) {
    updateInterventionsMutation.mutate(
      {
        id: item.id,
        data: {
          done: value,
        },
      },
      {
        onSuccess() {
          proceduresFromPatient.refetch();
        },
        onError(error) {
          notify(error.message, "error");
        },
      }
    );
  }

  const proceduresPatientsList = useQuery(
    ["proceduresPatientsList", page, limit, patientId],
    () =>
      getMonitorings({
        page,
        limit,
        work_list_status: patientId ? "finished" : "unfinished",
        ...searchFilter,
        type: "procedure",
        id_patient: patientId,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onError: () => {
        queryClient.setQueriesData("proceduresPatientsList", []);
      },
    }
  );

  const proceduresFromPatient = useQuery(
    ["proceduresFromPatient"],
    () =>
      getInterventions({
        page: proceduresPage,
        limit: limitOfProcedures,
        patient_id: selectedMonitoring?.attendance?.patient?.id,
        attendance_id: selectedMonitoring?.attendance?.id,
      }),
    {
      enabled: !!selectedMonitoring,
      onSuccess: (data) => {
        const conf = data.items.map((intervention) => {
          const actions = [
            {
              name: "response",
              handler: (intervention) => {
                setIntervention(intervention);
                setOpenEvolutionWithAttendance(true);
              },
            },
          ];
          const formatedIntervetion = {
            ...intervention,
            employee_specialty: {
              employee: {
                name: intervention?.soap?.employee_specialty?.employee?.name,
              },
            },
            id_plan: intervention?.interventions[0]?.id_plan,
          };
          return {
            title: (
              <PrescriptionInfo
                info={formatedIntervetion}
                notIntervention={false}
                statusField={statusField}
                actions={actions}
              />
            ),
            body: table(selectedMonitoring, intervention, handleUpdateIntervention, !patientId),
          };
        });

        setAccordionConf(conf);
      },
      onError() {
        setAccordionConf([]);
      },
    }
  );

  const handleClose = () => {
    setOpenDialog(false);
    setSelectedMonitoring();
    proceduresPatientsList.refetch();
  };

  const updateWorkListStatus = async (attendanceID, to = "finished") => {
    updateWorkListStatusMutation.mutate(
      { attendanceID, changes: { procedure: to } },
      {
        onSuccess: () => {
          proceduresPatientsList.refetch();
        },
        onError: (error) => {
          notify(error.message, "error");
        },
      }
    );
  };

  React.useEffect(() => {
    proceduresFromPatient.refetch();
  }, [selectedMonitoring, proceduresPage, limitOfProcedures]);

  React.useEffect(() => {
    proceduresPatientsList.refetch();
  }, [searchFilter]);

  return (
    <Box sx={{ pt: 5 }}>
      <Dialog
        title={"Procedimentos"}
        open={openEvolutionWithAttendance}
        handleClose={() => {
          setOpenEvolutionWithAttendance(false);
          proceduresPatientsList.refetch();
        }}
      >
        <PatientInfo attendance={selectedMonitoring?.attendance} />
        <EvolutionList
          title={"Procedimentos"}
          patient={selectedMonitoring?.attendance?.patient}
          attendance={selectedMonitoring?.attendance}
          formType={"procedure"}
          needProcedure={!selectedMonitoring?.has_plan}
          activeActions={!patientId}
          intervention={intervention}
        />
      </Dialog>
      <Dialog title={"Procedimentos"} open={openDialog} handleClose={handleClose}>
        <PatientInfo attendance={selectedMonitoring?.attendance} />
        <TAccordion configs={accordionConf} />
        <TablePagination
          count={proceduresFromPatient.data?.totalItems || 1}
          component="div"
          page={proceduresPage}
          onPageChange={(_, newPage) => {
            setProceduresPage(newPage);
          }}
          rowsPerPage={limitOfProcedures}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            setLimitOfProcedures(parseInt(event.target.value, 10));
            setPage(0);
          }}
        />
      </Dialog>
      <Button
        variant="contained"
        sx={{
          marginBottom: "10px",
        }}
        disabled={proceduresPatientsList.isLoading}
        onClick={() => {
          notify("Lista Atualizada");
          proceduresPatientsList.refetch();
        }}
      >
        Atualizar
      </Button>
      <PatientListTable
        data={proceduresPatientsList.data?.items}
        error={proceduresPatientsList.failureCount}
        loading={proceduresPatientsList.isFetching}
        noOutstanding={true}
        actionsTypes={patientId ? ["checklist", "response"] : undefined}
        callType={"procedure"}
        {...{
          page,
          limit,
          setLimit,
          setPage,
          setOpenDialog,
          setSelectedMonitoring,
          setOpenEvolutionWithAttendance,
          updateWorkListStatus,
          setSearchFilter,
        }}
        totalItems={proceduresPatientsList.data?.totalItems}
      />
    </Box>
  );
}
