import React, { useMemo, useState } from "react";
import { Box, Button, IconButton, Popover, Typography } from "@mui/material";
import { styled } from "@mui/material";
import { format, parseISO } from "date-fns";
import CommentOutlinedIcon from "@mui/icons-material/CommentOutlined";
import LocalPrintshopRoundedIcon from "@mui/icons-material/LocalPrintshopRounded";
import {
  CopyAllRounded,
  DoNotDisturbAltRounded,
  MedicationLiquidRounded,
} from "@mui/icons-material";
import {
  drugOnSitePrescriptionPDF,
  drugPrescriptionPDF,
  sadtPDF,
  specialPrescriptionPDF,
} from "../../../pdfModels";
import { groupPrescriptionCollection, openURL } from "../../../utils";

const CardContainer = styled(Box)(({ theme, suspended }) => ({
  backgroundColor: suspended ? "rgba(216, 0, 0, 0.1)" : theme.palette.primary.medium,
  borderRadius: 8,
  padding: "10px",
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
}));

const DateHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 15,
  fontWeight: 700,
  paddingLeft: 10,
  display: "flex",
  alignItems: "center",
  gap: "5px",
  marginBottom: 5,
}));

const InfoHeader = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: 14,
}));

const InfoDesc = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.light,
  fontSize: 12,
}));

const pdfTypes = {
  medications_on_site: (group, patient, documentData) => {
    return drugOnSitePrescriptionPDF(group, patient, documentData);
  },
  medications: (group, patient, documentData) => {
    return drugPrescriptionPDF(group, patient, documentData);
  },
  special_medications: (group, patient, documentData) => {
    return specialPrescriptionPDF(group, patient, documentData);
  },
  exams: (group, patient, documentData, documentType) => {
    return sadtPDF(documentType, group, patient, documentData);
  },
};

const PrescriptionCard = ({ data, canCopy, type, handleClick, handleSuspend, handleCopy }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const isSuspended = data.deletedAt;

  const documentData = useMemo(
    () => ({
      createdAt: data.createdAt,
      employee: {
        ...data.employee_specialty?.employee,
        specialty: data.employee_specialty?.specialty,
      },
      company: data.attendance.company,
    }),
    [data]
  );

  const prescriptionGroups = useMemo(() => {
    const groups = [];

    data[type].forEach((item) => groupPrescriptionCollection(groups, item));

    return groups;
  }, [data]);

  function handleClose() {
    setAnchorEl(null);
  }

  async function handlePrint(group, documentHandler, documentType) {
    const typeToVerify = type === "exams" ? documentType : documentHandler;

    const signedDocument = data.signed_documents.find((signedDocument) => {
      return signedDocument.type === typeToVerify && signedDocument.tab_id === group[0].tab_id;
    });

    if (signedDocument?.result) {
      return openURL(signedDocument.result);
    }

    const pdf = pdfTypes[documentHandler](
      group,
      data.attendance.patient,
      documentData,
      documentType
    );
    pdf.open();
  }

  function isAssistedMedication() {
    if (type === "medications_on_site") {
      return data.medications_on_site.some((medication) => {
        return medication.medication_assisted_treatment;
      });
    }

    return false;
  }

  return (
    <Box>
      {
        <DateHeader component="h3">
          {format(parseISO(data.createdAt), "dd 'de' MMMM, yyyy")}
        </DateHeader>
      }
      {isAssistedMedication() && (
        <DateHeader component="span">
          <MedicationLiquidRounded />
          <Typography>Medicamentos assistidos prescritos.</Typography>
        </DateHeader>
      )}
      <CardContainer suspended={!!data.cancellation_notes}>
        <Box display="flex" flexDirection="column">
          <InfoHeader fontWeight={700}>
            {`${data.employee_specialty.employee.name} - CRM - ${
              data.employee_specialty.employee.id_council_state
            } - ${data.employee_specialty.employee.council_registration_number} - ${format(
              parseISO(data.createdAt),
              "HH:mm'h'"
            )}`}
          </InfoHeader>
          <InfoDesc>
            <Typography component="span" fontWeight={700}>
              Início:{" "}
            </Typography>
            {format(parseISO(data.createdAt), "dd/MM/yyyy")}
          </InfoDesc>
          {isSuspended && (
            <InfoDesc>
              <Typography component="span" fontWeight={700}>
                Motivo de suspensão:{" "}
              </Typography>
              {data.cancellation_notes}
            </InfoDesc>
          )}
          {isSuspended && type === "medication" && (
            <InfoDesc>
              <Typography component="span" fontWeight={700}>
                Data de suspensão:{" "}
              </Typography>
              {format(parseISO(data.deletedAt), "dd/MM/yyyy")}
            </InfoDesc>
          )}
          {data.finishes_at && type === "medication" && (
            <InfoDesc>
              <Typography component="span" fontWeight={700}>
                Conclusão:{" "}
              </Typography>
              {format(parseISO(data.finishes_at), "dd/MM/yyyy")}
            </InfoDesc>
          )}
        </Box>
        <Box display="flex" alignItems="center">
          {canCopy && (
            <IconButton
              color="primary"
              disabled={isSuspended}
              onClick={() => handleCopy(data, type)}
            >
              <CopyAllRounded
                sx={{
                  width: 24,
                  height: 24,
                }}
              />
            </IconButton>
          )}
          <IconButton
            color="primary"
            disabled={isSuspended}
            onClick={() => handleClick(data, type)}
          >
            <CommentOutlinedIcon
              sx={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
          <IconButton
            color="primary"
            disabled={isSuspended}
            onClick={() => handleSuspend(data, type)}
          >
            <DoNotDisturbAltRounded
              sx={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
          <IconButton
            color="primary"
            disabled={isSuspended}
            onClick={(event) => setAnchorEl(event.currentTarget)}
          >
            <LocalPrintshopRoundedIcon
              sx={{
                width: 24,
                height: 24,
              }}
            />
          </IconButton>
          <Popover
            open={!!anchorEl}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            PaperProps={{
              sx: {
                display: "flex",
                gap: "4px",
                padding: "8px",
                marginTop: "-50px",
              },
            }}
          >
            {prescriptionGroups.map((group) => {
              let documentHandler = type;
              let documentType = null;
              let documentName = null;
              const firstItem = group[0];

              if (type === "exams") {
                documentType = "lab";
                documentName = "SADT LAB";
                const isXray = firstItem.procedure?.type?.includes("xray");

                if (isXray) {
                  documentType = "xray";
                  documentName = "SADT RX";
                } else if (firstItem.type === "Imagem") {
                  documentType = "image";
                  documentName = "SADT IMG";
                }
              } else if (type === "medications_on_site") {
                documentName = "Medicamentos";
              } else {
                documentName = "Receituário";

                if (firstItem.special) {
                  documentHandler = "special_medications";
                  documentName = "Especial";
                }
              }

              return (
                <Button onClick={() => handlePrint(group, documentHandler, documentType)}>
                  {`${documentName} (${firstItem.tab_id})`}
                </Button>
              );
            })}
          </Popover>
        </Box>
      </CardContainer>
    </Box>
  );
};

export default PrescriptionCard;
