
import { Grid, Typography } from "@mui/material";
import { AutocompleteField, RadioField, TextField } from "../../FormFields";
import { heartDiseases, kidneyDiseases, respiratoryDiseases } from "../../../config/susCodeStandardSelect";

function IndividualHealthConditionForm({
  control,
  watch,
  isEditingIndividual,
  handleChangeField,
  diffInYears
}) {

  return (
    <>
      <Grid item xs={12}>
        <Typography variant="h6" color="secondary">
          QUESTIONÁRIO AUTORREFERIDO DE CONDIÇÕES/SITUAÇÕES DE SAÚDE GERAIS
        </Typography>
      </Grid>

      <Grid item xs={2}>
        <RadioField
          name="pregnant"
          label="ESTÁ GESTANTE ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            (!diffInYears && diffInYears !== 0) ||
            !watch("gender") ||
            watch("gender")?.sus_code_id === 0 ||
            (
              watch("gender")?.sus_code_id === 1 &&
              !(diffInYears > 9 && diffInYears < 60)
            )
          }
          customOnChange={(value) => handleChangeField("pregnant", value)}
        />
      </Grid>

      <Grid item xs={10}>
        <TextField
          name="reference_maternity"
          label="SE SIM, QUAL É A MATERNIDADE DE REFERÊNCIA ?"
          control={control}
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            watch("gender")?.sus_code_id === 0 ||
            !watch("pregnant")
          }
        />
      </Grid>

      <Grid item xs={12}>
        <RadioField
          name="weight"
          label="SEU PESO, VOCE SE CONSIDERA"
          required
          control={control}
          options={[
            { label: "ABAIXO DO PESO", value: 21 },
            { label: "PESO ADEQUADO", value: 22 },
            { label: "ACIMA DO PESO", value: 23 },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="has_hypertension"
          required
          label="TEM HIPERTENÇÃO ARTERIAL ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="has_cancer"
          required
          label="TEM OU TEVE CÂNCER ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={6}>
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="is_smoker"
          label="ESTÁ FUMANTE ?"
          required
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="is_drug_user"
          required
          label="ESTÁ DEPENDENTE OU ABUSA DE DROGAS ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={6}>
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="is_alcohol_user"
          label="ESTÁ DEPENDENTE OU ABUSA DE ÁLCOOL ?"
          required
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="had_heart_attack"
          label="TEVE INFARTO ?"
          required
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={6}>
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="has_diabetes"
          label="TEM DIABETES ?"
          required
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="has_leprosy"
          label="ESTÁ COM HANSENÍASE ?"
          required
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={6}>
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="is_bedridden"
          label="ESTÁ ACAMADO ?"
          required
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="has_tuberculosis"
          required
          label="ESTÁ COM TUBERCULOSE ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={6}>
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="is_domicilied"
          required
          label="ESTÁ DOMICILIADO ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={3}>
        <RadioField
          name="had_stroke"
          required
          label="TEVE AVC/DERRAME ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={6}>
      </Grid>

      <Grid item xs={6}>
        <RadioField
          name="uses_other_complementary_integrative_practices"
          label="USA OUTRAS PRÁTICAS INTEGRATIVAS COMPLEMENTARES ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          required
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={6}>
      </Grid>

      <Grid item xs={6}>
        <RadioField
          name="mental_problem_by_healthcare_professional"
          label="TEVE DIAGNÓSTICO DE ALGUM PROBLEMA DE SAÚDE MENTAL POR PROFISSIONAL DE SAÚDE? ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          required
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>

      <Grid item xs={6}>
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="had_any_hospitalizations_in_the_last_12_months"
          label="TEVE ALGUMA INTERNAÇÃO NOS ÚLTIMOS 12 MESES ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          required
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
          customOnChange={(value) => handleChangeField("had_any_hospitalizations_in_the_last_12_months", value)}
        />
      </Grid>

      <Grid item xs={8}>
        <TextField
          name="reason_for_hospitalization"
          label="SE SIM, POR QUAL CAUSA ?"
          control={control}
          disabled={
            !isEditingIndividual ||
            (watch("individual_registration_rejection") ||
              !watch("had_any_hospitalizations_in_the_last_12_months"))
          }
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="uses_medicinal_plants"
          label="USA PLANTAS MEDICINAIS ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          required
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
          customOnChange={(value) => handleChangeField("uses_medicinal_plants", value)}
        />
      </Grid>

      <Grid item xs={8}>
        <TextField
          name="medicinal_plants"
          label="SE SIM, INDIQUE QUAIS ?"
          control={control}
          disabled={
            !isEditingIndividual ||
            (watch("individual_registration_rejection") ||
              !watch("uses_medicinal_plants"))
          }
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="has_respiratory_diseases"
          label="TEM DOENÇAS RESPIRATÓRIAS / NO PULMÃO ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          required
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
          customOnChange={(value) => handleChangeField("has_respiratory_diseases", value)}
        />
      </Grid>

      <Grid item xs={8}>
        <AutocompleteField
          name="respiratory_diseases"
          label="SE SIM, INDIQUE QUAIS?"
          control={control}
          options={respiratoryDiseases}
          optionLabelKey="describe"
          optionValueKey="sus_code_id"
          direction="row"
          disabled={
            !isEditingIndividual ||
            (watch("individual_registration_rejection") ||
              !watch("has_respiratory_diseases"))
          }
          required={watch("has_respiratory_diseases") && !watch("respiratory_diseases").length}
          multiple
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="has_heart_disease"
          label="TEM DOENÇA CARDÍACA / NO CORAÇÃO ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          required
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
          customOnChange={(value) => handleChangeField("has_heart_disease", value)}
        />
      </Grid>

      <Grid item xs={8}>
        <AutocompleteField
          name="heart_disease"
          label="SE SIM, INDIQUE QUAIS?"
          control={control}
          options={heartDiseases}
          optionLabelKey="describe"
          optionValueKey="sus_code_id"
          disabled={
            !isEditingIndividual ||
            watch("individual_registration_rejection") ||
            !watch("has_heart_disease")
          }
          multiple
          required={watch("has_heart_disease") && !watch("heart_disease").length}
        />
      </Grid>

      <Grid item xs={4}>
        <RadioField
          name="has_kidney_disease"
          label="TEM OU TEVE PROBLEMAS NOS RINS ?"
          control={control}
          options={[
            { label: "Sim", value: true },
            { label: "Não", value: false },
          ]}
          optionLabelKey="label"
          optionValueKey="value"
          direction="row"
          required
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
          customOnChange={(value) => handleChangeField("has_kidney_disease", value)}
        />
      </Grid>

      <Grid item xs={8}>
        <AutocompleteField
          name="kidney_disease"
          label="SE SIM, INDIQUE QUAIS?"
          control={control}
          optionLabelKey="describe"
          options={kidneyDiseases}
          optionValueKey="sus_code_id"
          direction="row"
          disabled={
            !isEditingIndividual ||
            (watch("individual_registration_rejection") ||
              !watch("has_kidney_disease"))
          }
          multiple
          required={watch("has_kidney_disease") && !watch("kidney_disease").length}
        />
      </Grid>

      <Grid item xs={12}>
      </Grid>
      <Grid item xs={12}>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="h6" color="secondary">OUTRAS CONDIÇÕES DE SAÚDE</Typography>
      </Grid>

      <Grid item xs={4}>
        <TextField
          name="other_health_conditions"
          label="1 - QUAL?"
          control={control}
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="other_health_conditions_2"
          label="2 - QUAL?"
          control={control}
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          name="other_health_conditions_3"
          label="3 - QUAL?"
          control={control}
          disabled={!isEditingIndividual || watch("individual_registration_rejection")}
        />
      </Grid>
    </>
  )
}

export default IndividualHealthConditionForm;