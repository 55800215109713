import React, { useContext, useMemo } from "react";
import { Typography, CardHeader, CardContent, Card, Grid } from "@mui/material";
import { formMakerPDF } from "../../../pdfModels";
import { format, parseISO } from "date-fns";
import { FormMakerContext } from "../../../contexts/FormMakerContext";
import { Button } from "../../FormFields";
import { openURL } from "../../../utils";

const fieldTypes = {
  text: (value) => (!!value ? value : "Não informado"),
  textarea: (value) => (!!value ? value : "Não informado"),
  select: (value, fieldProps) => {
    if (!!value) {
      if (value?.label) {
        return value?.label;
      }

      const { options } = fieldProps;
      const option = options?.find((option) => option.value === value);

      if (option) return option.label;
    }

    return "Não informado";
  },
  number: (value) => (!!value ? value : "Não informado"),
  radio: (value) =>
    !value
      ? "Não informado"
      : value === "true" || value === true
      ? "Sim"
      : value !== "" || value !== null
      ? value
      : "Não",
  checkbox: (value) => {
    if (!value?.length) return "Não informado";

    return value.map((option) => (
      <Typography key={option} component="li">
        {option}
      </Typography>
    ));
  },
  yesOrNot: (value) =>
    !value ? "Não informado" : value === "true" || value === true ? "Sim" : "Não",
  date: (value) => (!!value ? format(parseISO(value), "dd/MM/yyyy") : "Não informado"),
  range: (value) => (!!value ? value : "Não informado"),
};

function FormMakerFeedback({ additionalData, signature = false, signatureValidationPath = "" }) {
  const allStatus = {
    filling: "Preenchendo",
    canceled: "Cancelado",
    finished: "Finalizado",
  };
  const { recordDocument, feedbackFormatter } = useContext(FormMakerContext);

  const fields = useMemo(
    () => feedbackFormatter(recordDocument),
    [recordDocument, feedbackFormatter]
  );

  const fieldsWithoutTitles = fields.filter(({ type }) => type !== "label");

  return (
    <Card
      sx={{
        border: "3px solid transparent",
        borderColor: "white",
        borderWidth: "3px",
        boxShadow: "0 0 0 1.5px #FFFFFF",
      }}
    >
      <CardHeader
        title={
          <Typography variant="h1" color="secondary" fontWeight="600" fontSize={15}>
            {recordDocument.document_id.name}
          </Typography>
        }
        sx={{ mb: -3 }}
      />
      <CardContent>
        <Grid container spacing={2}>
          {additionalData && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              {additionalData.map(({ label, value }) => (
                <Typography variant="p" fontSize={13} color="secondary" fontWeight="500">
                  {label}:{" "}
                  <Typography color="primary" component="span" fontWeight={500} fontSize={13}>
                    {value}
                  </Typography>
                </Typography>
              ))}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="p" color="gray" fontWeight="700" fontSize={12} sx={{ mb: 2 }}>
              #1 DADOS DO DOCUMENTO
            </Typography>
            <Typography variant="p" fontSize={13} color="secondary" fontWeight="500">
              Profissional:{" "}
              <Typography color="primary" component="span" fontWeight={500} fontSize={13}>
                {recordDocument.employee_specialty.employee.name}
              </Typography>
            </Typography>
            <Typography variant="p" fontSize={13} color="secondary" fontWeight="500">
              Status:{" "}
              <Typography color="primary" component="span" fontWeight={500} fontSize={13}>
                {allStatus[recordDocument.status]}
              </Typography>
            </Typography>
            <Typography variant="p" fontSize={13} color="secondary" fontWeight="500">
              Versão:{" "}
              <Typography color="primary" component="span" fontWeight={500} fontSize={13}>
                {recordDocument.document_id.version}
              </Typography>
            </Typography>
            <Typography variant="p" fontSize={13} color="secondary" fontWeight="500">
              Data de resposta:{" "}
              <Typography color="primary" component="span" fontWeight={500} fontSize={13}>
                {format(parseISO(recordDocument.createdAt), "dd/MM/yyyy")}
              </Typography>
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Typography variant="p" color="gray" fontWeight="700" fontSize={12} sx={{ mb: 2 }}>
              #2 CAMPOS DO DOCUMENTO
            </Typography>
            {!fieldsWithoutTitles.length && (
              <Typography variant="p" fontSize={13} color="primary" fontWeight="500">
                Nenhum campo com resposta encontrado
              </Typography>
            )}
            {fieldsWithoutTitles.map(({ label, id, value, type, ...fieldProps }) => {
              return type !== "label" ? (
                <Typography key={id} variant="p" fontSize={13} color="secondary" fontWeight="500">
                  {label.replace(/:$/, "")}:{" "}
                  <Typography
                    color="primary"
                    component={type === "checkbox" && value?.length ? "ul" : "span"}
                    fontWeight={500}
                    fontSize={13}
                  >
                    {fieldTypes[type] instanceof Function
                      ? fieldTypes[type](value, fieldProps)
                      : "Não identificado"}
                  </Typography>
                </Typography>
              ) : null;
            })}
          </Grid>
        </Grid>
        <Grid item xs={12} marginTop={2}>
          <Button
            onClick={() => {
              if (recordDocument?.signed_document?.result) {
                openURL(recordDocument.signed_document.result);
              } else {
                const documentData = {
                  name: recordDocument.document_id.name,
                  status: allStatus[recordDocument.status],
                  version: recordDocument.document_id.version,
                  employee: {
                    ...recordDocument.employee_specialty.employee,
                    specialty: recordDocument.employee_specialty.specialty,
                  },
                  created_at: recordDocument.createdAt,
                  fields,
                };

                const pdf = formMakerPDF(
                  documentData,
                  additionalData,
                  signature,
                  signatureValidationPath
                );
                pdf.open();
              }
            }}
          >
            Imprimir
          </Button>
        </Grid>
      </CardContent>
    </Card>
  );
}

export default FormMakerFeedback;
