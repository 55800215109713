import React, { useState } from "react";
import { SubmitButton } from "../../../components";
import { FormSelectWithPaginate, FormTextField } from "../../../components/Form";
import { useMutation, useQueryClient } from "react-query";
import { Formik, Form } from "formik";
import { Grid } from "@mui/material";
import useNotifier from "../../../hooks/useNotifier";
import Yup from "../../../config/yup";
import { usePatientDischarge } from "../../../service";
import { useParams } from "react-router-dom";
import { patientDischargePDF } from "../../../pdfModels";
import useSignaturePassword from "../../../hooks/useSignaturePassword";
import { pdfToBase64 } from "../../../utils";

function PatientDischargeForm({ data, patient, handleClose }) {
  const [loading, setLoading] = useState(false);
  const notify = useNotifier();
  const validateSignatue = useSignaturePassword(setLoading);
  const { id } = useParams();

  const queryClient = useQueryClient();
  const { getDischargesReasons, postPatientDischarges } = usePatientDischarge();
  const postPatientDischargeMutation = useMutation(postPatientDischarges);

  async function handleSubmit({ reason, notes }, { resetForm }) {
    validateSignatue(async () => {
      setLoading(true);
      const pdf = patientDischargePDF({ notes, reason }, patient);
      const data = await pdfToBase64(pdf);

      const patientDischargeData = {
        attendance_id: id,
        reason_id: reason.id,
        notes,
        data,
        id: pdf.name.replace(".pdf", ""),
        original_file_name: pdf.name,
        signature_settings: {
          visible_sign_page: "*",
          visible_sign_x: 170,
          visible_sign_y: 595,
        },
      };

      postPatientDischargeMutation.mutate(patientDischargeData, {
        onSuccess(response) {
          setLoading(false);
          notify(response.message, "success");
          resetForm();
          queryClient.invalidateQueries("patient-discharge");
          handleClose();
        },
        onError(error) {
          setLoading(false);
          notify(error.message, "error");
        },
      });
    });
  }

  const validations = Yup.object().shape({
    reason: Yup.object().nullable().required("É requerido"),
    notes: Yup.string()
      .nullable()
      .required("É requerido")
      .min(4, "Mínimo de 4 caracteres")
      .test(
        "gulouse",
        "Anotação inválida, por favor insira novamente",
        (value) => !value?.match(/(.)\1{2,}/g)
      ),
  });

  const initialValues = {
    reason: data?.reason || null,
    notes: data?.notes || "",
  };

  return (
    <>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validations}>
        {() => (
          <Form>
            <Grid container spacing={3} marginTop="0.5rem">
              <Grid xs={6} md={4} item>
                <FormSelectWithPaginate
                  name="reason"
                  label="Motivos de alta"
                  required
                  service={(params) => getDischargesReasons({ ...params, filter: "active" })}
                  fields="describe"
                />
              </Grid>
              <Grid xs={12} item>
                <FormTextField
                  name="notes"
                  label="Observação/Anotação"
                  multiline
                  minRows={8}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <SubmitButton loading={loading}>Salvar</SubmitButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default PatientDischargeForm;
