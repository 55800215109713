import { Box, IconButton, styled, Typography, useTheme } from "@mui/material";
import React, { useMemo } from "react";
import { useController } from "react-hook-form";
import { accessObjectByString } from "../../../utils";
import {
  KeyboardDoubleArrowRightRounded,
  KeyboardDoubleArrowLeftRounded,
} from "@mui/icons-material";

const Container = styled(Box)({
  width: "100%",
  maxWidth: "800px",
  display: "flex",
  flexWrap: "wrap",
  gap: "24px",
});

const TransferListWrapper = styled(Box)(({ theme }) => ({
  flex: 1,
  backgroundColor: "#f5f5f7",
  boxShadow: theme.shadows[1],
  position: "relative",
  "& > h6": {
    color: "#FFF",
    fontWeight: 500,
    textTransform: "uppercase",
    backgroundColor: theme.palette.primary.light,
    padding: "10px 20px",
    borderRadius: "6px 6px 0 0",
    marginBottom: "-14px",
  },
  "& > div": {
    width: "100%",
    height: "100%",
    borderRadius: "6px 6px 0 0",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "absolute",
    top: 0,
    left: 0,
    p: {
      color: theme.palette.primary.light,
      fontWeight: 500,
    },
  },
  "& > ul": {
    listStyle: "none",
    minHeight: "280px",
    maxHeight: "280px",
    overflowY: "auto",
    li: {
      "&:nth-child(odd)": {
        backgroundColor: "#FFF",
      },
      marginLeft: "-40px",
      padding: "2px  8px",
      display: "flex",
      alignItems: "center",
      gap: "8px",
    },
  },
}));

export default function TransferList({
  name,
  control,
  options,
  filterOptions,
  optionLabelKey,
  optionCompareKey,
  optionValueKey,
  customOnChange,
}) {
  const theme = useTheme();
  const optionIdentifier = useMemo(() => {
    return optionCompareKey || optionLabelKey;
  }, [optionCompareKey, optionLabelKey]);

  const { field } = useController({ name, control });

  function verifyOptionIsEquals(option, selectedOption) {
    if (optionValueKey) {
      return accessObjectByString(option, optionValueKey) === selectedOption;
    }

    return (
      accessObjectByString(selectedOption, optionIdentifier) ===
      accessObjectByString(option, optionIdentifier)
    );
  }

  function handleSelect(option) {
    let value = option;

    if (optionValueKey) {
      value = accessObjectByString(option, optionValueKey);
    }

    const newValue = [...field.value, value];

    field.onChange(newValue);

    if (customOnChange instanceof Function) {
      customOnChange(newValue);
    }
  }

  function handleUnSelect(option) {
    const newValue = field.value.filter(
      (selectedOption) => !verifyOptionIsEquals(option, selectedOption)
    );

    field.onChange(newValue);

    if (customOnChange instanceof Function) {
      customOnChange(newValue);
    }
  }

  const filteredOptions = options.filter((option) => {
    const isSelected = field.value?.some((selectedOption) =>
      verifyOptionIsEquals(option, selectedOption)
    );

    if (!isSelected && filterOptions instanceof Function) {
      return filterOptions(option);
    }

    return !isSelected;
  });

  return (
    <Container>
      <TransferListWrapper>
        <Typography component={"h6"}>Selecionar para agrupamento</Typography>
        <Box component="ul">
          {filteredOptions.map((option, index) => (
            <Box
              key={`${accessObjectByString(option, optionIdentifier)}-unselected-${index}`}
              component="li"
            >
              <IconButton onClick={() => handleSelect(option)}>
                <KeyboardDoubleArrowRightRounded
                  fontSize="large"
                  htmlColor={theme.palette.primary.light}
                />
              </IconButton>
              <Typography component="span">
                {accessObjectByString(option, optionLabelKey)}
              </Typography>
            </Box>
          ))}
        </Box>
      </TransferListWrapper>
      <TransferListWrapper>
        <Typography component={"h6"}>Opçoes selecionadas</Typography>
        {field.value?.length ? (
          <Box component="ul">
            {field.value.map((option, index) => (
              <Box
                key={`${accessObjectByString(option, optionIdentifier)}-selected-${index}`}
                component="li"
              >
                <IconButton onClick={() => handleUnSelect(option)}>
                  <KeyboardDoubleArrowLeftRounded
                    fontSize="large"
                    htmlColor={theme.palette.primary.light}
                  />
                </IconButton>
                <Typography component="span">
                  {accessObjectByString(option, optionLabelKey)}
                </Typography>
              </Box>
            ))}
          </Box>
        ) : (
          <Box>
            <Typography>Para opções aqui, adicione clicando nos itens ao lado.</Typography>
          </Box>
        )}
      </TransferListWrapper>
    </Container>
  );
}
