import { useState, useRef, useCallback, useEffect } from "react";

const useCallbackQueue = (manualAdvance) => {
  const [queue, setQueue] = useState([]);
  const isProcessing = useRef(null);

  function next() {
    isProcessing.current = false;
    if (!queue.length) return -1;
  }

  async function processQueue() {
    isProcessing.current = true;
    const newQueue = [...queue];
    const currentCallback = newQueue.shift();

    await currentCallback();

    setQueue(newQueue);

    if (!manualAdvance) {
      next();
    }
  }

  const addCallbackToQueue = (callback) => {
    if (callback instanceof Function) {
      setQueue((prevQueue) => [...prevQueue, callback]);
    }
  };

  function clearQueue() {
    setQueue([]);
  }

  useEffect(() => {
    if (!isProcessing.current && queue.length > 0) {
      processQueue();
    }
  }, [isProcessing.current, queue]);

  return {
    addCallbackToQueue,
    next,
    clearQueue,
  };
};

export default useCallbackQueue;
