import PDFMerger from "pdf-merger-js/browser";

export default async function mergePDF(pdfs, types) {
  const result = {};

  const mergedPDF = new PDFMerger();

  for (const pdf of pdfs) {
    await mergedPDF.add(pdf);
  }

  for (const type of types) {
    switch (type) {
      case "blob":
        result[type] = await mergedPDF.saveAsBlob();
        break;
      case "url":
        result[type] = URL.createObjectURL(await mergedPDF.saveAsBlob());
        break;
      case "buffer":
        result[type] = await mergedPDF.saveAsBuffer();
        break;
      default:
        throw new Error(`Invalid type: ${type}`);
    }
  }

  return result;
}
