import React from "react";
import { Tabs } from "../../../components";
import ProntuaryDocuments from "../ProntuaryDocuments";
import FullDocumentHistory from "../FullDocumentHistory";

export default function FullDocumentPrinting({ patient }) {
  const tabs = [
    {
      label: "Impressão",
      content: <ProntuaryDocuments patientId={patient.id} />,
    },
    {
      label: "Histórico de Impressões",
      content: <FullDocumentHistory patient={patient} />,
    },
  ];

  return <Tabs tabs={tabs} />;
}
