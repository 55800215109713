import Dialog from "../../helper/DialogMedium";
import PatientsCard from "../PatientsCard/PatientsCard";
import { ReactComponent as SOAP } from "../../assets/svg/SOAP.svg";
import { ReactComponent as Dental } from "../../assets/svg/dental.svg";
import { ReactComponent as Vacinacao } from "../../assets/svg/cadVacinacao.svg";
import { ReactComponent as Programas } from "../../assets/svg/programas.svg";
import { ReactComponent as Atestado } from "../../assets/svg/atestado.svg";
import { ReactComponent as Exames } from "../../assets/svg/exames.svg";
import { ReactComponent as Prescricao } from "../../assets/svg/prescricao.svg";
import { ReactComponent as ProgramaAtivo } from "../../assets/svg/programaAtivo.svg";
import { Box, Grid } from "@mui/material";
import { useTheme } from "@emotion/react";
import { Typography } from "@mui/material";
import { useNavigate, useLocation } from "react-router";
import Prescription from "../../pages/SOAP/Prescription/Prescription";
import { useContext, useMemo, useState } from "react";
import Declaration from "../../pages/SOAP/Declaration/Declaration";
import ActiveProblemsList from "./ActiveProblemsList";
import PatientProgramsList from "./PatientProgramsList";
import PatientDischarge from "@mui/icons-material/LocalHospital";
import Forms from "@mui/icons-material/InsertDriveFileOutlined";
import {
  Groups,
  InfoOutlined,
  UploadFile,
  ContentPasteGoRounded,
  PrintRounded,
} from "@mui/icons-material";
import OdontoServices from "./OdontoServices";
// import CollectiveActivity from "./CollectiveActivity";
import { AttachedDocuments } from "../../pages";
import usePermissions from "../../config/usePermissions";
import { tokenCEMPDF } from "../../pdfModels";
import DocumentScannerRounded from "@mui/icons-material/DocumentScannerRounded";
import { AuthContext } from "../../contexts/AuthContext";
import ConfirmTransferDialog from "./transferDialogConfirm";
import Reference from "../../pages/SOAP/Reference/Reference";
import FullDocumentPrinting from "../../pages/Documents/FullDocumentPrinting";
import { attendanceAlerts } from "../../config/standardSelects";

function PatientServicesDialog({ open, handleClose, title, pendencie }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const location = useLocation();
  const attachedDocumentPermissions = usePermissions("/attached-documents");
  const fullDocumentPrintingPermissions = usePermissions("/full-document-printing");
  const { userData } = useContext(AuthContext);
  const [dialogType, setDialogType] = useState(null);
  const [confirmTransfer, setConfirmTransfer] = useState({
    open: false,
    navigateTo: "",
  });

  function handleCloseDialog() {
    setDialogType(null);
  }

  const alertsToShow = useMemo(() => {
    const alerts = [];

    if (open.patient?.in_attendance) alerts.push("inAttendance");
    if (pendencie?.type === "soap") alerts.push("soapPendency");
    if (pendencie?.type === "soap_complement") alerts.push("complementPendency");

    return alerts;
  }, [open, pendencie]);

  const dialogTypes = {
    odonto: {
      title: "Saúde Bucal",
      component: <OdontoServices attendance={open.patient} />,
    },
    prescription: {
      title: "Prescrições",
      component: <Prescription attendance={open.patient} isExternal />,
    },
    reference: {
      title: "Referência/Contrarreferência",
      component: <Reference attendance={open.patient} isExternal />,
    },
    // collectiveActivity: {
    //   title: "Atividade Coletiva",
    //   component: <CollectiveActivity attendance={open.patient} />,
    // },
    attachedDocument: {
      title: "Anexo ao prontuário",
      component: (
        <AttachedDocuments attendance_id={open.patient?.id} patient_id={open.patient?.patient.id} />
      ),
    },
    declarations: {
      title: "Histórico de declarações",
      component: <Declaration attendance={open.patient} isExternal />,
    },
    activeProblems: {
      title: "Problemas ativos",
      component: (
        <ActiveProblemsList handleClose={handleCloseDialog} patientId={open.patient?.patient?.id} />
      ),
    },
    programs: {
      title: "Programas",
      component: (
        <PatientProgramsList
          handleClose={handleCloseDialog}
          patientId={open.patient?.patient?.id}
        />
      ),
    },
    fullDocumentPrinting: {
      title: "Impressão Total ou Parcial do Prontuário",
      component: <FullDocumentPrinting patient={open.patient?.patient} />,
    },
  };

  const services = [
    {
      icon: (
        <Forms
          color="primary"
          sx={{
            fontSize: 90,
          }}
        />
      ),
      label: "Formulários",
      handleClick: () =>
        navigate(`/attendance/docs/forms/${open.patient.patient?.id}`, {
          state: { backTo: location.pathname, attendanceId: open.patient.id },
        }),
    },
    {
      icon: <SOAP />,
      label: "SOAP",
      handleClick: () => {
        const isWithoutEmployee = !open.patient.id_employee;
        const isOwner = userData.id === open.patient.id_employee;
        const onlyHistory =
          location.pathname === "/patient" ||
          open.patient.service.odontology ||
          open.patient.service.ceo;

        if (isWithoutEmployee || isOwner || onlyHistory) {
          navigate(`/soap/${open.patient.patient?.id}/${open.patient.id}`, {
            state: {
              isHistory: onlyHistory,
              backTo: location.pathname,
              canReevaluate: !!open.patient?.classification,
              attendance_data: open.patient,
            },
          });
        } else {
          setConfirmTransfer({
            open: true,
            navigateTo: `/soap/${open.patient.patient?.id}/${open.patient.id}`,
          });
        }
      },
    },
    {
      icon: (
        <Groups
          color="primary"
          sx={{
            fontSize: 90,
          }}
        />
      ),
      label: "Multi Profissionais",
      handleClick: () =>
        navigate(`/attendance/docs/multi/${open.patient.patient?.id}`, {
          state: { backTo: location.pathname, attendanceId: open.patient.id },
        }),
    },
    // {
    //   icon: (
    //     <Groups
    //       color="primary"
    //       sx={{
    //         fontSize: 90,
    //       }}
    //     />
    //   ),
    //   label: "Atividade Coletiva",
    //   handleClick: () => setDialogType("collectiveActivity"),
    // },
    {
      icon: <Vacinacao />,
      label: "Caderneta de Vacinação",
      handleClick: () =>
        navigate(`/vaccine/boocklet/${open.patient?.patient?.id}/${open.patient?.id}`, {
          state: { backTo: location.pathname },
        }),
    },
    {
      icon: <Dental />,
      label: "Saúde Bucal",
      handleClick: () => setDialogType("odonto"),
    },
    {
      icon: <Prescricao />,
      label: "Prescrição",
      handleClick: () => setDialogType("prescription"),
    },
    {
      icon: <Exames />,
      label: "Exames",
      handleClick: () => navigate(`/attendance/exams/${open.patient.id}`),
    },
    {
      icon: <Atestado />,
      label: "Atestado",
      handleClick: () => setDialogType("declarations"),
    },
    {
      icon: (
        <ContentPasteGoRounded
          color="primary"
          sx={{
            fontSize: 90,
          }}
        />
      ),
      label: "Referência/Contrarreferência",
      handleClick: () => setDialogType("reference"),
    },
    {
      icon: <Programas />,
      label: "Programas",
      handleClick: () => setDialogType("programs"),
    },
    {
      icon: <ProgramaAtivo />,
      label: "Problemas ativos",
      handleClick: () => setDialogType("activeProblems"),
    },
    {
      icon: (
        <PatientDischarge
          color="primary"
          sx={{
            fontSize: 90,
          }}
        />
      ),
      label: "Alta",
      handleClick: () =>
        navigate(`/attendance/patientDischarge/${open.patient.id}`, {
          state: {
            patientId: open.patient?.patient.id,
            backTo: location.pathname,
          },
        }),
    },
  ];

  function handlePrinter() {
    const pdf = tokenCEMPDF(open?.patient?.patient);
    pdf.open();
  }

  const dialogActions = [];

  if (userData.company.type.CEM && open?.patient?.has_preload) {
    dialogActions.push({
      title: "Imprimir ficha de atendimento do paciente",
      icon: <DocumentScannerRounded />,
      handle: () => handlePrinter(),
    });
  }

  if (attachedDocumentPermissions.read) {
    dialogActions.push({
      title: "Anexar documentos",
      icon: <UploadFile />,
      handle: () => setDialogType("attachedDocument"),
    });
  }

  if (fullDocumentPrintingPermissions.read) {
    dialogActions.push({
      title: "Impressão Total ou Parcial do Prontuário",
      icon: <PrintRounded />,
      handle: () => setDialogType("fullDocumentPrinting"),
    });
  }

  return (
    <>
      <ConfirmTransferDialog
        open={confirmTransfer.open}
        handleClose={() =>
          setConfirmTransfer({
            open: false,
          })
        }
        navigateTo={confirmTransfer.navigateTo}
        attendance={open.patient}
      />
      <Dialog
        open={!!dialogType}
        title={dialogTypes[dialogType]?.title}
        maxWidth="lg"
        fullWidth
        handleClose={handleCloseDialog}
      >
        {dialogTypes[dialogType]?.component}
      </Dialog>
      <Dialog
        maxWidth={"90vw"}
        open={open.isOpen}
        handleClose={handleClose}
        title={title}
        actions={dialogActions}
      >
        <PatientsCard
          patient={open.patient}
          pendencie={pendencie}
          key={"pattientCard"}
          handleClick={() => null}
          old={false}
          history={open.history}
          onDialog
        />
        {!!alertsToShow.length && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "auto 1fr",
              gap: "4px",
              alignItems: "center",
              padding: "6px",
              backgroundColor: theme.palette.primary.medium,
              marginTop: "12px",
              borderRadius: "8px",
            }}
          >
            <InfoOutlined color="primary" />
            <Typography
              sx={{
                color: theme.palette.primary.light,
                fontSize: "16px",
                fontWeight: "500",
              }}
            >
              Alertas do atendimento
            </Typography>
            <Box
              component="ul"
              sx={{
                gridColumn: "1 / -1",
                margin: "0px",
                paddingLeft: "20px",
              }}
            >
              {alertsToShow.map((alert) => (
                <Typography
                  key={alert}
                  component="li"
                  sx={{
                    color: theme.palette.primary.light,
                    fontSize: "14px",
                  }}
                >
                  {attendanceAlerts[alert]}
                </Typography>
              ))}
            </Box>
          </Box>
        )}
        <Grid container sx={{ marginTop: 2, width: "90vw" }} gap={2}>
          {services.map((service) => (
            <Grid
              sx={{
                cursor: !!service.handleClick ? "pointer" : "",
                height: "125px",
                textAlign: "center",
                border: `1px solid ${theme.palette.primary.main}`,
                borderRadius: "10px",
                fontWeight: 400,
                fontSize: "17px",
                lineHeight: "18px",
                fontFamily: "Montserrat",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "space-around",
              }}
              onClick={service.handleClick}
              item
              xs={2.86}
              xl={2.88}
            >
              {service.icon}
              <Typography fontSize={14} color="secondary.main">
                {service.label}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Dialog>
    </>
  );
}

export default PatientServicesDialog;
