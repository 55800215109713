import { useCallback } from "react";
import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

const path = "/callers";

function useCaller() {
  const getCallers = useCallback(async (filters) => {
    try {
      const response = await instance.get(path, {
        params: urlQuery(filters),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const postCaller = useCallback(async (data) => {
    try {
      const response = await instance.post(path, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const callToken = useCallback(async (data) => {
    try {
      const response = await instance.post("/callers/call", data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const updateCaller = useCallback(async ({ id, data }) => {
    try {
      const response = await instance.put(`${path}/${id}`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const deleteCaller = useCallback(async (id) => {
    try {
      const response = await instance.delete(`${path}/${id}`);
      return response.data;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []); 

  return {
    getCallers,
    postCaller,
    callToken,
    updateCaller,
    deleteCaller,
  };
}
export default useCaller;
