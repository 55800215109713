import { Box, Button, Divider, Grid, useTheme } from "@mui/material";
import { PatientsCard } from "../../../components";
import WarningSpan from "../../../helper/WarningSpan";
import {
  DateField,
  RadioField,
  SelectField,
  TextField,
} from "../../../components/FormFields";
import { useForm } from "react-hook-form";
import Feet from "./Feet";
import { useContext, useMemo } from "react";
import { AppContext } from "../../../contexts/AppContext";
import diabeticFootWarning from "../../../utils/diabeticFootWarning";
import { useLocalStorage } from "../../../hooks";

const radioBooleanOptions = [
  {
    label: "Sim",
    value: true,
  },
  {
    label: "Não",
    value: false,
  },
];

const types = [
  {
    describe: "Diabetes Tipo 1",
  },
  {
    describe: "Diabetes Tipo 2",
  },
  {
    describe: "Diabetes Gestacional",
  },
];

const resultOptions = [
  {
    describe: "Neuropatia ausente",
  },
  {
    describe: "Neuropatia presente, sem deformidades",
  },
  {
    describe:
      "Neuropatia presente, sinais de doença vascular e/ou deformidade presente",
  },
  {
    describe: "Úlcera/amputação prévia",
  },
];

const initialValues = {
  diabetic: null,
  amg: null,
  hypertensive: null,
  insulin_dependent: null,
  diabetic_type: "",
  last_amg_report_date: null,
  glycated_hemoglobin_result: "",
  glycated_hemoglobin_date: null,
  left_foot: {},
  right_foot: {},
  result: "",
};

export default function DiabeticFeet({ onlyView, attendance, handleClose }) {
  const theme = useTheme();
  const [, setSoapValues] = useLocalStorage("attendance", null);
  const { diabeticFeetEvaluation, setDiabeticFeetEvaluation } =
    useContext(AppContext);

  const { control, handleSubmit, watch, formState } = useForm({
    defaultValues: diabeticFeetEvaluation || initialValues,
  });

  const [result] = watch(["result"]);

  const diabeticFootWarningByResult = useMemo(
    () => diabeticFootWarning(result),
    [result]
  );

  const onSubmit = handleSubmit((values) => {
    setDiabeticFeetEvaluation(values);
    setSoapValues((soapValues) => ({
      ...soapValues,
      [attendance.id]: {
        ...soapValues[attendance.id],
        diabetic_foot_assessment: values,
      },
    }));
    handleClose();
  });

  return (
    <Grid item container xs={12} spacing={3}>
      <Grid item xs={12}>
        <PatientsCard patient={attendance} onDialog />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <Grid item xs={12}>
        {!!result && (
          <WarningSpan fontSize={"12px"} text={diabeticFootWarningByResult} />
        )}
      </Grid>
      <Grid item xs={2}>
        <RadioField
          control={control}
          name={"hypertensive"}
          label={"Hipertenso?"}
          options={radioBooleanOptions}
          optionValueKey={"value"}
          optionLabelKey="label"
          disabled={onlyView}
        />
      </Grid>
      <Grid item xs={2}>
        <RadioField
          control={control}
          name={"diabetic"}
          label={"Diabético?"}
          options={radioBooleanOptions}
          optionValueKey={"value"}
          optionLabelKey="label"
          disabled={onlyView}
        />
      </Grid>
      <Grid item xs={4}>
        <SelectField
          control={control}
          name={"diabetic_type"}
          label={"Tipos Diabetes"}
          options={types}
          optionLabelKey="describe"
          optionValueKey="describe"
          disabled={onlyView}
        />
      </Grid>
      <Grid item xs={4}>
        <DateField
          control={control}
          name={"last_amg_report_date"}
          maxDate={new Date()}
          label={"Data do ultimo laudo AMG"}
          disabled={onlyView}
        />
      </Grid>
      <Grid item xs={2}>
        <RadioField
          control={control}
          name={"amg"}
          label={"AMG?"}
          options={radioBooleanOptions}
          optionValueKey={"value"}
          optionLabelKey="label"
          disabled={onlyView}
        />
      </Grid>
      <Grid item xs={2}>
        <RadioField
          control={control}
          name={"insulin_dependent"}
          label={"Insulino Dependente?"}
          options={radioBooleanOptions}
          optionValueKey={"value"}
          optionLabelKey="label"
          disabled={onlyView}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          control={control}
          fullWidth
          name={"glycated_hemoglobin_result"}
          label={"Resultado hemoglobina glicada"}
          disabled={onlyView}
        />
      </Grid>
      <Grid item xs={4}>
        <DateField
          control={control}
          name={"glycated_hemoglobin_date"}
          label={"Data do resultado hemoglobina glicada"}
          disabled={onlyView}
        />
      </Grid>
      <Grid item xs={12}>
        <Feet control={control} disabled={onlyView} />
      </Grid>
      <Grid item xs={12}>
        <Box display={"flex"} columnGap={2}>
          <Box
            flex={1}
            sx={{
              bgcolor: theme.palette.secondary.light,
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              padding: 2,
              borderRadius: "8px",
              boxShadow: theme.shadows[1],
            }}
          >
            <RadioField
              control={control}
              name={"left_foot.ucle_foot_hitoric"}
              label={"Histórico de ulceração no pé?"}
              options={radioBooleanOptions}
              optionValcontrolueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"left_foot.abnormal_shape"}
              label={"O pé apresenta forma anormal?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"left_foot.fingers_deformation"}
              label={"Existe deformação nos dedos?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"left_foot.nails_ingrown"}
              label={"As unhas são grossas ou encravadas?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"left_foot.has_calluses"}
              label={"Apresenta calos?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"left_foot.has_edema"}
              label={"Apresenta edema?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"left_foot.has_increase_temperature"}
              label={"Apresenta elevação na temperatura?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"left_foot.has_muscle_weakness"}
              label={"Apresenta fraqueza muscular?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"left_foot.can_examine_soles_feet"}
              label={"Consegue examinar a planta dos seus pés?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"left_foot.appropriate_footwear"}
              label={"Utiliza calçados adequados?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
          </Box>
          <Box
            flex={1}
            sx={{
              bgcolor: theme.palette.secondary.light,
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              padding: 2,
              borderRadius: "8px",
              boxShadow: theme.shadows[1],
            }}
          >
            <RadioField
              control={control}
              name={"right_foot.ucle_foot_hitoric"}
              label={"Histórico de ulceração no pé?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"right_foot.abnormal_shape"}
              label={"O pé apresenta forma anormal?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"right_foot.fingers_deformation"}
              label={"Existe deformação nos dedos?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"right_foot.nails_ingrown"}
              label={"As unhas são grossas ou encravadas?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"right_foot.has_calluses"}
              label={"Apresenta calos?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"right_foot.has_edema"}
              label={"Apresenta edema?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"right_foot.has_increase_temperature"}
              label={"Apresenta elevação na temperatura?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"right_foot.has_muscle_weakness"}
              label={"Apresenta fraqueza muscular?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"right_foot.can_examine_soles_feet"}
              label={"Consegue examinar a planta dos seus pés?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
            <RadioField
              control={control}
              name={"right_foot.appropriate_footwear"}
              label={"Utiliza calçados adequados?"}
              options={radioBooleanOptions}
              optionValueKey={"value"}
              optionLabelKey="label"
              disabled={onlyView}
            />
          </Box>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <SelectField
          control={control}
          name={"result"}
          label={"Resultado"}
          options={resultOptions}
          optionLabelKey="describe"
          optionValueKey={"describe"}
          disabled={onlyView}
        />
      </Grid>
      <Grid item xs={12}>
        <Box display={"flex"} columnGap={2}>
          <Button
            variant="contained"
            disabled={!formState.isDirty || onlyView}
            onClick={() => onSubmit()}
          >
            FINALIZAR
          </Button>
          <Button onClick={() => handleClose()}>CANCELAR</Button>
        </Box>
      </Grid>
    </Grid>
  );
}
