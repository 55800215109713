import { useCallback } from "react";
import instance from "../config/api";

function useAuth() {
  const isTokenValid = useCallback(async (token) => {
    try {
      const response = await instance.get(`/authToken`, {
        headers: {
          "x-access-token": token,
        },
      });
      return response;
    } catch (err) {
      throw err;
    }
  }, []);

  const signin = useCallback(async (userCredentials) => {
    try {
      const response = await instance.post("/login", userCredentials);
      return response;
    } catch (err) {
      throw new Error(err.response.data.message);
    }
  }, []);

  const authSignature = useCallback(async (data) => {
    try {
      const response = await instance.post("/external-services/tsigner/login", data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const patchSignatureToken = useCallback(async (data) => {
    try {
      const response = await instance.patch(
        "/internal-services/tsigner/signer-token/current/update",
        data
      );
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  const signatureTokenIsValid = useCallback(async () => {
    try {
      const response = await instance.get("/external-services/tsigner/validate/signer-token");
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }, []);

  async function verifyUser(userCredentials) {
    try {
      const response = await instance.post("/login/verify", userCredentials);
      return response;
    } catch (err) {
      throw new Error(
        err.response.data ? err.response.data.message : "Falha de comunicação com o servidor"
      );
    }
  }

  async function changePassword(data) {
    try {
      const response = await instance.patch("/reset-password", data);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function resetPassword(id) {
    try {
      const response = await instance.patch(`/employees/${id}/reset-password`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function resetUsername({ id, newUsername }) {
    try {
      const response = await instance.patch(`/employees/${id}/reset-login`, {
        user_login: newUsername,
      });
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  async function expireToken() {
    try {
      const response = await instance.post(`/logout`);
      return response.data;
    } catch (error) {
      throw new Error(error.response.data.message);
    }
  }

  return {
    isTokenValid,
    signin,
    authSignature,
    signatureTokenIsValid,
    verifyUser,
    changePassword,
    resetPassword,
    patchSignatureToken,
    resetUsername,
    expireToken,
  };
}
export default useAuth;
