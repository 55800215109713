import { useNavigate, useParams } from "react-router-dom";
import { BoxContainer } from "../../components";
import { TITLE_NAME } from "../../config/config";
import { useIndividualRegister, usePatient } from "../../service";
import { useQuery } from "react-query";
import { Helmet } from "react-helmet";
import { format } from "date-fns";
import { Box, Grid, TablePagination, Typography } from "@mui/material";
import TableFilter from "../../components/Table/TableFilter";
import { useNotifier } from "../../hooks";
import { Person } from "@mui/icons-material";
import { useEffect, useState } from "react";

function IndividualHistoric() {
  const { getPatientIndividualHistoric } = useIndividualRegister();
  const { getPatients } = usePatient();
  const { id } = useParams();
  const navigate = useNavigate();
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const individualHistoric = useQuery(
    ["individual-patient-historic", [id, page, limit]],
    () => getPatientIndividualHistoric(id, { page: page, limit: limit }),
    {
      enabled: !!id,
      retry: false,
      refetchOnWindowFocus: false,
    }
  );

  const patient = useQuery(
    "patient",
    () =>
      getPatients({
        id: id,
        full_registration: true,
      }),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  const patiendData = patient?.data?.items[0] || null;

  const columns = [
    {
      field: "employee.name",
      name: "Nome do profissional",
      width: 250,
      type: "string",
    },
    {
      field: "employee?.sus_card",
      name: "CNS do profissional",
      width: 150,
      type: "string",
      use: (value) => !!value && value.replace(/^(\d{3})(\d{4})(\d{4})(\d{4})$/, "$1 $2 $3 $4"),
    },
    {
      field: "team.ine",
      name: "INE",
      width: 150,
      type: "string",
    },
    {
      field: "microarea.name",
      name: "Microárea",
      width: 250,
      type: "string",
      flex: 0.3,
    },
    {
      field: "createdAt",
      name: "Data",
      width: 100,
      type: "date",
      use: (value) => !!value && format(new Date(value), "dd/MM/yyyy HH:mm")
    },
  ];

  useEffect(() => {
    individualHistoric.refetch();
  }, [page, limit]);

  return (
    <>
      <Helmet>
        <title> {TITLE_NAME} | Histórico de ficha individual </title>
      </Helmet>

      <BoxContainer title="Histórico de ficha individual">
        <Box>
          <Grid item x2={12}>
            <Typography
              variant="h6"
              color="secondary"
              sx={{
                marginBottom: "1rem",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
              }}
            >
              <Person sx={{ fontSize: "2rem" }} />
              {patiendData?.name}
            </Typography>
          </Grid>

          <Grid xs={12}>
            <Grid item xs={4}>
              <Typography color="secondary">
                CPF:{" "}
                {patiendData?.physic_national?.replace(
                  /(\d{3})(\d{3})(\d{3})(\d{2})/,
                  "$1.$2.$3-$4"
                )}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="secondary">
                CNS:{" "}
                {patiendData?.sus_card?.replace(/^(\d{3})(\d{4})(\d{4})(\d{4})$/, "$1 $2 $3 $4")}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="secondary">Nome da mãe: {patiendData?.mother_name}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="secondary">
                Data de nascimento:{" "}
                {!!patiendData?.birth_date &&
                  format(new Date(patiendData?.birth_date), "dd/MM/yyyy")}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Grid item xs={12} sx={{ paddingTop: 3 }}>
          <TableFilter
            noFilter
            data={individualHistoric.data?.items || []}
            columns={columns}
            confirmMessage={`Você realmente deseja suspender este cadastro?`}
            actions
            actionsTypes={["view"]}
            actionHandleView={(i) => {
              navigate(`/cds/individual/register/${individualHistoric.data.items[i].id}?isHistoric=true`);
            }}
          />
        </Grid>
        <Box>
          <Grid item xs={12} sx={{ paddingTop: 3 }}>
            <TablePagination
              count={individualHistoric.data?.totalItems || 0}
              component="div"
              page={page}
              onPageChange={(_, newPage) => {
                setPage(newPage);
              }}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 20, 40, 75]}
              onRowsPerPageChange={(event) => {
                setLimit(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </Grid>
        </Box>
      </BoxContainer>
    </>
  );
}

export default IndividualHistoric;
