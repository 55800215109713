import { createElement, footer, getUserData } from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";
import { format, parseISO } from "date-fns";
import { SP_LOGO } from "./pdfSources";

function manualSADTPDF(examType, data, patient, documentData) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;
  const userData = getUserData();

  const patientCode = ["", "", "", "", "", "", "", "", ""];
  patient?.number
    ?.toString()
    .split("")
    .forEach((number, index) => (patientCode[index] = number));

  const attendanceCode = ["", "", "", "", "", "", "", ""];
  data?.attendance?.number
    ?.toString()
    .split("")
    .forEach((number, index) => (attendanceCode[index] = number));

  const firstRow = {
    margin: [0, 0, 0, 10],
    columnGap: 20,
    columns: [
      createElement({
        widths: [50, 98],
        height: 30,
        labels: [
          {
            fill: true,
            name: "Prontuário",
            rows: [
              {
                type: "text",
                value: "Nº",
              },
            ],
          },
          {
            fill: true,
            margin: [-40, 11, 0, 0],
            rows: [
              {
                type: "number",
                value: patientCode,
                width: 6,
                height: 11,
              },
            ],
          },
        ],
      }),
      createElement({
        widths: [123],
        height: 30,
        margin: [8, 0, 0, 0],
        labels: [
          {
            name: "Cód. Atendimento",
            fill: true,
            rows: [
              {
                type: "number",
                value: attendanceCode,
                width: 6,
                height: 11,
              },
            ],
          },
        ],
      }),
      createElement({
        widths: [105],
        margin: [8, 0, 0, 0],
        height: 30,
        labels: [
          {
            name: "Data de Nascimento",
            fill: true,
            rows: [
              {
                type: "date",
                value: patient?.birth_date
                  ? format(parseISO(patient?.birth_date), "dd/MM/yyyy")
                  : null,
                width: 25,
                height: 11,
              },
            ],
          },
        ],
      }),
    ],
  };

  const susCard = ["", "", "", "", "", "", "", "", "", "", "", "", "", "", ""];
  patient?.sus_card?.split("").forEach((number, index) => (susCard[index] = number));

  const secondRow = {
    margin: [0, 0, 0, 10],
    columnGap: 20,
    columns: [
      createElement({
        widths: [255],
        height: 30,
        labels: [
          {
            name: "Nome do Paciente",
            rows: [
              {
                type: "text",
                value: patient?.social_prioritize
                  ? patient?.social_name
                  : patient?.name || "Não informado",
              },
            ],
          },
        ],
      }),
      createElement({
        widths: [228],
        height: 30,
        labels: [
          {
            name: "CNS",
            fill: true,
            rows: [
              {
                type: "number",
                value: susCard,
                width: 6,
                height: 11,
              },
            ],
          },
        ],
      }),
    ],
  };

  const thirdRow = {
    margin: [0, 0, 0, 10],
    columnGap: 20,
    columns: [
      createElement({
        widths: [255],
        height: 30,
        labels: [
          {
            name: "Nome da Mãe",
            rows: [
              {
                type: "text",
                value: patient?.mother_name || "Não informado",
              },
            ],
          },
        ],
      }),
      createElement({
        widths: [228],
        height: 30,
        labels: [
          {
            name: "Nome do Profissional",
            rows: [
              {
                type: "text",
                value: document?.employee?.name || userData.name,
              },
            ],
          },
        ],
      }),
    ],
  };

  const fourthdRow = {
    margin: [0, 0, 0, 10],
    columnGap: 20,
    columns: [
      createElement({
        widths: [150],
        height: 30,
        labels: [
          {
            name: "Serviço",
            rows: [
              {
                type: "text",
                value: data?.attendance?.planCard
                  ? data.attendance.planCard.insurancePlan.insurance.describe
                  : "SUS",
              },
            ],
          },
        ],
      }),
      createElement({
        widths: [333],
        height: 30,
        labels: [
          {
            name: "Residente",
            rows: [
              {
                type: "text",
                value: patient?.contact?.county
                  ? `${patient?.contact?.county} - ${patient?.contact?.state}`
                  : "Não informado",
              },
            ],
          },
        ],
      }),
    ],
  };

  const ethnicities = {
    Preto: "P",
    Branco: "B",
    Amarelo: "A",
    Indígena: "I",
  };

  const genders = {
    Masculino: "M",
    Feminino: "F",
    Outro: "O",
  };

  const fifthdRow = {
    margin: [0, 0, 0, 10],
    columnGap: 20,
    columns: [
      createElement({
        widths: [110, 140],
        height: 30,
        labels: [
          {
            name: "Raça e Cor",
            rows: [
              {
                type: "number",
                value: [ethnicities[patient?.race] || ""],
                width: 8,
                height: 11,
              },
            ],
          },
          {
            margin: [-80, 12, 0, 0],
            rowMargin: [0, 0, 0, 0],
            rows: [
              {
                type: "text",
                value: "Branca (B)   Preta (P)   Amarela (A)   Indigena (I)",
              },
            ],
          },
        ],
      }),
      createElement({
        widths: [110, 107],
        height: 30,
        margin: [8, 0, 0, 0],
        labels: [
          {
            name: "Sexo",
            rows: [
              {
                type: "number",
                value: [genders[patient?.gender] || ""],
                width: 8,
                height: 11,
              },
            ],
          },
          {
            margin: [-80, 12, 0, 0],
            rowMargin: [0, 0, 0, 0],
            rows: [
              {
                type: "text",
                value: "Masculino (M)   Feminino (F)   Outro (O)",
              },
            ],
          },
        ],
      }),
    ],
  };

  const cids = data?.assessment?.cids?.reduce((str, { description }, index, array) => {
    return array.length - 1 === index ? str + description : str + `${description}, `;
  }, "");

  const sixthdRow = {
    margin: [0, 0, 0, 10],
    columnGap: 20,
    columns: [
      createElement({
        widths: [504],
        height: 55,
        labels: [
          {
            name: "Hipótese Diagnóstica",
            rows: [
              {
                type: "text",
                value: cids || "Não informado",
              },
            ],
          },
        ],
      }),
    ],
  };

  const seventhdRow = {
    margin: [0, 0, 0, 10],
    columnGap: 20,
    columns: [
      createElement({
        widths: [504],
        height: 55,
        labels: [
          {
            name: "Área e/ou Segmento Anatômico a ser Examinado",
            rows: [
              {
                type: "text",
                value: "",
              },
            ],
          },
        ],
      }),
    ],
  };

  const exams = data?.plan?.prescription?.exams?.reduce((str, { describe, type }, index, array) => {
    if (examType === type) {
      return str + `${describe} (${type}), `;
    }

    return str;
  }, "");

  const eighthRow = {
    margin: [0, 0, 0, -20],
    columnGap: 20,
    columns: [
      createElement({
        widths: [504],
        height: 100,
        labels: [
          {
            name: "Exame ou Serviços Solicitados",
            rows: [
              {
                type: "text",
                value: exams ? exams.replace(/, $/g, "") : "Não informado",
              },
            ],
          },
        ],
      }),
    ],
  };

  const authorization = [
    {
      canvas: [{ type: "line", x1: 100, y1: 40, x2: 420, y2: 40, lineWidth: 1 }],
    },
    {
      margin: [0, 10, 0, 0],
      alignment: "center",
      text: "Autorização quando solicitado",
    },
  ];

  const header = {
    margin: [40, 20, 40, 40],
    columnGap: -40,
    columns: [
      {
        image: "sp",
        width: 80,
        height: 30,
        margin: [0, 5, 0, 0],
      },
      {
        text: "GUIA DE ENCAMINHAMENTO SADT",
        fontSize: 12,
        bold: true,
        alignment: "center",
        margin: [0, 12, 0, 0],
      },
    ],
  };

  const definitions = {
    pageSize: "A4",
    pageMargins: [40, 70, 40, 120],
    defaultStyle: {
      fontSize: 10,
    },
    info: {
      title: pdfName,
    },
    header,
    footer: footer(documentData?.createdAt, true, documentData?.employee),
    content: [
      firstRow,
      secondRow,
      thirdRow,
      fourthdRow,
      fifthdRow,
      sixthdRow,
      seventhdRow,
      eighthRow,
      authorization,
    ],
    images: {
      sp: SP_LOGO,
    },
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;

  return documentPDF;
}

export default manualSADTPDF;
