import React from "react";
import { BoxContainer } from "../../../components";
import ProntuaryDocuments from "../ProntuaryDocuments";

export default function PendingSignature() {
  return (
    <BoxContainer title="Pendência de assinatura">
      <ProntuaryDocuments />
    </BoxContainer>
  );
}
