import { Box, Grid, Grow, IconButton, Typography, styled } from "@mui/material";
import React from "react";
import { DeleteIconButton, EditIconButton, PrintIconButton } from "../../../components/Buttons";
import { add, format } from "date-fns";
import { MedicationRounded } from "@mui/icons-material";

const CardPrescription = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  rowGap: 4,
  backgroundColor: theme.palette.primary.medium,
  borderRadius: 8,
  padding: "0.8rem",
  color: theme.palette.primary.light,
  position: "relative",
}));

const IconContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: 4,
  position: "absolute",
  top: 6,
  right: 15,
});

const PrescriptionHeader = styled(Typography, {
  shouldForwardProp: (prop) => {
    return prop !== "color" && prop !== "sx";
  },
})(({ theme }) => ({
  fontSize: 20,
  fontWeight: 600,
  color: theme.palette.primary.light,
}));

const PrescriptionDesc = styled(Typography)(({ theme }) => ({
  fontSize: 13,
  marginRight: 20,
  color: theme.palette.primary.light,
}));

export default function DrugPrescriptionCard({
  data,
  disabled,
  handleRemove,
  handleEdit,
  handlePrint,
}) {
  const isGroup = Array.isArray(data);
  let isRegistered = false;

  if (isGroup) {
    isRegistered = !!data[0].id_prescription;
  }

  function mountNotes() {
    let notes = `
      ${
        data.continuous_use
          ? "uso contínuo"
          : data.quantity.toString().replace(".", ",") + " " + data.presentation
      } , 
      ${data.posology}
      ${data.notes ? `, ${data.notes}` : ""}
    `;

    return `( ${notes} )`;
  }

  function hasAssistedMedications() {
    if (isGroup) {
      return data.some((medication) => {
        return medication.medication_assisted_treatment;
      });
    }

    return data.medication_assisted_treatment;
  }

  return (
    <Grid xs={12} item>
      <Grow in={true} timeout={300}>
        <CardPrescription>
          {hasAssistedMedications() && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "2px",
              }}
            >
              <IconButton>
                <MedicationRounded color="primary" />
              </IconButton>
              {isGroup ? (
                <PrescriptionDesc component="span">Contêm medicação assistida</PrescriptionDesc>
              ) : (
                <PrescriptionDesc component="span">
                  Medicação Assistida: Término Previsto{" "}
                  {format(
                    add(new Date(), {
                      days: data.treatment_cycle,
                    }),
                    "dd/MM/yyyy"
                  )}
                </PrescriptionDesc>
              )}
            </Box>
          )}
          <PrescriptionHeader component="h6">
            {isGroup ? `Agrupamento ${data[0].tab_id}` : data.name}
          </PrescriptionHeader>
          {isGroup ? (
            <PrescriptionDesc component="ul">
              {data.map((medication, index) => (
                <Typography key={`${medication.name}-${index}`} component="li">
                  {medication.name}
                </Typography>
              ))}
            </PrescriptionDesc>
          ) : (
            <PrescriptionDesc variant="p" component="p">
              {mountNotes()}
            </PrescriptionDesc>
          )}
          <IconContainer>
            {!isRegistered && <EditIconButton onClick={handleEdit} disabled={disabled} />}
            {!isRegistered && <DeleteIconButton onClick={handleRemove} disabled={disabled} />}
            {isRegistered && <PrintIconButton onClick={handlePrint} disabled={disabled} />}
          </IconContainer>
        </CardPrescription>
      </Grow>
    </Grid>
  );
}
