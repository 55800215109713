import { Box, Grid, TablePagination } from "@mui/material";
import { BoxContainer } from "../../components";
import { useForm } from "react-hook-form";
import RegexOf from "../../config/regex";
import { Button, DateField, TextField } from "../../components/FormFields";
import { usePatient } from "../../service";
import { useQuery } from "react-query";
import { useEffect, useState } from "react";
import { useNotifier } from "../../hooks";
import { cleanUpMask } from "../../config/mask";
import { yupResolver } from "@hookform/resolvers/yup";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import { LibraryAdd } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import TableFilter from "../../components/Table/TableFilter";
import { format, formatISO, isValid, parseISO } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";
import Yup from "../../config/yup";
import { validateCNS789, validateCPF } from "../../config/validations";

function IndividualRegister() {
  const notify = useNotifier();
  const { getPatients } = usePatient();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const initialValues = {
    physic_national: "",
    sus_card: "",
    name: "",
    birth_date: null,
    mother_name: "",
    updatedAt: null,
  };

  const searchScheme = Yup.object()
    .shape({
      physic_national: Yup.string().nullable().typeError("CPF é obrigatório."),
      sus_card: Yup.string().nullable().typeError("CNS é obrigatório."),
      name: Yup.string(),
      birth_date: Yup.date()
        .min(new Date("01/01/1900"), "O Ano deve ser posterior ou igual a 1900")
        .nullable()
        .typeError("Data inválida."),
      mother_name: "",
      updatedAt: Yup.date()
        .min(new Date("01/01/1900"), "O Ano deve ser posterior ou igual a 1900")
        .nullable()
        .typeError("Data inválida."),
    })
    .test("testPhysicNational", "CPF inválido", function (values) {
      const { sus_card, physic_national } = values;
      if (physic_national !== "" && !validateCPF(physic_national)) {
        return this.createError({
          path: "physic_national",
          message: "CPF inválido.",
        });
      } else if (sus_card !== "" && !validateCNS789(sus_card)) {
        return this.createError({
          path: "sus_card",
          message: "CNS inválido.",
        });
      } else return true;
    });

  const { watch, control, reset, handleSubmit } = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(searchScheme),
  });
  const [enableQuery, setEnableQuery] = useState(false);
  const navigate = useNavigate();

  const zonedUpdateDate = isValid(watch("updatedAt"))
    ? zonedTimeToUtc(new Date(watch("updatedAt")), "America/Sao_Paulo")
    : "";
  const zonedBirthDate = isValid(watch("birth_date"))
    ? zonedTimeToUtc(new Date(watch("birth_date")), "America/Sao_Paulo")
    : "";

  const formatedBirthDate = isValid(zonedBirthDate)
    ? formatISO(zonedBirthDate, { representation: "complete" })
    : "";
  const formatedUpdatedDate = isValid(zonedUpdateDate)
    ? formatISO(zonedUpdateDate, { representation: "complete" })
    : "";

  const patient = useQuery(
    "patient-search",
    () =>
      getPatients({
        page: page,
        limit: limit,
        physic_national: cleanUpMask(watch("physic_national"), "", ["_", "-", "."]),
        sus_card: cleanUpMask(watch("sus_card"), "", ["_"]),
        name: watch("name"),
        birth_date: formatedBirthDate,
        mother_name: watch("mother_name"),
        updated_at: formatedUpdatedDate,
        is_register_complete: true,
      }),
    {
      enabled: enableQuery,
      refetchOnWindowFocus: false,
      retry: false,
      onSuccess: (response) => {
        if (response.items.length) {
          notify(
            `${response.items.length > 1 ? "Individuos encontrados" : "Individuo encontrado"}`,
            "success"
          );
        } else {
          notify("Nenhuma ficha foi encontrado com este fitro", "warning");
        }
        setEnableQuery(!enableQuery);
      },
      onError: (error) => {
        setEnableQuery(!enableQuery);
        notify(error.message, "error");
      },
    }
  );

  useEffect(() => {
    patient.refetch();
  }, [page, limit]);

  const onSubmit = () => {
    setEnableQuery(!enableQuery);
  };

  const columns = [
    {
      field: "name",
      name: "Nome",
      width: 250,
      type: "string",
    },
    {
      field: "physic_national",
      name: "CPF",
      width: 150,
      type: "string",
      use: (value) => !!value && value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
    },
    {
      field: "sus_card",
      name: "CNS",
      width: 150,
      type: "string",
    },
    {
      field: "birth_date",
      name: "Data de Nascimento",
      width: 100,
      type: "date",
      use: (value) => !!value && format(parseISO(value), "dd/MM/yyyy"),
    },
    {
      field: "mother_name",
      name: "Nome da Mãe",
      width: 250,
      type: "string",
      flex: 0.3,
    },
    {
      field: "updatedAt",
      name: "Data",
      width: 100,
      type: "date",
      use: (value) => !!value && format(parseISO(value), "dd/MM/yyyy"),
    },
  ];

  return (
    <BoxContainer title={"Consulta de Ficha Individual"}>
      <Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <TextField
                name="physic_national"
                label="CPF DO CIDADÃO"
                control={control}
                mask={RegexOf.cpf}
              />
            </Grid>

            <Grid item xs={3}>
              <TextField
                name="sus_card"
                label="CNS DO CIDADÃO"
                control={control}
                mask={RegexOf.cns}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField name="name" label="NOME DO INDIVÍDUO" control={control} />
            </Grid>

            <Grid item xs={3}>
              <DateField
                name="birth_date"
                label="DATA DE NASCIMENTO"
                control={control}
                placeholder="DD/MM/AAAA"
              />
            </Grid>

            <Grid item xs={6}>
              <TextField name="mother_name" label="NOME DA MÃE" control={control} />
            </Grid>

            <Grid item xs={3}>
              <DateField name="updatedAt" label="DATA" control={control} placeholder="DD/MM/AAAA" />
            </Grid>

            <Grid item xs={12} alignItems="stretch">
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                  pt: 2,
                }}
              >
                <Link to="/cds/individual/register">
                  <Button sx={{ textDecoration: "none" }}>
                    <LibraryAdd /> Adicionar ficha
                  </Button>
                </Link>

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "end",
                    pt: 2,
                  }}
                >
                  <Button onClick={reset} sx={{ mr: 1 }} variant="text">
                    Limpar os Dados
                  </Button>
                  <Button type="submit" loading={patient.isFetching} loadingMessage="Buscando">
                    <SearchOutlinedIcon /> Buscar
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
      {patient.data?.items && (
        <>
          <Grid item xs={12} sx={{ paddingTop: 3 }}>
            <TableFilter
              noFilter
              data={patient.data?.items || []}
              columns={columns}
              emptyMessage="Nenhum paciente com ficha individual foi encontrado."
              confirmMessage={`Você realmente deseja suspender este cadastro?`}
              loading={patient.isFetching && patient.isLoading}
              actions
              actionsTypes={["view", "edit"]}
              actionHandleView={(i) => {
                navigate(`register/${patient.data.items[i].id}`);
              }}
              actionHandleEdit={(i) => {
                navigate(`register/${patient.data.items[i].id}?isEditing=true`);
              }}
            />
          </Grid>
          <Box>
            <Grid item xs={12} sx={{ paddingTop: 3 }}>
              <TablePagination
                count={patient.data?.totalItems || 0}
                component="div"
                page={page}
                onPageChange={(_, newPage) => {
                  setPage(newPage);
                }}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 20, 40, 75]}
                onRowsPerPageChange={(event) => {
                  setLimit(parseInt(event.target.value, 10));
                  setPage(0);
                }}
              />
            </Grid>
          </Box>
        </>
      )}
    </BoxContainer>
  );
}

export default IndividualRegister;
