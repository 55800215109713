import React, { useContext, useState } from "react";
import { BoxContainer } from "../../components";
import { Grid, Box, Button, Divider } from "@mui/material";
import { useProcedures, useScales, useSchedule } from "../../service";
import { usePatient } from "../../service";
import { differenceInDays, endOfMonth, format, isToday } from "date-fns";
import usePermissions from "../../config/usePermissions";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormProvider, useForm } from "react-hook-form";
import {
  DateField,
  PaginatedAutocompleteField,
  RadioField,
  TextField,
} from "../../components/FormFields";
import { useMutation, useQuery } from "react-query";
import { useNotifier } from "../../hooks";
import Yup from "../../config/yup";
import { searchByPatientIdentifiers, verifyAttendanceFlow } from "../../utils";
import { AuthContext } from "../../contexts/AuthContext";

function Schedule() {
  const { userData } = useContext(AuthContext);

  const hasContact = (patient) => {
    if (!!patient) {
      if (!!patient.contact?.cell_phone || !!patient.contact?.phone) {
        return true;
      } else {
        return (
          !!patient.responsible?.cell_phone || !!patient.responsible?.phone
        );
      }
    } else {
      return true;
    }
  };

  const validations = Yup.object().shape({
    patient: Yup.object()
      .nullable()
      .required("É Requerido")
      .test(
        "hasContact",
        "Paciente não contém telefone celular no seu cadastro",
        hasContact
      ),
    date: Yup.date().required("É requerido").nullable().default(undefined),
    schedule: Yup.object().nullable().required("É requerido"),
    description: Yup.string().required("É requerido"),
    procedures: Yup.object().nullable().required("É requerido"),
  });

  const typesControler = useForm({
    defaultValues: {
      type: "Data",
    },
  });

  const type = typesControler.watch("type");

  const form = useForm({
    defaultValues: {
      date: null,
      selectEmployeeOrSector: "Profissional",
      employeeOrSector: null,
      service: null,
      attendanceType: null,
      schedule: null,
      patient: null,
      procedures: null,
      spontaneous_visit: true,
      description: "",
      referred_by: "",
    },
    resolver: yupResolver(validations),
  });

  const [
    dateField,
    attendanceTypeField,
    selectEmployeeOrSector,
    employeeOrSectorField,
    serviceField,
    spontaneousVisitField,
    patientField,
  ] = form.watch(
    [
      "date",
      "attendanceType",
      "selectEmployeeOrSector",
      "employeeOrSector",
      "service",
      "spontaneous_visit",
      "patient",
    ],
    null
  );

  const typesOptions = [
    { value: "Data" },
    { value: "Profissional" },
    { value: "Setor" },
    { value: "Serviço" },
  ];

  const selectTypesOptions = [{ value: "Profissional" }, { value: "Setor" }];

  const spontaneousVisitOptions = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  const { getPatients } = usePatient();
  const { getProcedures } = useProcedures();
  const {
    getScalesDays,
    getScalesServices,
    getScalesTreatmentTypes,
    getScalesHours,
    getScalesSectors,
    getScalesEmployees,
  } = useScales();

  const { postScheduling } = useSchedule();
  const postMutate = useMutation({
    mutationKey: "postScheduling",
    mutationFn: postScheduling,
  });

  const notify = useNotifier();

  const permissions = usePermissions("/schedule/scheduling");
  const [currentDate, setCurrentDate] = useState(new Date());
  const endOfMonthDate = endOfMonth(currentDate);
  const daysUntilEndOfMonth = differenceInDays(endOfMonthDate, currentDate);

  const { data: scalesDays, isLoading: loadingDays } = useQuery(
    ["ScalesDaysQuery", currentDate, employeeOrSectorField, serviceField],
    () =>
      getScalesDays({
        initial_date: format(currentDate, "yyyy-MM-dd"),
        final_date: format(endOfMonthDate, "yyyy-MM-dd"),
        limit: daysUntilEndOfMonth + 1,
        employee_id: employeeOrSectorField?.name
          ? employeeOrSectorField?.id
          : undefined,
        sector_id: employeeOrSectorField?.describe
          ? employeeOrSectorField?.id
          : undefined,
        service_id: serviceField?.id || undefined,
        active: true,
      }),
    {
      refetchOnWindowFocus: false,
    }
  );

  const inputOrders = {
    Data: ["date", "selectEmployeeOrSector", "employeeOrSector", "service"],
    Profissional: ["employeeOrSector", "date", "service"],
    Setor: ["employeeOrSector", "date", "service"],
    Serviço: ["service", "date", "selectEmployeeOrSector", "employeeOrSector"],
  };

  const inputDisableByFormType = {
    Data: {
      employeeOrSector: !dateField,
      service: !(dateField && employeeOrSectorField),
    },
    Profissional: {
      date: !employeeOrSectorField,
      service: !(employeeOrSectorField && dateField),
    },
    Setor: {
      date: !employeeOrSectorField,
      service: !(employeeOrSectorField && dateField),
    },
    Serviço: {
      date: !serviceField,
      employeeOrSector: !(serviceField && dateField),
    },
  };

  const scheduleInputs = {
    date: (disabled) => (
      <DateField
        name="date"
        minDate={new Date()}
        fullWidth
        label={"Data"}
        loading={loadingDays}
        onOpen={() => {
          if (!isToday(currentDate) && !dateField) {
            setCurrentDate(new Date());
          }
        }}
        onMonthChange={(monthInitialDay) => {
          setCurrentDate(monthInitialDay);
        }}
        shouldDisableDate={(dateToDisable) => {
          return !scalesDays?.items?.some(
            ({ date }) => format(dateToDisable, "yyyy-MM-dd") === date
          );
        }}
        disabled={disabled}
      />
    ),
    selectEmployeeOrSector: () => (
      <RadioField
        name={"selectEmployeeOrSector"}
        options={selectTypesOptions}
        label={"Selecione o tipo"}
        optionLabelKey="value"
        optionValueKey={"value"}
      />
    ),
    employeeOrSector: (disabled) => (
      <PaginatedAutocompleteField
        service={(params) => {
          const filters = {
            ...params,
            scale_date: dateField ? format(dateField, "yyyy-MM-dd") : undefined,
            service_id: serviceField?.id || undefined,
          };

          if (
            type === "Profissional" ||
            selectEmployeeOrSector === "Profissional"
          ) {
            return getScalesEmployees(filters);
          }

          return getScalesSectors(filters);
        }}
        filterKey={
          type === "Profissional" || selectEmployeeOrSector === "Profissional"
            ? "name"
            : "describe"
        }
        refetchService={[dateField, serviceField, type, selectEmployeeOrSector]}
        queryKey={
          type === "Profissional" || selectEmployeeOrSector === "Profissional"
            ? "scales-employees"
            : "scale-sectors"
        }
        autoCompleteProps={{
          getOptionLabel: (option) => option.name || option.describe,
        }}
        name={"employeeOrSector"}
        label={
          type === "Profissional" || selectEmployeeOrSector === "Profissional"
            ? "Profissional"
            : "Setor"
        }
        disabled={disabled}
      />
    ),
    service: (disabled) => (
      <PaginatedAutocompleteField
        filterKey={"describe"}
        optionLabelKey={"describe"}
        service={(params) =>
          getScalesServices({
            ...params,
            date: dateField ? format(dateField, "yyyy-MM-dd") : undefined,
            employee_id: employeeOrSectorField?.name
              ? employeeOrSectorField?.id
              : undefined,
            sector_id: employeeOrSectorField?.describe
              ? employeeOrSectorField?.id
              : undefined,
          })
        }
        queryKey={"getScalesServices"}
        name={"service"}
        label="Serviços"
        refetchService={[employeeOrSectorField, dateField]}
        disabled={disabled}
      />
    ),
  };

  const handleSubmit = form.handleSubmit((values) => {
    const newValues = {
      date: format(new Date(values.date), "yyyy-MM-dd"),
      hour: values.schedule.hour,
      id_employee: values.employeeOrSector?.name
        ? values.employeeOrSector.id
        : undefined,
      id_patient: values.patient.id,
      id_sector: values.employeeOrSector?.describe
        ? values.employeeOrSector.id
        : undefined,
      id_service: values.service.id,
      id_treatment_type: values.attendanceType.id,
      procedure_id: values.procedures?.id,
      description: values.description,
      spontaneous_visit: values.spontaneous_visit,
      referred_by: values.referred_by,
    };

    postMutate.mutate(newValues, {
      onSuccess() {
        notify("Agendamento Criado com Sucesso!", "success");
        form.reset();
      },
      onError(err) {
        notify(err.message, "error");
      },
    });
  });

  return (
    <BoxContainer title={"Agendamento"}>
      <Box display={"flex"} flexDirection={"column"} rowGap={2}>
        <Box>
          <RadioField
            control={typesControler.control}
            name={"type"}
            options={typesOptions}
            label={"Pesquisar por..."}
            customOnChange={(value) => {
              form.resetField("date");
              form.resetField("employeeOrSector");
              form.setValue(
                "selectEmployeeOrSector",
                !["Profissional", "Setor"].includes(value)
                  ? "Profissional"
                  : null
              );
              form.resetField("service");
              form.resetField("schedule");
              form.resetField("attendanceType");
            }}
            optionLabelKey="value"
            optionValueKey={"value"}
          />
        </Box>

        <Divider />
        <FormProvider {...form}>
          <form onSubmit={handleSubmit}>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={3}>
                <PaginatedAutocompleteField
                  service={({ name, ...params }) => {
                    searchByPatientIdentifiers(name, params, [
                      "physic_national",
                      "sus_card",
                      "name",
                    ]);
                    return getPatients(params);
                  }}
                  queryKey={"patientsQuery"}
                  filterKey={"name"}
                  optionLabelKey={"name"}
                  name={"patient"}
                  label="Buscar por: CPF ou CNS ou nome do paciente.."
                  customOnChange={() => {
                    form.setValue("procedures", null);
                  }}
                />
              </Grid>
              {inputOrders[type]?.map((input, index) => (
                <Grid
                  key={index}
                  item
                  xs={
                    ["date", "selectEmployeeOrSector"].includes(input)
                      ? 2
                      : index === 0
                      ? 2
                      : 3
                  }
                >
                  {scheduleInputs[input](inputDisableByFormType[type][input])}
                </Grid>
              ))}
              <Grid item xs={4}>
                <PaginatedAutocompleteField
                  filterKey={"name"}
                  optionLabelKey={"describe"}
                  name={"attendanceType"}
                  service={(params) =>
                    getScalesTreatmentTypes({
                      ...params,
                      date: dateField
                        ? format(dateField, "yyyy-MM-dd")
                        : undefined,
                      employee_id: employeeOrSectorField?.name
                        ? employeeOrSectorField?.id
                        : undefined,
                      sector_id: employeeOrSectorField?.describe
                        ? employeeOrSectorField?.id
                        : undefined,
                      service_id: serviceField?.id || undefined,
                    })
                  }
                  queryKey={"getScalesTreatmentTypes"}
                  label="Tipo de Atendimento"
                  refetchService={[
                    dateField,
                    employeeOrSectorField,
                    serviceField,
                  ]}
                  disabled={
                    !(dateField && employeeOrSectorField && serviceField)
                  }
                />
              </Grid>
              <Grid item xs={4}>
                <PaginatedAutocompleteField
                  filterKey={"name"}
                  optionLabelKey={"hour"}
                  service={(params) =>
                    getScalesHours({
                      ...params,
                      open: true,
                      date: dateField
                        ? format(dateField, "yyyy-MM-dd")
                        : undefined,
                      employee_id: employeeOrSectorField?.name
                        ? employeeOrSectorField?.id
                        : undefined,
                      sector_id: employeeOrSectorField?.describe
                        ? employeeOrSectorField?.id
                        : undefined,
                      service_id: serviceField?.id || undefined,
                      treatment_type_id: attendanceTypeField?.id || undefined,
                    })
                  }
                  queryKey={"getScalesHours"}
                  name={"schedule"}
                  autoCompleteProps={{
                    getOptionDisabled: (option) => option.status !== "open",
                  }}
                  label="Horários"
                  refetchService={[
                    dateField,
                    employeeOrSectorField,
                    serviceField,
                    attendanceTypeField,
                  ]}
                  disabled={
                    !(
                      dateField &&
                      employeeOrSectorField &&
                      serviceField &&
                      attendanceTypeField
                    )
                  }
                />
              </Grid>
              <Grid item xs={2} display={"flex"} justifyContent={"center"}>
                <RadioField
                  name={"spontaneous_visit"}
                  label={"Procura Espontânea:"}
                  options={spontaneousVisitOptions}
                  optionLabelKey="label"
                  optionValueKey={"value"}
                />
              </Grid>
              <Grid item xs={10}>
                <TextField
                  name={"referred_by"}
                  label="Encaminhado por:"
                  disabled={spontaneousVisitField === "true"}
                  multiline
                />
              </Grid>
              <Grid item xs={12}>
                <PaginatedAutocompleteField
                  service={(params) => {
                    const types = ["administrative"];

                    if (userData?.company.type.CEM) {
                      types.push("administrative_cem");
                    }

                    return getProcedures({
                      ...params,
                      type: types,
                      filter: "active",
                      attendance_flow: verifyAttendanceFlow(),
                      patient_id: patientField?.id,
                    });
                  }}
                  refetchService={[patientField]}
                  queryKey={"proceduresQuery"}
                  filterKey={"filters"}
                  optionLabelKey={"describe"}
                  name={"procedures"}
                  label="Procedimentos:"
                  required
                  autoCompleteProps={{
                    getOptionLabel: (data) => {
                      let label = data?.name;

                      if (data?.tuss_code) {
                        label = `${data?.tuss_code} - ${label}`;
                      }

                      return label;
                    },
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  name={"description"}
                  label="Descrição:"
                  multiline
                  minRows={4}
                />
              </Grid>
            </Grid>
            <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
              <Box sx={{ flex: "1 1 auto" }} />
              <Button
                sx={{ marginRight: 2 }}
                onClick={() => {
                  form.reset();
                }}
              >
                Limpar Dados
              </Button>
              <Button
                disabled={!permissions.create}
                type="submit"
                variant="contained"
              >
                Agendar
              </Button>
            </Box>
          </form>
        </FormProvider>
      </Box>
    </BoxContainer>
  );
}

export default Schedule;
