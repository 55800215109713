import { Grid } from "@mui/material";
import React, { useState } from "react";
import { Button, RadioField, SelectField, TextField } from "../../../components/FormFields";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import { useSignedDocuments } from "../../../service";
import { useMutation } from "react-query";
import { useNotifier } from "../../../hooks";
import { formDataObject } from "../../../helper";
import { kinships } from "../../../config/standardSelects";
import { mergePDF, openURL } from "../../../utils";
import { v4 } from "uuid";

const inititalValues = {
  requestor: "Paciente",
  third_party_name: null,
  kinship: null,
  reason_for_printing: null,
};

const validations = Yup.object().shape({
  requestor: Yup.string().nullable(),
  third_party_name: Yup.string()
    .nullable()
    .when("requestor", {
      is: "Terceiro",
      then: (schema) => schema.required("É requerido"),
    }),
  kinship: Yup.string()
    .nullable()
    .when("requestor", {
      is: "Terceiro",
      then: (schema) => schema.required("É requerido"),
    }),
  reason_for_printing: Yup.string().nullable().required("É requerido"),
});

export default function FullPrintForm({ documents, documentTypes, patientId, handleClose }) {
  const notify = useNotifier();
  const [loadingType, setLoadingType] = useState(null);

  const { postSignedDocument } = useSignedDocuments();
  const postSignedDocumentMutation = useMutation(postSignedDocument);

  const { control, handleSubmit, watch } = useForm({
    defaultValues: inititalValues,
    resolver: yupResolver(validations),
  });

  const requestor = watch("requestor");

  async function generateMergedDocument() {
    const documentPromises = [];

    for (const document of documents) {
      if (document.result) {
        documentPromises.push(document.result);
      } else {
        const documentBlob = documentTypes[document.type].handler(document, "merge");
        documentPromises.push(documentBlob);
      }
    }

    const documentsResult = await Promise.all(documentPromises);
    const mergedDocument = await mergePDF(documentsResult, ["url", "blob"]);
    return mergedDocument;
  }

  const onSubmit = handleSubmit(async ({ requestor, ...values }) => {
    try {
      setLoadingType("generating");

      const mergedDocument = await generateMergedDocument();

      setLoadingType("sending");

      const id = v4();
      const name = `${id}.pdf`;

      const data = formDataObject({
        ...values,
        type: "total_or_partial_print",
        requested_by: values.third_party_name ? "third_party" : "patient",
        patient_id: patientId,
        document_id: id,
        original_file_name: name,
        attachments: new File([mergedDocument.blob], name, {
          type: "application/pdf",
        }),
      });

      postSignedDocumentMutation.mutate(data, {
        onSuccess: (response) => {
          notify(response.message, "success");
          openURL(mergedDocument.url);
          handleClose();
        },
        onError: (err) => {
          notify(err.message, "error");
        },
        onSettled() {
          setLoadingType(null);
        },
      });
    } catch (error) {
      console.error(error);
      setLoadingType(null);
      notify("Erro ao gerar o documento", "error");
    }
  });

  return (
    <Grid component="form" container spacing={2} onSubmit={onSubmit}>
      <Grid item container xs={12} spacing={2}>
        <Grid item xs={3}>
          <RadioField
            name="requestor"
            label="Responsável pelo recebimento"
            control={control}
            optionLabelKey="label"
            optionValueKey="label"
            options={[{ label: "Paciente" }, { label: "Terceiro" }]}
          />
        </Grid>
        {requestor === "Terceiro" && (
          <>
            <Grid item xs={5}>
              <TextField name="third_party_name" label="Nome" control={control} />
            </Grid>
            <Grid item xs={4}>
              <SelectField
                name="kinship"
                label="Parentesco"
                control={control}
                options={kinships}
                optionLabelKey="describe"
                optionValueKey="describe"
                required
              />
            </Grid>
            s
          </>
        )}
      </Grid>
      <Grid item xs={12}>
        <TextField
          name="reason_for_printing"
          label="Motivo de impressão"
          control={control}
          multiline
          minRows={6}
        />
      </Grid>
      <Grid item xs={12} display="flex" justifyContent="flex-end">
        <Button
          type="submit"
          loading={!!loadingType}
          loadingMessage={loadingType === "generating" ? "Gerando documento" : "Enviando"}
        >
          Confirmar
        </Button>
      </Grid>
    </Grid>
  );
}
