// import { Box, Typography } from '@material-ui/core';
import { Box, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useState } from "react";
import { Helmet } from "react-helmet";
import { TITLE_NAME } from "../../config/config";
import { Form, Formik } from "formik";
import SubmitButton from "../../components/Form/SubmitButton";
import { FormTextField } from "../../components";
import Yup from "../../config/yup";
import { useQuery } from "react-query";
import { useSignedDocuments } from "../../service";
import { useNavigate } from "react-router-dom";
import { ConfirmDialog } from "../../helper";
import { useLocalStorage } from "../../hooks";

const HomeContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
  position: "absolute",
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

const FormContained = styled(Form)({
  width: "100%",
  display: "grid",
  gridTemplateColumns: "minmax(200px, 1fr) auto",
  gap: 4,
  marginTop: 10,
});

function Home() {
  const navigate = useNavigate();
  const [signatureRedirect, setSignatureRedirect] = useLocalStorage("signature-redirect", false);
  const [loading, setLoading] = useState(false);
  const [confirm, setConfirm] = useState(false);
  const { getSignedDocumentsStatistics } = useSignedDocuments();

  useQuery(
    ["pending-documents"],
    () =>
      getSignedDocumentsStatistics({
        status: "pending",
      }),
    {
      onSuccess(response) {
        if (response.length > 0 && !signatureRedirect) {
          setConfirm(true);
        }
      },
    }
  );

  const initialValues = {
    question: "",
  };

  const validator = Yup.object().shape({
    question: Yup.string(),
  });

  function handleSubmit(_, { resetForm }) {
    setLoading(true);
    resetForm();
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }

  return (
    <>
      <Helmet>
        <title>{TITLE_NAME} Início</title>
      </Helmet>
      <ConfirmDialog
        open={confirm}
        actions
        message="Você possui documentos pendentes de assinatura digital, deseja realizar a assinatura deles agora?"
        cancelLabel="Não"
        confirmLabel="Sim"
        handleClose={() => {
          setConfirm(false);
          setSignatureRedirect(true);
        }}
        handleConfirm={() => navigate("/pending-signature")}
      />
      <HomeContainer>
        <Box>
          <Typography variant="h4" fontWeight="bold" color="secondary">
            Olá!!
          </Typography>
          <Typography variant="h4" fontWeight="regular" color="secondary">
            Bem Vindo à Atenção Primária{" "}
            <Typography variant="h4" fontWeight="bold" color="secondary" component="span">
              - Tascom
            </Typography>
          </Typography>
          <Typography variant="p" color="primary">
            Em caso de dúvidas , nossa equipe está a disposição =D
          </Typography>
          <Formik
            initialValues={{ ...initialValues }}
            validationSchema={validator}
            onSubmit={handleSubmit}
          >
            {() => (
              <FormContained>
                <FormTextField
                  name="question"
                  sx={{ background: "white" }}
                  color="secondary"
                  placeholder="Digite sua dúvida.."
                />
                <SubmitButton loading={loading}>Enviar</SubmitButton>
              </FormContained>
            )}
          </Formik>
        </Box>
      </HomeContainer>
    </>
  );
}

export default Home;
