import { useCallback } from "react";
import instance from "../config/api";
import urlQuery from "../helper/urlQuery";

function useDocuments() {
  const externalPath = "/external-services/tdocs";
  const path = "internal-services/tdocs";

  const getExternalDocuments = useCallback(async (params) => {
    try {
      const response = await instance.get(`${externalPath}/documents`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  const getExternalDocumentById = useCallback(async (documentId, params) => {
    try {
      const response = await instance.get(`${externalPath}/documents/${documentId}`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  const getRecords = useCallback(async ({ patientId, documentId, params, recordId }) => {
    try {
      if (!patientId) {
        const response = await instance.get(`${externalPath}/documents-records/${recordId}`, {
          params: urlQuery(params),
        });
        return response.data;
      }

      const response = await instance.get(
        `${externalPath}/patients/${patientId}/documents/${documentId}`,
        {
          params: urlQuery(params),
        }
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  const postRecords = useCallback(async ({ patientId, data }) => {
    try {
      const response = await instance.post(
        `${externalPath}/patients/${patientId}/documents/fill`,
        data
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  const patchRecords = useCallback(async ({ patientId, documentRecordId, data }) => {
    try {
      const response = await instance.patch(
        `${externalPath}/patients/${patientId}/documents/records/${documentRecordId}/edit`,
        data
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  const getDocumentsTypes = useCallback(async (params) => {
    try {
      const response = await instance.get(`${path}/types`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  const postTypeDocuments = useCallback(async ({ typeId, data }) => {
    try {
      const response = await instance.post(`${path}/types/${typeId}/add/documents`, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  const deleteTypeDocuments = useCallback(async ({ typeId, data }) => {
    try {
      const response = await instance.post(`${path}/types/${typeId}/documents/batch-delete `, data);
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  const deleteDocumentRecord = useCallback(async ({ patientId, documentRecordId }) => {
    try {
      const response = await instance.delete(
        `${externalPath}/patients/${patientId}/documents/records/${documentRecordId}/delete`
      );
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  const getDocumentsByType = useCallback(async (typeId, params) => {
    try {
      const response = await instance.get(`${path}/documents/types/${typeId}`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  const getDocumentsByTypes = useCallback(async (params) => {
    try {
      const response = await instance.get(`${path}/documents/types`, {
        params: urlQuery(params),
      });
      return response.data;
    } catch (err) {
      throw new Error(err.response?.data.message);
    }
  }, []);

  return {
    getExternalDocuments,
    getExternalDocumentById,
    getRecords,
    postRecords,
    patchRecords,
    getDocumentsTypes,
    postTypeDocuments,
    deleteTypeDocuments,
    deleteDocumentRecord,
    getDocumentsByType,
    getDocumentsByTypes,
  };
}
export default useDocuments;
