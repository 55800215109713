import { Box, Typography, useTheme } from "@mui/material";
import LeftFoot from "./LeftFoot";
import RightFoot from "./RightFoot";
import { useMemo, useState } from "react";
import HandleRegionValues from "./HandleRegionValues";
import useFootProblems from "../../../../service/useFootProblems";
import { useQuery } from "react-query";
import { accessObjectByString } from "../../../../utils";
import { footRegions } from "../../../../config/standardSelects";
import { useWatch } from "react-hook-form";
import {
  ChangeHistoryRounded,
  AddRounded,
  CloseRounded,
  RemoveRounded,
  CropSquareRounded,
} from "@mui/icons-material";

const legendIcons = {
  present_sensitivity: <AddRounded />,
  absent_sensitivity: <RemoveRounded htmlColor="#EAB308" />,
  callosity: <CloseRounded htmlColor="#EAB308" />,
  ulcer: <CropSquareRounded htmlColor="#DC2626" />,
  pre_ulcer: <ChangeHistoryRounded htmlColor="#DC2626" />,
};

export default function Feet({ control, disabled }) {
  const theme = useTheme();
  const [regionRef, setRegionRef] = useState(null);

  const { getProblems } = useFootProblems();
  const { data: problems } = useQuery(["foot-problems"], () => getProblems(), {
    initialData: [],
  });

  const [rightFoot, leftFoot] = useWatch({
    control,
    name: ["right_foot", "left_foot"],
  });

  function regionProblems(footValues) {
    if (!footValues) return;
    let problemsAdded = {};
    footRegions.forEach(({ path, subPath }) => {
      const value = accessObjectByString(
        footValues,
        subPath ? `${path}.${subPath}` : path
      );
      if (value) {
        if (!problemsAdded[path]) {
          problemsAdded[path] = {};
        }
        if (subPath && !problemsAdded[path][subPath]) {
          problemsAdded[path][subPath] = {};
        }
        value.problems?.forEach(({ name }) => {
          if (subPath) {
            problemsAdded[path][subPath][name] = true;
          } else {
            problemsAdded[path][name] = true;
          }
        });
      }
    });
    return problemsAdded;
  }

  const leftFootRegionProblems = useMemo(
    () => regionProblems(leftFoot),
    [leftFoot]
  );
  const rightFootRegionProblems = useMemo(
    () => regionProblems(rightFoot),
    [rightFoot]
  );

  return (
    <Box
      display={"grid"}
      gridTemplateColumns={"1fr 1fr"}
      columnGap={10}
      rowGap={6}
    >
      <HandleRegionValues
        anchorEl={regionRef?.current}
        handleClose={() => setRegionRef(null)}
        open={!!regionRef}
        foot={regionRef?.foot}
        region={regionRef?.region}
        subRegion={regionRef?.sub_region}
        problems={problems}
        control={control}
        disabled={disabled}
      />
      <Box
        sx={{
          gridColumn: "1/-1",
          display: "flex",
          gap: "12px",
          alignItems: "center",
          marginTop: "32px",
        }}
      >
        {problems.map((item) => (
          <Box
            key={item.name}
            sx={{
              display: "flex",
              gap: "4px",
              alignItems: "center",
            }}
          >
            {legendIcons[item.name]}
            <Typography
              sx={{
                fontSize: "14px",
                fontWeight: 500,
                color: theme.palette.primary.light,
              }}
            >
              {item.describe}
            </Typography>
          </Box>
        ))}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "right",
        }}
      >
        <RightFoot
          onRegionClick={setRegionRef}
          footValues={rightFootRegionProblems}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "left",
        }}
      >
        <LeftFoot
          onRegionClick={setRegionRef}
          footValues={leftFootRegionProblems}
        />
      </Box>
    </Box>
  );
}
