import * as Yup from "yup";
import {
  validateCEP,
  validateCNPJ,
  validateCPF,
  validateCNS12,
  validateEmail,
  validatePhone,
  validateSextantValue,
  validateTimeSchema,
} from "./validations";
import validationMessages from "./validationMessages";

// Messages
const { required } = validationMessages;

Yup.setLocale({
  string: {
    required,
  },
  number: {
    required,
  },
  array: {
    required,
  },
  object: {
    required,
  },
  mixed: {
    required,
  },
});

// Validations

Yup.addMethod(Yup.string, "cpfValid", function () {
  return this.test("cpf_valid", "CPF inválido.", (value, context) => {
    if (!value)
      return context.createError({
        path: context.path,
        message: required,
      });
    if (validateCPF(value)) return true;
    return false;
  });
});

Yup.addMethod(Yup.string, "cpfOrCnpjValidate", function () {
  return this.test("cpfOrCnpjValidate", function (value) {
    const { path, createError } = this;
    if (!value) return true;
    if (validateCPF(value) || validateCNPJ(value)) return true;
    else return createError({ path, message: "CPf/CNPJ Inválido!" });
  });
});

Yup.addMethod(Yup.string, "cnsValidate", function () {
  return this.test("cnsValidate", "CNS inválido", function (value) {
    if (value && validateCNS12(value)) return true;
    else return false;
  });
});

Yup.addMethod(Yup.string, "validateTimeSchema", function () {
  return this.test("validateTimeSchema", function (value) {
    const { path, createError } = this;

    if (!value) {
      return createError({ path, message: "É requerido" });
    } else if (validateTimeSchema(value)) {
      return true;
    } else {
      return createError({
        path,
        message: "Formato inválido. utilize: 0y 0m 0w 0d",
      });
    }
  });
});

Yup.addMethod(Yup.string, "cepValid", function () {
  return this.test("cep_valid", "CEP inválido.", (value, context) => {
    if (!value)
      return context.createError({
        path: context.path,
        message: required,
      });
    if (validateCEP(value)) return true;
    return false;
  });
});

Yup.addMethod(Yup.string, "emailValid", function () {
  return this.test("email_valid", "Email inválido.", (value, context) => {
    if (!value)
      return context.createError({
        path: context.path,
        message: required,
      });
    if (validateEmail(value)) return true;
    return false;
  });
});

Yup.addMethod(Yup.string, "phoneValid", function () {
  return this.test("phone_valid", "Telefone inválido.", (value, context) => {
    if (!value)
      return context.createError({
        path: context.path,
        message: required,
      });
    if (validatePhone(value)) return true;
    return false;
  });
});

Yup.addMethod(Yup.object, "validateSextant", function (notify, setPlanStepColor) {
  return this.test("validateSextant", function (s) {
    const { path, createError } = this;
    if (!s?.rps) {
      setPlanStepColor((curr) => ({
        ...curr,
        [path]: false,
      }));
      return true;
    } else if (validateSextantValue(s)) {
      setPlanStepColor((curr) => ({
        ...curr,
        [path]: false,
      }));
      return true;
    } else {
      setPlanStepColor((curr) => ({
        ...curr,
        [path]: true,
      }));
      notify("Formato inválido. verifique aba de sextantes.", "error");
      return createError({
        path: path + ".rps",
        message: "Formato inválido. utilize: 1, 2, 3, 4, * ou x",
      });
    }
  });
});

export default Yup;
