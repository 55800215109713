import BedRounded from "@mui/icons-material/BedRounded";
import HeartBrokenRounded from "@mui/icons-material/HeartBrokenRounded";
import SickRounded from "@mui/icons-material/SickRounded";
import OpacityRounded from "@mui/icons-material/OpacityRounded";
import SmokingRoomsRounded from "@mui/icons-material/SmokingRoomsRounded";
import PregnantWomanRounded from "@mui/icons-material/PregnantWomanRounded";
import FavoriteRounded from "@mui/icons-material/FavoriteRounded";
import HealthAndSafetyRounded from "@mui/icons-material/HealthAndSafetyRounded";
import PsychologyRounded from "@mui/icons-material/PsychologyRounded";
import LiquorRounded from "@mui/icons-material/LiquorRounded";
import MedicationRounded from "@mui/icons-material/MedicationRounded";
import {
  AirlineSeatFlatAngledRounded,
  AssignmentIndRounded,
  AssignmentLateRounded,
  ChildCareRounded,
  HealingRounded,
  HourglassBottomRounded,
  NoMeetingRoomRounded,
} from "@mui/icons-material";

const healthConditionsStatusColors = [
  {
    text: "Gestante",
    icon: <PregnantWomanRounded sx={{ color: "#ffffff" }} />,
    key: "pregnant",
    color: "#FBB6CE",
  },
  {
    text: "Menor que 1 ano",
    icon: <ChildCareRounded sx={{ color: "#ffffff" }} />,
    key: "less_than_1_year",
    color: "#869ef7",
  },
  {
    text: "Tabagista",
    icon: <SmokingRoomsRounded sx={{ color: "#ffffff" }} />,
    key: "is_smoker",
    color: "#718096",
  },

  // Cardiovascular Conditions
  {
    text: "Hipertensão",
    icon: <FavoriteRounded sx={{ color: "#ffffff" }} />,
    key: "has_hypertension",
    color: "#F56565",
  },
  // Respiratory Conditions
  {
    text: "Com Tuberculose",
    icon: <AirlineSeatFlatAngledRounded sx={{ color: "#ffffff" }} />,
    key: "has_tuberculosis",
    color: "#ECC94B",
  },
  {
    text: "Infarto",
    icon: <HealthAndSafetyRounded sx={{ color: "#ffffff" }} />,
    key: "had_heart_attack",
    color: "#E53E3E",
  },

  // Chronic Conditions
  {
    text: "Diabetes",
    icon: <OpacityRounded sx={{ color: "#ffffff" }} />,
    key: "has_diabetes",
    color: "#F6AD55",
  },
  {
    text: "AVC / Derrame",
    icon: <HeartBrokenRounded sx={{ color: "#ffffff" }} />,
    key: "had_stroke",
    color: "#F56565",
  },
  {
    text: "Câncer",
    icon: <HealingRounded sx={{ color: "#ffffff" }} />,
    key: "has_cancer",
    color: "#718096",
  },

  // Infectious Diseases
  {
    text: "Com Hanseníase",
    icon: <SickRounded sx={{ color: "#ffffff" }} />,
    key: "has_leprosy",
    color: "#4FD1C5",
  },

  // Substance Use
  {
    text: "Uso de Álcool",
    icon: <LiquorRounded sx={{ color: "#ffffff" }} />,
    key: "is_alcohol_user",
    color: "#63B3ED",
  },
  {
    text: "Uso de Drogas",
    icon: <MedicationRounded sx={{ color: "#ffffff" }} />,
    key: "is_drug_user",
    color: "#63B3ED",
  },

  // Mental and Emotional Health
  {
    text: "Problema de Saúde Mental",
    icon: <PsychologyRounded sx={{ color: "#ffffff" }} />,
    key: "mental_problem_by_healthcare_professional",
    color: "#B794F4",
  },

  // Other Conditions
  {
    text: "Acamado",
    icon: <BedRounded sx={{ color: "#ffffff" }} />,
    key: "is_bedridden",
    color: "#9F7AEA",
  },
];

const visitConclusionStatusColors = [
  {
    text: "Sem Visita",
    key: "no_visit",
    icon: <HourglassBottomRounded sx={{ color: "#ffffff" }} />,
    color: "rgba(0, 0, 0, 0.6)",
    backgroundColor: "#F7F7F7",
  },
  {
    text: "Visita Realizada (VREA)",
    key: "completed",
    icon: <AssignmentIndRounded sx={{ color: "#ffffff" }} />,
    color: "rgba(0, 150, 0, 0.5)",
    backgroundColor: "#d9fadc",
  },
  {
    text: "Visita Recusada (VREC) ",
    key: "refused",
    icon: <NoMeetingRoomRounded sx={{ color: "#ffffff" }} />,

    color: "rgba(216, 0, 0, 0.5)",
    backgroundColor: "rgba(216, 0, 0, 0.1)",
  },
  {
    text: "Visita Ausente (VAU)",
    key: "absentee",
    icon: <AssignmentLateRounded sx={{ color: "#ffffff" }} />,
    color: "rgba(255, 200, 65, 0.9)",
    backgroundColor: "rgba(255, 200, 65, 0.2)",
  },
];

export { visitConclusionStatusColors, healthConditionsStatusColors };
