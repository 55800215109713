import React, { useEffect, useRef } from "react";
import { Box, Button, Grid } from "@mui/material";
import { format, isToday, subDays } from "date-fns";
import _ from "lodash";
import { useState } from "react";
import { useQuery } from "react-query";
import PaginatedArrayList from "../../../components/PaginatedArrayList";
import useNotifier from "../../../hooks/useNotifier";
import AttendanceConfirmCard from "../../../components/PatientsCard/AttendanceConfirmCard";

import {
  useDateAPI,
  usePatient,
  useProvider,
  useServices,
} from "../../../service";
import convertDateTobirthday from "../../../utils/convertDateToBirthday";
import usePermissions from "../../../config/usePermissions";
import { FormProvider, useForm } from "react-hook-form";
import {
  RadioField,
  PaginatedAutocompleteField,
  DateField,
  AutocompleteField,
} from "../../../components/FormFields";
import { searchByPatientIdentifiers } from "../../../utils";

function FirstStep({ handleFinish }) {
  const form = useForm({
    defaultValues: {
      filter: "",
      day: null,
      id_employee: null,
      employee: null,
      id_sector: null,
      patient: null,
      service: null,
      justification_note: null,
    },
  });
  const [
    patientField,
    dayField,
    id_employeeField,
    employeeField,
    serviceField,
    justification_noteField,
    id_sectorField,
  ] = form.watch([
    "patient",
    "day",
    "id_employee",
    "employee",
    "service",
    "justification_note",
    "id_sector",
  ]);
  const notify = useNotifier();
  const today = new Date();
  const [typeFilter, setTypeFilter] = useState("");
  const [filters, setFilters] = useState({
    initial_date: today.toDateString(),
    final_date: new Date(
      today.getFullYear(),
      today.getMonth() + 1,
      0
    ).toDateString(),
    filter: typeFilter,
  });
  const [dates, setDates] = useState([]);
  const getDates = useRef(true);
  const permission = usePermissions("/attendance/confirm");

  const daysAgo =
    today.getDate() > 2 && today.getDate() < 8
      ? subDays(new Date(), today.getDate() - 1)
      : subDays(new Date(), 7);

  const types = [
    {
      label: "Profissional",
      value: "employee",
      checked: typeFilter === "employee",
    },
    { label: "Setor", value: "sector", checked: typeFilter === "sector" },
    {
      label: "Não agendados",
      value: "noAppointment",
      checked: typeFilter === "noAppointment",
    },
  ];

  const { getDateSchedulings } = useDateAPI();

  const scalesQuery = useQuery(
    ["scalesQuery", filters],
    () => {
      return filters.filter !== "noAppointment"
        ? getDateSchedulings({ ...filters, limit: 31, page: 0 })
        : [];
    },
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!(filters?.filter && filters?.filter !== "noAppointment"),
      onSuccess: (response) => {
        if (getDates.current) {
          getDates.current = false;
          const dates = response.items?.map(({ date }) => date) || [];
          setDates(dates);
        }
      },
      onError: (error) => {
        setDates([]);
        notify(error.message, "error");
      },
    }
  );

  useEffect(() => {
    if (typeFilter === "noAppointment") {
    }
  }, [typeFilter]);

  const { getPatients } = usePatient();
  const { getProviders } = useProvider();
  const { getServices } = useServices();

  function setDateRange(initial_date, final_date) {
    setFilters({ ...filters, initial_date, final_date });
  }

  function handleTypeFilter(value) {
    getDates.current = true;
    setFilters({
      initial_date: today.toDateString(),
      final_date: new Date(
        today.getFullYear(),
        today.getMonth() + 1,
        0
      ).toDateString(),
      filter: value,
    });
    setTypeFilter(value);
  }

  function handleFilters(value) {
    setFilters({ ...filters, ...value });
  }

  function patientList(value) {
    const fill = scalesQuery.data?.items?.filter((scale) => {
      if (filters?.filter === "employee")
        return scale?.employee?.id === value?.employee?.id;
      return scale?.sector?.id === value?.sector?.id;
    });

    return fill
      ?.map(({ schedulings, employee, sector }) =>
        schedulings.map((scheduling) => (
          <AttendanceConfirmCard
            age={convertDateTobirthday(scheduling.patient?.birth_date)}
            time={scheduling.hour}
            gender={scheduling.patient?.gender}
            id={scheduling.patient?.number}
            patient={scheduling.patient?.name}
            handleClick={() => {
              if (scheduling.date !== format(new Date(), "yyyy-MM-dd")) {
                notify(
                  "Não é possível confirmar atendimentos futuros.",
                  "error"
                );
                return;
              }
              if (!permission.create) {
                notify(
                  "Você não tem permissão para confirmar atendimentos.",
                  "success"
                );
                return;
              }
              handleFinish({
                ...scheduling,
                employee,
                sector,
                attendanceType: "Ambulatorial",
              });
            }}
          />
        ))
      )
      .flat();
  }

  const justifications = [
    {
      value: "Falta de energia elétrica",
    },
    {
      value: "Sistema Indisponível",
    },
    {
      value: "Computador inoperante",
    },
    {
      value: "Outros",
    },
  ];

  return (
    <Box>
      <FormProvider {...form}>
        <form>
          <Grid container justifyContent="center">
            <Grid item xs={4}>
              <Grid
                direction="column"
                container
                justifyContent="start"
                alignItems="center"
                sx={{
                  "& .MuiFormLabel-root": {
                    color: "#6A57EE",
                  },
                }}
              >
                <RadioField
                  name="filter"
                  options={types}
                  label="Pesquisar por..."
                  customOnChange={(value) => {
                    handleTypeFilter(value);
                    form.reset({
                      filter: value,
                      day: null,
                      id_employee: null,
                      employee: null,
                      id_sector: null,
                      patient: null,
                      service: null,
                      justification_note: null,
                    });
                  }}
                  optionValueKey="value"
                />
                <DateField
                  name="day"
                  type="static-date"
                  minDate={filters.filter === "noAppointment" ? daysAgo : null}
                  maxDate={
                    filters.filter === "noAppointment" ? new Date() : null
                  }
                  required={true}
                  onMonthChange={(value) => {
                    setDateRange(
                      value,
                      new Date(value.getFullYear(), value.getMonth() + 1, 0)
                    );
                    form.reset();
                  }}
                  customOnChange={(initial_date) => {
                    handleFilters({
                      initial_date,
                      final_date: initial_date,
                    });
                    if (isToday(new Date(initial_date))) {
                      form.setValue("justification_note", "");
                    }
                  }}
                  shouldDisableDate={(date) => {
                    return filters?.filter !== "noAppointment"
                      ? !dates.some(
                          (dateItem) => dateItem === format(date, "yyyy-MM-dd")
                        )
                      : false;
                  }}
                />
              </Grid>
            </Grid>
            {filters?.filter !== "noAppointment" && (
              <Grid item xs={4}>
                {dayField && (
                  <AutocompleteField
                    options={
                      _.uniqBy(scalesQuery.data?.items, function (e) {
                        if (filters?.filter === "employee")
                          return e?.employee?.name;
                        return e?.sector?.describe;
                      }) || []
                    }
                    optionLabelKey={
                      filters?.filter === "employee"
                        ? "employee.name"
                        : "sector.describe"
                    }
                    optionCompareKey={"id"}
                    customOnChange={(scale) =>
                      handleFilters({
                        id_employee: scale?.employee?.id,
                        id_sector: scale?.sector?.id,
                      })
                    }
                    name={
                      filters?.filter === "employee"
                        ? "id_employee"
                        : "id_sector"
                    }
                    label={
                      filters?.filter === "employee"
                        ? "Profissional: "
                        : "Setor :"
                    }
                  />
                )}

                {(id_employeeField || id_sectorField) && (
                  <PaginatedArrayList
                    title={"Lista de Pacientes"}
                    data={patientList(id_employeeField || id_sectorField)}
                    limit={3}
                  />
                )}
              </Grid>
            )}
            {filters?.filter === "noAppointment" && (
              <Grid
                item
                xs={4}
                c
                sx={{
                  display: "flex",
                  gap: 4,
                  flexDirection: "column",
                  marginTop: 5,
                }}
              >
                <PaginatedAutocompleteField
                  service={({ name, ...params }) => {
                    searchByPatientIdentifiers(name, params, [
                      "physic_national",
                      "sus_card",
                      "name",
                    ]);

                    return getPatients(params);
                  }}
                  queryKey={"patientsQuery"}
                  filterKey={"name"}
                  optionLabelKey={"name"}
                  name={"patient"}
                  label="Buscar por: CPF ou CNS ou nome do paciente.."
                />
                {patientField && (
                  <PaginatedAutocompleteField
                    name={"service"}
                    label={"Serviço"}
                    queryKey={"servicesQuery"}
                    service={(params) =>
                      getServices({
                        ...params,
                        ama_type: "unscheduled",
                        filter: "active",
                      })
                    }
                    filterKey={"describe"}
                    optionLabelKey={"describe"}
                  />
                )}
                {serviceField && patientField && (
                  <PaginatedAutocompleteField
                    name={"employee"}
                    label={"Profissional"}
                    queryKey={"employeeByService"}
                    service={async (params) =>
                      getProviders({
                        ...params,
                        id_service: serviceField?.id,
                        active: true,
                      })
                    }
                    refetchService={[serviceField?.id]}
                    filterKey={"name"}
                    optionLabelKey={"name"}
                  />
                )}
                {employeeField &&
                  serviceField &&
                  patientField &&
                  dayField &&
                  typeFilter === "noAppointment" &&
                  !isToday(new Date(dayField)) && (
                    <AutocompleteField
                      name="justification_note"
                      label="Justificativa"
                      optionLabelKey="value"
                      options={justifications}
                    />
                  )}
              </Grid>
            )}
          </Grid>
          <Grid
            item
            xs={12}
            sx={{ display: "flex", justifyContent: "end", marginTop: -2 }}
          >
            <Button
              variant="contained"
              onClick={() => {
                if (!patientField.physic_national && !patientField.sus_card) {
                  return notify(
                    "O paciente selecionado não possui CPF ou CNS, por favor atualize o seu cadastro.",
                    "info"
                  );
                }

                handleFinish({
                  attendanceType: "Demanda Espontanea",
                  employee: employeeField,
                  patient: patientField,
                  service: serviceField,
                  justification_note: justification_noteField.value,
                  date:
                    dayField && !isToday(new Date(dayField))
                      ? dayField
                      : undefined,
                });
              }}
              disabled={
                !permission.create ||
                !(employeeField && serviceField && patientField && dayField) ||
                (!justification_noteField && !isToday(new Date(dayField)))
              }
            >
              Continuar
            </Button>
          </Grid>
        </form>
      </FormProvider>
    </Box>
  );
}

export default FirstStep;
