import { header, footer, title, patientInformations } from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";

function examPrescriptionPDF(data, patient, documentData) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const medications = {
    margin: [0, 40, 0, 20],
    ol: data?.map(({ type, describe, notes }) => ({
      text: [
        { text: describe, bold: true },
        { text: ` (${type}) \n` },
        { text: notes ? notes.toUpperCase() : "" },
      ],
      margin: [0, 0, 0, 10],
    })),
  };

  const definitions = {
    info: { title: pdfName },
    pageMargins: [40, 105, 40, 120],
    defaultStyle: {
      fontSize: 10,
    },
    ...header(documentData?.company),
    footer: footer(documentData?.createdAt, true, documentData?.employee, documentData?.company),
    content: [title("Prescrição de Exames"), patientInformations(patient), medications],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;

  return documentPDF;
}

export default examPrescriptionPDF;
