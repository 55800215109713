import React, { useState } from "react";
import { Tabs } from "../../../components";
import { TablePagination, Box } from "@mui/material";
import { useQuery } from "react-query";
import { usePatient } from "../../../service";
import { format, parseISO } from "date-fns";
import {
  followUpStatementPDF,
  medicalStatementPDF,
  attendanceStatementPDF,
  medicalReportPDF,
  medicalOpinionPDF,
  maternityLeavePDF,
} from "../../../pdfModels";
import useNotifier from "../../../hooks/useNotifier";
import TableFilter from "../../../components/Table/TableFilter";
import { openURL } from "../../../utils";

function HistoryDeclaration({ attendance }) {
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [type, setType] = useState("Atestado");
  const { getDeclarations } = usePatient();

  const declarationsPrinters = {
    Atestado: medicalStatementPDF,
    Acompanhamento: followUpStatementPDF,
    Comparecimento: attendanceStatementPDF,
    Relatorio: medicalReportPDF,
    Laudo: medicalOpinionPDF,
    AtestadoMaternidade: maternityLeavePDF,
  };

  const declarationsQuery = useQuery(
    ["declarations", [page, limit, type]],
    () =>
      getDeclarations(attendance.patient.id, {
        page,
        limit,
        type,
      }),
    {
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      initialData: { items: [] },
      onError: (error) => {
        notify(error.message, "error");
      },
    }
  );

  function printDeclaration(_, declaration) {
    const signature = declaration.signed_document;

    if (signature.result) {
      openURL(signature.result);
    } else {
      const pdf = declarationsPrinters[type](declaration, attendance.patient);
      pdf.open();
    }
  }

  const tableColumns = [
    {
      name: "Criado em",
      field: "createdAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
    {
      name: "Profissional responsável",
      field: "soap?.employee_specialty.employee.name",
    },
  ];

  const tabs = [
    {
      label: "Atestado",
    },
    {
      label: "Acompanhamento",
    },
    {
      label: "Comparecimento",
    },
    {
      label: "Relatório",
      value: "Relatorio",
    },
    {
      label: "Laudo",
    },
    {
      label: "Atestado Maternidade",
      value: "AtestadoMaternidade",
    },
  ];

  return (
    <>
      <Tabs
        tabs={tabs}
        handleClick={(type) => {
          setType(type.value ?? type.label);
          setPage(0);
        }}
        sx={{ marginBottom: 1 }}
      />
      <TableFilter
        data={declarationsQuery.data?.items ?? []}
        columns={tableColumns}
        loading={declarationsQuery.isRefetching}
        actions
        actionsTypes={["print"]}
        confirmMessage="Você realmente deseja *action* este documento?"
        actionHandlePrint={printDeclaration}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={declarationsQuery.data?.totalItems ?? 0}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>
    </>
  );
}

export default HistoryDeclaration;
