import { Box, Typography } from "@mui/material";
import React from "react";

export default function SectionHeader({ icon, title, sx }) {
  return (
    <Box
      sx={{ display: "flex", alignItems: "center", gap: 1, px: 3, py: 0.5, borderRadius: 2, ...sx }}
    >
      {icon}
      <Typography
        variant="subtitle1"
        fontWeight={600}
        sx={{
          width: "100%",
        }}
      >
        {title}
      </Typography>
    </Box>
  );
}
