import React, { useState } from "react";
import { Grid } from "@mui/material";
import { Form, Formik } from "formik";
import { useMutation } from "react-query";
import { FormPassword, SubmitButton } from "../components";
import instance from "../config/api";
import Yup from "../config/yup";
import { useAuth } from "../service";
import Cookies from "js-cookie";
import { useQuery } from "react-query";
import { DialogMedium } from ".";
import useNotifier from "../hooks/useNotifier";

const SignaturePassword = ({ open, handleClose }) => {
  const notify = useNotifier();
  const [validToken, setValidToken] = useState(false);
  const { authSignature, patchSignatureToken, signatureTokenIsValid } = useAuth();

  const authSignatureMutation = useMutation(authSignature);
  const patchSignatureTokenMutation = useMutation(patchSignatureToken);

  useQuery(["validate-signer-token", open], signatureTokenIsValid, {
    refetchOnWindowFocus: false,
    enabled: open,
    retry: false,
    onSuccess(response) {
      setValidToken(response.valid);
    },
    onError(error) {
      setValidToken(false);
      notify(error.error ?? error.message, "error");
    },
  });

  function handleSubmit(values) {
    authSignatureMutation.mutate(values, {
      onSuccess(response) {
        Cookies.set(
          "signer-auth-token",
          response.access_token /**{
          sameSite: "none",
          secure: true,
        }**/,
        );
        localStorage.removeItem("delay-sign-until");
        notify("Assinatura autenticada com sucesso.", "success");
        handleClose();
      },
      onError(error) {
        notify(error.message, "error");
      },
    });
  }

  function handleCancel() {
    delete instance.defaults.headers["signer-auth-token"];
    Cookies.set(
      "signer-auth-token",
      null /**{
      sameSite: "none",
      secure: true,
    }**/,
    );
    localStorage.setItem("delay-sign-until", new Date().setHours(new Date().getHours() + 12));
  }

  function handleLogout() {
    patchSignatureTokenMutation.mutate(
      { expired: true },
      {
        onSuccess(response) {
          handleCancel();
          setValidToken(false);
          notify(response.message);
        },
        onError(error) {
          notify(error.message, "error");
        },
      },
    );
  }

  const initialValues = {
    password: "",
  };

  const validations = Yup.object().shape({
    password: Yup.string().required("É requerido"),
  });

  return (
    <>
      <DialogMedium
        title={
          validToken
            ? "Autenticado com o serviço de assinatura digital"
            : "Confirmar senha da assinatura digital"
        }
        open={open}
        fullWidth={true}
        maxWidth="sm"
        handleClose={handleClose}
      >
        <Formik
          onSubmit={handleSubmit}
          initialValues={initialValues}
          validationSchema={validations}
        >
          {() => (
            <Form>
              <Grid container spacing={2}>
                {!validToken ? (
                  <>
                    <Grid item xs={12} mb={2}>
                      <FormPassword name="password" sx={{ background: "white" }} />
                    </Grid>
                    <Grid item xs={4}>
                      <SubmitButton loading={authSignatureMutation.isLoading}>
                        Confirmar
                      </SubmitButton>
                    </Grid>
                    <Grid item xs={4}>
                      <SubmitButton
                        onClick={() => {
                          handleCancel();
                          handleClose();
                        }}
                        color="error"
                      >
                        Cancelar
                      </SubmitButton>
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={4}>
                    <SubmitButton onClick={handleLogout} color="error">
                      Sair do serviço
                    </SubmitButton>
                  </Grid>
                )}
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogMedium>
    </>
  );
};

export default SignaturePassword;
