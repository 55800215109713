import React, { useState } from "react";
import TableFilter from "../../../../components/Table/TableFilter";
import { Dialog, DialogMedium, Loading } from "../../../../helper";
import EvolutionForm from "./EvolutionForm";
import EvolutionFeedback from "./EvolutionFeedback";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { Box } from "@mui/system";
import { Button, Grid, TablePagination } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useMedicalRecords } from "../../../../service";
import { Form, Formik } from "formik";
import { FormTextField, SubmitButton } from "../../../../components";
import Yup from "../../../../config/yup";
import { evolutionsPDF } from "../../../../pdfModels";
import useNotifier from "../../../../hooks/useNotifier";
import { openURL } from "../../../../utils";

function EvolutionList({
  title,
  formType,
  patient,
  prescription,
  attendance,
  needProcedure,
  activeActions,
  intervention,
}) {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [registerEvolution, setRegisterEvolution] = useState(false);
  const [evolutionToEdit, setEvolutionToEdit] = useState();
  const [displayEvolution, setDisplayEvolution] = useState(false);
  const [suspendEvolution, setSuspendEvolution] = useState(false);
  const [evolution, setEvolution] = useState();

  const notify = useNotifier();

  const { getMedicalRecords, patchMedicalRecords, deleteMedicalRecords } = useMedicalRecords();

  const patchMedicalRecordsMutation = useMutation(patchMedicalRecords);
  const deleteMedicalRecordsMutation = useMutation(deleteMedicalRecords);

  function handleSuspend(values) {
    patchMedicalRecordsMutation.mutate(
      { id: evolution.id, data: values },
      {
        onSuccess(response) {
          notify(response.message, "success");
          setSuspendEvolution(false);
          medicalRecordsQuery.refetch();
        },
        onError(error) {
          notify(error.message, "error");
        },
      }
    );
  }

  function handleDelete(id) {
    deleteMedicalRecordsMutation.mutate(id, {
      onSuccess(response) {
        notify(response.message, "success");
        setSuspendEvolution(false);
        medicalRecordsQuery.refetch();
      },
      onError(error) {
        notify(error.message, "error");
      },
    });
  }

  const queryClient = useQueryClient();

  const medicalRecordsQuery = useQuery(
    ["medical-records", page, limit],
    () =>
      getMedicalRecords(attendance?.id, {
        page,
        limit,
        type: formType,
        prescription_id: prescription?.id,
      }),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      initialData: { totalItems: 0, items: [] },
      onError: () => {
        queryClient.setQueryData(["medical-records", page, limit], {
          totalItems: 0,
          items: [],
        });
      },
    }
  );

  function handlePrint(index) {
    const evolution = medicalRecordsQuery.data.items[index];
    if (evolution.signed_document) {
      openURL(evolution.signed_document.result);
    } else {
      const pdf = evolutionsPDF(evolution, patient, {
        title,
        createdAt: evolution.createdAt,
        employee: {
          ...evolution.employee_specialty.employee,
          specialty: evolution.employee_specialty.specialty,
        },
        company: evolution.attendance.company,
      });

      pdf.open();
    }
  }

  const tableColumns = [
    {
      name: "Profissional",
      field: "employee_specialty?.employee.name",
      condition: "row.employee_specialty",
      otherwise: (row) => row.created_by?.name,
      type: "text",
    },
    {
      name: "Status",
      field: "canceledAt",
      condition: "row.canceledAt",
      type: "text",
      use: () => "Suspenso",
      otherwise: (row) => (row.status === "filling" ? "Preenchendo" : "Finalizado"),
    },
    {
      name: "Hora da evolução",
      field: "createdAt",
      type: "date",
      use: (date) => format(parseISO(date), "HH:mm"),
    },
  ];

  const validations = Yup.object().shape({
    cancelation_notes: Yup.string().nullable().required("A justificativa é obrigatória"),
  });

  if (medicalRecordsQuery.isLoading) return <Loading />;

  function handleCloseForm() {
    setRegisterEvolution(false);
    setEvolutionToEdit();
  }
  return (
    <>
      <DialogMedium
        open={registerEvolution}
        title={`Cadastro de ${title}`}
        handleClose={handleCloseForm}
        maxWidth="lg"
        fullWidth
      >
        <EvolutionForm
          title={title}
          patient={patient}
          formType={formType}
          prescription={prescription}
          attendance={attendance}
          valuesToEdit={evolutionToEdit}
          handleClose={handleCloseForm}
          needProcedure={needProcedure}
          intervention={intervention}
        />
      </DialogMedium>
      <Dialog
        open={displayEvolution}
        title={`Visualização de ${title}`}
        handleClose={() => setDisplayEvolution(false)}
      >
        <EvolutionFeedback data={evolution} patient={patient} />
      </Dialog>
      <Dialog
        open={suspendEvolution}
        title={`Suspensão de ${title}`}
        maxWidth="md"
        fullWidth={true}
        fullScreen={false}
        handleClose={() => setSuspendEvolution(false)}
      >
        <Formik
          onSubmit={handleSuspend}
          initialValues={{ cancelation_notes: "" }}
          validationSchema={validations}
        >
          {() => (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FormTextField
                    name="cancelation_notes"
                    label="Justificativa de suspensão"
                    required
                    multiline
                    minRows={8}
                  />
                </Grid>
                <Grid item xs={2}>
                  <SubmitButton>Suspender</SubmitButton>
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </Dialog>
      <TableFilter
        data={medicalRecordsQuery.data.items}
        columns={tableColumns}
        loading={false}
        emptyMessage={medicalRecordsQuery.error?.message}
        confirmMessage="Você realmente deseja *action* esta evolução?"
        actions
        disableActions={(row, action) =>
          (!!row.canceledAt && ["suspend", "print", "edit"].includes(action)) ||
          (row.status !== "filling" && ["edit", "delete"].includes(action)) ||
          (row.status !== "finished" && ["suspend", "print"].includes(action))
        }
        actionsTypes={activeActions ? ["view", "print", "edit", "delete", "suspend"] : ["view"]}
        actionHandleView={(index) => {
          const evolution = medicalRecordsQuery.data.items[index];
          setEvolution(evolution);
          setDisplayEvolution(true);
        }}
        actionHandleSuspend={(index) => {
          const evolution = medicalRecordsQuery.data.items[index];
          setEvolution(evolution);
          setSuspendEvolution(true);
        }}
        actionHandleEdit={(_, data) => {
          setEvolutionToEdit(data);
          setRegisterEvolution(true);
        }}
        actionHandlePrint={handlePrint}
        actionHandleDelete={(index) => {
          const evolution = medicalRecordsQuery.data.items[index];
          handleDelete(evolution.id);
        }}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={medicalRecordsQuery.data.totalItems}
          component="div"
          page={page}
          onPageChange={(_, newPage) => {
            setPage(newPage);
          }}
          rowsPerPage={limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={({ target }) => {
            setLimit(parseInt(target.value, 10));
            setPage(0);
          }}
        />
      </Box>

      <Button
        variant="contained"
        color="secondary"
        disabled={!activeActions}
        onClick={() => setRegisterEvolution(true)}
      >
        Novo Cadastro
      </Button>
    </>
  );
}

export default EvolutionList;
