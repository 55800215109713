import { Box, Button, Grid, Typography } from "@mui/material";
import LunaLoading from "../../assets/gifs/lunaLoading.GIF";
import { AppContext } from "../../contexts/AppContext";
import { ReportProblem } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";

function LunaAI({ data, isLoading, isError, setPrescriptionsDialog, initialPrescriptionTab }) {
  const { setExamPrescriptions, setDrugOnSitePrescriptions } = useContext(AppContext);
  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [dots, setDots] = useState(".");

  const borderStyle = "1px solid #d3d3d3";
  const titleStyle = { fontWeight: "bold", marginTop: "2rem" };
  const textStyle = { fontStyle: "italic" };
  const loadingTexts = [
    "Avaliando histórico de atendimentos",
    "Analisando dados do paciente",
    "Analisando dados preenchidos",
  ];
  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
    }, 4000);

    if (!isLoading) {
      clearInterval(intervalId);
    }

    return () => clearInterval(intervalId);
  }, [loadingTexts.length, isLoading]);

  useEffect(() => {
    const dotsIntervalId = setInterval(() => {
      setDots((prevDots) => (prevDots.length < 3 ? prevDots + "." : ""));
    }, 500);

    if (!isLoading) {
      clearInterval(dotsIntervalId);
    }

    return () => clearInterval(dotsIntervalId);
  }, [isLoading]);

  const handlePrescribeExams = () => {
    setExamPrescriptions((exams) => {
      const newExams = [];
      data.exams.forEach((newExam) => {
        if (!exams.some((exam) => exam.id === newExam.id)) {
          newExams.push({
            ...newExam,
            id: exams.length + newExams.length + 1,
            notPreseted: true,
            notes: null,
            segment_area: null,
            diagnostic_hypothesis: null,
          });
        }
      });
      return exams.concat(newExams);
    });
    setPrescriptionsDialog(true);
    initialPrescriptionTab(2);
  };

  const handlePrescribeMedicines = () => {
    setDrugOnSitePrescriptions((medicines) => {
      const newMedicines = [];

      data.medicines.forEach((newMedicine) => {
        if (!medicines.some((medicine) => medicine.id === newMedicine.id)) {
          newMedicines.push({
            ...newMedicine,
            notPreseted: true,
            id: medicines.length + newMedicines.length + 1,
            notes: null,
          });
        }
      });
      return medicines.concat(newMedicines);
    });
    initialPrescriptionTab(0);
    setPrescriptionsDialog(true);
  };

  if (isLoading) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{ minWidth: "425px", height: "85vh" }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
          gap="2rem"
        >
          <img
            style={{ maxHeight: "300px", margin: "auto" }}
            src={LunaLoading}
            alt="Analisando dados do paciente"
          />
          <Typography color="primary" component="h4" variant="h5" sx={{ textAlign: "center" }}>
            {loadingTexts[currentTextIndex]}
            {dots}
          </Typography>
        </Box>
      </Box>
    );
  }

  if (isError || (!isLoading && !data)) {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexDirection="column"
        sx={{ minWidth: "425px", paddingTop: "3rem" }}
      >
        <ReportProblem sx={{ fontSize: 80, marginBottom: "1rem", color: "#ff5252" }} />
        <Typography color="#ff5252" variant="h5" component="h4">
          Ocorreu um erro durante análise, por favor tente novamente.
        </Typography>
      </Box>
    );
  }

  const formatText = (text) => {
    const parts = text.split(/\*\*(.*?)\*\*/g);
    return parts.map((part, index) => {
      const isTitle = index % 2 !== 0;
      return (
        <Typography
          key={index}
          sx={isTitle ? titleStyle : textStyle}
          component={isTitle ? "h6" : "p"}
        >
          {part}
        </Typography>
      );
    });
  };

  const safelySplitText = (text) => {
    if (Array.isArray(text)) {
      return text.flatMap((t) => t.split("\n\n"));
    }
    return text ? text.split("\n\n") : ["Sem informações"];
  };

  const processAttribute = (attribute) => {
    return safelySplitText(attribute).map((line, index) => (
      <Typography color="#4f38a1" key={index}>
        {formatText(line)}
      </Typography>
    ));
  };

  const dataObjs = [
    {
      title: "Alertas",
      data: data.alerts,
      titleColor: "#ff5252",
    },
    {
      title: "Resumo",
      data: data.text,
      titleColor: "primary",
    },
    {
      title: "Plano",
      data: data.plan,
      titleColor: "primary",
    },
    {
      title: "Recomendações",
      data: data.procedures,
      titleColor: "primary",
    },
  ];

  return (
    <Grid
      sx={{
        maxWidth: "425px",
        p: "0.5rem",
        overflowY: "auto",
        maxHeight: "90vh",
      }}
    >
      {dataObjs.map((obj, index) => {
        return (
          <Grid
            sx={{
              m: "2rem auto",
              p: "1rem",
              border: borderStyle,
              borderRadius: "1rem",
            }}
            key={index}
          >
            <Typography
              color={obj.titleColor}
              component="h4"
              variant="h5"
              sx={{ fontWeight: "bold" }}
            >
              {obj.title}:
            </Typography>
            <hr style={{ borderTop: borderStyle }} />
            <Typography sx={{ fontWeight: "bold" }}>{processAttribute(obj.data)}</Typography>
            {obj.title === "Recomendações" && data.exams && data.exams.length !== 0 && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handlePrescribeExams}
                disabled
                sx={{ mt: 2 }}
              >
                Sugestão de prescrição de exames
              </Button>
            )}
            {obj.title === "Recomendações" && data.medicines && data.medicines?.length !== 0 && (
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={handlePrescribeMedicines}
                disabled
                sx={{ mt: 2 }}
              >
                Sugestão de medicamentos
              </Button>
            )}
          </Grid>
        );
      })}
    </Grid>
  );
}

export default LunaAI;
