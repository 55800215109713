import { headerHorizontalStyle, footer, title } from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";

function medicalStatementPDF(data, patient, odonto, ceo) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const description = {
    alignment: "center",
    text: [
      {
        text: "Atesto que atendi nesta data o(a) Sr(a) ",
      },
      {
        text: patient.social_prioritize ? patient.social_name : patient.name,
        bold: true,
      },
      {
        text: ", data de nascimento : ",
      },
      {
        text: format(parseISO(patient.birth_date), "dd/MM/yyyy", {
          locale: ptBR,
        }),
        bold: true,
      },
      { text: ". Sendo necessário o seu afastamento de suas atividades " },
      {
        text: !!data.days
          ? `por ${data.days} dia(s)`
          : `das ${data.arrival_time}hrs às ${data.departure_time}hrs`,
        bold: true,
      },
      { text: ", a partir de " },
      {
        text: `${format(!!data.createdAt ? new Date(data.createdAt) : new Date(), "dd/MM/yyyy")}`,
        bold: true,
      },
      { text: ", tendo como causa do atendimento o código abaixo:" },
      { text: `\n\nObservação: ${data.observations}` },
    ],
  };

  const code = {
    type: "none",
    margin: [0, 30, 0, 0],
    alignment: "center",
    ul: [
      {
        text: data.cid_id || "",
        fontSize: 26,
        bold: true,
        border: [false, false, false, false],
        decoration: "underline",
        decorationStyle: "dashed",
        decorationColor: "#514C4C",
      },
      {
        text: data.cid_id ? "Código da doença" : "",
        fontSize: 10,
        color: "#514C4C",
      },
    ],
  };

  const patientSign = [
    {
      canvas: [{ type: "line", x1: 100, y1: 80, x2: 420, y2: 80, lineWidth: 1 }],
    },
    {
      margin: [0, 10, 0, 0],
      alignment: "center",
      fontSize: 10,
      text: [
        { text: "Aceito a colocação do " },
        { text: "CID ", bold: true },
        { text: "assinado:" },
      ],
    },
  ];

  const urlType = pdfName + "/types/declaration";

  let employee;

  if (data.soap?.employee_specialty) {
    employee = {
      ...data.soap.employee_specialty.employee,
      specialty: data.soap.employee_specialty.specialty,
    };
  }

  const definitions = {
    info: { title: pdfName },
    pageMargins: [40, 105, 40, 120],
    defaultStyle: {
      fontSize: 10,
    },
    ...headerHorizontalStyle(data?.attendance?.company),
    footer: footer(data?.createdAt, true, employee, data?.attendance?.company, urlType),
    content: [
      title(odonto || ceo ? "Atestado" : "Atestado Médico"),
      description,
      code,
      patientSign,
    ],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;

  return documentPDF;
}

export default medicalStatementPDF;
