import React, { useState } from "react";
import TableFilter from "../../../components/Table/TableFilter";
import { format, parseISO } from "date-fns";
import { useQuery } from "react-query";
import { useSignedDocuments } from "../../../service";
import { Box, TablePagination } from "@mui/material";
import { openURL } from "../../../utils";

const initialData = {
  totalItems: 0,
  items: [],
};

export default function FullDocumentHistory({ patient }) {
  const [pagination, setPagination] = useState({
    page: 0,
    limit: 5,
  });

  const { getSignedDocuments } = useSignedDocuments();

  const fullDocumentsQuery = useQuery(
    ["full-documents", patient.id, pagination],
    () =>
      getSignedDocuments({
        type: "total_or_partial_print",
        patient_id: patient.id,
        ...pagination,
      }),
    {
      initialData,
    }
  );

  const columns = [
    { name: "Profissional", field: "employee_specialty.employee.name" },
    { name: "Função", field: "employee_specialty.employee.employee_Type.description" },
    {
      name: "Solicitante",
      field: "third_party_name",
      use: (third_party_name) => third_party_name ?? patient.name,
    },
    {
      name: "Data / Hora",
      field: "createdAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy 'às' HH:mm "),
    },
  ];

  return (
    <>
      <TableFilter
        columns={columns}
        data={fullDocumentsQuery.data.items}
        actions
        loading={false}
        actionsTypes={["print"]}
        actionUniqueIdentifier="id"
        actionHandlePrint={(_, row) => openURL(row.result)}
      />
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "flex-end",
        }}
      >
        <TablePagination
          count={fullDocumentsQuery.data.totalItems}
          component="div"
          page={pagination.page}
          onPageChange={(_, newPage) => {
            setPagination((pagination) => ({
              ...pagination,
              page: newPage,
            }));
          }}
          rowsPerPage={pagination.limit}
          rowsPerPageOptions={[5, 10, 20, 75]}
          onRowsPerPageChange={(event) => {
            const limit = parseInt(event.target.value);
            setPagination({
              limit,
              page: 0,
            });
          }}
        />
      </Box>
    </>
  );
}
