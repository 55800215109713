import { useForm } from "react-hook-form";
import {
  AutocompleteField,
  Button,
  DateField,
  RadioField,
  SwitchField,
  TextField,
} from "../../FormFields";
import { Box, Grid, Typography } from "@mui/material";
import {
  individualDefaultValues,
  individualValidations,
  payloadConstructor,
} from "./IndividualValidations";
import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "react-query";
import { useIndividualRegister, usePatient, useSusCode } from "../../../service";
import { useNotifier } from "../../../hooks";
import { validateCNS789, validateCPF } from "../../../config/validations";
import { useEffect, useState } from "react";
import { DialogMedium } from "../../../helper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  brazil,
  childrenResponsible,
  disabilityTypes,
  genderIdentities,
  genders,
  heartDiseases,
  homelessnessTime,
  howTimesFeedDay,
  kidneyDiseases,
  kinshipRelationship,
  maritalStatus,
  nationality,
  originFoods,
  personalHygienes,
  raceColors,
  respiratoryDiseases,
  sexualOrientations,
  typeBlood,
} from "../../../config/susCodeStandardSelect";
import IndividualUserForm from "./IndividualUserForm";
import IndividualSociodemograpicForm from "./IndividualSociodemographicForm";
import IndividualHealthConditionForm from "./IndividualHealthConditionForm";
import IndividualHomelessnessForm from "./IndividualHomelessnessForm";
import { differenceInYears, isValid, parseISO } from "date-fns";
import { cleanUpMask } from "../../../config/mask";
import IndividualEmployeeForm from "./IndividualEmployeeForm";

function IndividualForm() {
  const {
    control,
    watch,
    setValue,
    handleSubmit,
    reset,
    clearErrors,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    defaultValues: individualDefaultValues,
    resolver: yupResolver(individualValidations),
  });
  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isTermsRejectDialogOpen, setIsTermsRejectDialogOpen] = useState(null);
  const [isPatientWarningDialogOpen, setIsPatientWarningDialogOpen] = useState(false);
  const [isEditingIndividual, setIsEditingIndividual] = useState(false);
  const [enablePatientQuery, setEnablePatientQuery] = useState(false);
  const [patientQueryParams, setPatientQueryParams] = useState({
    full_registration: true,
  });
  const [warningFieldToClear, setWarningFieldToClear] = useState();
  const [individualRegisterId, setIndividualRegisterId] = useState(null);
  const { getSusCodeByProperty, susCodeIndividualPaths } = useSusCode();
  const { postIndividual, getIndividualHistoric } = useIndividualRegister();
  const individualMutate = useMutation(postIndividual);
  const { getPatients } = usePatient();
  const notify = useNotifier();
  const { id } = useParams();
  const [search] = useSearchParams();
  const navigate = useNavigate();
  const isHistoric = search.get("isHistoric");

  const physicNational = watch("physic_national");
  const susCard = watch("sus_card");
  const diffInYears =
    isValid(watch("birth_date")) && differenceInYears(new Date(), watch("birth_date"));

  useEffect(() => {
    const searchParam = search.get("isEditing");
    const isEditing = !!searchParam || (!searchParam && !id);
    setIsEditingIndividual(isEditing);
  }, [search]);

  useEffect(() => {
    if (Object.keys(errors).length > 0) {
      notify("Alguns campos não foram preenchidos corretamente. Revise o formulário", "error");
    }
  }, [errors]);

  const patient = useQuery(["patient", patientQueryParams], () => getPatients(patientQueryParams), {
    enabled: enablePatientQuery && !!patientQueryParams,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
    onSuccess: (response) => {
      if (!response?.items?.length)
        return notify("Nenhum paciente foi encontrado com este CPF/CNS", "success");

      if (!!id && !physicNational && !susCard) {
        handleFormValuesByPatient(response.items[0]);
        if (!response.items[0]?.sus_card) setWarningFieldToClear("sus_card");
        if (!response.items[0]?.physic_national) setWarningFieldToClear("physic_national");
        setEnablePatientQuery(false);
      } else if (!id) {
        handleFormValuesByPatient(response.items[0]);
        notify("Já existe um paciente com este CPF/CNS", "success");
        setEnablePatientQuery(false);
        setIsEditDialogOpen(true);
      } else {
        setEnablePatientQuery(false);
        setIsPatientWarningDialogOpen(true);
      }
    },
  });

  const patientHistoric = useQuery(["patient-historic", id], () => getIndividualHistoric(id), {
    enabled: !!id && !!isHistoric,
    refetchOnWindowFocus: false,
    retry: false,
    cacheTime: 0,
    onSuccess: (response) => {
      if (response) {
        handleFormValuesByPatient(response);
        notify(`Histórico encontrado.`, "success");
      }
    },
    onError: (error) => {
      notify(error.message, "error");
    },
  });

  const definePatientData = () => {
    if (!isHistoric) {
      return patient?.data?.items.length ? patient?.data?.items[0] : {};
    } else {
      return patientHistoric?.data;
    }
  };

  const patientData = definePatientData();

  const handleEnablePatientQuery = () => {
    const isPhysicNationalValid = physicNational && validateCPF(physicNational);
    const isSusCardValid = susCard && validateCNS789(susCard);

    if (patientData && !Object.keys(patientData).length && !isHistoric) {
      if (!!id) {
        setPatientQueryParams({ full_registration: true, id: id });
        setEnablePatientQuery(true);
        return;
      } else if (!id && (isPhysicNationalValid || isSusCardValid)) {
        setPatientQueryParams({
          full_registration: true,
          ...(isSusCardValid
            ? { sus_card: cleanUpMask(susCard?.toString(), "", ["_"]) }
            : {
                physic_national: cleanUpMask(physicNational, "", ["_", "-", "."]),
              }),
        });
        setEnablePatientQuery(true);
        return;
      }
    }

    if (patientData && (!patientData.sus_card || !patientData.physic_national)) {
      if (isSusCardValid && isPhysicNationalValid) {
        setPatientQueryParams({
          ...(patientData.physic_national
            ? { sus_card: cleanUpMask(susCard?.toString(), "", ["_"]) }
            : {
                physic_national: cleanUpMask(physicNational, "", ["_", "-", "."]),
              }),
        });
        setEnablePatientQuery(true);
        return;
      }
    }

    setEnablePatientQuery(false);
  };

  useEffect(() => {
    handleEnablePatientQuery();
  }, [id, physicNational, susCard]);

  useQuery(
    "professions",
    () =>
      getSusCodeByProperty(susCodeIndividualPaths.profession, {
        cbo_code: patientData.sociodemographic_information.profession,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!patientData?.sociodemographic_information?.profession,
      onSuccess: (response) => {
        if (response.items.length) {
          setValue("profession", response.items[0]);
        }
      },
    }
  );

  useQuery(
    "ethnicities",
    () =>
      getSusCodeByProperty(susCodeIndividualPaths.ethnicities, {
        id: patientData?.ethnicity,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!patientData?.ethnicity,
      onSuccess: (response) => {
        if (response.items.length) {
          setValue("ethnicity", response.items[0]);
        }
      },
    }
  );

  useQuery(
    "traditional-communities",
    () =>
      getSusCodeByProperty(susCodeIndividualPaths.traditionalCommunityNames, {
        id: patientData?.sociodemographic_information?.traditional_community_name,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!patientData?.sociodemographic_information?.traditional_community_name,
      onSuccess: (response) => {
        if (response.items.length) {
          setValue("traditional_community_name", response.items[0]);
        }
      },
    }
  );

  useQuery(
    "educational-attainments",
    () =>
      getSusCodeByProperty(susCodeIndividualPaths.educationalAttainments, {
        id: patientData?.sociodemographic_information?.educational_attainment,
        limit: 25,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!patientData?.sociodemographic_information?.educational_attainment,
      onSuccess: (response) => {
        if (response.items.length) {
          const education = response.items.find(
            (e) => e.id === patientData?.sociodemographic_information?.educational_attainment
          );
          setValue("educational_attainment", education);
        }
      },
    }
  );

  useQuery(
    "work-situation",
    () =>
      getSusCodeByProperty(susCodeIndividualPaths.workSituations, {
        id: patientData?.sociodemographic_information?.work_situation,
        limit: 25,
      }),
    {
      refetchOnWindowFocus: false,
      retry: false,
      enabled: !!patientData?.sociodemographic_information?.work_situation,
      onSuccess: (response) => {
        if (response.items.length) {
          const work = response.items.find(
            (e) => e.id === patientData?.sociodemographic_information?.work_situation
          );
          setValue("work_situation", work);
        }
      },
    }
  );

  const handleEdit = () => {
    setIsEditingIndividual(true);
    setIsEditDialogOpen(false);
  };

  const handleClose = () => {
    setIsEditingIndividual(false);
    setIsEditDialogOpen(false);
  };

  const handleFormValuesByPatient = async (values) => {
    if (values.birth_date) setValue("birth_date", parseISO(values.birth_date));
    if (values.date_naturalization)
      setValue("date_naturalization", parseISO(values.date_naturalization));
    if (values.date_entry_country)
      setValue("date_entry_country", parseISO(values.date_entry_country));
    if (values.date_deceased) setValue("date_deceased", parseISO(values.date_deceased));

    setValue(
      "individual_registration_rejection",
      values.individual_registration_rejection || false
    );
    setValue("name", values.name);
    setValue("social_name", values.social_name);
    setValue(
      "gender",
      genders.find((e) => e.sus_code_id === values.gender)
    );
    setValue("mother_name", values.mother_name);
    setValue("dad_name", values.dad_name);
    setValue("phone", values.phone);
    setValue("organ_donor", values.organ_donor);
    setValue(
      "type_blood",
      typeBlood.find((e) => e.label === values.type_blood)
    );
    setValue("email", values?.contact?.email || "");
    setValue("phone", values?.contact?.phone?.replace(/(\d{2})(\d{5})(\d{4})/, "($1)$2-$3") || "");
    setValue("does_not_know_dad", !!values.does_not_know_dad ? [{ label: "DESCONHECE" }] : []);
    setValue(
      "does_not_know_mother",
      !!values.does_not_know_mother ? [{ label: "DESCONHECE" }] : []
    );
    if (!watch("physic_national") && values.physic_national) {
      setValue(
        "physic_national",
        values.physic_national.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4")
      );
    }
    if (!watch("sus_card") && values.sus_card) {
      setValue("sus_card", values.sus_card);
    }
    setValue(
      "marital_status",
      maritalStatus.find(
        (e) => e.label.toLocaleLowerCase() === values.marital_status?.toLocaleLowerCase()
      )
    );
    setValue(
      "race",
      raceColors.find((e) => e.sus_code_id === values.race)
    );
    setValue(
      "nationality",
      nationality.find((e) => e.sus_code_id === values.nationality)
    );
    setValue("naturalization_ordinance", values?.contact?.naturalization_ordinance || null);
    setValue(
      "nis_pis_pasep",
      values.nis_pis_pasep?.replace(/^(\d{3})(\d{5})(\d{2})(\d{1})$/, "$1.$2.$3-$4")
    );
    if (values.health_conditions) {
      setValue(
        "had_any_hospitalizations_in_the_last_12_months",
        values.health_conditions.had_any_hospitalizations_in_the_last_12_months
      );
      setValue("had_heart_attack", values.health_conditions.had_heart_attack);
      setValue("had_stroke", values.health_conditions.had_stroke);
      setValue("has_cancer", values.health_conditions.has_cancer);
      setValue("has_diabetes", values.health_conditions.has_diabetes);
      setValue("has_heart_disease", values.health_conditions.has_heart_disease);
      setValue("has_hypertension", values.health_conditions.has_hypertension);
      setValue("has_kidney_disease", values.health_conditions.has_kidney_disease);
      setValue("has_leprosy", values.health_conditions.has_leprosy);
      setValue("has_respiratory_diseases", values.health_conditions.has_respiratory_diseases);
      setValue("has_tuberculosis", values.health_conditions.has_tuberculosis);
      setValue(
        "heart_disease",
        heartDiseases.filter((e) =>
          values.health_conditions?.heart_disease?.includes(e.sus_code_id)
        ) || []
      );
      setValue("is_alcohol_user", values.health_conditions.is_alcohol_user);
      setValue("is_bedridden", values.health_conditions.is_bedridden);
      setValue("is_domicilied", values.health_conditions.is_domicilied);
      setValue("is_drug_user", values.health_conditions.is_drug_user);
      setValue("is_smoker", values.health_conditions.is_smoker);
      setValue(
        "kidney_disease",
        kidneyDiseases.filter((e) =>
          values.health_conditions?.kidney_disease?.includes(e.sus_code_id)
        ) || []
      );
      setValue("medicinal_plants", values.health_conditions.medicinal_plants);
      setValue(
        "mental_problem_by_healthcare_professional",
        values.health_conditions.mental_problem_by_healthcare_professional
      );
      setValue("other_health_conditions", values.health_conditions.other_health_conditions);
      setValue("other_health_conditions_2", values.health_conditions.other_health_conditions_2);
      setValue("other_health_conditions_3", values.health_conditions.other_health_conditions_3);
      setValue("pregnant", values.health_conditions.pregnant);
      setValue("reason_for_hospitalization", values.health_conditions.reason_for_hospitalization);
      setValue("reference_maternity", values.health_conditions.reference_maternity);
      setValue(
        "reference_maternity_hospital",
        values.health_conditions.reference_maternity_hospital
      );
      setValue(
        "respiratory_diseases",
        values?.health_conditions?.respiratory_diseases?.length
          ? respiratoryDiseases.filter((e) =>
              values?.health_conditions?.respiratory_diseases?.includes(e.sus_code_id)
            )
          : []
      );
      setValue("uses_medicinal_plants", values.health_conditions.uses_medicinal_plants);
      setValue(
        "uses_other_complementary_integrative_practices",
        values.health_conditions.uses_other_complementary_integrative_practices
      );
      setValue("weight", values.health_conditions.weight);
      setValue(
        "had_any_hospitalizations_in_the_last_12_months",
        values.health_conditions.had_any_hospitalizations_in_the_last_12_months
      );
    }
    setValue("is_family_responsible", values.is_family_responsible);
    if (values.is_family_responsible === false) {
      setValue("responsible_physic_national", values.responsible_physic_national);
      setValue("responsible_sus_card", values.responsible_sus_card);
      setValue(
        "kinship",
        values.responsible_kinship
          ? kinshipRelationship.find((e) => e.sus_code_id === values.responsible_kinship)
          : null
      );
    }
    if (values.sociodemographic_information) {
      setValue(
        "attends_scholl_or_daycare",
        values.sociodemographic_information.attends_scholl_or_daycare
      );
      setValue(
        "attends_traditional_caregiver",
        values.sociodemographic_information.attends_traditional_caregiver
      );
      setValue(
        "children_responsible",
        childrenResponsible.filter((e) =>
          values.sociodemographic_information.children_responsible?.includes(e.sus_code_id)
        )
      );
      setValue(
        "disability_type",
        disabilityTypes.filter((e) =>
          values.sociodemographic_information.disability_type?.includes(e.sus_code_id)
        ) || []
      );
      setValue("disabled", values.sociodemographic_information.disabled);
      setValue(
        "gender_identity",
        genderIdentities.find(
          (e) => e.sus_code_id === values.sociodemographic_information?.gender_identity
        )
      );
      setValue("have_health_insurance", values.sociodemographic_information.have_health_insurance);
      setValue("is_gender_identity", values.sociodemographic_information.is_gender_identity);
      setValue(
        "is_member_of_traditional_community",
        values.sociodemographic_information.is_member_of_traditional_community
      );
      setValue("is_sexual_orientation", values.sociodemographic_information.is_sexual_orientation);
      setValue(
        "participate_in_community_group",
        values.sociodemographic_information.participate_in_community_group
      );
      setValue(
        "sexual_orientation",
        sexualOrientations.find(
          (e) => e.sus_code_id === values.sociodemographic_information?.sexual_orientation
        )
      );
    }
    if (values.socioeconomic_information) {
      setValue(
        "food_finished_before_buy_more",
        values.socioeconomic_information.food_finished_before_buy_more
      );
      setValue(
        "ate_some_food_that_had_money_rounded",
        values.socioeconomic_information.ate_some_food_that_had_money_rounded
      );
    }
    if (values.homelessness_information) {
      setValue(
        "does_it_have_any_family_references",
        values.homelessness_information.does_it_have_any_family_references
      );
      setValue(
        "family_members_references",
        values.homelessness_information.family_members_references
      );
      setValue(
        "have_access_to_personal_hygiene",
        values.homelessness_information.have_access_to_personal_hygiene
      );
      setValue(
        "homelessness_time",
        values.homelessness_information.homelessness_time
          ? homelessnessTime.find(
              (e) => e.sus_code_id === values.homelessness_information.homelessness_time
            )
          : null
      );
      setValue(
        "how_many_times_do_you_feed_a_day",
        values.homelessness_information.how_many_times_do_you_feed_a_day
          ? howTimesFeedDay.find(
              (e) =>
                e.sus_code_id === values.homelessness_information.how_many_times_do_you_feed_a_day
            )
          : null
      );
      setValue("instituition_name", values.homelessness_information.instituition_name);
      setValue(
        "is_accompanied_by_another_institution",
        values.homelessness_information.is_accompanied_by_another_institution
      );
      setValue("is_homeless", values.homelessness_information.is_homeless);
      setValue(
        "personal_hygiene",
        personalHygienes.filter((e) =>
          values.homelessness_information.personal_hygiene?.includes(e.sus_code_id)
        ) || []
      );
      setValue("receive_some_benefit", values.homelessness_information.receive_some_benefit);
      setValue(
        "visit_family_members_frequently",
        values.homelessness_information.visit_family_members_frequently
      );
      setValue(
        "what_is_the_origin_of_the_food",
        originFoods.filter((e) =>
          values.homelessness_information.what_is_the_origin_of_the_food?.includes(e.sus_code_id)
        ) || []
      );
    }
  };

  const onSubmit = async (values) => {
    values["microarea_id"] = values.microarea?.id || null;
    values["employee_specialty_id"] = values.employee_cbo.employee_specialty_id;
    const payloadId = individualRegisterId ? individualRegisterId : id || patientData.id;
    const payload = await payloadConstructor(values, payloadId);

    if (payload) {
      individualMutate.mutate(payload, {
        onSuccess(response) {
          notify(
            `${patientData?.is_fully_registered ? "Atualizado" : "Cadastrado"} com sucesso!`,
            "success"
          );
          reset();
          navigate("/cds/individual");
        },
        onError(error) {
          notify(error.message, "error");
          if (error.data?.patient.id) setIndividualRegisterId(error.data.patient.id);
        },
      });
    }
  };

  const handleChangeField = (field, value) => {
    if (isEditingIndividual) {
      switch (field) {
        case "does_not_know_dad":
          clearErrors("dad_name");
          if (value?.length) setValue("dad_name", "");
          break;
        case "does_not_know_mother":
          clearErrors("mother_name");
          if (value?.length) setValue("mother_name", "");
          break;
        case "race":
          clearErrors("ethnicity");
          if (value?.sus_code_id !== 5) setValue("ethnicity", null);
          break;
        case "gender":
          if (value?.id !== 1) {
            setValue("pregnant", null);
            setValue("reference_maternity", "");
          }
          break;
        case "pregnant":
          if (!value) {
            setValue("reference_maternity", "");
          }
          break;
        case "birth_date":
          setValue("pregnant", null);
          setValue("children_responsible", []);
          clearErrors("pregnant");
          break;
        case "nationality":
          clearErrors([
            "naturalization_country",
            "date_naturalization",
            "naturalization_ordinance",
            "date_entry_country",
            "naturality",
          ]);
          if (value?.sus_code_id === 1) {
            setValue("naturalization_country", brazil);
            setValue("date_naturalization", null);
            setValue("naturalization_ordinance", "");
            setValue("date_entry_country", null);
          } else if (value?.sus_code_id === 2) {
            setValue("naturalization_country", null);
            setValue("naturality", null);
            setValue("date_entry_country", null);
          } else if (value?.sus_code_id === 3) {
            setValue("naturalization_country", null);
            setValue("naturality", null);
            setValue("date_naturalization", null);
            setValue("naturalization_ordinance", "");
          } else {
            setValue("naturality", null);
            setValue("naturalization_country", null);
            setValue("date_naturalization", null);
            setValue("naturalization_ordinance", "");
            setValue("date_entry_country", null);
          }
          break;
        case "is_family_responsible":
          clearErrors(["kinship", "responsible_physic_national"]);
          if (value) {
            setValue("responsible_name", "");
            setValue("responsible_email", "");
            setValue("responsible_phone", "");
            setValue("responsible_physic_national", "");
            setValue("responsible_sus_card", "");
            setValue("kinship", null);
          }
          break;
        case "responsible_sus_card":
          clearErrors("responsible_physic_national");
          break;
        case "is_member_of_traditional_community":
          clearErrors("traditional_community_name");
          if (!value) {
            setValue("traditional_community_name", null);
          }
          break;
        case "is_sexual_orientation":
          clearErrors("sexual_orientation");
          if (!value) {
            setValue("sexual_orientation", null);
          }
          break;
        case "is_gender_identity":
          clearErrors("gender_identity");
          if (!value) {
            setValue("gender_identity", null);
          }
          break;
        case "disabled":
          clearErrors("disability_type");
          if (!value) {
            setValue("disability_type", []);
          }
          break;
        case "individual_registration_rejection":
          if (value) {
            clearErrors();
            setValue("have_health_insurance", null);
            setValue("attends_scholl_or_daycare", null);
            setValue("children_responsible", []);
            setValue("educational_attainment", null);
            setValue("work_situation", null);
            setValue("profession", null);
            setValue("attends_traditional_caregiver", null);
            setValue("participate_in_community_group", null);
            setValue("is_member_of_traditional_community", null);
            setValue("traditional_community_name", null);
            setValue("is_sexual_orientation", null);
            setValue("sexual_orientation", null);
            setValue("is_gender_identity", null);
            setValue("gender_identity", null);
            setValue("disabled", null);
            setValue("disability_type", []);
            setValue("organ_donor", null);
            setValue("type_blood", null);
            setValue("food_finished_before_buy_more", null);
            setValue("ate_some_food_that_had_money_rounded", null);
            setValue("pregnant", null);
            setValue("reference_maternity", "");
            setValue("weight", null);
            setValue("has_hypertension", null);
            setValue("has_cancer", null);
            setValue("is_smoker", null);
            setValue("is_drug_user", null);
            setValue("is_alcohol_user", null);
            setValue("had_heart_attack", null);
            setValue("has_diabetes", null);
            setValue("has_leprosy", null);
            setValue("is_bedridden", null);
            setValue("has_tuberculosis", null);
            setValue("is_domicilied", null);
            setValue("had_stroke", null);
            setValue("uses_other_complementary_integrative_practices", null);
            setValue("mental_problem_by_healthcare_professional", null);
            setValue("had_any_hospitalizations_in_the_last_12_months", null);
            setValue("reason_for_hospitalization", "");
            setValue("uses_medicinal_plants", null);
            setValue("medicinal_plants", "");
            setValue("has_respiratory_diseases", null);
            setValue("respiratory_diseases", []);
            setValue("has_heart_disease", null);
            setValue("heart_disease", []);
            setValue("has_kidney_disease", null);
            setValue("kidney_disease", []);
            setValue("other_health_conditions", "");
            setValue("other_health_conditions_2", "");
            setValue("other_health_conditions_3", "");
            setValue("is_homeless", null);
            setValue("homelessness_time", null);
            setValue("receive_some_benefit", null);
            setValue("have_access_to_personal_hygiene", null);
            setValue("personal_hygiene", []);
            setValue("is_accompanied_by_another_institution", null);
            setValue("instituition_name", "");
            setValue("how_many_times_do_you_feed_a_day", null);
            setValue("what_is_the_origin_of_the_food", []);
            setValue("visit_family_members_frequently", null);
            setValue("does_it_have_any_family_references", null);
            setValue("family_members_references", "");
            setValue("reason_for_leaving_the_register", null);
            setValue("date_deceased", null);
            setValue("do_number", "");
          }
          break;
        case "had_any_hospitalizations_in_the_last_12_months":
          clearErrors("reason_for_hospitalization");
          if (!value) {
            setValue("reason_for_hospitalization", "");
          }
          break;
        case "uses_medicinal_plants":
          if (!value) {
            setValue("medicinal_plants", "");
            clearErrors("medicinal_plants");
          }
          break;
        case "has_respiratory_diseases":
          clearErrors("respiratory_diseases");
          if (!value) {
            setValue("respiratory_diseases", []);
          }
          break;
        case "has_heart_disease":
          clearErrors("heart_disease");
          if (!value) {
            setValue("heart_disease", []);
          }
          break;
        case "has_kidney_disease":
          clearErrors("kidney_disease");
          if (!value) {
            setValue("kidney_disease", []);
          }
          break;
        case "is_homeless":
          if (!value) {
            setValue("homelessness_time", null);
            setValue("receive_some_benefit", null);
            setValue("have_access_to_personal_hygiene", null);
            setValue("personal_hygiene", []);
            setValue("is_accompanied_by_another_institution", null);
            setValue("instituition_name", "");
            setValue("how_many_times_do_you_feed_a_day", null);
            setValue("what_is_the_origin_of_the_food", []);
            setValue("visit_family_members_frequently", null);
            setValue("does_it_have_any_family_references", null);
            setValue("family_members_references", "");
          }
          break;
        case "have_access_to_personal_hygiene":
          clearErrors("personal_hygiene");
          if (!value) {
            setValue("personal_hygiene", []);
          }
          break;
        case "is_accompanied_by_another_institution":
          clearErrors("instituition_name");
          if (!value) {
            setValue("instituition_name", "");
          }
          break;
        case "does_it_have_any_family_references":
          clearErrors("family_members_references");
          if (!value) {
            setValue("family_members_references", "");
          }
          break;
        case "reason_for_leaving_the_register":
          clearErrors(["date_deceased", "do_number"]);
          if (!value || value.value === 136) {
            setValue("date_deceased", null);
            setValue("do_number", "");
          }
          break;
        default:
          break;
      }
    }
  };

  const handleCloseWarningDialog = () => {
    setValue(warningFieldToClear, "");
    setIsPatientWarningDialogOpen(false);
    navigate(0);
  };

  return (
    <Box>
      <DialogMedium
        title={
          patientData?.is_fully_registered
            ? "já possui uma ficha deseja editá-la?"
            : "Localizamos um cadastro para este CPF/CNS."
        }
        open={isEditDialogOpen}
        fullWidth={true}
        maxWidth="sm"
        handleClose={handleClose}
      >
        <Grid container spacing={2}>
          <Grid item xs={2}>
            <Button onClick={handleEdit}>
              {patientData?.is_fully_registered ? "Editar" : "OK"}
            </Button>
          </Grid>
          {patientData?.is_fully_registered && (
            <Grid item xs={2}>
              <Button variant="text" onClick={handleClose}>
                Cancelar
              </Button>
            </Grid>
          )}
        </Grid>
      </DialogMedium>

      <DialogMedium
        title={
          "Devido a ativação de recusa, os dados do paciente não serão atualizados no cadastro do paciente."
        }
        open={isTermsRejectDialogOpen}
        fullWidth={true}
        maxWidth="sm"
        handleClose={() => setIsTermsRejectDialogOpen(false)}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Button onClick={() => setIsTermsRejectDialogOpen(false)}>Ok</Button>
          </Grid>
        </Grid>
      </DialogMedium>

      <DialogMedium
        title={"Já existe um outro cadastrado com este CPF/CNS."}
        open={isPatientWarningDialogOpen}
        fullWidth={true}
        maxWidth="sm"
        handleClose={handleCloseWarningDialog}
      >
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Button onClick={handleCloseWarningDialog}>Ok</Button>
          </Grid>
        </Grid>
      </DialogMedium>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <IndividualEmployeeForm control={control} watch={watch} setValue={setValue} />

          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>

          <IndividualUserForm
            id={id}
            control={control}
            watch={watch}
            setValue={setValue}
            patientData={patientData}
            isEditingIndividual={isEditingIndividual}
            setIsEditingIndividual={setIsEditingIndividual}
            isHistoric={isHistoric}
            handleChangeField={handleChangeField}
            nationality={nationality}
          />

          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color="secondary">
              TERMO DE RECUSA DO CADASTRO INDIVIDUAL DA ATENÇÃO BÁSICA
            </Typography>
            <SwitchField
              name="individual_registration_rejection"
              label="Usuário recusou o cadastro por meio do Termo de Recusa do Cadastro."
              control={control}
              customOnChange={(value) =>
                handleChangeField("individual_registration_rejection", value)
              }
              disabled={!isEditingIndividual}
            />
          </Grid>

          <Grid item xs={12}></Grid>

          <IndividualSociodemograpicForm
            control={control}
            watch={watch}
            isEditingIndividual={isEditingIndividual}
            handleChangeField={handleChangeField}
            diffInYears={diffInYears}
          />

          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color="secondary">
              INFORMAÇÕES SOCIOECONÔMICAS - TRIAGEM PARA RISCO DE INSEGURANÇA ALIMENTAR (TRIA)
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <RadioField
              name="food_finished_before_buy_more"
              label="Nos últimos três meses, os alimentos acabaram antes que você tivesse dinheiro pra comprar
              mais comida ?"
              control={control}
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
              optionLabelKey="label"
              optionValueKey="value"
              required
              direction="row"
              disabled={!isEditingIndividual || watch("individual_registration_rejection")}
            />
          </Grid>

          <Grid item xs={5}>
            <RadioField
              name="ate_some_food_that_had_money_rounded"
              required
              label="Nos últimos três meses, você comeu apenas alguns alimentos que ainda tinha, porque o dinheiro acabou ?"
              control={control}
              options={[
                { label: "Sim", value: true },
                { label: "Não", value: false },
              ]}
              optionLabelKey="label"
              optionValueKey="value"
              direction="row"
              disabled={!isEditingIndividual || watch("individual_registration_rejection")}
            />
          </Grid>

          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>

          <IndividualHealthConditionForm
            control={control}
            watch={watch}
            isEditingIndividual={isEditingIndividual}
            handleChangeField={handleChangeField}
            diffInYears={diffInYears}
          />

          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>

          <IndividualHomelessnessForm
            control={control}
            watch={watch}
            isEditingIndividual={isEditingIndividual}
            handleChangeField={handleChangeField}
          />

          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Typography variant="h6" color="secondary">
              SAÍDA DO CIDADÃO DO CADASTRO
            </Typography>
          </Grid>

          <Grid item xs={4}>
            <AutocompleteField
              name="reason_for_leaving_the_register"
              label="Saída do cidadão"
              control={control}
              options={[
                { label: "Óbito", value: 135 },
                { label: "Mudança de território", value: 136 },
              ]}
              optionLabelKey="label"
              optionValueKey="value"
              direction="row"
              customOnChange={(value) =>
                handleChangeField("reason_for_leaving_the_register", value)
              }
              disabled={!isEditingIndividual || watch("individual_registration_rejection")}
            />
          </Grid>

          <Grid item xs={12}></Grid>

          <Grid item xs={6}>
            <DateField
              name="date_deceased"
              label="DATA DO ÓBITO"
              control={control}
              placeholder="DD/MM/AAAA"
              disabled={
                !isEditingIndividual ||
                watch("individual_registration_rejection") ||
                !watch("reason_for_leaving_the_register") ||
                watch("reason_for_leaving_the_register")?.value === 136
              }
              required={watch("reason_for_leaving_the_register")?.value === 135}
            />
          </Grid>

          <Grid item xs={6}>
            <TextField
              name="do_number"
              label="NÚMERO DA D.O."
              control={control}
              placeholder="NÚMERO"
              disabled={
                !isEditingIndividual ||
                watch("individual_registration_rejection") ||
                !watch("reason_for_leaving_the_register") ||
                watch("reason_for_leaving_the_register")?.value === 136
              }
              required={watch("reason_for_leaving_the_register")?.value === 135}
              type="number"
              inputProps={{ minLength: 1, min: 1 }}
            />
          </Grid>

          <Grid item xs={12}></Grid>
          <Grid item xs={12}></Grid>

          <Grid item xs={12}>
            <Button
              type={
                !!watch("individual_registration_rejection") && isTermsRejectDialogOpen !== false
                  ? "button"
                  : "submit"
              }
              onClick={() =>
                watch("individual_registration_rejection") &&
                isTermsRejectDialogOpen !== false &&
                setIsTermsRejectDialogOpen(true)
              }
            >
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default IndividualForm;
