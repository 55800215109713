import { Button, Grid } from "@mui/material";
import { useForm } from "react-hook-form";
import { TextField } from "../../../components/FormFields";
import { yupResolver } from "@hookform/resolvers/yup";
import Yup from "../../../config/yup";
import { useNotifier } from "../../../hooks";
import { useMutation, useQueryClient } from "react-query";
import usePrescriptions from "../../../service/usePrescriptions";

const validations = Yup.object().shape({
  cancellation_notes: Yup.string().required("Informe o motivo da suspensão."),
});

const inititalValues = {
  cancellation_notes: "",
};

export default function PrescriptionSuspendForm({ prescriptionId, handleClose }) {
  const notify = useNotifier();
  const queryClient = useQueryClient();
  const { patchPrescription } = usePrescriptions();

  const { control, handleSubmit } = useForm({
    defaultValues: inititalValues,
    resolver: yupResolver(validations),
  });

  const suspendMutation = useMutation(patchPrescription);

  const suspend = handleSubmit(({ cancellation_notes }) => {
    suspendMutation.mutate(
      { id: prescriptionId, data: { cancellation_notes } },
      {
        onSuccess(data) {
          notify(data.message);
          queryClient.invalidateQueries("prescriptions");
          handleClose();
        },
        onError(data) {
          notify(data.message, "error");
        },
      }
    );
  });

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <TextField
          control={control}
          name={"cancellation_notes"}
          label={"Motivo da suspensão"}
          multiline
          minRows={4}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" onClick={() => suspend()}>
          Suspender
        </Button>
      </Grid>
    </Grid>
  );
}
