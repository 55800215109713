import React, { useState } from "react";
import FormFilters from "../FormFilters";
import {
  Box,
  Chip,
  Divider,
  Grid,
  IconButton,
  TablePagination,
  Tooltip,
  Typography,
} from "@mui/material";
import { HistoryRounded, InfoRounded, PeopleAltRounded, PlaceRounded } from "@mui/icons-material";
import { useQuery } from "react-query";
import { useFamily } from "../../../../service";
import { ColorInfoLabels, DynamicFeedback } from "../../../../components";
import { healthConditionsStatusColors, visitConclusionStatusColors } from "./constants";
import SectionHeader from "../../../../components/Panels/SectionHeader";
import { PaginatedScrollList } from "../../../../components/PaginatedScrollList";
import DetailCard from "../../../../components/Panels/DetailCard";
import { DialogMedium } from "../../../../helper";
import { formatWithMask } from "../../../../config/mask";
import RegexOf from "../../../../config/regex";
import { differenceInYears, format, isValid, parseISO } from "date-fns";
import { convertDateToBirthday, formatDateString } from "../../../../utils";
import { useForm } from "react-hook-form";
import { AutocompleteField, Button, DateField } from "../../../../components/FormFields";
import TableFilter from "../../../../components/Table/TableFilter";
import { useNotifier } from "../../../../hooks";

const complementTableColumns = [
  {
    name: "Data da Visita",
    field: "createdAt",
    use: (item) => format(parseISO(item), "dd/MM/yyyy 'às' HH:mm"),
    type: "text",
  },
  {
    name: "Desfecho",
    field: "visit_conclusion",
    use: (item) => {
      if (item === "absentee") return "Ausente";
      if (item === "completed") return "Realizada";
      return "Recusada";
    },
    type: "text",
  },
  {
    name: "Tipo de Visita",
    field: "type",
    use: (item) => (item === "family" ? "Familiar" : "Individual"),
    type: "text",
  },
  {
    name: "Periódica",
    field: "visit_reasons",
    use: (item) => {
      return item.visit_type && item.visit_type.includes("Visita Periódica") ? "Sim" : "Não";
    },
    type: "text",
  },
  {
    name: "Visita Compartilhada",
    field: "shared_visit",
    use: (item) => (item ? "Sim" : "Não"),
    type: "text",
  },
  {
    name: "Membros Presentes",
    field: "patient",
    use: (item, row) =>
      item?.name
        ? item?.name
        : row?.members_present_name?.length
        ? row?.members_present_name.join("\n")
        : "Não informado",
    type: "text",
  },
];

const defaultValues = {
  initial_date: null,
  final_date: null,
  visit_conclusion: { describe: "Todos", value: "all" },
  type: { describe: "Ambas", value: "both" },
};

function Beehive({ onSubmitFilters, initialValues, filters, setFilters }) {
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [families, setFamilies] = useState([]);
  const [healthCondtion, setHealthCondition] = useState(null);
  const [visit, setVisit] = useState(null);
  const [familyToShowHistory, setFamilyToShowHistory] = useState(null);
  const [familyToShowRecords, setFamilyToShowRecords] = useState([]);
  const [historyFilters, setHistoryFilters] = useState({
    page: 0,
    limit: 75,
    family_id: null,
  });
  
  const filterForm = useForm({
    defaultValues,
  });

  const {
    getFamily,
    getFamilyVisitsStatistics,
    getPatientAndFamilyRecords,
    getFamilyHealthInformationStatistics,
  } = useFamily();

  const enabledQueryCondition = !!(
    filters?.company_id &&
    ((filters.initial_date && filters.final_date) || filters.moth)
  );

  const familyQuery = useQuery(
    ["familyWithCommorbities", page, filters],
    () =>
      getFamily({
        limit: 30,
        page: page,
        microarea_id: filters?.microarea_id,
        family_record_initial_date: filters?.initial_date + "T00:00:00",
        family_record_final_date: filters?.final_date + "T23:59:59",
        active: true,
        company_id: filters?.company.is_master ? null : filters?.company.id,
        family_record_visit_conclusion: filters?.family_record_visit_conclusion,
        patient_health_information: filters?.patient_health_information,
        team_id: filters?.team_id,
      }),
    {
      enabled: enabledQueryCondition,      
      onSuccess: (data) => {
        if (page === 0) {
          setFamilies(data.items);
        } else {
          setFamilies((current) => [...current, ...data.items]);
        }
      },
    }
  );

  const conclusionStats = useQuery(
    ["families-visit-conclusion-stats", filters],
    () =>
      getFamilyVisitsStatistics({
        company_id: filters?.company.is_master ? null : filters?.company.id,
        microarea_id: filters?.microarea_id,
        team_id: filters?.team_id,
        initial_date: filters?.initial_date,
        final_date: filters?.final_date,
      }),
    {
      enabled: enabledQueryCondition,      
    }
  );

  const healthStats = useQuery(
    ["families-health-info-stats", filters],
    () =>
      getFamilyHealthInformationStatistics({
        company_id: filters?.company.is_master ? null : filters?.company.id,
        microarea_id: filters?.microarea_id,
        team_id: filters?.team_id,
        initial_date: filters?.initial_date,
        final_date: filters?.final_date,
      }),
    {
      enabled: enabledQueryCondition,      
    }
  );

  const familyHistory = useQuery(
    ["familyAndPatientRecords", historyFilters],
    () => getPatientAndFamilyRecords(historyFilters),
    {
      onSuccess: (data) => {
        setFamilyToShowRecords(data.items);
      },
      onError: (error) => {
        notify(error.message, "error");
        setFamilyToShowRecords([]);
      },
      enabled: !!historyFilters.family_id,
    }
  );

  const section = {
    icon: <PeopleAltRounded color="white" fontSize="large" />,
    title: `Famílias`,
    paginated: true,
  };

  const shapeFeedback = [
    {
      type: "description",
      label: "Nome",
      valueKey: "patient.name",
      formatter: (name, row) => row.social_name || name || "Não Informado",
    },
    {
      type: "description",
      label: "Nome da mãe",
      valueKey: "patient.mother_name",
      formatter: (sus_card) => sus_card || "Não Informado",
    },
    {
      type: "description",
      label: "Data de Nascimento",
      valueKey: "patient.birth_date",
      formatter: (birth) => {
        const formattedDate = parseISO(birth);
        return isValid(formattedDate)
          ? format(formattedDate, `dd/MM/yyyy - '${convertDateToBirthday(birth)}'`)
          : "Não Recebido";
      },
    },
    {
      type: "description",
      label: "CPF",
      valueKey: "patient.physic_national",
      formatter: (cpf) =>
        formatWithMask({
          text: cpf,
          mask: RegexOf.cpf,
        }).masked || "Não Informado",
    },
    {
      type: "description",
      label: "CNS",
      valueKey: "patient.sus_card",
      formatter: (sus_card) => sus_card || "Não Informado",
    },
    {
      type: "description",
      label: "Parentesco",
      valueKey: "kinship_id",
      formatter: (kinship_id) => kinship_id || "Não Informado",
    },
  ];

  const visitShapeFeedback = [
    {
      type: "header",
      label: "#1 Dados da visita",
    },
    {
      type: "description",
      label: "Propriedade",
      valueKey: "property_type",
      formatter: (property) => property || "Não Informado",
    },
    {
      type: "description",
      label: "Nome",
      valueKey: "patient.name",
      formatter: (name) => name || "Não Informado",
      hidden: (_, row) => (row?.type === "family" ? true : false),
    },
    {
      type: "description",
      label: "Nascimento/Idade",
      valueKey: "patient.birth_date",
      formatter: (birth) =>
        (isValid(new Date(birth)) &&
          format(parseISO(birth), "dd/MM/yyyy") + " - " + convertDateToBirthday(birth)) ||
        "Não Informado",
      hidden: (_, row) => (row?.type === "family" ? true : false),
    },
    {
      type: "description",
      label: "Data da Visita",
      valueKey: "createdAt",
      formatter: (createdAt) => {
        const formattedDate = parseISO(createdAt);
        return isValid(formattedDate)
          ? format(formattedDate, "dd/MM/yyyy 'às' HH:mm")
          : "Não Recebido";
      },
    },
    {
      type: "description",
      label: "Última atualização",
      valueKey: "updatedAt",
      formatter: (createdAt) => {
        const formattedDate = parseISO(createdAt);
        return isValid(formattedDate)
          ? format(formattedDate, "dd/MM/yyyy 'às' HH:mm")
          : "Não Recebido";
      },
    },
    {
      type: "description",
      label: "Visita Compartilhada",
      valueKey: "shared_visit",
      formatter: (shared_visit) => (shared_visit ? "Sim" : "Não"),
    },
    {
      type: "description",
      label: "Motivos da Visita",
      valueKey: "visit_reasons.visit_type",
      formatter: (bs) => (bs?.length ? bs.join(", ") : "Não informado"),
    },
    {
      type: "description",
      label: "Busca Ativa",
      valueKey: "visit_reasons.active_search",
      hidden: (bs, row) => {
        return row?.type === "family" ? true : false;
      },
      formatter: (bs) => (bs?.length ? bs.join(", ") : "Não informado"),
    },
    {
      type: "description",
      label: "Acompanhamento",
      valueKey: "visit_reasons.follow_up",
      hidden: (fl, row) => {
        return row?.type === "family" ? true : false;
      },
      formatter: (fl) => (fl && fl?.length ? fl.join(", ") : "Não informado"),
    },
    {
      type: "description",
      label: "Controle Vetorial",
      valueKey: "visit_reasons.env_control_and_vector",
      hidden: (_, row) => (row?.type === "patient" ? true : false),
      formatter: (bs) => (bs?.length ? bs.join(", ") : "Não informado"),
    },
    {
      type: "description",
      label: "Membros Presentes",
      valueKey: "members_present_name",
      hidden: (_, row) => (row?.type === "patient" ? true : false),
      formatter: (fm) => (fm?.length ? `Qnt ( ${fm.length} ) ` + fm.join(", ") : "Não informado"),
    },
    {
      type: "description",
      label: "Notas",
      valueKey: "notes",
      formatter: (notes) => notes || "Não informado",
    },
    {
      type: "header",
      label: "#2 Aferição",
      hidden: (_, row) => (row?.type === "family" ? true : false),
    },
    {
      type: "description",
      label: "Altura",
      valueKey: "clinical_assessment.height",
      formatter: (data) => (data ? data + " CM" : "Não informado"),
      hidden: (_, row) => (row?.type === "family" ? true : false),
    },
    {
      type: "description",
      label: "Peso",
      valueKey: "clinical_assessment.weight",
      formatter: (data) => (data ? data + " KG" : "Não informado"),
      hidden: (_, row) => (row?.type === "family" ? true : false),
    },
    {
      type: "description",
      label: "Temperatura no momento",
      valueKey: "clinical_assessment.temperature",
      formatter: (data) => (data ? data + " ˚C" : "Não informado"),
      hidden: (_, row) => (row?.type === "family" ? true : false),
    },
    {
      type: "description",
      label: "Pressão Arterial",
      valueKey: "clinical_assessment.blood_pressure",
      formatter: (data) => data || "Não informado",
      hidden: (_, row) => (row?.type === "family" ? true : false),
    },
    {
      type: "description",
      label: "Glicemia",
      valueKey: "clinical_assessment.glycemia",
      formatter: (data) => (data ? data + "mg/dL" : "Não informado"),
      hidden: (_, row) => (row?.type === "family" ? true : false),
    },
    {
      type: "description",
      label: "Momento da Coleta",
      valueKey: "clinical_assessment.glycemia_collected_type ",
      formatter: (data) => data || "Não informado",
      hidden: (_, row) => (row?.type === "family" ? true : false),
    },
  ];

  const handleVisitConclusion = (familyRecords) => {
    let colorObject = {
      backgroundColor: visitConclusionStatusColors[0].backgroundColor,
      text: visitConclusionStatusColors[0].text,
      color: visitConclusionStatusColors[0].color,
    };

    if (familyRecords.length === 0) {
      return colorObject;
    }

    const mostRecentFamilyRecord = familyRecords[0];

    const status = visitConclusionStatusColors.find(
      (visitConclusion) => visitConclusion.key === mostRecentFamilyRecord.visit_conclusion
    );

    colorObject = {
      ...status,
    };

    return colorObject;
  };

  const handleMemberHealthConditions = (members) => {
    const healthConditionsToRender = {};

    for (const member of members) {
      const memberDifference = differenceInYears(new Date(), parseISO(member?.patient?.birth_date));
      if (
        (member.patient.individual_register &&
          member.patient.individual_register[0]?.health_information) ||
        memberDifference < 1
      ) {
        const healthConditions = Object.entries(
          member.patient?.individual_register[0]?.health_information || {}
        );

        if (memberDifference < 1) {
          if (!healthConditionsToRender["less_than_1_year"]) {
            healthConditionsToRender["less_than_1_year"] = [];
          }
        }

        for (const [key, value] of healthConditions) {
          if (value === true) {
            if (!healthConditionsToRender[key]) {
              healthConditionsToRender[key] = [member];
            } else if (healthConditionsToRender[key]) {
              healthConditionsToRender[key].push(member);
            }
          }
        }
      }
    }

    return healthConditionsToRender;
  };

  return (
    <>
      <DialogMedium
        fullWidth
        maxWidth={"md"}
        title={
          healthCondtion?.healthCondtion
            ? `${
                healthConditionsStatusColors.find(
                  (status) => status.key === healthCondtion?.healthCondtion
                )?.text
              }`
            : "Membros da Família"
        }
        open={!!healthCondtion}
        handleClose={() => {
          setHealthCondition(null);
        }}
      >
        {healthCondtion?.members.map((member, index) => {
          const memberHealthCondtions = Object.entries(
            member.patient.individual_register[0]?.health_information || {}
          );

          const doesMemberHasHealthCondtions = memberHealthCondtions.find(
            ([key, value]) => key === healthCondtion?.healthCondtion && value === true
          );

          const patientLessThanOneYear = isValid(new Date(member?.patient?.birth_date))
            ? differenceInYears(new Date(), parseISO(member?.patient?.birth_date)) < 1
            : false;

          if (
            (healthCondtion?.healthCondtion && doesMemberHasHealthCondtions) ||
            (healthCondtion?.healthCondtion === "less_than_1_year" && patientLessThanOneYear) ||
            !healthCondtion?.healthCondtion
          )
            return (
              <>
                <Box
                  sx={{
                    flex: 1,
                    width: "100%",
                  }}
                >
                  <DynamicFeedback shape={shapeFeedback} data={member} />
                  {index !== healthCondtion.members.length - 1 && (
                    <Divider
                      sx={{
                        marginBottom: "2px",
                        width: "100%",
                      }}
                    />
                  )}
                </Box>
              </>
            );
          return <></>;
        })}
      </DialogMedium>
      <DialogMedium
        fullWidth
        maxWidth={"md"}
        title={"Detalhes da visita"}
        open={!!visit}
        handleClose={() => {
          setVisit(null);
        }}
      >
        <DynamicFeedback shape={visitShapeFeedback} data={visit} />
      </DialogMedium>
      <DialogMedium
        fullWidth
        maxWidth={"lg"}
        title={
          "Histórico des visitas familiares / individuais: " + familyToShowHistory?.family_name
        }
        open={!!familyToShowHistory}
        handleClose={() => {
          setFamilyToShowRecords([]);
          setHistoryFilters({
            page: 0,
            limit: 75,
            family_id: null,
          });
          filterForm.reset(defaultValues);
          setFamilyToShowHistory(null);
        }}
      >
        <Grid
          container
          component={"form"}
          spacing={2}
          onSubmit={filterForm.handleSubmit((values) => {
            const newValues = { ...values };

            if (values.final_date && values.final_date) {
              newValues.initial_date = format(values.initial_date, "yyyy-MM-dd");
              newValues.final_date = format(values.final_date, "yyyy-MM-dd");
            }

            if (newValues.type?.value === "both") {
              newValues.type = ["family", "patient"];
            } else {
              newValues.type = newValues.type?.value;
            }

            if (newValues.visit_conclusion?.value === "all") {
              newValues.visit_conclusion = ["completed", "refused", "absentee"];
            } else {
              newValues.visit_conclusion = newValues.visit_conclusion?.value;
            }

            setHistoryFilters((currFilters) => ({
              ...currFilters,
              ...newValues,
              family_id: familyToShowHistory?.family_id,
            }));
          })}
        >
          <Grid item xs={3}>
            <AutocompleteField
              control={filterForm.control}
              name={"type"}
              label={"Tipo da visita"}
              options={[
                { describe: "Ambas", value: "both" },
                { describe: "Familiar", value: "family" },
                { describe: "Individual", value: "patient" },
              ]}
              optionLabelKey="describe"
            />
          </Grid>
          <Grid item xs={3}>
            <AutocompleteField
              control={filterForm.control}
              name={"visit_conclusion"}
              label={"Desfecho da visita"}
              options={[
                { describe: "Todos", value: "all" },
                { describe: "Realizada", value: "completed" },
                { describe: "Recusada", value: "refused" },
                { describe: "Ausente", value: "absentee" },
              ]}
              optionLabelKey="describe"
            />
          </Grid>
          <Grid item xs={3}>
            <DateField control={filterForm.control} name={"initial_date"} label={"Data Inicial"} />
          </Grid>
          <Grid item xs={3}>
            <DateField
              control={filterForm.control}
              name={"final_date"}
              maxDate={new Date()}
              label={"Data Final"}
            />
          </Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <Button type="submit">Filtrar</Button>
          </Grid>
          <Grid item xs={12}>
            <TableFilter
              columns={complementTableColumns}
              data={familyToShowRecords}
              actions={true}
              actionsTypes={["view"]}
              actionHandleView={(_, row) => {
                setVisit(row);
              }}
              noFilter
            />
          </Grid>
          <Grid item xs={12}>
            <TablePagination
              count={familyHistory.data?.totalItems || 0}
              component="div"
              page={page}
              onPageChange={(_, newPage) => {
                setHistoryFilters((currFilters) => ({
                  ...currFilters,
                  page: newPage,
                }));
              }}
              rowsPerPage={historyFilters.limit}
              rowsPerPageOptions={[30, 45, 50, 75]}
              onRowsPerPageChange={({ target }) => {
                setHistoryFilters((currFilters) => ({
                  ...currFilters,
                  page: 0,
                  limit: parseInt(target.value, 10),
                }));
              }}
            />
          </Grid>
        </Grid>
      </DialogMedium>
      <FormFilters
        onSubmit={(values) => {
          setPage(0);
          onSubmitFilters({ ...filters, ...values });
        }}
        values={initialValues}
        filtersToHideIndex={[0, 1]}
        limitDate
      />
      <Grid container xs={12} spacing={2}>
        <Grid item xs={12}>
          <Divider
            sx={{
              margin: "10px 0px",
            }}
          />
        </Grid>

        {enabledQueryCondition ? (
          <>
            <Grid item container xs={12}>
              <Grid item xs={12}>
                <ColorInfoLabels
                  labels={visitConclusionStatusColors}
                  handleFilter={(value) => {
                    setPage(0);
                    setFilters((currentFilters) => ({
                      ...currentFilters,
                      family_record_visit_conclusion: value,
                    }));
                  }}
                  text="Desfecho da última visita"
                  quantity={conclusionStats.data}
                />
              </Grid>
              <Grid item xs={12}>
                <ColorInfoLabels
                  labels={healthConditionsStatusColors}
                  text="Condições de Saúde"
                  handleFilter={(value) => {
                    setPage(0);
                    setFilters((currentFilters) => ({
                      ...currentFilters,
                      patient_health_information: value,
                    }));
                  }}
                  quantity={healthStats.data}
                />
              </Grid>
            </Grid>
            <Grid item container xs={12} spacing={1}>
              <>
                <Grid item xs={12}>
                  <SectionHeader
                    icon={section.icon}
                    title={
                      <Box
                        component={"span"}
                        sx={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography variant="subtitle1" fontWeight={600}>
                          {section.title}
                        </Typography>
                        <Typography variant="subtitle1" fontWeight={600}>
                          Exibindo {families.length} /{" "}
                          {familyQuery?.data?.totalItems || "Carregando..."}
                        </Typography>
                      </Box>
                    }
                    sx={{
                      backgroundColor: (theme) => theme.palette.secondary.main,
                      color: "white",
                    }}
                  />
                </Grid>
              </>
              <PaginatedScrollList
                sx={{
                  marginTop: 2,
                  marginLeft: 1,
                }}
                maxHeight="95vh"
                totalPage={familyQuery?.data?.total || 0}
                page={page}
                loading={familyQuery?.isLoading}
                handlePage={setPage}
                componentContainer={(data, component) => (
                  <Grid xs={12} container spacing={2}>
                    {data.map((value, index) => component(value, index))}
                  </Grid>
                )}
                component={(item, index) => (
                  <Grid item xs={12} sm={6} md={4} lg={1.5} key={index}>
                    <DetailCard
                      title={
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            justifyContent: "center",
                            maxWidth: "120px",
                            gap: 1,
                            marginTop: 2,
                          }}
                        >
                          <Tooltip title="Microarea">
                            <Chip
                              size="small"
                              sx={{
                                background: handleVisitConclusion(item.family_records).color,
                                "& .MuiChip-icon": {
                                  color: "white",
                                },
                                color: "white",
                              }}
                              clickable
                              icon={<PlaceRounded />}
                              label={item?.domiciliary?.microarea?.name || "FA"}
                            />
                          </Tooltip>
                          <Tooltip title="Membros">
                            <Chip
                              size="small"
                              sx={{
                                background: handleVisitConclusion(item.family_records).color,
                                color: "white",
                                "& .MuiChip-icon": {
                                  color: "white",
                                },
                              }}
                              clickable
                              icon={<PeopleAltRounded />}
                              onClick={() => {
                                setHealthCondition({
                                  healthCondtion: "",
                                  members: item.members,
                                });
                              }}
                              label={item.members.length}
                            />
                          </Tooltip>
                          <Tooltip title="Histórico des visitas familiares / individuais">
                            <Chip
                              size="small"
                              sx={{
                                background: handleVisitConclusion(item.family_records).color,
                                color: "white",
                                "& .MuiChip-icon": {
                                  color: "white",
                                },
                              }}
                              clickable
                              icon={<HistoryRounded />}
                              onClick={() => {
                                setFamilyToShowHistory({
                                  family_id: item.id,
                                  family_name: item.name,
                                });
                                filterForm.setValue("initial_date", new Date(filters.initial_date));
                                filterForm.setValue("final_date", new Date(filters.final_date));
                              }}
                              label={"His. Visitas"}
                            />
                          </Tooltip>
                          <Tooltip title={handleVisitConclusion(item.family_records).text}>
                            <Chip
                              size="small"
                              sx={{
                                background: handleVisitConclusion(item.family_records).color,
                                "& .MuiChip-icon": {
                                  color: "white",
                                },
                                color: "white",
                              }}
                              clickable
                              onClick={() => {
                                if (item?.family_records?.length) {
                                  setVisit(item.family_records[0]);
                                }
                              }}
                              icon={<InfoRounded />}
                              label={handleVisitConclusion(item.family_records).text}
                            />
                          </Tooltip>
                        </Box>
                      }
                      content={item.name}
                      footer={() => {
                        const membersWithConditions = handleMemberHealthConditions(item.members);

                        return (
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                justifyContent: "center",
                                padding: "10px 10px",
                                gap: "10px",
                                width: "100%",
                                flexWrap: "wrap",
                                height: "100%",
                              }}
                            >
                              {healthConditionsStatusColors.map(({ icon, color, key, text }) => {
                                const hasConditionToShow = membersWithConditions[key];
                                if (hasConditionToShow) {
                                  return (
                                    <Tooltip
                                      key={key}
                                      title={
                                        <Box>
                                          <Typography fontSize={10} textAlign={"center"}>
                                            {text}
                                          </Typography>
                                          {membersWithConditions[key].map(({ patient }, index) => (
                                            <Typography
                                              key={`${patient.name}-${index}`}
                                              fontSize={10}
                                              whiteSpace={"nowrap"}
                                            >
                                              {patient.name} -{" "}
                                              {formatDateString(patient.birth_date)}
                                            </Typography>
                                          ))}
                                        </Box>
                                      }
                                    >
                                      <IconButton
                                        sx={{
                                          background: color,
                                          width: "28px",
                                          maxHeight: "28px",
                                          "&:hover": {
                                            opacity: 0.8,
                                            background: color,
                                          },
                                        }}
                                        onClick={() => {
                                          setHealthCondition({
                                            healthCondtion: key,
                                            members: item.members,
                                          });
                                        }}
                                      >
                                        {icon}
                                      </IconButton>
                                    </Tooltip>
                                  );
                                }
                                return null;
                              })}
                            </Box>
                          </>
                        );
                      }}
                      handleClick={() => {}}
                      sx={{
                        backgroundColor: handleVisitConclusion(item.family_records).backgroundColor,
                        color: handleVisitConclusion(item.family_records).color,
                        borderRadius: 1.5,
                        height: "100%",
                      }}
                    />
                  </Grid>
                )}
                data={families}
              />
            </Grid>
          </>
        ) : (
          <Typography
            sx={{
              marginBottom: "10px",
              color: (theme) => theme.palette.primary.light,
              fontWeight: "medium",
              maxWidth: "700px",
              marginLeft: 2,
            }}
          >
            É necessário escolher uma (data inicial e final) ou mês, unidade e uma ou mais
            microareas para ver os dados.
          </Typography>
        )}
      </Grid>
    </>
  );
}

export default Beehive;
