import Tooth from "./Tooth";
import ToothFaces from "./ToothFaces";
import TeethArrayObject, { archViewBox } from "./TeethArrayObject";

export default function TopArch({
  handleCrownClick,
  handleRootClick,
  handleFaceClick,
  teethWithValue,
  type,
  size,
  disabled,
  disableFaces,
  svg,
}) {
  return (
    <>
      <svg width={size} viewBox={archViewBox[type]} fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="@TopArcada">
          {TeethArrayObject?.[type]?.top?.map((item, index) => (
            <Tooth
              onCrownClick={handleCrownClick}
              onRootClick={handleRootClick}
              dCrown={item.dCrown}
              dRoot={item.dRoot}
              id={item.id}
              strokeWidth={item.strokeWidth}
              badgePosition={item.badgePosition}
              hasEvolutions={teethWithValue?.[item.id]}
              key={index}
              disabled={disabled}
              svg={svg}
            />
          ))}
        </g>
      </svg>

      {!svg && (
        <svg
          width={size}
          viewBox={archViewBox.faces}
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g id="@TopArcadaFaces">
            {TeethArrayObject?.[type]?.top?.map((item, index) => (
              <ToothFaces
                position={{ cx: 42 * index }}
                handleFaceClick={handleFaceClick}
                tooth_id={item.id}
                values={teethWithValue?.[item.id]?.faces}
                key={`tooth-faces-${index}`}
                disabled={disabled}
                disableClick={disableFaces}
                top
              />
            ))}
          </g>
        </svg>
      )}
    </>
  );
}
