import React, { useMemo, useState } from "react";
import { SubmitButton } from "../../../../components";
import { FormRadio, FormSelectWithPaginate, FormTextField } from "../../../../components/Form";
import { useMutation, useQueryClient } from "react-query";
import { Formik, Form } from "formik";
import { Grid, Typography, useTheme } from "@mui/material";
import Yup from "../../../../config/yup";
import TableFilter from "../../../../components/Table/TableFilter";
import { useMedicalRecords, useProcedures } from "../../../../service";
import { evolutionsPDF } from "../../../../pdfModels";
import useSignaturePassword from "../../../../hooks/useSignaturePassword";
import useNotifier from "../../../../hooks/useNotifier";
import { pdfToBase64, verifyAttendanceFlow } from "../../../../utils";

function PatientDischargeForm({
  formType,
  title,
  patient,
  prescription,
  attendance,
  valuesToEdit,
  handleClose,
  needProcedure,
  intervention,
}) {
  const theme = useTheme();
  const notify = useNotifier();
  const [procedures, setProcedures] = useState(valuesToEdit?.procedures || []);
  const enabledFields = useMemo(() => {
    return {
      order_number: formType === "lab",
      tag: formType === "lab",
      demand_type: formType === "xray",
      evolution_file_type: formType === "medications_on_site",
      chief_complaint: formType === "xray",
      procedures:
        !["medications", "medications_on_site", "procedure"].includes(formType) || needProcedure,
    };
  }, [formType]);

  const queryClient = useQueryClient();
  const { getProcedures } = useProcedures();
  const { postMedicalRecords, patchMedicalRecords } = useMedicalRecords();
  const [loading, setLoading] = useState(false);
  const postMedicalRecordsMutation = useMutation(postMedicalRecords);
  const patchMedicalRecordsMutation = useMutation(patchMedicalRecords);
  const verifySignature = useSignaturePassword(setLoading);

  async function handleSubmit(values, { resetForm }) {
    verifySignature(async () => {
      const newValues = Object.entries(values).reduce((acc, [key, value]) => {
        return key !== "procedure" ? { ...acc, [key]: value || null } : acc;
      }, {});

      const evalutionData = {
        ...newValues,
        type: formType,
        procedures_ids: procedures.length ? procedures : null,
        attendance_id: attendance?.id || null,
        prescription_id: prescription?.id || null,
        plan_id: intervention ? intervention.id_plan : null,
      };

      if (values.status === "finished") {
        const pdf = evolutionsPDF({ ...newValues, procedures }, patient, {
          title,
        });

        const base64 = await pdfToBase64(pdf);

        evalutionData.id = pdf.name.replace(".pdf", "");
        evalutionData.original_file_name = pdf.name;
        evalutionData.data = base64;
        evalutionData.signature_settings = {
          visible_sign_page: "*",
          visible_sign_x: 170,
          visible_sign_y: 595,
        };
      }
      if (!!valuesToEdit) {
        evalutionData.attendance_id = valuesToEdit.attendance_id;
        patchMedicalRecordsMutation.mutate(
          { id: valuesToEdit.id, data: evalutionData },
          {
            onSuccess(response) {
              notify(response.message, "success");
              setProcedures([]);
              handleClose();
              queryClient.invalidateQueries("medical-records");
            },
            onError(error) {
              notify(error.message, "error");
            },
          }
        );
      } else {
        postMedicalRecordsMutation.mutate(evalutionData, {
          onSuccess(response) {
            notify(response.message, "success");
            setProcedures([]);
            handleClose();
            queryClient.invalidateQueries("medical-records");
          },
          onError(error) {
            notify(error.message, "error");
          },
        });
      }
    });
  }

  function addProcedures(procedure, setFieldValue) {
    setProcedures((procedures) => [...procedures, procedure]);
    setFieldValue("procedure", null);
  }

  function removeProcedures(index) {
    const filteredProcedures = procedures.filter((_, order) => order !== index);
    setProcedures(filteredProcedures);
  }

  const validations = Yup.object().shape({
    status: Yup.string(),
    order_number: Yup.number().nullable(),
    tag: Yup.string()
      .when("status", {
        is: (status) => status === "finished" && enabledFields.tag,
        then: Yup.string().required("É requerido"),
      })
      .nullable(),
    demand_type: Yup.string()
      .when("status", {
        is: (status) => status === "finished" && enabledFields.demand_type,
        then: Yup.string().required("É requerido"),
      })
      .nullable(),
    evolution_file_type: Yup.string()
      .when("status", {
        is: (status) => status === "finished" && enabledFields.evolution_file_type,
        then: Yup.string().required("É requerido"),
      })
      .nullable(),
    notes: Yup.string()
      .when("status", {
        is: "finished",
        then: Yup.string().required("É requerido"),
      })
      .nullable(),
  });

  const initialValues = {
    order_number: valuesToEdit?.order_number || "",
    tag: valuesToEdit?.tag || "",
    chief_complaint: valuesToEdit?.chief_complaint || "",
    notes: valuesToEdit?.notes || "",
    demand_type: valuesToEdit?.demand_type || "",
    evolution_file_type: valuesToEdit?.evolution_file_type || "",
    procedure: valuesToEdit?.procedures || null,
    status: "filling",
  };

  const evolutionFileType = [
    { label: "Ambulatorial", value: "outpatient" },
    { label: "Eventual", value: "eventual" },
  ];
  const demandOptions = [
    { label: "Espontânea", value: "spontaneous" },
    { label: "Referenciada", value: "referred" },
    { label: "Ocorrência Policial", value: "police_report" },
    { label: "Acidente de trabalho", value: "workplace_accident" },
    { label: "Outros", value: "others" },
  ];

  const tableColumns = [
    {
      name: "Código",
      field: "tuss_code",
      type: "number",
    },
    {
      name: "Nome",
      field: "name",
      type: "text",
    },
  ];

  const proceduresFilters = {
    medications: {
      group_id: "0b3eaf6b-2cc2-49fc-aa54-97e1c82cad2f",
      type: "medications",
      filter: "active",
    },
    medications_on_site: {
      group_id: "0b3eaf6b-2cc2-49fc-aa54-97e1c82cad2f",
      type: "medications",
      filter: "active",
    },
    xray: {
      sub_group_id: "19e03424-fe43-41b8-bcb0-6e4e227da33f",
      type: "image",
      filter: "active",
    },
    lab: {
      type: "external_lab",
      filter: "active",
    },
    ecg: {
      sub_group_id: "256785aa-d678-4c44-9f49-1dcef06d4461",
      type: "image",
      filter: "active",
    },
    procedure: {
      type: "procedure",
      filter: "active",
    },
  };

  return (
    <>
      <Formik onSubmit={handleSubmit} initialValues={initialValues} validationSchema={validations}>
        {({ values, setFieldValue, errors, submitForm }) => (
          <Form>
            <Grid container spacing={3} marginTop="0.5rem">
              {enabledFields.order_number && (
                <Grid xs={3} item>
                  <FormTextField name="order_number" label="Número de Ordem" type="number" />
                </Grid>
              )}
              {enabledFields.tag && (
                <Grid xs={3} item>
                  <FormTextField name="tag" label="Etiqueta" required />
                </Grid>
              )}
              {enabledFields.evolution_file_type && (
                <Grid xs={12} item>
                  <FormRadio
                    name="evolution_file_type"
                    legend="Ficha de Evolução"
                    radios={evolutionFileType}
                    keys={["label", "value"]}
                    row={true}
                    required
                  />
                </Grid>
              )}
              {enabledFields.demand_type && (
                <Grid xs={12} item>
                  <FormRadio
                    name="demand_type"
                    legend="Demanda"
                    radios={demandOptions}
                    keys={["label", "value"]}
                    row={true}
                    required
                  />
                </Grid>
              )}
              {enabledFields.chief_complaint && (
                <Grid xs={6} item>
                  <FormTextField
                    name="chief_complaint"
                    label="Queixa principal"
                    multiline
                    minRows={8}
                  />
                </Grid>
              )}
              <Grid xs={6} item>
                <FormTextField name="notes" label="Anotações" multiline minRows={8} required />
              </Grid>
              {enabledFields.procedures && !prescription && (
                <>
                  <Grid xs={12} item>
                    <Typography
                      sx={{
                        fontSize: 16,
                        fontWeight: 600,
                        color: theme.palette.primary.light,
                      }}
                    >
                      Exames e Procedimentos
                    </Typography>
                  </Grid>

                  <Grid lg={4} md={5} xs={6} item>
                    <FormSelectWithPaginate
                      name="procedure"
                      label="Exames/Procedimentos"
                      service={(params) =>
                        getProcedures({
                          ...params,
                          ...proceduresFilters[formType],
                          patient_id: patient?.id,
                          attendance_flow: verifyAttendanceFlow(attendance.risk_classification_id),
                        })
                      }
                      fields="describe"
                      searchBy="filters"
                      getOptionLabel={(data) => {
                        let label = data?.name;

                        if (data?.tuss_code) {
                          label = `${data?.tuss_code} - ${label}`;
                        }

                        return label;
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <SubmitButton
                      disabled={!values.procedure}
                      handleClick={() => addProcedures(values.procedure, setFieldValue)}
                      loading={false}
                    >
                      Adicionar
                    </SubmitButton>
                  </Grid>
                  <Grid item xs={12}>
                    <TableFilter
                      data={procedures}
                      emptyMessage={""}
                      columns={tableColumns}
                      loading={false}
                      actions
                      actionsTypes={["delete"]}
                      actionHandleDelete={removeProcedures}
                    />
                  </Grid>
                </>
              )}
              <Grid item xs={12} display={"flex"} columnGap={2}>
                <SubmitButton
                  onClick={() => {
                    values.status = "filling";
                    submitForm();
                  }}
                  disabled={
                    (!procedures.length && !prescription && enabledFields.procedures) ||
                    postMedicalRecordsMutation.isLoading ||
                    patchMedicalRecordsMutation.isLoading ||
                    loading
                  }
                  loading={postMedicalRecordsMutation.isLoading || loading}
                >
                  Salvar
                </SubmitButton>
                <SubmitButton
                  onClick={() => {
                    values.status = "finished";
                    submitForm();
                  }}
                  disabled={
                    (!procedures.length && !prescription && enabledFields.procedures) ||
                    postMedicalRecordsMutation.isLoading ||
                    patchMedicalRecordsMutation.isLoading ||
                    loading
                  }
                  loading={
                    postMedicalRecordsMutation.isLoading ||
                    patchMedicalRecordsMutation.isLoading ||
                    loading
                  }
                >
                  Finalizar
                </SubmitButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </>
  );
}

export default PatientDischargeForm;
