import { Helmet } from "react-helmet";
import { BoxContainer } from "../../components";
import { Box, Grid, TablePagination, Typography } from "@mui/material";
import TableFilter from "../../components/Table/TableFilter";
import { useDomiciliaries } from "../../service";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import { format, parseISO } from "date-fns";
import { TITLE_NAME } from "../../config/config";
import { useNotifier } from "../../hooks";
import { Home } from "@mui/icons-material";
import { useEffect, useState } from "react";

function TerritorialHistoric() {
  const { getDomiciliaryHistoric, getDomiciliary } = useDomiciliaries();
  const { id } = useParams();
  const navigate = useNavigate();
  const notify = useNotifier();
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);

  const territorialHistoric = useQuery(["territory", [id, page, limit]],
    () => getDomiciliaryHistoric(id, {page: page, limit: limit}),
    {
      enabled: !!id,
      refetchOnWindowFocus: false,
      retry: false,
      onError: (error) => {
        notify(error.message, "error");
      }
    }
  );

  const domiciliary = useQuery(["territorial-historic", id],
    () => getDomiciliary(id),
    {
      enabled: !!id,
      retry: false,
      refetchOnWindowFocus: false
    });

  const domiciliaryData = domiciliary?.data;

  const columns = [
    {
      field: "employee.name",
      name: "Nome do profissional",
      width: 250,
      type: "string",
    },
    {
      field: "employee.sus_card",
      name: "CNS do responsável",
      width: 150,
      type: "string",
      use: (value) => !!value && value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4"),
    },
    {
      field: "team.ine",
      name: "INE",
      width: 100,
      type: "string",
    },
    {
      field: "microarea.name",
      name: "Microárea",
      width: 250,
      type: "string",
    },
    {
      field: "createdAt",
      name: "Data",
      width: 100,
      type: "date",
      use: (value) => !!value && format(parseISO(value), "dd/MM/yyyy HH:mm")
    },
  ];

  useEffect(() => {
    territorialHistoric.refetch();
  }, [page, limit]);

  return (
    <>
      <Helmet >
        <title> {TITLE_NAME} | Histórico de ficha territorial </title>
      </Helmet>

      <BoxContainer title="Histórico de ficha territorial">
        <Box >
          <Grid item x2={12}>
            <Typography
              variant="h6"
              color="secondary"
              sx={{
                marginBottom: "1rem",
                fontWeight: "bold",
                display: "flex",
                alignItems: "center",
                gap: "0.5rem"
              }}
            >
              <Home sx={{ fontSize: "2rem" }} />
              {domiciliaryData?.address_location_stay?.address?.street}
            </Typography>
          </Grid>

          <Grid xs={12}>
            <Grid item xs={4}>
              <Typography color="secondary">Número: {domiciliaryData?.address_location_stay?.number}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="secondary">Complemento: {domiciliaryData?.address_location_stay?.complement}</Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography color="secondary">
                Municipio: {domiciliaryData?.address_location_stay?.address?.county} ({domiciliaryData?.address_location_stay?.address?.uf})
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Grid item xs={12} sx={{ paddingTop: 3 }}>
          <TableFilter
            noFilter
            data={territorialHistoric.data?.items || []}
            columns={columns}
            actions
            actionsTypes={["view"]}
            actionHandleView={(i) => {
              navigate(`/cds/territorial/register/${territorialHistoric.data.items[i].id}?isHistoric=true`);
            }}
          />
        </Grid>

        <Box >
          <Grid item xs={12} sx={{ paddingTop: 3 }}>
            <TablePagination
              count={territorialHistoric.data?.totalItems || 0}
              component="div"
              page={page}
              onPageChange={(_, newPage) => {
                setPage(newPage);
              }}
              rowsPerPage={limit}
              rowsPerPageOptions={[5, 10, 20, 40, 75]}
              onRowsPerPageChange={(event) => {
                setLimit(parseInt(event.target.value, 10));
                setPage(0);
              }}
            />
          </Grid>
        </Box>

      </BoxContainer>
    </>
  )
}

export default TerritorialHistoric;