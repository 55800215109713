import React, { useState, useContext } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { styled } from "@mui/system";
//Components
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import ProfileMenu from "../ProfileMenu/ProfileMenu";
import ChangePassword from "../../helper/ChangePassword/ChangePassword";
import {
  Box,
  List,
  CssBaseline,
  Typography,
  Divider,
  ListItemIcon,
  ListItemText,
  IconButton,
  Collapse,
  Toolbar,
  ListItemButton,
} from "@mui/material";

//Icons
import {
  MenuRounded,
  Share,
  FaceRounded,
  ExpandLessRounded,
  ExpandMoreRounded,
  AssignmentIndRounded,
  AssignmentRounded,
  HomeRounded,
  ListAltRounded,
  InsertInvitationRounded,
  ArrowRightRounded,
  ManageSearchRounded,
  SettingsRounded,
  CloseRounded,
  AnalyticsRounded,
  VaccinesRounded,
  TuneRounded,
  TextSnippet,
  PendingActionsRounded,
} from "@mui/icons-material";
import { ReactComponent as TPlusHealth } from "../../assets/svg/TPlusHealth.svg";
import { useTheme } from "@emotion/react";
import usePermissions from "../../config/usePermissions";
import { AuthContext } from "../../contexts/AuthContext";
import { useCallback } from "react";
import { BACK_IPS } from "../../config/config";
import useSignaturePassword from "../../hooks/useSignaturePassword";

const drawerWidth = 300;
const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(7.2)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
const MyListItem = styled(ListItemButton)(({ theme }) => ({
  "&.Mui-selected": {
    background: "rgba(106, 87, 238, 0.1)",
    "&:hover": {
      background: "rgba(106, 87, 238, 0.1)",
    },
  },
}));
const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    // width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));
const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  backgroundColor: theme.palette.primary,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
const MyToolbar = styled(Toolbar)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
});
const Toggle = styled("div")({
  display: "flex",
  alignItems: "center",
});
// const User = styled("div")({
//   display: "flex",
//   alignItems: "center",
//   gap: "20px",
// });
const AvatarContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});
const Footer = styled("div")({
  display: "flex",
  position: "fixed",
  bottom: 0,
  marginTop: 20,
  zIndex: 50,
  padding: 10,
  background: "#F5FBFF",
  width: "100%",
  marginLeft: "-25px",
  color: "white",
});
export default function PageWrapper({ children }) {
  const theme = useTheme();
  const [open, setOpen] = useState(false);
  const [openSchedule, setOpenSchedule] = useState(false);
  const [openAttendance, setOpenAttendance] = useState(false);
  const [openCDS, setOpenCDS] = useState(false);
  const [openVaccine, setOpenVaccine] = useState(false);
  const [openProgram, setOpenProgram] = useState(false);
  const [openCockPit, setOpenCockPit] = useState(false);
  const [openReports, setOpenReports] = useState(false);
  const [activeMenuItem, setActiveMenuItem] = useState();
  let navigate = useNavigate();
  const handleDrawerOpen = () => {
    setOpen(!open);
    if (open) {
      setOpenSchedule(false);
      setOpenAttendance(false);
      setOpenSchedule(false);
      setOpenVaccine(false);
      setOpenProgram(false);
      setOpenCockPit(false);
      setOpenReports(false);
      setOpenCDS(false);
    }
  };
  const closeOnContainerClick = () => {
    setOpen(false);
    setOpenSchedule(false);
    setOpenAttendance(false);
    setOpenVaccine(false);
    setOpenSchedule(false);
    setOpenProgram(false);
    setOpenCockPit(false);
    setOpenReports(false);
    setOpenCDS(false);
  };
  const routes = [
    {
      name: "Inicio",
      link: "/",
      icon: <HomeRounded color="primary" />,
      subRoutes: [],
      eventClick: "handleNotCollapse",
      collapseRoute: "openHome",
    },
    {
      name: "Histórico Paciente",
      link: "/patient",
      icon: <FaceRounded color="primary" />,
      subRoutes: [],
      eventClick: "handleNotCollapse",
      collapseRoute: "openPatient",
    },
    {
      name: "Agenda",
      icon: <InsertInvitationRounded color="primary" />,
      subRoutes: [
        {
          name: "Consulta de Agenda",
          link: "/schedule/consultation",
        },
        {
          name: "Cadastro de Escala",
          link: "/schedule/scales",
        },
        {
          name: "Agendamento",
          link: "/schedule/scheduling",
        },
        {
          name: "Cadastro Profissional",
          link: "/schedule/employee",
        },
        {
          name: "Agendamentos Integrados",
          link: "/schedule/integration",
        },
        {
          name: "Gestão de Mensageria",
          link: "/schedule/whatsapp-schedulings",
        },
        // {
        //   name: "Regulação",
        //   link: "/schedule/regulation",
        // },
      ],
      eventClick: "handleOpenSchedule",
      collapseRoute: "openSchedule",
    },
    {
      name: "CDS",
      icon: <TextSnippet color="primary" />,
      subRoutes: [
        { name: "Ficha de cadastro individual", link: "/cds/individual" },
        { name: "Ficha de Cadastro Domiciliar/Territorial", link: "/cds/territorial" },
      ],
      eventClick: "handleOpenCDS",
      collapseRoute: "openCDS",
    },

    {
      name: "Atendimentos",
      icon: <AssignmentIndRounded color="primary" />,
      subRoutes: [
        {
          name: "Recepção",
          link: "/attendance/patientUnregistered",
        },
        {
          name: "Atendimento Inicial",
          link: "/attendance/initial",
        },
        {
          name: "Exclusão de Atendimento",
          link: "/attendance/delete",
        },
        {
          name: "Cadastro Paciente",
          link: "/attendance/patient",
        },
        {
          name: "Cadastro Família",
          link: "/attendance/family",
        },
        {
          name: "Confirmação Atend.",
          link: "/attendance/confirm",
        },
        {
          name: "Monitoramento",
          link: "/attendance/monitoring",
        },
        {
          name: "Referência / Contrarreferência",
          link: "/attendance/againstReference",
        },
        {
          name: "Classificação de risco",
          link: "/attendance/classification",
        },
        {
          name: "e-SUS Thrift",
          link: "/attendance/thrift",
        },
        {
          name: "Registro de faturamento",
          link: "/attendance/billing",
        },
        {
          name: "Anotações",
          link: "/attendance/general-annotations",
        },
      ],
      eventClick: "handleOpenAttendance",
      collapseRoute: "openAttendance",
    },
    {
      name: "Vacinas",
      icon: <VaccinesRounded color="primary" />,
      subRoutes: [
        {
          name: "Fabricante",
          link: "/vaccine/manufacturer",
        },
        {
          name: "Imunobiológicos",
          link: "/vaccine/immunizing",
        },
        {
          name: "Lotes",
          link: "/vaccine/lots",
        },
        {
          name: "Unidade Básica de Saúde",
          link: "/vaccine/unitHealth",
        },
        {
          name: "Campanha de Vacinação",
          link: "/vaccine/campaign",
        },
      ],
      eventClick: "handleOpenVaccine",
      collapseRoute: "openVaccine",
    },
    {
      name: "Programas de Saúde",
      icon: <AssignmentRounded color="primary" />,
      subRoutes: [
        {
          name: "Plano de Cuidado",
          link: "/program/carePlan",
        },
        {
          name: "Dicas de Saúde",
          link: "/program/Healthtips",
        },
        {
          name: "Cadastro Programas",
          link: "/program/-/",
        },
        {
          name: "Paciente Saúde",
          link: "/program/patientHealth",
        },
        {
          name: "Cadastro Equipe",
          link: "/program/team",
        },
        {
          name: "Microáreas",
          link: "/program/microarea",
        },
      ],
      eventClick: "handleOpenProgram",
      collapseRoute: "openProgram",
    },
    {
      name: "Fichas",
      link: "/files",
      icon: <ManageSearchRounded color="primary" />,
      subRoutes: [],
      eventClick: "handleNotCollapse",
      collapseRoute: "openFiles",
    },
    {
      name: "Relatórios",
      icon: <ListAltRounded color="primary" />,
      subRoutes: [
        // {
        //   name: "Gerenciais",
        //   link: "/reports/management",
        // },
        {
          name: "Operacionais",
          link: "/reports/operational",
        },
        {
          name: "Estatísticos",
          link: "/reports/statistical",
        },
        {
          name: "Cadastrais",
          link: "/reports/cadastral",
        },
        {
          name: "RPA - Produção",
          link: "/reports/RPA",
        },
      ],
      eventClick: "handleOpenReports",
      collapseRoute: "openReports",
    },
    {
      name: "CockPit",
      icon: <AnalyticsRounded color="primary" />,
      subRoutes: [
        {
          name: "Painel RPA",
          link: "/cockpit/siga",
        },
        {
          name: "Painel CEM",
          link: "/cockpit/cem",
        },
        {
          name: "Indicadores de Operação",
          link: "/cockpit/operationIndicator",
        },
        {
          name: "Indicadores de Agenda",
          link: "/cockpit/schedules",
        },
        {
          name: "Indicadores Previne",
          link: "/cockpit/previne",
        },
        {
          name: "Painel ACS",
          link: "/cockpit/acs",
        },
      ],
      eventClick: "handleOpenCockPit",
      collapseRoute: "openCockPit",
    },
    {
      name: "Conectividade",
      link: "/connectivity",
      icon: <Share color="primary" />,
      subRoutes: [],
      eventClick: "handleNotCollapse",
      collapseRoute: "openConnectivity",
    },
    {
      name: "Pendência de assinatura",
      link: "/pending-signature",
      icon: <PendingActionsRounded color="primary" />,
      subRoutes: [],
      eventClick: "handleNotCollapse",
      collapseRoute: "openPendingSignature",
    },
    {
      name: "Predefinições",
      link: "/presets",
      icon: <TuneRounded color="primary" />,
      subRoutes: [],
      eventClick: "handleNotCollapse",
      collapseRoute: "openPresets",
    },
    {
      name: "Configurações",
      link: "/settings",
      icon: <SettingsRounded color="primary" />,
      subRoutes: [],
      eventClick: "handleNotCollapse",
      collapseRoute: "openSettings",
    },
  ];
  const { changePassword, setChangePassword } = useContext(AuthContext);
  const permissions = usePermissions("all");
  const {
    name,
    company,
    specialty: { describe, cbo_code } = {
      describe: "",
      cbo_code: "",
    },
  } = JSON.parse(localStorage.getItem("user-data")) || {
    name: "",
    company: "",
    specialty: { describe: "", cbo_code: "" },
  };
  const signatureDialog = useSignaturePassword();
  const routesList = useCallback(() => {
    const hasPermission = (route) => !!permissions[route.link];
    const permitedRoutes = routes
      .map((route) => {
        if (route.link === "/") return route;
        if (route.subRoutes.length) {
          route.subRoutes = route.subRoutes.filter(hasPermission);
          return route.subRoutes.length ? route : 0;
        }
        return hasPermission(route) ? route : 0;
      })
      .filter((i) => i);
    return permitedRoutes;
  }, [permissions]);

  function openMenu(e) {
    if (!open) {
      setOpen(true);
    }
  }
  function variables(e) {
    return {
      openSchedule: openSchedule,
      openAttendance: openAttendance,
      openProgram: openProgram,
      openCockPit: openCockPit,
      openVaccine: openVaccine,
      openCDS: openCDS,
      openReports,
      handleOpenSchedule: () => {
        setOpenSchedule(!openSchedule);
        setOpenVaccine(false);
        setOpenAttendance(false);
        setOpenProgram(false);
        setOpenReports(false);
        setOpenCockPit(false);
        setOpenCDS(false);
        openMenu();
        setActiveMenuItem(e);
      },
      handleOpenAttendance: () => {
        setOpenAttendance(!openAttendance);
        setOpenVaccine(false);
        setOpenSchedule(false);
        setOpenProgram(false);
        setOpenReports(false);
        setOpenCockPit(false);
        setOpenCDS(false);
        openMenu();
        setActiveMenuItem(e);
      },
      handleOpenVaccine: () => {
        setOpenVaccine(!openVaccine);
        setOpenProgram(false);
        setOpenAttendance(false);
        setOpenSchedule(false);
        setOpenAttendance(false);
        setOpenReports(false);
        setOpenCockPit(false);
        setOpenCDS(false);
        openMenu();
        setActiveMenuItem(e);
      },
      handleOpenCDS: () => {
        setOpenCDS(!openCDS);
        setOpenAttendance(false);
        setOpenVaccine(false);
        setOpenSchedule(false);
        setOpenProgram(false);
        setOpenReports(false);
        setOpenCockPit(false);
        openMenu();
        setActiveMenuItem(e);
      },
      handleOpenProgram: () => {
        setOpenProgram(!openProgram);
        setOpenAttendance(false);
        setOpenReports(false);
        setOpenVaccine(false);
        setOpenSchedule(false);
        setOpenAttendance(false);
        setOpenCockPit(false);
        setOpenCDS(false);
        openMenu();
        setActiveMenuItem(e);
      },
      handleOpenCockPit: () => {
        setOpenCockPit(!openCockPit);
        setOpenVaccine(false);
        setOpenAttendance(false);
        setOpenSchedule(false);
        setOpenAttendance(false);
        setOpenProgram(false);
        setOpenReports(false);
        setOpenCDS(false);
        openMenu();
        setActiveMenuItem(e);
      },
      handleOpenReports: () => {
        setOpenReports(!openReports);
        setOpenCockPit(false);
        setOpenVaccine(false);
        setOpenAttendance(false);
        setOpenSchedule(false);
        setOpenAttendance(false);
        setOpenProgram(false);
        setOpenCDS(false);
        openMenu();
        setActiveMenuItem(e);
      },
      handleNotCollapse: () => {
        openMenu();
        setActiveMenuItem(e);
      },
    };
  }

  return (
    <>
      <ChangePassword open={changePassword} handleClose={() => setChangePassword(false)} />
      <Box sx={{ display: "flex", bgcolor: "transparent", paddingBottom: "50px" }}>
        <CssBaseline />
        <AppBar
          position="fixed"
          open={open}
          color=""
          elevation={1}
          sx={{
            padding: "0px",
          }}
        >
          <MyToolbar>
            <Toggle sx={{ display: "flex", alignItems: "center" }}>
              {open ? (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: "36px",
                  }}
                >
                  <CloseRounded color="primary" />
                </IconButton>
              ) : (
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    marginRight: "36px",
                  }}
                >
                  <MenuRounded color="primary" />
                </IconButton>
              )}
              <TPlusHealth
                style={{ cursor: "pointer" }}
                width={120}
                onClick={() => {
                  navigate("/");
                  setActiveMenuItem("openHome");
                }}
              />
              <Typography
                component={"span"}
                color="secondary"
                fontWeight="500"
                sx={{ paddingLeft: "15px" }}
              >
                {company?.name}
                {company?.virtual && " - Virtual"}
              </Typography>
            </Toggle>
            <div>
              <AvatarContainer
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Box>
                  <Typography
                    variant="h7"
                    noWrap
                    textAlign={"right"}
                    component="div"
                    color="primary"
                    fontWeight="500"
                  >
                    {company?.name} - CBO: {cbo_code} |
                  </Typography>
                  <Typography
                    variant="h7"
                    sx={{
                      textTransform: "uppercase",
                    }}
                    component="div"
                    color="primary"
                    fontWeight="700"
                  >
                    {describe} - {name ? name : "Username"}
                  </Typography>
                </Box>
                <ProfileMenu
                  handleOpenSignature={() => signatureDialog(undefined, true)}
                  handleOpenChangePassword={() => setChangePassword(true)}
                />
              </AvatarContainer>
            </div>
          </MyToolbar>
        </AppBar>
        <Drawer variant="permanent" open={open} color="secondary">
          <DrawerHeader>
            <Typography fontWeight="500" color="#337EAD" align="center">
              Atenção Primária
            </Typography>
          </DrawerHeader>
          <Divider />
          <List>
            {routesList().map((item) => (
              <div key={item.name}>
                <MyListItem
                  button={"true"}
                  component={item.subRoutes.length ? "span" : NavLink}
                  to={item.name !== "Conectividade" ? item.link : undefined}
                  selected={activeMenuItem === item.collapseRoute}
                  end={item.link === "/" ? true : false}
                  onClick={
                    item.name !== "Conectividade"
                      ? variables(item.collapseRoute)[item.eventClick]
                      : () => {
                          window.open("http://tsaudehsm.fiec.one:5126/", "_blank");
                        }
                  }
                  sx={{ display: "flex", color: theme.palette.primary.font }}
                >
                  <ListItemIcon>{item.icon}</ListItemIcon>
                  <ListItemText sx={{ ml: -1 }} primary={item.name} />
                  {item.subRoutes.length ? (
                    variables()[item.collapseRoute] ? (
                      <ExpandLessRounded />
                    ) : (
                      <ExpandMoreRounded />
                    )
                  ) : (
                    <span></span>
                  )}
                </MyListItem>
                <Collapse in={variables()[item.collapseRoute]}>
                  {item.subRoutes.map((item) => (
                    <MyListItem
                      key={item.name}
                      button={"true"}
                      component={NavLink}
                      to={item.link}
                      style={({ isActive }) => ({
                        background: isActive && "rgba(106, 87, 238, 0.1)",
                      })}
                      end
                      onClick={variables()[item.eventClick]}
                      sx={{
                        display: "flex",
                        pl: 4,
                        color: theme.palette.primary.font,
                      }}
                    >
                      <ListItemIcon>
                        <ArrowRightRounded color="primary" />
                      </ListItemIcon>
                      <ListItemText primary={item.name} />
                    </MyListItem>
                  ))}
                </Collapse>
              </div>
            ))}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3, position: "relative", minHeight: "100vh" }}
          onClick={closeOnContainerClick}
        >
          <DrawerHeader />
          {children}
          <Footer>
            <Typography variant="h7" color={theme.palette.primary.light} sx={{ ml: 2 }}>
              <Typography component="span" fontWeight="500">
                @Tascom Tecnologia em Saúde
              </Typography>{" "}
              - ( {window.TSAUDE_VERSION} ) Ambiente - (
              {BACK_IPS[window.API_URL] ?? BACK_IPS.default} )
            </Typography>
          </Footer>
        </Box>
      </Box>
    </>
  );
}
