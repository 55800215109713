import { headerHorizontalStyle, footer, title, patientInformations } from "./pdfComponents";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { v4 as uuidv4 } from "uuid";
import { format, parseISO } from "date-fns";
import calcIMC from "../utils/calcIMC";
import { calcByDUM, calcByUSG } from "../utils/pregnantCalculations";
import {
  ampiOptions,
  cross_bite,
  deep_bite_class_1,
  deep_bite_class_2,
  deep_bite_class_3,
  dental_supplies,
  drugAdmininstrations,
  footQuestions,
  footRegions,
  open_bite,
  risk_stratification_labels,
} from "../config/standardSelects";
import convertDateTobirthday from "../utils/convertDateToBirthday";
import { accessObjectByString, mapObject } from "../utils";

function mountMedications(medications) {
  const proceduresTitles = drugAdmininstrations.reduce((previous, { title, procedure_id }) => {
    return { ...previous, [procedure_id]: title };
  }, {});

  let result;
  const groupedMedications = [];
  const notGroupedMedications = [];

  medications.forEach((medication) => {
    const groupIndex = groupedMedications.findIndex(
      ({ procedure_id }) => medication.procedure_id === procedure_id
    );

    if (!medication.procedure_id) {
      notGroupedMedications.push(medication);
    } else if (groupIndex !== -1) {
      groupedMedications[groupIndex].medications.push(medication);
    } else {
      groupedMedications.push({
        title: proceduresTitles[medication.procedure_id],
        procedure_id: medication.procedure_id,
        medications: [medication],
      });
    }
  });

  if (notGroupedMedications.length) {
    result = {
      margin: [0, 10, 0, 20],
      ol: notGroupedMedications.map(
        ({ name, presentation, quantity, posology, notes, continuous_use }) => ({
          text: [
            {
              text: `${name} ${quantity ?? ""} ${continuous_use ? "(Uso contínuo)" : presentation}`,
              bold: true,
            },
            { text: ` (${posology}) \n` },
            { text: notes ? notes.toUpperCase() : "" },
          ],
          margin: [0, 0, 0, 10],
        })
      ),
    };
  } else {
    result = groupedMedications.map((group, index) => [
      {
        margin: [0, 0, 0, 10],
        fontSize: 10,
        text: group.title,
        bold: true,
        style: "header",
      },
      {
        ol: group.medications.map(
          ({ name, presentation, quantity, posology, notes, continuous_use }) => {
            for (let i = name.length; i < 80; i++) {
              name += ".";
            }

            return [
              {
                text: `${name} | ${quantity ? `${quantity} |` : ""} ${
                  continuous_use ? "Uso contínuo" : presentation
                }`,
                bold: true,
              },
              {
                ul: [
                  {
                    text: posology,
                  },
                  {
                    text: notes || "",
                  },
                ],
                margin: [0, 0, 0, 10],
              },
            ];
          }
        ),
      },
    ]);
  }

  return result;
}

function mountMedicationsOnSite(medications) {
  const proceduresTitles = drugAdmininstrations.reduce((previous, { title, procedure_id }) => {
    return { ...previous, [procedure_id]: title };
  }, {});

  let result;
  const groupedMedications = [];
  const notGroupedMedications = [];

  medications.forEach((medication) => {
    const groupIndex = groupedMedications.findIndex(
      ({ procedure_id }) => medication.procedure_id === procedure_id
    );

    if (!medication.procedure_id) {
      notGroupedMedications.push(medication);
    } else if (groupIndex !== -1) {
      groupedMedications[groupIndex].medications.push(medication);
    } else {
      groupedMedications.push({
        title: proceduresTitles[medication.procedure_id],
        procedure_id: medication.procedure_id,
        medications: [medication],
      });
    }
  });

  if (notGroupedMedications.length) {
    result = {
      margin: [0, 10, 0, 20],
      ol: notGroupedMedications.map(({ name, presentation, quantity, posology, notes }) => ({
        text: [
          { text: `${name} ${quantity} ${presentation}`, bold: true },
          { text: ` ${posology} \n` },
          { text: notes ? notes.toUpperCase() : "" },
        ],
        margin: [0, 0, 0, 10],
      })),
    };
  } else {
    result = groupedMedications.map((group, index) => [
      {
        margin: [0, 0, 0, 10],
        fontSize: 10,
        text: group.title,
        bold: true,
        style: "header",
      },
      {
        ol: group.medications.map(({ name, presentation, quantity, posology, notes }) => {
          for (let i = name.length; i < 80; i++) {
            name += ".";
          }

          return [
            {
              text: `${name} | ${quantity ? `${quantity} |` : ""} ${presentation}`,
              bold: true,
            },
            {
              ul: [
                {
                  text: posology,
                },
                {
                  text: notes || "",
                },
              ],
              margin: [0, 0, 0, 10],
            },
          ];
        }),
      },
    ]);
  }

  return result;
}

function mountExams(exams) {
  return {
    ol: exams.map(({ describe, notes, type }) => [
      {
        text: `${describe} - ${type}`,
        bold: true,
      },
      {
        ul: [
          {
            text: notes || "",
          },
        ],
        margin: [0, 0, 0, 10],
      },
    ]),
  };
}

function mountAgainstReference(againstReferences) {
  return {
    ol: againstReferences.map(({ requested_specialty, details, priority, justification }) => [
      {
        text: `${requested_specialty.describe} - ${priority}`,
        bold: true,
      },
      {
        ul: [
          {
            text: `Hipótese diagnóstica: ${details.hyphotesis || "Não informada."}`,
          },
          {
            text: `Exames realizados: ${details.exams || "Não informada."}`,
          },
          {
            text: `Motivo: ${justification || "Não informada."}`,
          },
        ],
        margin: [0, 0, 0, 10],
      },
    ]),
  };
}

function mountDeclarations(declarations) {
  return {
    ol: declarations.map(
      ({ type, arrival_time, departure_time, companion_name, cid_id, observations }) => {
        const ul = [];

        let line = [
          {
            text: type,
            bold: true,
          },
          {
            ul,
            margin: [0, 0, 0, 10],
          },
        ];

        if (cid_id) {
          ul.push({
            text: `CID: ${cid_id}`,
          });
        }
        if (arrival_time) {
          ul.push({
            text: `Chegada: ${arrival_time}`,
          });
        }
        if (departure_time) {
          ul.push({
            text: `Saida: ${departure_time}`,
          });
        }
        if (companion_name) {
          ul.push({
            text: `Nome do acompanhante: ${companion_name}`,
          });
        }
        if (observations) {
          ul.push({
            text: `Observação: ${observations}`,
          });
        }

        return line;
      }
    ),
  };
}

function soapPDF(data, patient, documentData, odontoProcedures, rps) {
  const pdfName = uuidv4();
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  const convertedBirthDate = convertDateTobirthday(patient.birth_date, ["years", "months"], false);

  const prontuary = patientInformations(patient);

  prontuary.columns[0].ul.push({
    text: [
      { text: "Data do prontuário: ", bold: true },
      {
        text: data?.createdAt ? format(parseISO(data?.createdAt), "dd/MM/yyyy") : "Não informado",
      },
    ],
    margin: [0, 0, 0, 5],
  });

  prontuary.columns[1].ul.push({
    text: [
      { text: "Horário do prontuário: ", bold: true },
      {
        text: data?.createdAt ? format(parseISO(data.createdAt), "HH:mm") : "Não informado",
      },
    ],
    margin: [0, 0, 0, 5],
  });

  let subjectiveCiaps = "Não informado";

  if (data.subjective?.ciaps?.length) {
    subjectiveCiaps = data.subjective.ciaps.reduce((ciapString, { description }, index, array) => {
      return array.length - 1 === index
        ? `${ciapString} ${description}`
        : `${ciapString} ${description},`;
    }, "");
  }
  let objectpreExistIllnesses = "Não encontrado";
  if (data.objective?.preExistIllnesses?.length) {
    objectpreExistIllnesses = data.objective.preExistIllnesses.map(({ describe }, index, array) => {
      return array.length - 1 === index ? `${describe}` : `${describe},`;
    }, "");
  }

  const subjective = [
    {
      margin: [0, 10, 0, 10],
      fontSize: 12,
      text: "SUBJETIVO",
      bold: true,
      style: "header",
    },
    {
      margin: [-10, 0, 0, 0],
      type: "none",
      ul: [
        {
          text: [
            { text: "Razão: ", bold: true },
            { text: data.subjective.reason || "Não informado" },
          ],
          margin: [0, 0, 0, 5],
        },
        {
          text: [{ text: "Alega: ", bold: true }, { text: subjectiveCiaps }],
          margin: [0, 0, 40, 5],
        },
      ],
    },
  ];

  const admeasurement = [
    {
      margin: [0, 5, 0, 10],
      fontSize: 12,
      text: "AFERIÇÕES",
      bold: true,
      style: "header",
    },
    {
      margin: [-10, 0, 0, 0],
      columnGap: -10,
      columns: [
        {
          type: "none",
          ul: [],
        },
        {
          type: "none",
          ul: [],
        },
        {
          type: "none",
          ul: [],
        },
      ],
    },
  ];

  const hasAdmeasurement = data.objective?.admeasurement_objectives?.some(({ value }) =>
    Boolean(value)
  );

  if (!hasAdmeasurement) {
    admeasurement[1].columns[0].ul.push({
      text: "Nenhuma aferição informada",
      margin: [0, 0, 0, 5],
    });
  } else {
    let countAdmeasurement = 0;
    let imcValues = {};
    data.objective?.admeasurement_objectives?.forEach(
      ({ admeasurement: { describe, unit }, value }) => {
        if (!!value) {
          const title = describe.slice(0, 1) + describe.slice(1).toLowerCase();
          if (["PESO", "ALTURA"].includes(describe.toUpperCase())) {
            imcValues[describe.toUpperCase()] = value;
          }
          admeasurement[1]?.columns[countAdmeasurement]?.ul?.push({
            text: [{ text: `${title}: `, bold: true }, { text: `${value} ${unit}` }],
            margin: [0, 0, 0, 5],
          });
          if (countAdmeasurement === 2) {
            countAdmeasurement = 0;
          } else {
            countAdmeasurement++;
          }
        }
      }
    );
    if (imcValues?.PESO && imcValues?.ALTURA) {
      admeasurement[1]?.columns[countAdmeasurement]?.ul?.push({
        text: [
          { text: `IMC: `, bold: true },
          {
            text: `${calcIMC(imcValues?.PESO, imcValues?.ALTURA, "Valor inserido incorretamente")}`,
          },
        ],
        margin: [0, 0, 0, 5],
      });
    }
  }

  const objective = [
    {
      margin: [0, 10, 0, 10],
      fontSize: 12,
      text: "OBJETIVO",
      bold: true,
      style: "header",
    },
    {
      margin: [-10, 5, 0, 0],
      columnGap: -25,
      columns: [
        {
          type: "none",
          ul: [
            {
              text: [
                { text: "Exame físico/psíquico: ", bold: true },
                { text: data.objective?.physical_exams || "Não informado" },
              ],
              margin: [0, 0, 30, 5],
            },
            {
              text: [
                { text: "Possui alergia: ", bold: true },
                { text: data.objective?.allergy_status || "Não informado" },
              ],
              margin: [0, 0, 40, 5],
            },
            {
              text: [
                { text: "Descrição: ", bold: true },
                {
                  text: data.objective?.allergy_description || "Não informado",
                },
              ],
              margin: [0, 0, 40, 5],
            },
          ],
        },
        {
          type: "none",
          ul: [
            {
              text: [
                { text: "Vacina em dia: ", bold: true },
                { text: data?.objective?.vaccine_up_to_date ? "Sim" : "Não" },
              ],
              margin: [0, 0, 40, 5],
            },
            {
              text: [
                {
                  text: "Exames complementares | Medicamentos em uso: ",
                  bold: true,
                },
                {
                  text: data?.objective?.complementary_exams || "Não informado",
                },
              ],
              margin: [0, 0, 40, 5],
            },
            {
              text: [
                { text: "Comorbidades: ", bold: true },
                {
                  text:
                    data.objective?.preExistIllnesses?.map(({ describe }) => describe) ||
                    "Não informado",
                },
              ],
              margin: [0, 0, 40, 5],
            },
          ],
          margin: [0, 0, 40, 5],
        },
      ],
    },
  ];

  let riskStratification = null;

  if (data?.objective?.risk_stratification) {
    const { id, cardiovascular_risk, therapeutic_goal, ...responses } =
      data?.objective?.risk_stratification;

    riskStratification = [
      {
        margin: [0, 5, 0, 10],
        fontSize: 12,
        text: "ESTRATIFICAÇÃO RCV",
        bold: true,
        style: "header",
      },
      {
        margin: [-10, 0, 0, 10],
        columnGap: -10,
        columns: [
          {
            type: "none",
            ul: [
              {
                margin: [0, 5, 0, 10],
                fontSize: 10,
                text: `RISCO CARDIOVASCULAR: ${cardiovascular_risk}`,
                bold: true,
                style: "header",
              },
            ],
          },
          {
            type: "none",
            ul: [
              {
                margin: [0, 5, 0, 10],
                fontSize: 10,
                text: therapeutic_goal,
                bold: true,
                style: "header",
              },
            ],
          },
        ],
      },
    ];

    const questionsLabel = {
      ...risk_stratification_labels.risk_factors,
      ...risk_stratification_labels.cd_advanced,
      ...risk_stratification_labels.loa,
    };

    Object.entries(questionsLabel).forEach(([key, label], i) => {
      const childData = {
        text: [{ text: `${label}: `, bold: true }, { text: responses[key] ? "Sim" : "Não" }],
        margin: [0, 0, 40, 5],
      };

      if (i % 2 === 0) {
        riskStratification[1].columns[1].ul.push(childData);
      } else {
        riskStratification[1].columns[0].ul.push(childData);
      }
    });
  }

  if (data?.objective?.pregnancy_record) {
    const pregnanteData = [
      {
        text: [{ text: "Gestante: ", bold: true }, { text: "Sim" }],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Data da última menstruação: ", bold: true },
          {
            text: data.objective.pregnancy_record.last_menstrual_period
              ? format(
                  parseISO(data.objective.pregnancy_record.last_menstrual_period),
                  "dd/MM/yyyy"
                )
              : "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Data do USG obstétrico: ", bold: true },
          {
            text: data.objective.pregnancy_record.obstetric_usg_date
              ? format(parseISO(data.objective.pregnancy_record.obstetric_usg_date), "dd/MM/yyyy")
              : "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Semanas no USG: ", bold: true },
          {
            text: data.objective.pregnancy_record.obstetric_usg_weeks || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Dias no USG: ", bold: true },
          {
            text: data.objective.pregnancy_record.obstetric_usg_days || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Número de Gestações: ", bold: true },
          {
            text: data.objective.pregnancy_record.gravidity || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Número de Partos: ", bold: true },
          {
            text: data.objective.pregnancy_record.parity || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Número de Partos Normais: ", bold: true },
          {
            text: data.objective.pregnancy_record.vaginal_deliveries_number || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Número de Partos Cesáreos: ", bold: true },
          {
            text: data.objective.pregnancy_record.cesarean_deliveries_number || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Número de Abortos: ", bold: true },
          {
            text: data.objective.pregnancy_record.abortions_number || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Gravidez planejada: ", bold: true },
          {
            text: data.objective?.pregnancy_record?.planned_pregnancy ? "Sim" : "Não",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Movimentos fetais: ", bold: true },
          {
            text: data.objective.pregnancy_record.fetal_movements ? "Sim" : "Não",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Suplementação com Ácido Fólico: ", bold: true },
          {
            text: data.objective.pregnancy_record.folic_acid_supplementation ? "Sim" : "Não",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Suplementação de Carbonato de Cálcio: ", bold: true },
          {
            text: data.objective.pregnancy_record.calcium_carbonate_supplementation ? "Sim" : "Não",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Edema: ", bold: true },
          { text: data.objective.pregnancy_record.edema || "Sem edema" },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Risco gestacional: ", bold: true },
          {
            text: data.objective.pregnancy_record.gestational_risk || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Apresentação fetal: ", bold: true },
          {
            text: data.objective.pregnancy_record.fetal_presentation || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Exame clínico normal: ", bold: true },
          {
            text: data.objective.pregnancy_record.normal_clinical_exam || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Exame ginecológico normal: ", bold: true },
          {
            text: data.objective.pregnancy_record.normal_gynecological_exam || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Exame ginecológico normal: ", bold: true },
          {
            text: data.objective.pregnancy_record.normal_gynecological_exam || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Exame de mamas normal: ", bold: true },
          {
            text: data.objective.pregnancy_record.normal_breast_exam || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Últimas gestações: ", bold: true },
          {
            text:
              data.objective.pregnancy_record.previous_pregnancies?.length > 0
                ? data?.objective?.pregnancy_record.previous_pregnancies.reduce(
                    (current, value, index, array) => {
                      if (array.length - 1 === index) {
                        return current + format(parseISO(value), "dd/MM/yyyy");
                      } else {
                        return current + format(parseISO(value), "dd/MM/yyyy") + ", ";
                      }
                    },
                    ""
                  )
                : "Não informado",
          },
        ],
        margin: [0, 0, 20, 5],
      },
    ];

    for (let i = pregnanteData.length; i > 0; i--) {
      if (i % 2 === 0) {
        objective[1].columns[1].ul.unshift(pregnanteData[i]);
      } else {
        objective[1].columns[0].ul.unshift(pregnanteData[i]);
      }
    }
  }

  if (data?.objective?.ampi_last_classification && data?.objective?.ampi_classification) {
    const ampiData = [
      {
        text: [
          { text: "Classificação AMPI: ", bold: true },
          {
            text: ampiOptions[data?.objective?.ampi_classification] || "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Data da última classificação: ", bold: true },
          {
            text:
              format(parseISO(data?.objective?.ampi_last_classification), "dd/MM/yyyy") ||
              "Não informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
    ];

    objective[1].columns[1].ul.unshift(ampiData[0]);
    objective[1].columns[0].ul.unshift(ampiData[1]);
  }

  let gestationalCalculation = null;

  if (data?.objective?.pregnancy_record) {
    gestationalCalculation = [
      {
        margin: [0, 5, 0, 10],
        fontSize: 12,
        text: "CALCULO GESTACIONAL",
        bold: true,
        style: "header",
      },
      {
        margin: [-10, 0, 0, 0],
        columnGap: -10,
        columns: [
          {
            type: "none",
            ul: [],
          },
          {
            type: "none",
            ul: [],
          },
        ],
      },
    ];

    if (data.objective.pregnancy_record?.last_menstrual_period) {
      const { gestationalAge, probableDateOfBirth } = calcByDUM(
        data.attendance.createdAt,
        data.objective.pregnancy_record?.last_menstrual_period
      );

      gestationalCalculation[1].columns[0].ul.push(
        {
          text: [{ text: "Idade gestacional (DUM): ", bold: true }, { text: gestationalAge }],
          margin: [0, 0, 40, 5],
        },
        {
          text: [
            { text: "Data provável de parto (DUM): ", bold: true },
            { text: probableDateOfBirth },
          ],
          margin: [0, 0, 40, 5],
        }
      );
    }

    if (
      data.objective.pregnancy_record?.obstetric_usg_date &&
      data.objective.pregnancy_record?.obstetric_usg_weeks &&
      data.objective.pregnancy_record?.obstetric_usg_days >= 0
    ) {
      const { gestationalAge, probableDateOfBirth } = calcByUSG(
        data.attendance.createdAt,
        data.objective.pregnancy_record?.obstetric_usg_date,
        data.objective.pregnancy_record?.obstetric_usg_weeks,
        data.objective.pregnancy_record?.obstetric_usg_days
      );

      gestationalCalculation[1].columns[1].ul.push(
        {
          text: [{ text: "Idade gestacional (USG): ", bold: true }, { text: gestationalAge }],
          margin: [0, 0, 40, 5],
        },
        {
          text: [
            { text: "Data provável de parto (USG): ", bold: true },
            { text: probableDateOfBirth },
          ],
          margin: [0, 0, 40, 5],
        }
      );
    }

    if (
      !gestationalCalculation[1].columns[0].ul.length &&
      !gestationalCalculation[1].columns[1].ul.length
    ) {
      gestationalCalculation[1].columns[0].ul.push({
        text: "Nenhum resultado disponível.",
        margin: [0, 0, 0, 5],
      });
    }
  }

  let diabeticFoot = null;
  if (data?.objective?.diabetic_foot_assessment) {
    const diabeticFootData = data?.objective?.diabetic_foot_assessment;
    diabeticFoot = [
      {
        margin: [0, 5, 0, 10],
        fontSize: 12,
        text: "PÉ DIABÉTICO",
        bold: true,
        style: "header",
      },
      {
        margin: [-10, 0, 0, 0],
        columnGap: -10,
        columns: [
          {
            type: "none",
            ul: [
              {
                text: [
                  { text: "Data da avaliação do pé diabético:", bold: true },
                  {
                    text: data?.objective?.date_diabetic_foot_assessment
                      ? format(
                          parseISO(data?.objective?.date_diabetic_foot_assessment),
                          "dd/MM/yyyy"
                        )
                      : "Não informado",
                  },
                ],
                margin: [0, 0, 40, 5],
              },
              {
                text: [
                  { text: "Data prevista para nova avaliação:", bold: true },
                  {
                    text: data?.objective?.new_evaluation_scheduled_date
                      ? format(
                          parseISO(data?.objective?.new_evaluation_scheduled_date),
                          "dd/MM/yyyy"
                        )
                      : "Não informado",
                  },
                ],
                margin: [0, 0, 40, 5],
              },
              {
                text: [
                  { text: "Diabético:", bold: true },
                  { text: diabeticFootData.diabetic ? "Sim" : "Não" },
                ],
                margin: [0, 0, 40, 5],
              },
              {
                text: [
                  { text: "Hipertenso:", bold: true },
                  { text: diabeticFootData.hypertensive ? "Sim" : "Não" },
                ],
                margin: [0, 0, 40, 5],
              },
              {
                text: [
                  { text: "Insulino Dependente:", bold: true },
                  { text: diabeticFootData.insulin_dependent ? "Sim" : "Não" },
                ],
                margin: [0, 0, 40, 5],
              },
              {
                text: [
                  { text: "Tipo Diabetes:", bold: true },
                  { text: diabeticFootData.diabetic_type },
                ],
                margin: [0, 0, 40, 5],
              },
              {
                text: [
                  { text: "AMG:", bold: true },
                  { text: diabeticFootData.amg ? "Sim" : "Não" },
                ],
                margin: [0, 0, 40, 5],
              },
              {
                text: [
                  { text: "Data do ultimo laudo AMG:", bold: true },
                  {
                    text: diabeticFootData.last_amg_report_date
                      ? format(parseISO(diabeticFootData.last_amg_report_date), "dd/MM/yyyy")
                      : "Não informado",
                  },
                ],
                margin: [0, 0, 40, 5],
              },
              {
                text: [
                  { text: "Resultado hemoglobina glicada:", bold: true },
                  { text: diabeticFootData.glycated_hemoglobin_result },
                ],
                margin: [0, 0, 40, 5],
              },
              {
                text: [
                  {
                    text: "Data do resultado hemoglobina glicada: ",
                    bold: true,
                  },
                  {
                    text: diabeticFootData.glycated_hemoglobin_date
                      ? format(parseISO(diabeticFootData.glycated_hemoglobin_date), "dd/MM/yyyy")
                      : "Não informado",
                  },
                ],
                margin: [0, 0, 40, 5],
              },
            ],
          },
          {
            type: "none",
            ul: [],
          },
        ],
      },
      {
        margin: [-10, 10, 0, 0],
        columnGap: -10,
        columns: [
          {
            type: "none",
            ul: [
              {
                text: [{ text: "Pé Esquerdo", bold: true }],
                margin: [0, 0, 40, 5],
              },
            ],
          },
          {
            type: "none",
            ul: [
              {
                text: [{ text: "Pé Direito", bold: true }],
                margin: [0, 0, 40, 5],
              },
            ],
          },
        ],
      },
    ];

    Object.entries(footQuestions).forEach(([key, value]) => {
      const formattedValue = value.includes("?") ? value + " " : value + ": ";

      if (typeof diabeticFootData.left_foot[key] === "boolean") {
        diabeticFoot[2].columns[0].ul.push({
          text: [
            { text: formattedValue, bold: true },
            { text: diabeticFootData.left_foot[key] ? "Sim" : "Não" },
          ],
          margin: [0, 0, 40, 5],
        });
      }
      if (typeof diabeticFootData.right_foot[key] === "boolean") {
        diabeticFoot[2].columns[1].ul.push({
          text: [
            { text: formattedValue, bold: true },
            { text: diabeticFootData.right_foot[key] ? "Sim" : "Não" },
          ],
          margin: [0, 0, 40, 5],
        });
      }
    });

    footRegions.forEach(({ name, path, subPath }) => {
      const formattedName = name + ": ";
      const valuePath = subPath ? `${path}.${subPath}` : path;
      const rightFootRegionValues = accessObjectByString(diabeticFootData.right_foot, valuePath);
      const leftFootRegionValues = accessObjectByString(diabeticFootData.left_foot, valuePath);

      if (rightFootRegionValues) {
        if (rightFootRegionValues?.problems || rightFootRegionValues?.notes) {
          diabeticFoot[2].columns[1].ul.push({
            text: [{ text: formattedName, bold: true }],
            margin: [0, 0, 40, 5],
          });

          rightFootRegionValues.problems?.forEach(({ describe }) => {
            diabeticFoot[2].columns[1].ul.push({
              text: describe,
              margin: [10, 0, 40, 5],
            });
          });

          if (rightFootRegionValues.notes) {
            diabeticFoot[2].columns[1].ul.push({
              text: [{ text: "Observações: ", bold: true }, { text: rightFootRegionValues.notes }],
              margin: [10, 0, 40, 5],
            });
          }
        }
      }

      if (leftFootRegionValues) {
        if (leftFootRegionValues?.problems || leftFootRegionValues?.notes) {
          if (leftFootRegionValues.problems?.length) {
            diabeticFoot[2].columns[0].ul.push({
              text: [{ text: formattedName, bold: true }],
              margin: [0, 0, 40, 5],
            });

            leftFootRegionValues.problems.forEach(({ describe }) => {
              diabeticFoot[2].columns[0].ul.push({
                text: describe,
                margin: [10, 0, 40, 5],
              });
            });

            if (leftFootRegionValues.notes) {
              diabeticFoot[2].columns[0].ul.push({
                text: [{ text: "Observações: ", bold: true }, { text: leftFootRegionValues.notes }],
                margin: [10, 0, 40, 5],
              });
            }
          }
        }
      }
    });
  }

  if (convertedBirthDate.years <= 6) {
    const childData = [
      {
        text: [
          { text: "Aleitamento materno: ", bold: true },
          {
            text: data?.objective?.child_record?.breastfeeding || "Não iformado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Ortolani: ", bold: true },
          { text: data?.objective?.child_record?.ortolani },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Desenvolvimento Neuropsicomotor(DNPM): ", bold: true },
          { text: data?.objective?.child_record?.neuropsychomotor_development },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Reflexo Vermelho: ", bold: true },
          { text: data?.objective?.child_record?.red_reflex_exam },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Comorbidades : ", bold: true },
          {
            text: objectpreExistIllnesses,
          },
        ],
        margin: [0, 0, 0, 5],
      },
    ];

    childData.forEach((_, i) => {
      if (i % 2 === 0) {
        objective[1].columns[1].ul.push(childData[i]);
      } else {
        objective[1].columns[0].ul.push(childData[i]);
      }
    });
  }

  let evaluationCiaps = "Não informado";
  let evaluationCids = "Não informado";
  let planConducs = [];
  if (data.assessment?.ciaps?.length) {
    evaluationCiaps = data.assessment.ciaps.reduce(
      (ciapString, { description, id }, index, array) => {
        return array.length - 1 === index
          ? `${ciapString} ${description}(${id})`
          : `${ciapString} ${description}(${id}),`;
      },
      ""
    );
  }

  if (data.conducts?.length) {
    planConducs = {
      text: [
        { text: "Condutas: ", bold: true },
        {
          text: data.conducts.reduce((ciapString, { description }, index, array) => {
            return array.length - 1 === index
              ? `${ciapString} ${description}`
              : `${ciapString} ${description},`;
          }, ""),
        },
      ],
      margin: [0, 0, 40, 5],
    };
  }

  if (data.assessment?.cids?.length) {
    evaluationCids = data.assessment.cids.reduce((cidString, { id, description }, index, array) => {
      return array.length - 1 === index
        ? `${cidString} ${description}(${id})`
        : `${cidString} ${description}(${id}),`;
    }, "");
  }

  function generateOralHealthSurveillanceList(oralHealthSurveillanceData) {
    if (!oralHealthSurveillanceData || !oralHealthSurveillanceData.length) {
      return [];
    }
    return oralHealthSurveillanceData.map((item) => item.description).join(", ");
  }

  const oralHealthSurveillanceList = generateOralHealthSurveillanceList(
    data.assessment?.oral_health_surveillances
  );

  const evaluation = [
    {
      margin: [0, 10, 0, 10],
      fontSize: 12,
      text: "AVALIAÇÃO",
      bold: true,
      style: "header",
    },
    {
      margin: [-10, 0, 0, 0],
      type: "none",
      columns: [
        {
          width: 400,
          ul: [
            {
              text: [
                { text: "Avaliação da consulta: ", bold: true },
                { text: data.assessment?.query_assessment || "Não informado" },
              ],
              margin: [0, 0, 0, 5],
            },
            {
              text: [{ text: "Cids: ", bold: true }, { text: evaluationCids }],
              margin: [0, 0, 40, 5],
            },
            {
              text: [{ text: "Ciaps: ", bold: true }, { text: evaluationCiaps }],
              margin: [0, 0, 40, 5],
            },
            {
              text: [
                !!data?.assessment?.oral_health_surveillances?.length > 0
                  ? {
                      text: [
                        { text: "Vigilância em saúde bucal: ", bold: true },
                        {
                          text: oralHealthSurveillanceList,
                        },
                      ],
                    }
                  : null,
              ],
              margin: [0, 0, 40, 5],
            },
          ],
          type: "none",
        },
      ],
    },
  ];

  let planCiaps = "Não informado";

  if (data.plan?.ciaps?.length) {
    planCiaps = data.plan.ciaps.reduce((ciapString, { description, acronym }, index, array) => {
      return array.length - 1 === index
        ? `${ciapString} ${description}(${acronym})`
        : `${ciapString} ${description}(${acronym}),`;
    }, "");
  }

  let medications;

  const prescriptionWithoutComplement = data.plan?.prescriptions?.find(
    (prescription) => !prescription.soap_complement_id
  );

  if (!prescriptionWithoutComplement?.medications?.length) {
    medications = {
      margin: [0, 10, 0, 20],
      text: "Nenhum medicamento prescrito neste plano.",
    };
  } else {
    medications = mountMedications(prescriptionWithoutComplement.medications);
  }

  let medicationsOnSite;

  if (!prescriptionWithoutComplement?.medications_on_site?.length) {
    medicationsOnSite = {
      margin: [0, 10, 0, 20],
      text: "Nenhum medicamento prescrito neste plano.",
    };
  } else {
    medicationsOnSite = mountMedicationsOnSite(prescriptionWithoutComplement.medications_on_site);
  }

  let exams;

  if (!prescriptionWithoutComplement?.exams?.length) {
    exams = {
      margin: [0, 10, 0, 20],
      text: "Nenhum exame prescrito neste plano.",
    };
  } else {
    exams = mountExams(prescriptionWithoutComplement.exams);
  }

  let againstReference;

  const againstReferencesWithoutComplement = data.against_references?.filter(
    (reference) => !reference.soap_complement_id
  );

  if (againstReferencesWithoutComplement.length) {
    againstReference = mountAgainstReference(againstReferencesWithoutComplement);
  } else {
    againstReference = {
      margin: [0, 10, 0, 20],
      text: "Nenhuma contrarreferência realizada neste plano.",
    };
  }

  let documents;

  const documentsWithoutComplement = data.plan?.documents?.filter(
    (declaration) => !declaration.soap_complement_id
  );

  if (documentsWithoutComplement.length) {
    documents = mountDeclarations(documentsWithoutComplement);
  } else {
    documents = {
      margin: [0, 10, 0, 20],
      text: "Nenhuma declaração realizada neste plano.",
    };
  }

  let interventions;

  if (!data.plan?.interventions?.length) {
    interventions = {
      margin: [0, 10, 0, 20],
      text: "Não houve intervenções e/ou procedimentos clínico neste plano.",
    };
  } else {
    interventions = {
      ol: data.plan?.interventions?.map(({ notes, procedure, done }) => [
        {
          text: `${procedure.name} - ${done ? "Realizado" : "Não realizado"}`,
          bold: true,
        },
        {
          ul: [
            {
              text: notes || "",
            },
          ],
          margin: [0, 0, 0, 10],
        },
      ]),
    };
  }
  const plan = [
    {
      margin: [0, 10, 0, 10],
      fontSize: 12,
      text: "PLANO",
      bold: true,
      style: "header",
    },
    {
      margin: [-10, 0, 0, -15],
      type: "none",
      ul: [
        {
          text: [
            { text: "Plano de consulta: ", bold: true },
            { text: data.plan?.query_plan || "Não informado" },
          ],
          margin: [0, 0, 0, 5],
        },
        {
          text: [{ text: "Ciaps: ", bold: true }, { text: planCiaps }],
          margin: [0, 0, 40, 5],
        },
        planConducs,
        {
          text: [
            { text: "Procedimento Administrativo : ", bold: true },
            { text: data.plan?.administrative_procedure?.name || "Não informado" },
          ],
          margin: [0, 0, 40, 10],
        },
        {
          text: [{ text: "Receituário: ", fontSize: 12, bold: true }, { text: "" }],
          margin: [0, 0, 0, 5],
        },
        medications,
        {
          text: [{ text: "Medicação: ", fontSize: 12, bold: true }, { text: "" }],
          margin: [0, 0, 0, 5],
        },
        medicationsOnSite,
        {
          text: [{ text: "Exames: ", fontSize: 12, bold: true }, { text: "" }],
          margin: [0, 0, 0, 5],
        },
        exams,
        {
          text: [{ text: "Contrarreferência: ", fontSize: 12, bold: true }, { text: "" }],
          margin: [0, 0, 0, 5],
        },
        againstReference,
        {
          text: [{ text: "Declarações: ", fontSize: 12, bold: true }, { text: "" }],
          margin: [0, 0, 0, 5],
        },
        documents,
        {
          text: [
            {
              text: " Intervenções e/ou procedimentos clínico: ",
              fontSize: 12,
              bold: true,
            },
            { text: "" },
          ],
          margin: [0, 0, 0, 5],
        },
        interventions,
      ],
    },
  ];

  let complements;

  if (data.complements?.length) {
    complements = [
      {
        margin: [0, 10, 0, 10],
        fontSize: 12,
        text: "COMPLEMENTO",
        bold: true,
        style: "header",
      },
      {
        margin: [0, 0, 0, -45],
        ol: [],
      },
    ];

    data.complements.forEach((complement) => {
      const complementStructure = {
        stack: [
          {
            text: format(parseISO(complement.createdAt), "dd/MM/yyyy 'às' HH:mm"),
            bold: true,
            margin: [0, 0, 0, 5],
          },
          {
            text: complement.complement,
            margin: [0, 0, 0, 5],
          },
          {
            ul: [],
          },
        ],
      };

      const prescription = data.plan.prescriptions.find(
        (prescription) => prescription.soap_complement_id === complement.id
      );

      if (prescription) {
        if (prescription.medications?.length) {
          const medications = mountMedications(prescription.medications);

          complementStructure.stack[2].ul.push({
            stack: [
              {
                text: [{ text: "Receituário: ", fontSize: 12, bold: true }, { text: "" }],
                margin: [0, 0, 0, 5],
              },
              medications,
            ],
          });
        }

        if (prescription.medications_on_site?.length) {
          const medicationsOnSite = mountMedicationsOnSite(prescription.medications_on_site);

          complementStructure.stack[2].ul.push({
            stack: [
              {
                text: [{ text: "Medicação: ", fontSize: 12, bold: true }, { text: "" }],
                margin: [0, 0, 0, 5],
              },
              medicationsOnSite,
            ],
          });
        }

        if (prescription.exams?.length) {
          const exams = mountExams(prescription.exams);

          complementStructure.stack[2].ul.push({
            stack: [
              {
                text: [{ text: "Exames: ", fontSize: 12, bold: true }, { text: "" }],
                margin: [0, 0, 0, 5],
              },
              exams,
            ],
          });
        }
      }

      const againstReferences = data.against_references.filter(
        (reference) => reference.soap_complement_id === complement.id
      );

      if (againstReferences.length) {
        const againstReference = mountAgainstReference(againstReferences);

        complementStructure.stack[2].ul.push({
          stack: [
            {
              text: [{ text: "Contrarreferência: ", fontSize: 12, bold: true }, { text: "" }],
              margin: [0, 0, 0, 5],
            },
            againstReference,
          ],
        });
      }

      const declarations = data.plan.documents.filter(
        (declaration) => declaration.soap_complement_id === complement.id
      );

      if (declarations.length) {
        const againstReference = mountDeclarations(declarations);

        complementStructure.stack[2].ul.push({
          stack: [
            {
              text: [{ text: "Declarações: ", fontSize: 12, bold: true }, { text: "" }],
              margin: [0, 0, 0, 5],
            },
            againstReference,
          ],
        });
      }

      complements[1].ol.push(complementStructure);
    });
  }

  if (data?.plan?.reavaluation_comments) {
    plan[1].ul.splice(1, 0, {
      text: [{ text: "Reavaliação: ", bold: true }, { text: data.plan?.reavaluation_comments }],
      margin: [0, 0, 40, 5],
    });
  }

  if (data?.attendance?.service?.odontology || data?.attendance?.service?.ceo) {
    plan[1].ul.splice(2, 0, [
      {
        text: [
          { text: "Possui aparelho: ", bold: true },
          { text: data?.plan?.braces ? "Sim" : "Não" },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Possui contenção: ", bold: true },
          { text: data?.plan?.retainer ? "Sim" : "Não" },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: "Comanda Prótese: ",
        bold: true,
        margin: [0, 0, 0, 5],
      },
      data?.plan?.prosthesisCommand
        ? {
            margin: [10, 0, 0, 5],
            ul: [
              {
                text: [
                  { text: "Nº Caixa: ", bold: true },
                  {
                    text: data?.plan?.prosthesisCommand.box_number || "Não Informado",
                  },
                ],
                margin: [0, 0, 0, 2],
              },
              {
                text: [
                  { text: "Tipo de Trabalho: ", bold: true },
                  {
                    text: data?.plan?.prosthesisCommand?.work_type || "Não Informado",
                  },
                ],
                margin: [0, 0, 0, 2],
              },
              {
                text: [
                  { text: "Cor do dente: ", bold: true },
                  {
                    text: data?.plan?.prosthesisCommand?.tooth_color || "Não Informado",
                  },
                ],
                margin: [0, 0, 0, 2],
              },
              {
                text: [
                  { text: "Observações: ", bold: true },
                  {
                    text: data?.plan?.prosthesisCommand?.notes || "Não Informado",
                  },
                ],
                margin: [0, 0, 0, 2],
              },
            ],
          }
        : {
            margin: [10, 0, 0, 5],
            ul: [{ text: "Não informado" }],
          },
      {
        text: "Procedimentos: (Local | Problema | Procedimento | Observação): ",
        bold: true,
        margin: [0, 0, 0, 5],
      },
      odontoProcedures.length
        ? {
            margin: [10, 0, 0, 5],
            bold: true,
            ol: odontoProcedures.map((item) => ({
              text: `${item?.local ?? "-"} | ${item?.problems ?? "-"} | ${item?.procedures} | ${
                item?.notes ?? "-"
              }`,
              bold: false,
              margin: [0, 0, 0, 2],
            })),
          }
        : {
            margin: [10, 0, 0, 5],
            ul: [{ text: "Não informado" }],
          },
      rps.length
        ? [
            {
              text: "Registro periodontal simplificado:",
              bold: true,
              margin: [0, 0, 0, 5],
            },
            {
              text: rps.map((item, index, array) => {
                if (array.length - 1 === index) {
                  return item;
                }
                return item + " | ";
              }),
              margin: [0, 0, 0, 5],
            },
            {
              text: [{ text: "Observações: ", bold: true }, data?.plan?.sextant?.notes],
              margin: [0, 0, 0, 5],
            },
          ]
        : [],
      data?.plan?.orthodontic?.open_bite
        ? [
            {
              text: "Mordida aberta (Ortodontia):",
              bold: true,
              margin: [0, 0, 0, 5],
            },
            {
              text: mapObject(data.plan?.orthodontic.open_bite, (value, label) => {
                if (value) {
                  return open_bite[label];
                }
              })
                .filter((i) => i)
                .join(", "),
              margin: [0, 0, 0, 5],
            },
          ]
        : [],
      data?.plan?.orthodontic?.crossbite
        ? [
            {
              text: "Mordida cruzada (Ortodontia):",
              bold: true,
              margin: [0, 0, 0, 5],
            },
            {
              text: mapObject(data.plan.orthodontic.crossbite, (value, label) => {
                if (value) {
                  return cross_bite[label];
                }
              })
                .filter((i) => i)
                .join(", "),
              margin: [0, 0, 0, 5],
            },
          ]
        : [],
      data?.plan?.orthodontic?.deep_bite
        ? [
            {
              text: "Mordida profunda (Ortodontia):",
              bold: true,
            },
            {
              text: [
                {
                  text: "Classe I com apinhamento: ",
                },
                {
                  text: `${mapObject(data.plan.orthodontic.deep_bite.class_1, (value, label) => {
                    if (value && label !== "note") {
                      return deep_bite_class_1[label];
                    }
                  })
                    .filter((i) => i)
                    .join(", ")} - ${data.plan?.orthodontic.deep_bite.class_1.note}`,
                },
              ],
              margin: [0, 0, 0, 5],
            },
            {
              text: [
                {
                  text: "Classe II - Divisão 1ª: ",
                },
                {
                  text: `${mapObject(
                    data.plan.orthodontic.deep_bite.class_2?.division_1,
                    (value, label) => {
                      if (value) {
                        return deep_bite_class_2.division_1[label];
                      }
                    }
                  ).join(", ")}`,
                },
              ],
              margin: [0, 0, 0, 5],
            },
            {
              text: [
                {
                  text: "Classe II - Divisão 2ª: ",
                },
                {
                  text: `${mapObject(
                    data.plan?.orthodontic.deep_bite.class_2?.division_2,
                    (value, label) => {
                      if (value) {
                        return deep_bite_class_2.division_2[label];
                      }
                    }
                  ).join(", ")}`,
                },
              ],
              margin: [0, 0, 0, 5],
            },
            {
              text: [
                {
                  text: "Classe III: ",
                },
                {
                  text: `${mapObject(data.plan.orthodontic.deep_bite.class_3, (value, label) => {
                    if (value) {
                      return deep_bite_class_3[label];
                    }
                  }).join(", ")}`,
                },
              ],
              margin: [0, 0, 0, 5],
            },
          ]
        : [],
      {
        margin: [0, 0, 0, 5],
        bold: true,
        text: "Técnica anestésica | Tipo de anestésico | Quantidade | Lote | Validade: ",
      },
      data?.plan?.anesthesia?.[0]?.types?.length > 0
        ? {
            margin: [10, 0, 0, 5],
            bold: true,
            ol: data.plan.anesthesia[0].types.map((item) => ({
              bold: false,
              margin: [0, 0, 0, 2],
              text: [
                {
                  text: `${item.anesthetic_technique?.describe ?? "-"} | `,
                },
                { text: `${item.typesDescribe?.describe ?? "-"} | ` },
                { text: `${item.typesQuantity ?? "-"} | ` },
                { text: `${item.typesBatch ?? "-"} | ` },
                {
                  text: `${
                    item.typesExpirationDate
                      ? format(parseISO(item.typesExpirationDate), "dd/MM/yyyy")
                      : "-"
                  }`,
                },
              ],
            })),
          }
        : {
            margin: [10, 0, 0, 5],
            ul: [{ text: "Não informado" }],
          },
      {
        margin: [0, 0, 0, 5],
        bold: true,
        text: "Instrumentais | Lote | Validade: ",
      },
      data?.plan?.anesthesia?.[0]?.sterilization?.length > 0
        ? {
            margin: [10, 0, 0, 5],
            bold: true,
            ol: data.plan.anesthesia[0].sterilization.map((item, index) => ({
              bold: false,
              text: [
                {
                  text: `${item?.sterilizationDescribe?.firstCharToUp()} | `,
                },
                { text: `${item?.sterilizationBatch} | ` },
                {
                  text: `${
                    item?.sterilizationExpirationDate
                      ? format(parseISO(item.sterilizationExpirationDate), "dd/MM/yyyy")
                      : ""
                  }`,
                },
              ],
            })),
          }
        : {
            margin: [10, 0, 0, 5],
            ul: [{ text: "Não informado" }],
          },
      {
        text: [
          { text: "Prótese superior: ", bold: true },
          { text: data.plan.upper_denture ? "Sim" : "Não" },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Prótese inferior: ", bold: true },
          { text: data.plan.lower_denture ? "Sim" : "Não" },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Tipo de atendimento: ", bold: true },
          { text: data?.attendance_type?.describe || "Não Informada" },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Tipo de consulta: ", bold: true },
          {
            text: data?.dental_appointment_type?.description || "Não Informada",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Tipo de Tratamento: ", bold: true },
          {
            text: data?.plan?.treatment_type?.name
              ? `> ${data?.plan?.treatment_type?.name} - ${data?.plan?.treatment_type_conclusion?.name}`
              : data?.plan?.treatment_type_conclusion?.name
              ? data?.plan?.treatment_type_conclusion?.name
              : "Não Informado",
          },
        ],
        margin: [0, 0, 0, 5],
      },
      {
        text: [
          { text: "Fornecimento: ", bold: true },
          {
            text:
              data.plan?.dental_supplies?.length === 0
                ? "Não informado"
                : data?.plan?.dental_supplies?.map((item, index, dentalSupplies) => {
                    if (dentalSupplies?.length - 1 === index) {
                      return dental_supplies[item - 1];
                    }
                    return dental_supplies[item - 1] + ", ";
                  }),
          },
        ],
        margin: [0, 0, 0, 5],
      },
    ]);
  }

  const urlType = pdfName + "/types/soap";

  const definitions = {
    info: { title: pdfName },
    pageSize: "A4",
    pageMargins: [40, 105, 40, 260],
    defaultStyle: {
      fontSize: 10,
    },
    ...headerHorizontalStyle(data.attendance?.company),
    footer: footer(
      documentData?.createdAt,
      true,
      documentData?.employee,
      data.attendance?.company,
      urlType,
      [0, 140, 0, 0]
    ),
    content: [
      title(
        data?.attendance?.service?.odontology || data?.attendance?.service?.ceo
          ? "Prontuário Odontológico"
          : "Prontuário"
      ),
      prontuary,
      subjective,
      objective,
      gestationalCalculation,
      diabeticFoot,
      riskStratification,
      admeasurement,
      evaluation,
      plan,
      complements,
    ],
  };

  const documentPDF = pdfMake.createPdf(definitions);
  documentPDF.name = `${pdfName}.pdf`;

  return documentPDF;
}

export default soapPDF;
