import { DoNotDisturb, Visibility } from "@mui/icons-material";
import { Box, IconButton, ListItemButton, Typography, useTheme } from "@mui/material";
import { format, parseISO } from "date-fns";
import { ptBR } from "date-fns/locale";
import React, { useContext } from "react";
import { limitName, verifyTwentyFourHoursCompany } from "../../../utils";
import { AuthContext } from "../../../contexts/AuthContext";
import { EditIconButton } from "../../../components/Buttons";

export default function SoapCard({
  data,
  isSelected,
  handleClick,
  handleView,
  handleEdit,
  handleSuspend,
  isEditing,
  pendencyId,
}) {
  const theme = useTheme();
  const { userData } = useContext(AuthContext);
  const hasPendencies = pendencyId === data.id;

  function isOldAttendance(attendance) {
    const isOld = verifyTwentyFourHoursCompany(attendance.createdAt);

    if (userData?.company.type.TWENTY_FOUR_HOURS) {
      return isOld && !attendance.id_scheduling;
    }

    return isOld;
  }

  return (
    <ListItemButton
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        py: 3,
        backgroundColor: !data.deletedAt ? theme.palette.primary.medium : "rgba(216, 0, 0, 0.1)",
        border: `2px solid ${isSelected ? theme.palette.primary.light : "tranparent"}`,
        "&:hover": {
          background: !data.deletedAt ? "" : "rgba(216, 0, 0, 0.1)",
        },
        borderRadius: 2,
      }}
      onClick={handleClick}
      disabled={isEditing}
    >
      <Typography color={theme.palette.primary.main}>
        <Typography component="span" fontWeight="500">
          {format(parseISO(data.createdAt), "dd, MMMM ", {
            locale: ptBR,
          })}
        </Typography>
        {format(parseISO(data.createdAt), "yyyy", {
          locale: ptBR,
        })}
      </Typography>
      <Box>
        <Typography color="secondary" fontWeight="medium">
          N. Atendimento:
          <Typography component="span" fontWeight="700" sx={{ ml: "3px" }}>
            {data.attendance?.number}
          </Typography>{" "}
          - às {format(parseISO(data.createdAt), "HH:mm")}h
        </Typography>
        {data.employee_specialty && (
          <Typography fontSize={14} color="primary" fontWeight={500}>
            {`${limitName(data.employee_specialty.employee?.name, 2)} - ${
              data.employee_specialty.specialty?.describe
            }`}
          </Typography>
        )}
        <>
          {data.attendance?.date_late ? (
            <Typography fontSize={12} color="secondary.main">
              <Typography component="span" color="secondary.main" fontWeight="medium" fontSize={12}>
                Dt. de Referência :{" "}
                <Typography component="span" color="primary" fontSize={12}>
                  {format(new Date(data.attendance?.date_late), "dd/MM/yyyy")}
                </Typography>{" "}
              </Typography>
            </Typography>
          ) : null}
        </>
        <Typography fontSize={12} color="secondary.main">
          <Typography component="span" color="primary" fontSize={12}>
            Serviço :{" "}
            <Typography component="span" color="primary" fontSize={12}>
              {data.attendance?.service?.describe}
            </Typography>{" "}
          </Typography>
        </Typography>
      </Box>
      {(hasPendencies || data.editedAt) && (
        <Typography
          component="span"
          sx={{
            display: "inline-block",
            fontWeight: "500",
            padding: "4px 8px",
            borderRadius: "4px",
            backgroundColor: hasPendencies
              ? theme.palette.error.light
              : theme.palette.primary.light,
            color: "#FFF",
            marginTop: "4px",
          }}
        >
          {hasPendencies ? "Edição pendente" : "Editado"}
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "absolute",
          right: 12,
          top: 0,
        }}
      >
        <IconButton
          color="primary"
          onClick={(event) => {
            event.stopPropagation();
            handleView();
          }}
        >
          <Visibility fontSize="large" />
        </IconButton>
        {!isOldAttendance(data.attendance) && (
          <EditIconButton
            disabled={
              userData.id !== data.employee_specialty?.employee?.id ||
              data.attendance.company.id !== userData.company.id ||
              !!data.deletedAt ||
              isEditing ||
              (pendencyId && !hasPendencies)
            }
            onClick={(event) => {
              event.stopPropagation();
              handleEdit();
            }}
          />
        )}
        {!data.deletedAt && (
          <IconButton
            color="primary"
            disabled={
              userData.id !== data.employee_specialty?.employee?.id || isEditing || hasPendencies
            }
            onClick={(event) => {
              event.stopPropagation();
              handleSuspend();
            }}
          >
            <DoNotDisturb fontSize="large" />
          </IconButton>
        )}
      </Box>
    </ListItemButton>
  );
}
