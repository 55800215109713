import React, { useState } from "react";
import Yup from "../../../config/yup";
import { Grid, Typography } from "@mui/material";
import TableFilter from "../../../components/Table/TableFilter";
import { drugAdmininstrations } from "../../../config/standardSelects";
import { usePresets, useProcedures } from "../../../service";
import { useMutation, useQueryClient } from "react-query";
import useNotifier from "../../../hooks/useNotifier";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AutocompleteField,
  Button,
  PaginatedAutocompleteField,
  RadioField,
  TextField,
} from "../../../components/FormFields";
import { useLocalStorage } from "../../../hooks";

function DrugsPreset({ type = "register", presetType, data }) {
  const notify = useNotifier();
  const [medications, setMedications] = useState(() => (type === "edit" ? data.data : []));

  const [userData] = useLocalStorage("user-data");

  const queryClient = useQueryClient();
  const { getProcedures } = useProcedures();
  const { postPresets, patchPreset } = usePresets();

  const postPresetsMutation = useMutation(postPresets);
  const patchPresetMutation = useMutation(patchPreset);

  const validations = Yup.object().shape({
    preset_name: Yup.string().required("É requerido"),
    in_system: Yup.boolean(),
    special: Yup.boolean(),
    name: Yup.mixed().when("in_system", {
      is: true,
      then: Yup.object().required("É requerido").nullable(),
      otherwise: Yup.string().typeError("Valor inválido").required("É requerido").nullable(),
    }),
    quantity: Yup.number().when("continuous_use", {
      is: "true",
      then: (schema) =>
        schema.min(0.1, "A quantidade deve ser maior que 0").required("É requerido"),
      otherwise: Yup.number().typeError("Valor inválido").required("É requerido").nullable(),
    }),
    presentation: Yup.string().required("É requerido"),
    posology: Yup.string().required("É requerido"),
    continuous_use: Yup.boolean().required("É requerido"),
    notes: Yup.string().nullable(),
    drug_administration: Yup.object().required("É requerido").nullable(),
  });

  const { control, watch, setValue, handleSubmit, reset } = useForm({
    resolver: yupResolver(validations),
    defaultValues: {
      preset_name: type === "edit" ? data.describe : "",
      in_system: true,
      special: false,
      name: null,
      quantity: "",
      presentation: "",
      continuous_use: false,
      posology: "",
      notes: "",
      drug_administration: null,
    },
  });

  const [continuousUse, inSystem, presetName] = watch([
    "continuous_use",
    "in_system",
    "preset_name",
  ]);

  const addPrescription = (values) => {
    const medication = {
      ...values,
      tuss_code:
        userData.company.id === "55e1278d-8749-4576-896f-25df0167a478" && !values.name?.tuss_code
          ? "0301100012"
          : values.name?.tuss_code ?? null,
      procedure_id: values.drug_administration?.procedure_id ?? null,
      name: typeof values.name === "string" ? values.name : values.name.name,
    };

    delete medication.drug_administration;
    delete medication.preset_name;

    if (presetType === "medications_on_site") {
      delete medication.continuous_use;

      setMedications((prescriptions) => [...prescriptions, medication]);
    } else {
      if (medication.continuous_use) medication.quantity = null;

      setMedications((prescriptions) => [...prescriptions, medication]);
    }

    reset({
      preset_name: values.preset_name,
      drug_administration: values.drug_administration,
    });
  };

  const removePrescription = (index) => {
    const newPrescriptions = medications.filter((p, i) => i !== index);
    setMedications(newPrescriptions);
  };

  const submitPresets = () => {
    const presetdata = {
      describe: presetName,
      type: presetType,
      data: medications,
    };

    if (type === "edit") {
      patchPresetMutation.mutate(
        { presetId: data.id, data: presetdata },
        {
          onSuccess(response) {
            notify(response.message, "success");
            queryClient.invalidateQueries("presets");
          },
          onError(error) {
            notify(error.message, "error");
          },
        }
      );
    } else {
      postPresetsMutation.mutate(presetdata, {
        onSuccess(response) {
          reset({
            preset_name: "",
            drug_administration: null,
          });
          setMedications([]);
          notify(response.message, "success");
          queryClient.invalidateQueries("presets");
        },
        onError(error) {
          notify(error.message, "error");
        },
      });
    }
  };

  const radios = [
    { label: "Sim", value: true },
    { label: "Não", value: false },
  ];

  const tableColumns =
    presetType === "medications_on_site"
      ? [
          { name: "Nome", field: "name" },
          { name: "Quantidade", field: "quantity" },
          { name: "Apresentação", field: "presentation" },
          { name: "Observação", field: "notes" },
        ]
      : [
          { name: "Nome", field: "name" },
          { name: "Quantidade", field: "quantity" },
          { name: "Apresentação", field: "presentation" },
          { name: "Posologia", field: "posology" },
          {
            name: "Uso contínuo",
            field: "continuous_use",
            use: (value) => (value ? "Sim" : "Não"),
          },
          {
            name: "Receituário especial",
            field: "special",
            use: (value) => (value ? "Sim" : "Não"),
          },
          { name: "Observação", field: "notes" },
        ];

  return (
    <Grid container spacing={2} component="form" marginTop="0.5rem">
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography fontSize={18} color="secondary" fontWeight="600">
            Dados da pré definição
          </Typography>
        </Grid>
        <Grid md={3} xs={4} item>
          <TextField name="preset_name" label="Nome da pré definição" requerid control={control} />
        </Grid>
      </Grid>
      <Grid container item xs={12} spacing={2}>
        <Grid item xs={12}>
          <Typography fontSize={18} color="secondary" fontWeight="600">
            Dados dos medicamentos
          </Typography>
        </Grid>
        <Grid item md={1.2} xs={2.5}>
          <RadioField
            name="in_system"
            label="Medicamento no sistema?"
            control={control}
            options={radios}
            optionLabelKey="label"
            optionValueKey="value"
            orientation="row"
            required
            customOnChange={() => setValue("name", null)}
          />
        </Grid>
        <Grid md={4} xs={6} item>
          {inSystem ? (
            <PaginatedAutocompleteField
              name="name"
              label="Medicamento"
              control={control}
              required
              filterKey="filters"
              optionLabelKey="describe"
              queryKey="procedures"
              service={(params) =>
                getProcedures({
                  ...params,
                  type: "medications",
                  filter: "active",
                })
              }
              autoCompleteProps={{
                getOptionLabel: (data) => {
                  let label = data?.name;

                  if (data?.tuss_code) {
                    label = `${data?.tuss_code} - ${label}`;
                  }

                  return label;
                },
              }}
            />
          ) : (
            <TextField name="name" label="Medicamento" control={control} required />
          )}
        </Grid>
        <Grid md={2} xs={3} item>
          <TextField
            name="quantity"
            control={control}
            label="Quantidade"
            type="number"
            disabled={continuousUse}
            required={!continuousUse}
          />
        </Grid>
        <Grid md={2} xs={6} item>
          <TextField
            name="presentation"
            label="Apresentação"
            control={control}
            inputProps={{ maxLength: 50 }}
            required
          />
        </Grid>
        <Grid xs={4} md={3} item>
          <AutocompleteField
            name="drug_administration"
            label="Via de adminstração"
            control={control}
            required
            options={drugAdmininstrations}
            optionCompareKey="id"
            autoCompleteProps={{
              getOptionLabel: (data) => data.describe + " - " + data.title,
            }}
          />
        </Grid>
        {presetType === "medications" && (
          <Grid md={1.2} xs={2.5} item>
            <RadioField
              name="continuous_use"
              label="Uso contínuo?"
              control={control}
              options={radios}
              optionLabelKey="label"
              optionValueKey="value"
              orientation="row"
            />
          </Grid>
        )}
        <Grid xs={4.2} item>
          <TextField
            name="posology"
            label="Posologia"
            control={control}
            inputProps={{ maxLength: 100 }}
            required
          />
        </Grid>
        {presetType === "medications" && (
          <Grid md={1.2} xs={2.5} item>
            <RadioField
              name="special"
              label="Receituário Especial?"
              control={control}
              options={radios}
              optionLabelKey="label"
              optionValueKey="value"
              orientation="row"
            />
          </Grid>
        )}
        <Grid md={7} xs={8} item>
          <TextField name="notes" label="Observação" control={control} />
        </Grid>
        <Grid item md={3} xs={4}>
          <Button onClick={handleSubmit(addPrescription)}>Adicionar Medicamento</Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TableFilter
          data={medications}
          columns={tableColumns}
          noHeaderOptions
          actions
          actionsTypes={["delete"]}
          confirmMessage="Você realmente deseja *remover* esse medicamento?"
          actionHandleDelete={removePrescription}
        />
      </Grid>
      <Grid item xs={12}>
        <Button
          loading={patchPresetMutation.isLoading || postPresetsMutation.isLoading}
          onClick={submitPresets}
          disabled={!medications.length || !presetName}
        >
          Salvar
        </Button>
      </Grid>
    </Grid>
  );
}

export default DrugsPreset;
