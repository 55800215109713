import React from "react";
import { Box, Chip, Divider, Stack, Tooltip, Typography, useTheme } from "@mui/material";
import calcPercent from "../../utils/calcPercent";

export default function ColorInfoLabels({
  labels,
  text = "Mapa de cores",
  handleFilter,
  quantity,
}) {
  const [active, setActive] = React.useState("");
  const theme = useTheme();

  const handleClick = (label) => {
    setActive((currentLabel) => {
      if (currentLabel === label.key) {
        if (handleFilter) {
          handleFilter(null);
        }
        return "";
      }
      if (handleFilter) {
        handleFilter(label.key);
      }
      return label.key;
    });
  };

  return (
    <Box
      sx={{
        marginBottom: "10px",
      }}
    >
      <Typography
        sx={{
          marginBottom: "5px",
          color: theme.palette.primary.light,
          fontWeight: "medium",
        }}
      >
        {text}
      </Typography>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          flexWrap: "wrap",
          marginBottom: "10px",
        }}
      >
        {labels.map((label, index) => (
          <Box
            key={index}
            sx={{
              display: "flex",
              gap: "5px",
              alignItems: "center",
            }}
          >
            {!label.icon && (
              <Box
                sx={{
                  width: "25px",
                  height: "15px",
                  borderRadius: "4px",
                  background: label.color || theme.palette.primary.main,
                }}
              />
            )}

            {label.icon ? (
              <Tooltip title={label.text} placement="right-end">
                {
                  <Chip
                    sx={{
                      background:
                        !active || active === label.key ? label.color : theme.palette.text.disabled,
                      color: "white",
                      fill: "white",
                      fontWeight: "700",
                      "& .MuiChip-icon": {
                        color: "white",
                      },
                      "& .MuiChip-deleteIcon": {
                        margin: 0,
                        cursor: "pointer",
                      },
                      "&:hover": {
                        opacity: 0.8,
                        background:
                          !active || active === label.key
                            ? label.color
                            : theme.palette.text.disabled,
                      },
                    }}
                    onClick={() => handleClick(label)}
                    deleteIcon={
                      <Stack direction="row">
                        <Chip
                          label={quantity?.[label.key]}
                          sx={{
                            cursor: "pointer",
                            borderRadius: !quantity?.total ? "0 16px 16px 0" : "initial",
                            color: "white",
                          }}
                        />
                        {!!quantity?.total && (
                          <>
                            <Divider orientation="vertical" variant="middle" flexItem />
                            <Chip
                              label={`${calcPercent(quantity?.[label.key], quantity?.total)}%`}
                              sx={{
                                borderRadius: "0 16px 16px 0",
                                cursor: "pointer",
                                color: "white",
                              }}
                            />
                          </>
                        )}
                      </Stack>
                    }
                    onDelete={!!quantity ? () => handleClick(label) : null}
                    icon={label.icon}
                    label={label.text}
                  />
                }
              </Tooltip>
            ) : (
              <Typography
                sx={{
                  fontSize: 12,
                }}
              >
                {label.text}
              </Typography>
            )}
          </Box>
        ))}
      </Box>
    </Box>
  );
}
