import React, { useContext, useMemo, useRef } from "react";
import TableFilter from "../../../components/Table/TableFilter";
import {
  Button,
  DateField,
  PaginatedAutocompleteField,
  TextField,
} from "../../../components/FormFields";
import { Box, TablePagination, Typography, useTheme, Tooltip, Grid } from "@mui/material";
import { format, parseISO } from "date-fns";
import { useMutation, useQueries, useQuery, useQueryClient } from "react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { FileDownloadOff, InfoRounded, Publish, SearchRounded } from "@mui/icons-material";
import { Dialog, DialogMedium } from "../../../helper";
import { usePatient, useSignedDocuments } from "../../../service";
import { DynamicFeedback } from "../../../components";
import {
  againstReferencePDF,
  attendanceStatementPDF,
  drugOnSitePrescriptionPDF,
  drugPrescriptionPDF,
  evolutionsPDF,
  examPrescriptionPDF,
  followUpStatementPDF,
  formMakerPDF,
  medicalStatementPDF,
  patientDischargePDF,
  sadtPDF,
  soapPDF,
  specialPrescriptionPDF,
} from "../../../pdfModels";
import { FormMakerContext } from "../../../contexts/FormMakerContext";
import { useNotifier } from "../../../hooks";
import useSignaturePassword from "../../../hooks/useSignaturePassword";
import {
  convertDateToBirthday,
  formatOdontoProcedures,
  formatOdontoRps,
  openURL,
  pdfToBase64,
  pdfToBlob,
} from "../../../utils";
import Accordion from "../../../components/Accordion";
import Yup from "../../../config/yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompany } from "../../../service/useCompany";
import useService from "../../../service/useServices";
import usePermissions from "../../../config/usePermissions";
import FullPrintForm from "../FullPrintForm";

const medicalRecordTitles = {
  medications: "Evolução Farmaceutica",
  medications_on_site: "Evolução de Medicamentos",
  lab: "Evolução de Exames Laboratoriais",
  xray: "Evolução de Raio-X",
  ecg: "Evolução de ECG",
  procedure: "Evolução de Procedimentos",
};

const loadingMessages = {
  generating: "Gerando documentos",
  signing: "Assinando documentos",
  null: "Carregando",
};

const initialData = {
  totalItems: 0,
  items: [],
  metadata: {
    status: {
      to_sign: false,
    },
  },
};

const filterValidations = Yup.object().shape({
  attendance_number: Yup.number().nullable(),
  patients: Yup.array(),
  companies: Yup.array(),
  services: Yup.array(),
  initial_date: Yup.date().nullable(),
  final_date: Yup.date().nullable(),
});

export default function ProntuaryDocuments({ patientId }) {
  const maxItems = 100;
  const theme = useTheme();
  const notify = useNotifier();
  const validateSignature = useSignaturePassword();
  const { feedbackFormatter } = useContext(FormMakerContext);
  const [document, setDocument] = useState(null);
  const submitType = useRef(null);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState({});
  const [enabledQuery, setEnabledQuery] = useState({});
  const [loadingType, setLoadingType] = useState(null);
  const [toSignCount, setToSignCount] = useState(0);
  const [openMergeForm, setOpenMergeForm] = useState(false);

  const pendingSignaturePermissions = usePermissions("/pending-signature");
  const fullDocumentPrintingPermissions = usePermissions("/full-document-printing");
  const permissions = patientId ? fullDocumentPrintingPermissions : pendingSignaturePermissions;

  const signatureForm = useForm({
    defaultValues: {},
  });

  const filterForm = useForm({
    resolver: yupResolver(filterValidations),
    defaultValues: {
      attendance_number: null,
      patients: [],
      companies: [],
      services: [],
      initial_date: null,
      final_date: null,
    },
  });

  const documentsToSign = signatureForm.watch();
  const allDocumentsToSign = Object.values(documentsToSign).flat();

  const queryClient = useQueryClient();
  const { getSignedDocuments, signDocuments, getSignedDocumentsStatistics } = useSignedDocuments();
  const { getPatients } = usePatient();
  const { getCompanies } = useCompany();
  const { getServices } = useService();

  const signDocumentMutation = useMutation(signDocuments);

  const documentTypesQuery = useQuery(
    ["pending-documents", patientId, filters],
    () => {
      const params = {
        status: ["pending", "to_sign"],
        ...filters,
      };

      if (patientId) {
        params.patient_id = patientId;
        params.status.push("signed");
      } else {
        params.created_by_me = true;
      }

      return getSignedDocumentsStatistics(params);
    },
    {
      initialData: [],
      onSuccess(response) {
        const initialDocumentsToSign = {};
        const inititalPagination = {};
        let toSignCount = 0;

        response.forEach(({ type, to_sign_count }) => {
          initialDocumentsToSign[type] = [];
          inititalPagination[type] = {
            page: 0,
            limit: 5,
          };

          if (to_sign_count) {
            toSignCount += to_sign_count;
          }
        });

        if (toSignCount && !patientId) {
          submitType.current = "batch_signature";
          setLoadingType("signing");
          setToSignCount(toSignCount);
        } else {
          submitType.current = null;
          setLoadingType(null);
          setToSignCount(0);
        }

        setPagination(inititalPagination);
        signatureForm.reset(initialDocumentsToSign);
      },
      onError(error) {
        if (patientId) return;

        submitType.current = null;
        setLoadingType(null);
        setToSignCount(0);
        notify(error.message, "error");
      },
    }
  );

  const queries = useMemo(() => {
    return documentTypesQuery.data.map(({ type }) => {
      return {
        queryKey: ["signed-documents", patientId, type, filters, pagination[type]],
        queryFn: () => {
          const params = {
            status: ["pending", "to_sign"],
            type,
            ...filters,
            ...pagination[type],
          };

          if (patientId) {
            params.patient_id = patientId;
            params.status.push("signed");
          } else {
            params.created_by_me = true;
          }

          return getSignedDocuments(params);
        },
        enabled: !!enabledQuery[type],
        initialData,
      };
    });
  }, [enabledQuery, filters, pagination, documentTypesQuery.data]);

  const documentsQueries = useQueries(queries);

  let isLoading = false;

  documentsQueries.forEach((query) => {
    if (!isLoading) {
      isLoading = query.isFetching;
    }
  });

  async function handleSign() {
    try {
      setLoadingType("generating");

      const documentsPromise = [];

      for (const document of allDocumentsToSign) {
        const signedDocumentData = documentTypes[document.type].handler(document, "send");
        documentsPromise.push(signedDocumentData);
      }

      const documentsToSign = await Promise.all(documentsPromise);

      const data = {
        type: submitType.current,
        documents: documentsToSign,
      };

      validateSignature(
        () => {
          setLoadingType("signing");

          signDocumentMutation.mutate(data, {
            onSuccess(response) {
              notify(response.message, "success");
            },
            onError(error) {
              submitType.current = null;
              setLoadingType(null);
              notify(error.message, "error");
            },
            onSettled() {
              signatureForm.reset();
              documentTypesQuery.refetch();
              queryClient.invalidateQueries("signed-documents");
            },
          });
        },
        false,
        () => {
          submitType.current = null;
          setLoadingType(null);
        }
      );
    } catch (error) {
      console.error(error);
      setLoadingType(null);
      return notify("Erro ao gerar o documentos", "error");
    }
  }

  function idMapper(array) {
    return array.map(({ id }) => id);
  }

  const onSubmitFilters = filterForm.handleSubmit((values) => {
    const pagination = {};
    const filters = {
      attendance_number: values.attendance_number,
      patient_id: patientId ?? idMapper(values.patients),
      company_id: idMapper(values.companies),
      service_id: idMapper(values.services),
      initial_date: values.initial_date ? format(values.initial_date, "yyyy-MM-dd") : null,
      final_date: values.final_date ? format(values.final_date, "yyyy-MM-dd") : null,
    };

    documentTypesQuery.data.forEach((type) => {
      pagination[type.value] = {
        page: 0,
        limit: 5,
      };
    });

    signatureForm.reset();
    setPagination(pagination);
    setFilters(filters);
  });

  function handleTitle(title) {
    if (document?.type === "declaration") {
      return `Declaração de ${document.documentationHistory.type}`;
    }

    if (document?.type === "medical_record") {
      return medicalRecordTitles[document.medicalRecord.type];
    }

    return title;
  }

  async function handleCheckAll(documents, query, type) {
    if (!!loadingType) {
      return;
    }

    const documentsToSignByType = documentsToSign[type];
    const totalItemsToSign = allDocumentsToSign.length;
    const totalItems = query.data.items.length;

    const hasSelectedDocuments = documents.some((document) => {
      return documentsToSignByType.some((documentToSign) => document.id === documentToSign.id);
    });

    if (hasSelectedDocuments) {
      const newDocumentsToSign = documentsToSignByType.filter((document) => {
        return !documents.some((documentToSign) => document.id === documentToSign.id);
      });

      signatureForm.setValue(type, newDocumentsToSign);
    } else if (totalItemsToSign < maxItems) {
      const itemsToFill = maxItems - totalItemsToSign;
      const maxIndex = totalItems < itemsToFill ? totalItems : itemsToFill;
      const slicedItems = query.data.items.slice(0, maxIndex);
      const documentsToAdd = [];

      slicedItems.forEach((item) => {
        const isSelected = documentsToSignByType.some(
          (documentToSign) => item.id === documentToSign.id
        );

        if (!isSelected) {
          documentsToAdd.push(item);
        }
      });

      signatureForm.setValue(type, documentsToSignByType.concat(documentsToAdd));
    }
  }

  const documentTypes = {
    medications: {
      title: "Receituário",
      handler: async (document, type) => {
        const patient = document.attendance.patient;
        const medications = document.prescription.medications.filter((exam) => {
          return !exam.special && exam.tab_id === document.tab_id;
        });

        const pdf = drugPrescriptionPDF(medications, patient, document);

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_x: 215,
              visible_sign_y: 590,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    medications_on_site: {
      title: "Prescrição de Medicamentos",
      handler: async (document, type) => {
        const patient = document.attendance.patient;
        const medications = document.prescription.medications_on_site.filter((exam) => {
          return exam.tab_id === document.tab_id;
        });

        const pdf = drugOnSitePrescriptionPDF(medications, patient, document);

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_x: 180,
              visible_sign_y: 600,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    special_medications: {
      title: "Receituário Especial",
      handler: async (document, type) => {
        const patient = document.attendance.patient;
        const medications = document.prescription.medications.filter((exam) => {
          return exam.special && exam.tab_id === document.tab_id;
        });

        const pdf = specialPrescriptionPDF(medications, patient, document);

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_x: 215,
              visible_sign_y: 590,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    xray: {
      title: "SADT(RAIO-X)",
      handler: async (document, type) => {
        const patient = document.attendance.patient;
        const documentData = {
          employee: document.employee_specialty.employee,
          company: document.company,
        };

        const exams = document.prescription.exams.filter((exam) => {
          return (
            exam.type === "Imagem" &&
            exam.procedure?.type?.includes("xray") &&
            exam.tab_id === document.tab_id
          );
        });

        const pdf = sadtPDF("xray", exams, patient, documentData);

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_x: 30,
              visible_sign_y: 630,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    image: {
      title: "SADT(IMAGEM)",
      handler: async (document, type) => {
        const patient = document.attendance.patient;
        const documentData = {
          employee: document.employee_specialty.employee,
          company: document.company,
        };

        const exams = document.prescription.exams.filter((exam) => {
          return (
            exam.type === "Imagem" &&
            !exam.procedure?.type?.includes("xray") &&
            exam.tab_id === document.tab_id
          );
        });

        const pdf = sadtPDF("xray", exams, patient, documentData);

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_x: 30,
              visible_sign_y: 630,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    lab: {
      title: "SADT(LABORATORIAL)",
      handler: async (document, type) => {
        const patient = document.attendance.patient;
        const documentData = {
          employee: document.employee_specialty.employee,
          company: document.company,
        };

        const exams = document.prescription.exams.filter((exam) => {
          return exam.type === "Laboratorial" && exam.tab_id === document.tab_id;
        });

        const pdf = sadtPDF("lab", exams, patient, documentData);

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_x: 30,
              visible_sign_y: 630,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    exams: {
      title: "Prescrição de Exames",
      handler: async (document, type) => {
        const patient = document.attendance.patient;

        const pdf = examPrescriptionPDF(document.prescription.exams, patient, {
          createdAt: document.createdAt,
          employee: document.employee_specialty.employee,
          company: document.company,
        });

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: document.prescription.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
          };
        }

        pdf.open();
      },
    },
    declaration: {
      title: "Declaração",
      handler: async (document, type) => {
        const {
          documentationHistory,
          attendance: { patient },
        } = document;

        let pdf;

        if (documentationHistory.type === "Comparecimento") {
          pdf = attendanceStatementPDF(documentationHistory, patient);
        }

        if (documentationHistory.type === "Atestado") {
          pdf = medicalStatementPDF(documentationHistory, patient);
        }

        if (documentationHistory.type === "Acompanhamento") {
          pdf = followUpStatementPDF(documentationHistory, patient);
        }

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: documentationHistory.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_page: "*",
              visible_sign_x: 170,
              visible_sign_y: 595,
            },
          };
        }

        pdf.open();
      },
    },
    against_reference: {
      title: "Ficha de Referência / Contra Referência",
      handler: async (document, type) => {
        const patient = document.attendance.patient;

        const pdf = againstReferencePDF(
          document.against_reference,
          patient,
          document.against_reference.cids
        );

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: document.against_reference.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_x: 200,
              visible_sign_y: 560,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    patient_discharge: {
      title: "Alta",
      handler: async (document, type) => {
        const {
          patientDischarge,
          attendance: { patient },
        } = document;

        const pdf = patientDischargePDF(patientDischarge, patient, {
          createdAt: patientDischarge.createdAt,
          employee: document.employee_specialty.employee,
        });

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: patientDischarge.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_page: "*",
              visible_sign_x: 170,
              visible_sign_y: 595,
            },
          };
        }

        pdf.open();
      },
    },
    soap: {
      title: "Prontuário",
      handler: async (document, type) => {
        const soapData = document.soap;
        soapData.attendance = document.attendance;
        const {
          service: { odontology, ceo },
          patient,
        } = document.attendance;

        const complementtaryData = {
          createdAt: document.createdAt,
          employee: document.employee_specialty.employee,
        };

        let pdf;

        if (odontology || ceo) {
          const rps = formatOdontoRps(soapData.plan.sextant);
          const odontoProcedures = formatOdontoProcedures(soapData.plan);

          pdf = soapPDF(soapData, patient, complementtaryData, odontoProcedures, rps);
        } else {
          pdf = soapPDF(soapData, patient, complementtaryData);
        }

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: soapData.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_x: 170,
              visible_sign_y: 765,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    medical_record: {
      title: "Evolução",
      handler: async (document, type) => {
        const medicalRecord = document.medicalRecord;

        const pdf = evolutionsPDF(medicalRecord, document.attendance.patient, {
          title: medicalRecordTitles[medicalRecord.type],
          createdAt: medicalRecord.createdAt,
          employee: medicalRecord.finished_by,
        });

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: medicalRecord.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_x: 170,
              visible_sign_y: 595,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
    risk_classification: {
      title: "Classificação de Risco",
      handler: async (document, type) => {
        const patient = document?.attendance?.patient;
        const classification = document.riskClassification;
        const documentRecord = classification.document_record;

        const patientData = [
          {
            label: "Paciente",
            value: patient?.social_prioritize
              ? patient.social_name
              : patient?.name || "Não informado",
          },
          {
            label: "CPF",
            value: patient?.physic_national || "Não informado",
          },
          {
            label: "Ficha",
            value: classification.token_audit.token_describe,
          },
          { label: "CNS", value: patient?.sus_card || "Não informado" },
          {
            label: "Data da classificação",
            value: format(parseISO(classification.updatedAt), "dd/MM/yyyy"),
          },
          {
            label: "Data de nascimento",
            value: patient?.birth_date
              ? format(parseISO(patient.birth_date), "dd/MM/yyyy")
              : "Não informado",
          },
          {
            label: "Horário da classificação",
            value: format(parseISO(classification.updatedAt), "HH:mm"),
          },
        ];

        documentRecord.document_data.document_id = {
          versions: documentRecord.document_structure.versions,
        };

        const formattedRecord = feedbackFormatter(documentRecord.document_data);

        const documentDataToPDF = {
          name: documentRecord.document_structure.name,
          status: "Finalizado",
          version: documentRecord.document_data.version,
          employee: document.employee_specialty.employee,
          created_at: documentRecord.createdAt,
          fields: formattedRecord,
        };

        const pdf = formMakerPDF(documentDataToPDF, patientData, true, "risk_classification");

        if (type === "merge") {
          return await pdfToBlob(pdf);
        }

        if (type === "send") {
          return {
            id: document.id,
            type: document.type,
            document_id: classification.id,
            original_file_name: `${document.id}.pdf`,
            data: await pdfToBase64(pdf),
            signature_settings: {
              visible_sign_x: 170,
              visible_sign_y: 615,
              visible_sign_page: "*",
            },
          };
        }

        pdf.open();
      },
    },
  };

  const tableColumns = [
    {
      name: "N. atendimento",
      field: "attendance?.number",
      use: (number) => number ?? "Não informado",
    },
    {
      name: "Serviço",
      field: "attendance?.service.describe",
      use: (service) => service ?? "Não informado",
    },
    {
      name: "Unidade",
      field: "company?.name",
      use: (company) => company ?? "Não informado",
    },
    {
      name: "Paciente",
      field: "attendance?.patient",
      use: (patient) => {
        if (!patient) return "Não identificado";

        return patient.social_prioritize ? patient.social_name : patient.name;
      },
    },
    {
      name: "Documento",
      field: "type",
      use: (type, row) => {
        if (type === "declaration") {
          return `Declaração de ${row.documentationHistory.type}`;
        }

        if (row.type === "medical_record") {
          return medicalRecordTitles[row.medicalRecord.type];
        }

        return documentTypes[type].title;
      },
    },
    {
      name: "Criado em",
      field: "createdAt",
      use: (date) => format(parseISO(date), "dd/MM/yyyy"),
    },
  ];

  if (patientId) {
    tableColumns.splice(5, 0, {
      name: "Profissional",
      field: "employee_specialty.employee.name",
    });
  } else {
    tableColumns.push({
      name: "Status",
      alignInRow: "center",
      use: (_, row) => (
        <Tooltip title={row.status === "to_sign" ? "Assinando" : "Sem assinatura"}>
          {row.status === "to_sign" ? (
            <Publish color="primary" fontSize="large" />
          ) : (
            <FileDownloadOff color="primary" fontSize="large" />
          )}
        </Tooltip>
      ),
    });
  }

  const feedbackShape = [
    {
      type: "header",
      label: "#1 Dados do paciente",
    },
    {
      type: "description",
      label: "Cod. paciente",
      valueKey: "attendance.patient.number",
    },
    {
      type: "description",
      label: "Nome",
      valueKey: "attendance.patient",
      formatter: (patient) => {
        if (!patient) return "Não identificado";

        return patient.social_prioritize ? patient.social_name : patient.name;
      },
    },
    {
      type: "description",
      label: "Gênero",
      valueKey: "attendance.patient.gender",
    },
    {
      type: "description",
      label: "Idade",
      valueKey: "attendance.patient.birth_date",
      formatter: (date) =>
        date ? convertDateToBirthday(date, ["years", "months"]) : "Não informado",
    },
    {
      type: "description",
      label: "Data de nascimento",
      valueKey: "attendance.patient.birth_date",
      formatter: (date) => (date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado"),
    },
    {
      type: "description",
      label: "CPF",
      valueKey: "attendance.patient.physic_national",
      formatter: (value) => value ?? "Não informado",
    },
    {
      type: "description",
      label: "RG",
      valueKey: "attendance.patient.ident_national",
      formatter: (value) => value ?? "Não informado",
    },
    {
      type: "description",
      label: "CNS",
      valueKey: "attendance.patient.sus_card",
      formatter: (value) => value ?? "Não informado",
    },
    {
      type: "header",
      label: "#2 Dados do prestador",
    },
    {
      type: "description",
      label: "Nome",
      valueKey: "employee_specialty.employee.name",
    },
    {
      type: "description",
      label: "Especialidade",
      valueKey: "employee_specialty.specialty.describe",
    },
    {
      type: "header",
      label: "#3 Informações complementares",
    },
    {
      type: "description",
      label: "N. atendimento",
      valueKey: "attendance.number",
      formatter: (number) => number ?? "Não informado",
    },
    {
      type: "description",
      label: "Serviço",
      valueKey: "attendance.service.describe",
      formatter: (service) => service ?? "Não informado",
    },
    {
      type: "description",
      label: "Compania",
      valueKey: "company.name",
      formatter: (company) => company ?? "Não informado",
    },
    {
      type: "description",
      label: "Data do documento",
      valueKey: "createdAt",
      formatter: (date) => (date ? format(parseISO(date), "dd/MM/yyyy") : "Não informado"),
    },
  ];

  return (
    <>
      <Dialog
        title={handleTitle(documentTypes[document?.type]?.title)}
        open={!!document}
        handleClose={() => setDocument(null)}
      >
        {document && <DynamicFeedback data={document} shape={feedbackShape} />}
      </Dialog>
      <DialogMedium
        title="Informações para liberação"
        fullWidth
        maxWidth="md"
        open={openMergeForm}
        handleClose={() => setOpenMergeForm(false)}
      >
        <FullPrintForm
          patientId={patientId}
          documentTypes={documentTypes}
          documents={allDocumentsToSign}
          handleClose={() => {
            signatureForm.reset();
            setOpenMergeForm(false);
          }}
        />
      </DialogMedium>
      {!patientId && !!loadingType && (
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            gap: "8px",
            margin: "20px auto 32px auto",
            maxWidth: "800px",
            padding: "12px 22px",
            borderRadius: "8px",
            backgroundColor: theme.palette.primary.medium,
            boxShadow: theme.shadows[1],
          }}
        >
          <InfoRounded color="primary" fontSize="large" />
          <Typography
            sx={{
              fontSize: "14px",
            }}
          >
            {submitType.current === "batch_signature"
              ? "Os documentos marcados para assinar estão em processamento para aplicação da sua assinatura digital. Você poderá assinar outros documentos assim que os anteriores forem finalizados, sinta-se a vontade para utilizar o sistema normalmente."
              : "Os documentos marcados para assinar estão sendo processados em modo de prioridade para aplicãção da sua assinatura digital, por favor, permaneça na tela e aguarde."}
          </Typography>
        </Box>
      )}
      <Grid container spacing={2} my="12px">
        <Grid item xs={4}>
          <TextField
            label="Número do atendimento"
            name="attendance_number"
            control={filterForm.control}
          />
        </Grid>
        {!patientId && (
          <Grid item xs={4}>
            <PaginatedAutocompleteField
              label="Paciente"
              name="patients"
              multiple
              control={filterForm.control}
              filterKey="name"
              queryKey="patients"
              service={getPatients}
              optionLabelKey="name"
              optionCompareKey="id"
            />
          </Grid>
        )}
        <Grid item xs={4}>
          <PaginatedAutocompleteField
            label="Compania"
            name="companies"
            control={filterForm.control}
            multiple
            filterKey="name"
            queryKey="companies"
            service={getCompanies}
            optionLabelKey="name"
            optionCompareKey="id"
          />
        </Grid>
        <Grid item xs={3}>
          <PaginatedAutocompleteField
            label="Serviço"
            name="services"
            control={filterForm.control}
            multiple
            filterKey="describe"
            queryKey="services"
            service={({ describe }) => getServices({ describe })}
            optionLabelKey="describe"
            optionCompareKey="id"
          />
        </Grid>
        <Grid item xs={3}>
          <DateField label="Data inicial" name="initial_date" control={filterForm.control} />
        </Grid>
        <Grid item xs={3}>
          <DateField label="Data final" name="final_date" control={filterForm.control} />
        </Grid>
        <Grid item xs={12} display="flex" justifyContent="flex-end" gap="12px">
          <Button loadingMessage={null} variant="text" onClick={() => filterForm.reset()}>
            Limpar os dados
          </Button>
          <Button loadingMessage={null} loading={isLoading} onClick={onSubmitFilters}>
            <SearchRounded />
          </Button>
        </Grid>
      </Grid>
      <Box display="flex" justifyContent="space-between" alignItems="center" my="20px">
        <Typography
          component="span"
          sx={{
            padding: "8px 16px",
            borderRadius: "8px",
            backgroundColor: theme.palette.primary.light,
            color: "#fff",
            fontWeight: "600",
            fontSize: "16px",
          }}
        >
          {toSignCount || allDocumentsToSign.length} / {maxItems}
        </Typography>
        <Button
          disabled={submitType.current === "urgency_signature"}
          onClick={() => {
            documentTypesQuery.refetch();
            queryClient.invalidateQueries("signed-documents");
          }}
        >
          Atualizar
        </Button>
      </Box>
      <Accordion
        defaultOpen={false}
        configs={documentsQueries.map((query, index) => {
          const documentType = documentTypesQuery.data[index];

          return {
            customOnChange: () => {
              if (!query.isFetched) {
                setEnabledQuery((enabled) => ({ ...enabled, [documentType.type]: true }));
              }
            },
            title: (
              <Box display="flex" justifyContent="space-between" alignItems="center" width="100%">
                <Typography fontWeight="500">{documentTypes[documentType.type].title}</Typography>
                <Typography>
                  <Typography fontWeight="500" component="span">
                    Total:
                  </Typography>{" "}
                  {documentType.total}
                </Typography>
              </Box>
            ),
            body: (
              <>
                <TableFilter
                  data={query.data.items}
                  columns={tableColumns}
                  actions
                  actionsTypes={["checkbox", "print", "view"]}
                  noFilter
                  loading={query.isFetching}
                  emptyMessage={query.error?.message}
                  disableActions={(row, action) => {
                    const isSelected = documentsToSign[documentType.type].find(
                      (document) => document.id === row.id
                    );

                    if (action === "checkbox") {
                      return (
                        (!isSelected && allDocumentsToSign.length >= maxItems) || !!loadingType
                      );
                    }

                    return !permissions.update || !!loadingType;
                  }}
                  actionHandleView={(_, row) => setDocument(row)}
                  actionHandlePrint={(_, row) => {
                    if (row.result) {
                      return openURL(row.result);
                    }

                    documentTypes[row.type].handler(row);
                  }}
                  checkboxActionController={signatureForm}
                  actionUniqueIdentifier="id"
                  checkBoxName={documentType.type}
                  handleCheckAll={(documents) =>
                    handleCheckAll(documents, query, documentType.type)
                  }
                />
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <TablePagination
                    count={query.data.totalItems ?? 0}
                    component="div"
                    page={pagination[documentType.type]?.page ?? 0}
                    onPageChange={(_, newPage) => {
                      setPagination((pagination) => ({
                        ...pagination,
                        [documentType.type]: {
                          ...pagination[documentType.type],
                          page: newPage,
                        },
                      }));
                    }}
                    rowsPerPage={pagination[documentType.type]?.limit ?? 5}
                    rowsPerPageOptions={[5, 10, 20, 75]}
                    onRowsPerPageChange={(event) => {
                      const limit = parseInt(event.target.value);
                      setPagination((pagination) => ({
                        ...pagination,
                        [documentType.type]: {
                          limit,
                          page: 0,
                        },
                      }));
                      signatureForm.setValue(documentType.type, []);
                    }}
                  />
                </Box>
              </>
            ),
          };
        })}
      />
      {!documentsQueries.length && (
        <Typography
          sx={{
            textAlign: "center",
            fontSize: "16px",
            fontWeight: "500",
            color: theme.palette.primary.main,
            my: "40px",
          }}
        >
          Nenhum documento encontrado.
        </Typography>
      )}
      <Box display="flex" gap="20px" mt="20px">
        {!patientId ? (
          <>
            <Button
              loading={!!loadingType && submitType.current === "batch_signature"}
              disabled={!permissions.update || !!loadingType || !allDocumentsToSign.length}
              loadingMessage={loadingMessages[loadingType]}
              onClick={() => {
                submitType.current = "batch_signature";
                handleSign();
              }}
            >
              Assinar em lote
            </Button>
            <Button
              loading={!!loadingType && submitType.current === "urgency_signature"}
              disabled={
                !permissions.update || !allDocumentsToSign.length || allDocumentsToSign.length > 15
              }
              color="error"
              loadingMessage={loadingMessages[loadingType]}
              onClick={() => {
                submitType.current = "urgency_signature";
                handleSign();
              }}
            >
              Assinar com prioridade
            </Button>
          </>
        ) : (
          <Button
            disabled={!permissions.update || !allDocumentsToSign.length}
            onClick={() => setOpenMergeForm(true)}
          >
            Imprimir
          </Button>
        )}
      </Box>
    </>
  );
}
