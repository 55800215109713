import { Grid } from "@mui/material";
import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import {
  AutocompleteField,
  Button,
  DateField,
  PaginatedAutocompleteField,
  SelectField,
} from "../../../../components/FormFields";
import { useMicroArea, useTeam } from "../../../../service";
import { useCompany } from "../../../../service/useCompany";
import { AuthContext } from "../../../../contexts/AuthContext";
import { addMonths } from "date-fns";

export default function FormFilters({ onSubmit, values, filtersToHideIndex = [], limitDate }) {
  const { userData } = useContext(AuthContext);
  const optionsReportTypes = [{ describe: "Sintético" }, { describe: "Analítico" }];
  const actualCompany = userData?.company.is_master ? null : userData?.company;
  const date = new Date();

  const initialValues = {
    report_types: values?.report_types || optionsReportTypes[0],
    sts: values?.sts || null,
    company: values?.company || actualCompany,
    team: values?.team || null,
    micro_area: values?.micro_area || [],
    initial_date: values?.initial_date || null,
    final_date: values?.final_date || null,
  };

  const { control, handleSubmit, reset, watch, setValue } = useForm({
    defaultValues: initialValues,
  });

  const { getMicroArea } = useMicroArea();
  const { getCompanies } = useCompany();
  const { getTeam } = useTeam();

  const [initialDate, sts, company, team] = watch(["initial_date", "sts", "company", "team"]);

  const formFields = [
    {
      0: (
        <SelectField
          control={control}
          name="report_types"
          label="Tipo de Relatório"
          options={optionsReportTypes}
          optionLabelKey={"describe"}
          required
        />
      ),
    },
    {
      1: (
        <AutocompleteField
          control={control}
          name="sts"
          label="Região"
          options={[
            { describe: "ITAIM" },
            { describe: "SAO MIGUEL" },
            { describe: "ITAQUERA" },
            { describe: "TIRADENTES" },
            { describe: "GUAIANASES" },
          ]}
          optionLabelKey={"describe"}
        />
      ),
    },
    {
      2: (
        <PaginatedAutocompleteField
          control={control}
          name="company"
          label="Unidade"
          service={(params) =>
            getCompanies({
              region: sts ? sts.describe : null,
              virtual: false,
              ...params,
            })
          }
          refetchService={sts}
          optionLabelKey={"name"}
          queryKey={"companies"}
          filterKey={"name"}
        />
      ),
    },
    {
      3: (
        <PaginatedAutocompleteField
          control={control}
          name="team"
          label="Equipe"
          service={(params) =>
            getTeam({
              region: sts ? sts.describe : null,
              company_id: company?.id,
              ...params,
            })
          }
          refetchService={sts || company}
          queryKey={"teams"}
          filterKey={"name"}
          optionLabelKey={"name"}
        />
      ),
    },
    {
      4: (
        <PaginatedAutocompleteField
          control={control}
          name="micro_area"
          label="Micro Área"
          multiple
          service={(params) => {
            let filters = { filter: "simple_list", ...params };
            if (team) {
              filters = { team_id: team?.id, ...filters };
            }
            if (company) {
              filters = { company_id: company?.id, ...filters };
            }
            return getMicroArea(filters);
          }}
          refetchService={team || company}
          optionLabelKey={"name"}
          queryKey={"microareas"}
          filterKey={"name"}
        />
      ),
    },
    {
      5: (
        <DateField
          control={control}
          name="initial_date"
          label="Data Inicial"
          maxDate={date}
          customOnChange={() => setValue("final_date", null)}
          required
        />
      ),
      grid: 2,
    },
    {
      6: (
        <DateField
          control={control}
          name="final_date"
          label="Data Final"
          minDate={initialDate}
          maxDate={limitDate && initialDate ? addMonths(initialDate, 1) : date}
          required
        />
      ),
      grid: 2,
    },
  ];
  return (
    <Grid my={1} container spacing={2} component="form" onSubmit={handleSubmit(onSubmit)}>
      {formFields.map((formField, index) => {
        if (!filtersToHideIndex.includes(index)) {
          return (
            <Grid item xs={formField.grid || 4}>
              {formField[index]}
            </Grid>
          );
        }
        return null;
      })}
      <Grid item xs={12} display="flex" justifyContent="flex-end" gap={2}>
        <Button type="submit" variant="contained" color="secondary">
          Filtrar
        </Button>
        <Button variant="text" color="secondary" onClick={() => reset()}>
          Limpar
        </Button>
      </Grid>
    </Grid>
  );
}
