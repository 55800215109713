import React, { useContext, useState } from "react";
import Yup from "../../../config/yup";
import { Grid, Box } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircleOutline";
import { AppContext } from "../../../contexts/AppContext";
import { useAgainstReferences, useCids, useProvider } from "../../../service";
import { againstReferencePDF } from "../../../pdfModels";
import { AuthContext } from "../../../contexts/AuthContext";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  AutocompleteField,
  Button,
  PaginatedAutocompleteField,
  SelectField,
  TextField,
} from "../../../components/FormFields";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useNotifier } from "../../../hooks";
import PatientDataConfirm from "../PatientDataConfirm";
import ReferenceCard from "./ReferenceCard";
import useSignaturePassword from "../../../hooks/useSignaturePassword";
import { endOfDay, formatISO, startOfDay, subHours } from "date-fns";
import { openURL, pdfToBase64 } from "../../../utils";

function NewReference({ attendance, isExternal }) {
  const notify = useNotifier();
  const validateSignature = useSignaturePassword();
  const [isEdit, setIsEdit] = useState(false);
  const [againstReference, setAgainstReference] = useState([]);
  const [indexReference, setIndexReference] = useState();
  const { patientData, setPatientData, soapToEdit } = useContext(AppContext);
  const { userData } = useContext(AuthContext);
  const [updatePatientType, setUpdatePatientType] = useState(() =>
    !patientData?.race ? "race" : null
  );
  const disabledInternalFields = !attendance.has_soap && isExternal;

  const queryClient = useQueryClient();
  const { postAgainstReference, putAgainstReference, getAgainstReferences } =
    useAgainstReferences();
  const { getSpecialties, getProviders } = useProvider();
  const { getCids } = useCids();

  const postAgainstReferenceMutation = useMutation(postAgainstReference);
  const putAgainstReferenceMutation = useMutation(putAgainstReference);

  const validations = Yup.object().shape({
    specialty: Yup.object().required("É requerido").nullable(),
    employee: Yup.object().nullable(),
    priority: Yup.object().required("É requerido").nullable(),
    hyphotesis: Yup.string().required("É requerido"),
    cids_ids: Yup.array().nullable(),
    justification: Yup.string().required("É requerido"),
    exams: Yup.string().required("É requerido"),
  });

  const initialValues = {
    specialty: null,
    employee: null,
    priority: null,
    hyphotesis: "",
    cids_ids: [],
    justification: "",
    exams: "",
  };

  const { handleSubmit, control, watch, setValue, reset } = useForm({
    resolver: yupResolver(validations),
    defaultValues: initialValues,
  });

  const specialty = watch("specialty");

  const specialtiesQuery = useQuery(
    "specialty",
    () => getSpecialties({ caps: userData?.company?.type?.CAPS || false }),
    {
      onError: (err) => {
        notify(err.message, "error");
      },
    }
  );

  const currentAgainstReferenceQuery = useQuery(
    ["current-against-reference"],
    () => {
      const params = {
        page: 0,
        limit: 75,
        created_by_me: true,
        created_from_soap: !isExternal,
        attendance_id: attendance.id,
        patient_id: attendance.patient.id,
      };

      if (soapToEdit) {
        params.pendency_type = "soap_complement";
      } else if (!isExternal) {
        params.pendency_type = "soap";
      }

      const date = new Date();

      if (userData.company.type.TWENTY_FOUR_HOURS) {
        params.initial_date = formatISO(subHours(date, 24));
        params.final_date = formatISO(date);
      } else {
        params.initial_date = formatISO(startOfDay(date));
        params.final_date = formatISO(endOfDay(date));
      }

      return getAgainstReferences(params);
    },
    {
      enabled: attendance.has_soap || !isExternal,
      onSuccess(response) {
        setAgainstReference(response.items);
      },
    }
  );

  const handleDelete = (index) => {
    const newReference = againstReference.filter((_, i) => i !== index);
    setAgainstReference(newReference);

    if (isEdit && indexReference === index) {
      reset(initialValues);
      setIsEdit(false);
    }
  };

  const handleAddAgainstReference = handleSubmit(async (values) => {
    const signSettingsNewReference = {
      visible_sign_x: 200,
      visible_sign_y: 560,
      visible_sign_page: "*",
    };

    const counterReference = {
      priority: values.priority?.describe,
      justification: values.justification,
      id_requested_employee: values.employee?.id || null,
      requested_employee: { name: values?.employee?.name },
      id_specialty: values.specialty?.id || null,
      requested_specialty: { describe: values.specialty.describe },
      details: { hyphotesis: values?.hyphotesis, exams: values?.exams },
      cids_ids: [],
      signature_settings: signSettingsNewReference,
      cids_object: values.cids_ids,
      created_from_soap: !isExternal,
    };

    if (values.cids_ids?.length) {
      for (const cid of values.cids_ids) {
        counterReference.cids_ids.push(cid.id);
      }
    }

    const pdf = againstReferencePDF(counterReference, patientData, values.cids_ids || []);
    counterReference.id = pdf.name.replace(".pdf", "");
    counterReference.original_file_name = pdf.name;
    counterReference.data = await pdfToBase64(pdf);

    if (isEdit) {
      setAgainstReference((againstReference) => {
        const references = [...againstReference];
        references[indexReference] = counterReference;
        return references;
      });
      setIsEdit(false);
    } else {
      setAgainstReference((counterReferences) => [...counterReferences, counterReference]);
    }
    reset(initialValues);
  });

  function handlePrint(data) {
    if (data.signed_document.result) {
      return openURL(data.signed_document.result);
    }

    const pdf = againstReferencePDF(data, patientData, data.cids || []);
    pdf.open();
  }

  function verifyPendencies() {
    if (isExternal) return;

    const hasPendencies = queryClient.getQueryData("verify-has-pendencies");

    if (!hasPendencies) {
      queryClient.invalidateQueries("verify-has-pendencies");
    }
  }

  async function onSubmit() {
    if (againstReference.length && !patientData?.contact?.cell_phone) {
      return setUpdatePatientType("phone");
    }

    await validateSignature(() => {
      const formattedReferences = [];

      againstReference.forEach(
        ({ requested_employee, requested_specialty, cids_object, ...reference }) => {
          if (!reference.signed_document) {
            formattedReferences.push(reference);
          }
        }
      );

      const data = {
        attendance_id: attendance.id,
        id_soap: soapToEdit ? soapToEdit.id : null,
        against_references: formattedReferences,
      };

      postAgainstReferenceMutation.mutate(data, {
        onSuccess(response) {
          verifyPendencies();
          currentAgainstReferenceQuery.refetch();
          notify(response.message, "success");
        },
        onError(error) {
          notify(error.message, "error");
        },
      });
    });
  }

  const handleEdit = (index) => {
    setIsEdit(true);
    setIndexReference(index);

    const findedAgainstReference = againstReference[index];

    reset({
      specialty: {
        id: findedAgainstReference?.id_specialty,
        describe: findedAgainstReference?.requested_specialty.describe,
      },
      employee: !!findedAgainstReference?.requested_employee?.name
        ? {
            id: findedAgainstReference?.id_requested_employee,
            name: findedAgainstReference?.requested_employee?.name,
          }
        : null,
      priority: { describe: findedAgainstReference?.priority },
      hyphotesis: findedAgainstReference?.details?.hyphotesis,
      cids_ids: findedAgainstReference?.cids_object,
      justification: findedAgainstReference?.justification,
      exams: findedAgainstReference?.details?.exams,
    });
  };

  function verifyDisabledSave() {
    const hasUnSavedReferences = againstReference.some((reference) => !reference.signed_document);
    return disabledInternalFields || !hasUnSavedReferences;
  }

  const priorities = [{ describe: "Baixa" }, { describe: "Normal" }, { describe: "Alta" }];

  return (
    <>
      <PatientDataConfirm
        open={!!updatePatientType}
        patientId={patientData.id}
        type={updatePatientType}
        handleClose={() => setUpdatePatientType(null)}
        onSuccess={(data, _, type) => {
          setPatientData(() => {
            if (type === "phone") {
              return {
                ...patientData,
                contact: {
                  ...patientData.contact,
                  ...data.contact,
                },
              };
            } else {
              return { ...patientData, race: data.race };
            }
          });
        }}
      />
      <Box component="form">
        <Grid container spacing={2} mt="0.5rem">
          <Grid item xs={5}>
            <AutocompleteField
              control={control}
              options={specialtiesQuery.data || specialtiesQuery.data}
              optionLabelKey="describe"
              name="specialty"
              label="Especialidade"
              required
              disabled={disabledInternalFields}
              customOnChange={() => setValue("employee", null)}
            />
          </Grid>
          <Grid item xs={5}>
            <PaginatedAutocompleteField
              control={control}
              service={(params) =>
                getProviders({
                  ...params,
                  active: true,
                  id_specialty: specialty?.id,
                  exclude_me: true,
                })
              }
              filterKey={"name"}
              name="employee"
              label="Profissional"
              optionLabelKey="name"
              queryKey="employee"
              refetchService={[specialty]}
              disabled={!specialty || disabledInternalFields}
            />
          </Grid>
          <Grid item xs={2}>
            <SelectField
              control={control}
              name="priority"
              label="Prioridade"
              optionLabelKey="describe"
              options={priorities}
              required
              disabled={disabledInternalFields}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              control={control}
              multiline
              minRows={1}
              maxRows={5}
              name="hyphotesis"
              label="Hipótese diagnóstica/diagnóstico"
              required
              disabled={disabledInternalFields}
            />
          </Grid>
          <Grid item xs={12}>
            <PaginatedAutocompleteField
              control={control}
              label="CID"
              name="cids_ids"
              multiple
              service={(params) => getCids({ ...params, patient_id: attendance?.patient.id })}
              autoCompleteProps={{
                getOptionLabel: (option) => `${option.id ? option.id : ""} ${option.description}`,
              }}
              optionCompareKey="id"
              filterKey="fieldValue"
              queryKey="cids"
              disabled={disabledInternalFields}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              control={control}
              multiline
              minRows={5}
              maxRows={5}
              name="justification"
              label="Motivo de Encaminhamento"
              required
              disabled={disabledInternalFields}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              control={control}
              multiline
              minRows={1}
              maxRows={5}
              name="exams"
              label="Exames e procedimentos realizados"
              required
              disabled={disabledInternalFields}
            />
          </Grid>
          <Grid item xs={12} display="flex" justifyContent="flex-end" margin="1.2rem auto">
            <Button
              variant={isEdit ? "contained" : "text"}
              onClick={handleAddAgainstReference}
              startIcon={!isEdit ? <AddCircleIcon /> : null}
              disabled={disabledInternalFields}
            >
              {isEdit ? "Atualizar" : "Adicionar"}
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Grid container spacing={2}>
        {againstReference.map((data, index) => {
          return (
            <ReferenceCard
              key={data.requested_specialty + "-" + index}
              data={data}
              handleEdit={() => handleEdit(index)}
              handleDelete={() => handleDelete(index)}
              handlePrint={(data) => handlePrint(data)}
            />
          );
        })}
      </Grid>
      <Grid display="flex" justifyContent="center" mt={2}>
        <Button
          variant="contained"
          onClick={onSubmit}
          loadingMessage={currentAgainstReferenceQuery.isFetching ? "Carregando..." : "Enviando..."}
          loading={
            postAgainstReferenceMutation.isLoading ||
            putAgainstReferenceMutation.isLoading ||
            currentAgainstReferenceQuery.isFetching
          }
          disabled={verifyDisabledSave()}
        >
          Salvar
        </Button>
      </Grid>
    </>
  );
}

export default NewReference;
