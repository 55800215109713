import { Grid, Button, Typography, Box, useTheme, Badge } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import React, { useContext, useState } from "react";
import { FormCheckbox, FormTextField, LunaAI, SubTitle } from "../../components";
import FormMultipleSelectField from "../../components/Form/FormMultipleSelectField";
import { Dialog, DialogMedium } from "../../helper";
import Reference from "./Reference/Reference";
import Prescription from "./Prescription/Prescription";
import Declaration from "./Declaration/Declaration";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { AppContext } from "../../contexts/AppContext";
import { useFormikContext } from "formik";
import { useMutation, useQuery } from "react-query";
import { useLunaAI, usePatient, useProcedures } from "../../service";
import { useParams } from "react-router-dom";
import usePermissions from "../../config/usePermissions";
import { FormRadio, FormSelectWithPaginate } from "../../components/Form";
import Intervention from "./Interventions";
import EditorForms from "../Attendance/EditorForms/EditorForms";
import { ReactComponent as VitalSignIcon } from "../../assets/svg/vitalSign.svg";
import { useLocalStorage } from "../../hooks";
import { convertDateToBirthday, openURL, verifyAttendanceFlow } from "../../utils";
import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import Drawer from "../../components/Drawer";
import { ReactComponent as LunaIconSVG } from "../../assets/svg/LUNA_IA_ICON.svg";
import FileOpenIcon from "@mui/icons-material/FileOpen";

function Plan({ ciaps, attendance, conducts, allergies, admeasurment }) {
  const [referencesDialog, setReferencesDialog] = useState(false);
  const params = useParams();
  const idPatient = params.id;
  const [page] = useState(0);
  const [limit] = useState(5);
  const [prescriptionsDialog, setPrescriptionsDialog] = useState(false);
  const [declarationDialog, setDeclarationDialog] = useState(false);
  const [interventionDialog, setInterventionDialog] = useState(false);
  const [lunaAIDialog, setLunaAIDialog] = useState(false);
  const [isLunaDrawerOpen, setIsLunaDrawerOpen] = useState(false);
  const {
    disableFields,
    interventions,
    patientData,
    disabledFieldNames,
    soapToEdit,
    drugPrescriptions,
    examPrescriptions,
  } = useContext(AppContext);
  const { values } = useFormikContext();
  const drugPermissions = usePermissions("/soap/drugPrescription");
  const examsPermissions = usePermissions("/soap/examsPrescription");
  const lunaAIPermissions = usePermissions("/soap/lunaAI");
  const [userData] = useLocalStorage("user-data");
  const { postPatientSOA } = useLunaAI();
  const unitIsCaps = userData?.company?.type?.CAPS;
  const [initialPrescriptionTab, setInitialPrescriptionTab] = useState(0);
  const { getProgramsAvailableForPatient } = usePatient();
  const { getProcedures } = useProcedures();
  const [carePlanFormDialog, setCarePlanFormDialog] = useState(false);
  const [lunaResponse, setLunaResponse] = useState(null);
  const theme = useTheme();
  const programsQuery = useQuery(
    ["programsAvailableForPatient", [page, limit]],
    () => getProgramsAvailableForPatient(page, limit, idPatient),
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: true,
      initialData: { items: [] },
    }
  );

  function handleReferenceClick() {
    setReferencesDialog(true);
  }

  function handlePrescriptionClick() {
    setInitialPrescriptionTab(0);
    setPrescriptionsDialog(true);
  }

  function handleDeclarationClick() {
    setDeclarationDialog(true);
  }

  function handleInterventionClick() {
    setInterventionDialog(true);
  }

  function handleClose() {
    setReferencesDialog(false);
    setPrescriptionsDialog(false);
    setDeclarationDialog(false);
    setInterventionDialog(false);
  }

  const convertedBirthDate = convertDateToBirthday(patientData.birth_date, ["years"], false);

  const lengthInterventions = interventions.length;

  function getAdmeasurmentId(name) {
    const admeasurmentId = admeasurment.find(
      (e) => e.describe.toLowerCase() === name.toLowerCase()
    )?.id;
    return admeasurmentId;
  }

  const lunaAIQuery = useMutation((payload) => postPatientSOA(payload), {
    onSuccess: (data) => {
      setLunaResponse(data);
    },
    onError: () => {
      setLunaResponse(null);
    },
  });

  async function handleLunaAIObj() {
    setIsLunaDrawerOpen(true);
    const previous_pregnancies = [];
    Object.keys(values).forEach((key) => {
      if (key.startsWith("previous_pregnancies-")) {
        previous_pregnancies.push(values[key]);
      }
    });
    const lunaObj = {
      id: idPatient,
      name: patientData.name,
      age: convertedBirthDate.years,
      physical_national: patientData.physic_national,
      sus_card: patientData.sus_card,
      gender: patientData.gender,
      subjective: {
        reason: values.reason,
        ciapSubjective: values.ciapSubjective,
      },
      objective: {
        physical_exams: values.physical_exams,
        preexisting_illnesses: values.doencasPreexistentes,
        vaccine: values.radioVacina,
        admeasurement_objective: {
          height: values.admeasurement_objective[getAdmeasurmentId("Altura")],
          weight: values.admeasurement_objective[getAdmeasurmentId("Peso")],
          hearth_rate: values.admeasurement_objective[getAdmeasurmentId("Frequência cardíaca")],
          temperature: values.admeasurement_objective[getAdmeasurmentId("Temperatura")],
          saturation: values.admeasurement_objective[getAdmeasurmentId("Saturação")],
          respiratory_frequency:
            values.admeasurement_objective[getAdmeasurmentId("Frequência respiratória")],
          capillary_blood_glucose:
            values.admeasurement_objective[getAdmeasurmentId("Glicemia capilar")],
          abdominal_circumference:
            values.admeasurement_objective[getAdmeasurmentId("Circunferência abdominal")],
          cephalic_perimeter:
            values.admeasurement_objective[getAdmeasurmentId("Perímetro cefálico")],
          systolic_blood_pressure:
            values.admeasurement_objective[getAdmeasurmentId("Pressão arterial sistólica")],
          diastolic_blood_pressure:
            values.admeasurement_objective[getAdmeasurmentId("Pressão arterial diastólica")],
        },
        allergy_status: values.radioStatusAlergia,
        allergy_description: values.allergy_description,
        allergy_substances: values.allergy_substances,
        complementary_exams: values.complementary_exams,
      },
      evaluation: {
        consult: values.consult,
        ciapEvaluation: values.ciapEvaluation,
        cid: values.cid,
      },
      pregnant: {
        fetal_movements: values.fetal_movements,
        folic_acid_supplementation: values.folic_acid_supplementation,
        calcium_carbonate_supplementation: values.calcium_carbonate_supplementation,
        ferrous_sulfate_supplementation: values.ferrous_sulfate_supplementation,
        planned_pregnancy: values.planned_pregnancy,
        edema: values.edema,
        gestational_risk: values.gestational_risk,
        fetal_presentation: values.fetal_presentation,
        normal_clinical_exam: values.normal_clinical_exam,
        normal_gynecological_exam: values.normal_gynecological_exam,
        normal_breast_exam: values.normal_breast_exam,
        uterineHeight: values.admeasurement_objective["1909ef63-ba93-45f5-bfd8-f10bc68245c9"],
        fetalHeartbeat: values.admeasurement_objective["63dd4eea-d320-493f-ab33-701273e8d76a"],
        previous_pregnancies: previous_pregnancies,
      },
      drug_prescriptions: drugPrescriptions,
      exam_prescriptions: examPrescriptions,
    };
    lunaAIQuery.mutate(lunaObj);
  }

  function handleCloseLunaDrawer() {
    setIsLunaDrawerOpen(false);
  }

  function LunaIcon() {
    const pdfDocuments = [
      {
        link: "https://storage.googleapis.com/tascom/tconnect/files-luna/Rastreamento%20e%20diagn%C3%83%C2%B3stico%20da%20hiperglicemia%20na%20gesta%C3%83%C2%A7%C3%83%C2%A3o.pdf?GoogleAccessId=bucket-uploader%40aps-tscm.iam.gserviceaccount.com&Expires=1882467485&Signature=obp6wNnvG%2BxB1cpipsWXQinbFJfl3xJRR04sLj%2B%2F9nPmI4TgCd4CUzH2977%2Bzeyv18%2FhqzAY2Kg1LZwG7xsXWyLuVh31H4yE9I185vOP6OQQuZ2q64ARMJbuyR%2BCRLpLOTpesODcW%2Bio5ri14bQgBlaFSF1cE67774UecP6Jt1LZaxLN5TqhTo2yDuCYmgsWrZEfN%2F63oyoiZ0xfd36nEZtMAXm7Ytmsw5N1LAqnT9jX3sCklqUjvzuZpwndng%2FHyUY3BjNYjOmGZbo7qmoPPVOWJBlv9s2Y%2B1foaXx8hygxHw9lXCJrs2okjFMDkl0ZOn%2BxTA2Tj%2Fns7O3yApo6Aw%3D%3D",
        text: "Rastreamento e diagnóstico da hiperglicemia na gestação",
      },
      {
        link: "https://storage.googleapis.com/tascom/tconnect/files-luna/Manual%20e%20Regimento%20do%20time%20de%20regula%C3%83%C2%A7%C3%83%C2%A3o.pdf.crdownload.pdf?GoogleAccessId=bucket-uploader%40aps-tscm.iam.gserviceaccount.com&Expires=1882466498&Signature=kwmOodqZmnlxYc3OviJXWRYigh29HmoYMyKu27SF4n5pFyKlC20uzMxuQc1mjOOHYUS58lwpKYMDsFINyPMDd8%2FAJC4hyPVlN2c13aJJyZrvc35M2TS7DBC8oFKzHTEspxaaKQWqyrRHxYzgMJEMUB9hXqwbrFqpay7uFZp95aLTEyXCO%2BV3BQ1GhfaQdce1BZ6i%2FthGzvt4wEAErSW2oziiUdxsG%2BZp%2B%2Ba1C196ENlqnY64APNpgVIo5t4jr2L4xSaBpBNCrGIEV9nTkGkAsbZNArwDEY5K39fT5pTmGTbI7ZT7cViG%2BBK61ETo8KXJs9Y5PWSkk2dEbZ8e%2Fl98WQ%3D%3D",
        text: "MANUAL E REGIMENTO DO TIME DE REGULAÇÃO SANTA MARCELINA",
      },
      {
        link: "https://storage.googleapis.com/tascom/tconnect/files-luna/Rastreamento%20de%20comorbidades%20autoimunes%20no%20DM1.pdf?GoogleAccessId=bucket-uploader%40aps-tscm.iam.gserviceaccount.com&Expires=1882467663&Signature=EKHvtZjloSkV94bQfyq3HMlrQY%2B6v5svXFRWaV1PBpLVxEb5R6%2Bxb4LhR1hb8PEsXOwXZokwqBta4l4b0J%2F6gzdw6J%2FfqY7pd7TjukOhiAex65TcxhO%2BBWBJqrmlKcdouYYW5Aq1m%2FEwbpk45CGObfmysk%2BEcwwupc4yCdb1PNVxFV%2FhZf%2FBsy8LNTvjudrPVmde2L1WE2tNngS4IQMziqjkRVvpNkioSp0HOUMEByL9DKGym8EW3sSVCsTMWZmI7pGH3pXZcGsq2X0yuoZFig42BhQ3C5b%2FDezU1wg6qykxm%2BhZrc4zJmwuRMwM9UQiakkUCeEpUnqyJKQxulh2Yw%3D%3D",
        text: "Rastreamento de comorbidades autoimunes",
      },
      {
        link: "https://storage.googleapis.com/tascom/tconnect/files-luna/Planejamento%2C%20metas%20e%20monitoriza%C3%83%C2%A7%C3%83%C2%A3o%20do%20diabetes%20durante%20a%20gesta%C3%83%C2%A7%C3%83%C2%A3o.pdf?GoogleAccessId=bucket-uploader%40aps-tscm.iam.gserviceaccount.com&Expires=1882467663&Signature=axvqp0Hh0i9gZLtldqqaryp%2B4jU6XF8I025XFiqqy1Q8fFRIC%2BLqFb6e0DOBVnXuT%2FsqXGJd0pLojocpo8XM%2Bd4LhRxlmh7e7GMICEgyajnFB2VexARIKWrF%2FPrM%2F10TLRlLjK4sBlJD7crXoVRJVQT8bFFMrclPYDfdmcbm2tSWduAJqOlayKtTV3ZCGHba5RVOSTOOmz8ynymCI4qjIp7GAJTQML5pPoRrCjK61rndw0O2NhMv%2FdcNSH5OwqivNM2nnn%2Fh3UEPQL%2Falx%2FgIB6WaAj6nf90HL6yWQ7FvqT5kxuhzHgv7YFrLvoJLUBN%2FpD8syxcv%2Bflk8X8UFrKLA%3D%3D",
        text: "Monitorização do diabetes durante a gestação",
      },
      {
        link: "https://storage.googleapis.com/tascom/tconnect/files-luna/SMS_SP_PROTOCOLO_DE_PRE_NATAL_DE_BAIXO_RISCO_06_NOVEMBRO_2023.pdf?GoogleAccessId=bucket-uploader%40aps-tscm.iam.gserviceaccount.com&Expires=1882468009&Signature=s1XBGsJjHcvxt%2B9wTJBTmXVkj3cV4rlsit%2F1LvITnr3dpc%2BXrk1BMKOP6TKSK4DS3xuKTUEt0wO5VOYF%2FsRJIGLEbH%2BlZFFc6WxelYuZXWUEbv5YfZMjve0tm%2FsX5fVBsNSz0X%2FpPR6ZCZ2noXWAIW%2BzDZUzJpUS3hr4FrjTk0SrircxcgNLp4sE1H4PXlzsTzV0Oth%2F3EgM0XJOHSbioHPfxjlcUjKLtjM6Cyp6b89s56sOXeOLdUzuiL%2FeHgSzlSFVQjHgrnrO5elAcjgpqyAFjZcb%2BJnGX8NAUyFqjikO7Ocg0oSiQsmD5F%2B%2FM8Wf2Ktst%2BjBG%2Fdw3utLWbHl8w%3D%3D",
        text: "PROTOCOLO DE PRÉ-NATAL - SECRETARIA MUNICIPAL DA SAÚDE DE SÂO PAULO",
      },
      {
        link: "https://www.scielo.br/j/reben/a/rK7G6VycSgQjmGQV77VfHPK/?format=pdf&lang=pt",
        text: "Protocolo SBAR",
      },
    ];

    return (
      <>
        <DialogMedium
          open={lunaAIDialog}
          title={"Como a LUNA funciona?"}
          maxWidth="md"
          fullWidth={true}
          handleClose={() => setLunaAIDialog(false)}
          removeTips
        >
          <Typography component="h3" fontWeight="semi-bold">
            A{" "}
            <Typography component="span" fontWeight="bold">
              LUNA
            </Typography>{" "}
            é uma IA médica integrada ao Gemini da Google. Ela analisa dados clínicos do paciente
            como atendimentos, exames e aferições para gerar planos terapêuticos precisos, sugerir
            intervenções e emitir alertas importantes. Com Luna, o médico ganha uma aliada que
            otimiza o atendimento e apoia decisões mais assertivas e personalizadas.
          </Typography>
          <Box display="flex" alignItems="center">
            <ArrowRightIcon color="secondary" fontSize="large" sx={{ m: -1 }} />
            <Typography
              fontSize="1.25rem"
              lineHeight="1.5"
              component="h3"
              color="secondary"
              fontWeight="bold"
              marginY="0.5rem"
            >
              Material de referência:
            </Typography>
          </Box>
          <Box display="flex" flexDirection="column" justifyContent="start" gap="10px">
            {pdfDocuments.map((doc, index) => (
              <Button
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
                variant="outlined"
                onClick={() => openURL(doc.link)}
              >
                {doc.text}
                <FileOpenIcon />
              </Button>
            ))}
          </Box>
        </DialogMedium>
        <Box
          onClick={() => setLunaAIDialog(true)}
          display="flex"
          alignItems="center"
          gap="0.125rem"
          sx={{ cursor: "pointer" }}
        >
          <InfoIcon />
          <Typography fontSize="0.875rem" lineHeight="1.57" fontWeight="500">
            Como a luna funciona
          </Typography>
        </Box>
        <Box display="flex" justifyContent="center" alignItems="center" gap="10px">
          <LunaIconSVG
            style={{
              width: "24px",
              paddingTop: "8px",
            }}
          />
          <Typography component="h3" fontSize="1.5rem" lineHeight="1.334" fontWeight="bold">
            Luna
          </Typography>
        </Box>
      </>
    );
  }

  return (
    <>
      {lunaAIPermissions.read && (
        <>
          <Grid item xs={12} justifyContent="flex-end" container>
            <Button
              variant="contained"
              style={{ color: "white" }}
              onClick={handleLunaAIObj}
              startIcon={<AutoFixHighIcon />}
              disabled={lunaAIQuery.isLoading}
            >
              Luna
            </Button>
          </Grid>

          <Drawer open={isLunaDrawerOpen} handleClose={handleCloseLunaDrawer} title={<LunaIcon />}>
            <LunaAI
              data={lunaResponse}
              initialPrescriptionTab={setInitialPrescriptionTab}
              setPrescriptionsDialog={setPrescriptionsDialog}
              isLoading={lunaAIQuery.isLoading}
              isError={lunaAIQuery.isError}
            />
          </Drawer>
        </>
      )}
      <Dialog
        open={carePlanFormDialog}
        title={"Plano de Cuidados"}
        maxWidth="md"
        fullWidth={true}
        handleClose={() => {
          setCarePlanFormDialog(false);
        }}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 5,
          }}
        >
          {patientData?.social_prioritize ? patientData.social_name : patientData.name}
        </Typography>
        <EditorForms
          staticPatientId={patientData.id}
          attendanceId={attendance?.id}
          showOnlyTable={true}
          staticType={"carePlan"}
        />
      </Dialog>
      <DialogMedium
        open={referencesDialog}
        title={"Referência / Contrarreferência"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData?.social_prioritize ? patientData.social_name : patientData.name}
        </Typography>
        <Reference attendance={attendance} handleClose={handleClose} />
      </DialogMedium>
      <DialogMedium
        open={prescriptionsDialog}
        title={"Prescrição do Paciente"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData?.social_prioritize ? patientData.social_name : patientData.name}
        </Typography>
        <Prescription
          initialTab={initialPrescriptionTab}
          handleClose={handleClose}
          attendance={attendance}
          allergies={allergies}
        />
      </DialogMedium>
      <DialogMedium
        open={declarationDialog}
        title={"Gerar Declaração"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData?.social_prioritize ? patientData.social_name : patientData.name}
        </Typography>
        <Declaration
          attendance={attendance}
          handleClose={handleClose}
          defaultCids={values.cid}
          patient={patientData}
          isPregnancy={values.pregnant}
        />
      </DialogMedium>
      <DialogMedium
        open={interventionDialog}
        title={"Intervenções e/ou procedimentos clínicos"}
        maxWidth="md"
        fullWidth={true}
        handleClose={handleClose}
      >
        <Typography
          sx={{
            color: (theme) => theme.palette.primary.light,
            fontWeight: 500,
            fontSize: "1.5em",
            marginBottom: 1,
          }}
        >
          {patientData?.social_prioritize ? patientData.social_name : patientData.name}
        </Typography>
        <Intervention attendance={attendance} onClose={handleClose} />
      </DialogMedium>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {userData?.company?.type?.CAPS && (
            <Grid item xs={12}>
              <Button
                variant="outlined"
                onClick={() => {
                  setCarePlanFormDialog(true);
                }}
                sx={{
                  display: "flex",
                  gap: "10px",
                  alignItems: "center",
                }}
              >
                <VitalSignIcon fill={theme.palette.primary.main} />
                Plano de Cuidados
              </Button>
            </Grid>
          )}

          {!!values.pts ? (
            <Grid item xs={12} mt={1}>
              <FormTextField
                name="pts_describe"
                multiline
                minRows={3}
                maxRows={4}
                label="PTS descrição:"
                disabled={disableFields}
              />
            </Grid>
          ) : null}
        </Grid>
        <Grid item xs={12}>
          <FormTextField
            name="plan"
            multiline
            minRows={4}
            maxRows={4}
            label="Plano de Consulta : "
            sx={{ mb: 2 }}
            disabled={disableFields}
          />
        </Grid>
        {attendance?.reevaluation === "Reavaliar" && (
          <Grid item xs={12}>
            <FormTextField
              name="reavaluation_comments"
              multiline
              minRows={3}
              maxRows={3}
              label="Reavaliação : "
              sx={{ mb: 2 }}
              disabled={disableFields}
            />
          </Grid>
        )}
        <Grid item xs={6}>
          <FormMultipleSelectField
            name="ciapPlan"
            options={ciaps || []}
            keys={["id", "description"]}
            label="CIAP : "
            disabled={disableFields}
          />
        </Grid>
        <Grid item xs={6}>
          <FormMultipleSelectField
            name="programs"
            options={programsQuery.data.items}
            keys={["id", "name"]}
            disabled={disableFields}
            label="Programas : "
          />
        </Grid>
        <Grid item xs={5} md={4} lg={3} mt={2.5}>
          <FormCheckbox
            name="confirm_procedure"
            label="Confirmar procedimento"
            disabled={
              disableFields ||
              attendance?.service?.vaccine ||
              disabledFieldNames.includes("confirm_procedure")
            }
          />
        </Grid>
        <Grid item xs={7} md={8} lg={9}>
          <FormSelectWithPaginate
            service={(params) =>
              getProcedures({
                ...params,
                type: "administrative",
                filter: "active",
                patient_id: patientData?.id,
                attendance_flow: verifyAttendanceFlow(attendance.risk_classification_id),
                cid_id: values?.cid?.map(({ id }) => id),
              })
            }
            searchBy="filters"
            name="plan_procedure"
            label="Procedimento administrativo:"
            getOptionLabel={(data) => {
              let label = data?.name;

              if (data?.tuss_code) {
                label = `${data?.tuss_code} - ${label}`;
              }

              return label;
            }}
            disabled={
              disableFields ||
              attendance?.service?.vaccine ||
              disabledFieldNames.includes("plan_procedure") ||
              values?.confirm_procedure
            }
            required
          />
        </Grid>
        {attendance?.service?.mab &&
          userData.employee_type.higher_education &&
          !attendance.scheduling && (
            <Grid item xs={6}>
              <FormRadio
                name="attendance_type"
                legend="Tipo de atendimento"
                radios={[
                  { label: "Consulta no dia", value: 5 },
                  { label: "Urgència", value: 6 },
                ]}
                keys={["label", "value"]}
                row={true}
                disabled={disableFields}
                required
              />
            </Grid>
          )}
      </Grid>
      <Box display="flex" gap={3} mt={2}>
        <Button
          variant="outlined"
          onClick={handlePrescriptionClick}
          disabled={
            (!soapToEdit && disableFields) ||
            !(
              drugPermissions.create ||
              drugPermissions.read ||
              examsPermissions.create ||
              examsPermissions.read
            )
          }
        >
          Prescrições
        </Button>
        <Button
          variant="outlined"
          onClick={handleReferenceClick}
          disabled={!soapToEdit && disableFields}
        >
          Referência / Contrarreferência
        </Button>
        <Button
          variant="outlined"
          onClick={handleDeclarationClick}
          disabled={!soapToEdit && disableFields}
        >
          Declarações
        </Button>
        <Button variant="outlined" onClick={handleInterventionClick} disabled={disableFields}>
          Intervenções e/ou procedimentos clínicos
          <Badge
            color="secondary"
            overlap="circular"
            badgeContent={lengthInterventions}
            style={{
              position: "absolute",
              top: 0,
              right: 0,
            }}
          />
        </Button>
      </Box>
      {((!unitIsCaps && !!conducts.length && !disableFields) ||
        (disableFields && !!values.conducts?.length)) && (
        <Grid container item xs={12} spacing={1}>
          <Grid item xs={12}>
            <SubTitle text="Condutas" sx={{ mb: 2 }} />
          </Grid>
          {conducts.map((data) => {
            return (
              <Grid key={data.id} item xs={6}>
                <FormCheckbox
                  name="conducts"
                  label={data.description.firstCharToUp()}
                  value={data.id}
                  multiple
                  disabled={disableFields}
                />
              </Grid>
            );
          })}
        </Grid>
      )}
    </>
  );
}

export default Plan;
