import React, { useContext, useEffect, useRef, useState } from "react";
import { TITLE_NAME } from "../../config/config";
import { Helmet } from "react-helmet";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Grid, Stepper, Step, StepLabel, Button, Typography, Box } from "@mui/material";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import Subjective from "./Subjective";
import Objective from "./Objective";
import Evaluation from "./Evaluation";
import Plan from "./Plan";
import { Loading, ConfirmDialog } from "../../helper";
import { AppContext } from "../../contexts/AppContext";
import SoapHistory from "./SoapHistory/SoapHistory";
import {
  useSOAP,
  useCiaps,
  useAdmeasurement,
  usePreillness,
  usePatient,
  useProgram,
  useAttendaces,
  useDiabeticFoot,
} from "../../service/";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { theme } from "../../config/theme";
import usePermissions from "../../config/usePermissions";
import HasNoPermission from "../../components/RequireAuth/HasNoPermission";
import SoapContainer from "../../components/SoapContainer/SoapContainer";
import { soapPDF } from "../../pdfModels";
import Cookies from "js-cookie";
import { useConducts } from "../../service/useConducts";
import { differenceInMonths, parseISO } from "date-fns";
import HandleChange from "../../helper/HandleChange";
import PlanOdonto from "./PlanOdonto";
import { useLocalStorage } from "../../hooks";
import useNotifier from "../../hooks/useNotifier";
import useSignaturePassword from "../../hooks/useSignaturePassword";
import {
  convertDateToBirthday,
  formatOdontoProcedures,
  formatOdontoRps,
  pdfToBase64,
} from "../../utils";
import useDocuments from "../../service/useDocuments";
import { ampiOptions, attendanceAlerts } from "../../config/standardSelects";
import useRiskStratification from "../../service/useRiskStratification";
import {
  AMPI_CLASSIFICATION_FIELD_ID,
  DIASTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID,
  HYPERTENSION_PRE_EXISTING_ILLNESSES_ID,
  MEDICAL_EMPLOYEE_TYPE_ID,
  NURSE_EMPLOYEE_TYPE_ID,
  NURSING_ASSISTANT_EMPLOYEE_TYPE_ID,
  PHYSIOTHERAPIST_EMPLOYEE_TYPE_ID,
  SYSTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID,
} from "../../config/constants";
import useCallbackQueue from "../../hooks/useCallbackQueue";
import { usePrompt } from "../../helper/BlockNavigation";

function Soap({ odonto, ceo }) {
  const location = useLocation();
  const notify = useNotifier();
  const [loading, setLoading] = useState(false);
  const [subjectiveStepColor] = useState({});
  const [objectiveStepColor, setObjectiveStepColor] = useState({});
  const [evaluationStepColor, setEvaluationStepColor] = useState({});
  const [planStepColor, setPlanStepColor] = useState({});
  const [previousPregnancies, setPreviousPregnancies] = useState(1);
  const [hasConducts, setHasConducts] = useState(false);
  const [userData] = useLocalStorage("user-data");
  const {
    interventions,
    setInterventions,
    activeProblemsToInactive,
    patientData,
    setPatientData,
    preData,
    setDisableFields,
    setDisabledFieldNames,
    disableFields,
    clearAppContext,
    setIsRenderInterventions,
    diabeticFeetEvaluation,
    soapToEdit,
    setSoapToEdit,
    setDiabeticFeetEvaluation,
  } = useContext(AppContext);
  const [active, setActive] = useState(-1);
  const subjectivePermissions = usePermissions("/soap/subjective");
  const objectvePermissions = usePermissions("/soap/objective");
  const assessmentPermissions = usePermissions("/soap/assessment");
  const planPermissions = usePermissions("/soap/plan");
  const { getConducts } = useConducts();
  const { getRecords } = useDocuments();
  const [lastSoapId, setLastSoapId] = useState(null);
  const params = useParams();
  const [idAttendance, setIdAttendance] = useState(params.attendance);
  const idPatient = params.id;
  const [touched, setTouched] = useState(false);
  const activeHandleValues = useRef(true);
  const activeInitAttendance = useRef(true);

  const { addCallbackToQueue, next } = useCallbackQueue(true);
  const [openConfirm, setOpenConfirm] = useState({
    open: false,
    text: "",
  });

  const employeeTypes = [MEDICAL_EMPLOYEE_TYPE_ID, NURSE_EMPLOYEE_TYPE_ID];

  const odonto_ceo = {
    orthodontic: {
      open_bite: {
        anterior: false,
        posterior: false,
      },
      crossbite: {
        before: false,
        unilateral: false,
        bilateral: false,
      },
      deep_bite: {
        class_1: {
          mouth_breathing: false,
          crowding: false,
          maxillary_atresia: false,
          mandibular_atresia: false,
          others: false,
          note: "",
        },
        class_2: {
          division_1: {
            maxillary_protrusion: false,
            labial_movement_incisors: false,
            mandibular_retrusion: false,
            excessive_overjet: false,
          },
          division_2: {
            excessive_overbite: false,
            incisor_extrusion: false,
            mandibular_retrusion: false,
          },
        },
        class_3: {
          mandibular_prognathism: false,
          pre_maxillary_hypoplasia: false,
        },
      },
    },
    endodontics: {
      rubber_dam_clamp: "",
      canal: "",
      radiographic_field: "",
      curvature: "",
      reference_lima: "",
      master_cone: "",
      sealer: "",
      obturation_technique: "",
      composite_work: "",
    },
  };
  const soapOdonto = {
    teeth: [],
    braces: false,
    retainer: false,
    lower_denture: false,
    upper_denture: false,
    treatment_type_finished_and_completed_procedures: [],
    dental_supplies: [],
    ...(odonto ? { oral_health_surveillance: [] } : {}),
    attendance_type_id: [],
    arch: undefined,
    sextant: {
      s1: {
        rps: "",
      },
      s2: {
        rps: "",
      },
      s3: {
        rps: "",
      },
      s4: {
        rps: "",
      },
      s5: {
        rps: "",
      },
      s6: {
        rps: "",
      },
      notes: "",
    },
    ...(ceo ? odonto_ceo : {}),
  };

  const initialValuesForm = {
    reason: preData.subjective.reason,
    check: [],
    doencasPreexistentes: [],
    cid: [],
    ciapPlan: [],
    admeasurement_objective: preData.admeasurement_objective,
    ciapEvaluation: [],
    complementary_exams: "",
    ciapSubjective: [],
    ciap: preData.subjective.ciap_ids || [],
    radioVacina: preData.objective.vaccine_up_to_date,
    radioStatusAlergia: preData.objective.allergy_status,
    allergy_substances: preData.objective.allergy_substances,
    allergy_description: preData.objective.allergy_description || null,
    programs: [],
    pts: false,
    ampi_last_classification: null,
    ampi_classification: null,
    pregnant: false,
    last_menstrual_period: null,
    obstetric_usg_date: null,
    obstetric_usg_weeks: null,
    obstetric_usg_days: null,
    gravidity: preData.objective.gravidity || null,
    parity: preData.objective.parity || null,
    vaginal_deliveries_number: preData.objective.vaginal_deliveries_number || null,
    cesarean_deliveries_number: preData.objective.cesarean_deliveries_number || null,
    abortions_number: preData.objective.abortions_number || null,
    fetal_movements: false,
    folic_acid_supplementation: false,
    calcium_carbonate_supplementation: false,
    edema: null,
    ferrous_sulfate_supplementation: false,
    planned_pregnancy: preData.objective.planned_pregnancy || false,
    gestational_risk: null,
    fetal_presentation: null,
    normal_clinical_exam: null,
    normal_gynecological_exam: null,
    normal_breast_exam: null,
    breastfeeding: null,
    ortolani: null,
    neuropsychomotor_development: null,
    red_reflex_exam: null,
    plan_procedure: null,
    confirm_procedure: false,
    consult: "",
    plan: "",
    conducts: [],
    treatment_type_conclusion_procedure_id: null,
    physical_exams: "",
    interventions: [],
    soap_saved_date: new Date(),
    risk_stratification: null,
    complement: null,
    ...(odonto || ceo ? soapOdonto : {}),
  };

  const [soapValues, setSoapValues, originalSoapValues] = useLocalStorage("attendance", {
    [idAttendance]: null,
  });

  const soapValuesByAttendance = soapValues[idAttendance];

  const dontHasStoragedSoap = !originalSoapValues?.[idAttendance];

  const [historyStorage, setHistoryStorage] = useLocalStorage("history", (value) => {
    return location?.state?.isHistory || !!value;
  });

  const [admeasurementFilters, setAdmeasurementFilters] = useState({
    filter: "active",
    platform: "web",
    pregnant: false,
  });

  const convertedBirthDate = convertDateToBirthday(
    patientData.birth_date,
    ["years", "months"],
    false
  );

  const attendanceIsAMPI = convertedBirthDate?.years >= 60;

  const [validations, setValidations] = useState({
    dental_appointment_type:
      planPermissions.create && odonto
        ? Yup.string()
            .nullable()
            .when("attendance_type", {
              is: (v) => v == 2,
              then: (schema) => {
                setPlanStepColor((curr) => ({
                  ...curr,
                  dental_appointment_type: true,
                }));
                return schema.required("É requerido");
              },
              otherwise: (schema) => {
                setPlanStepColor((curr) => ({
                  ...curr,
                  dental_appointment_type: false,
                }));
                return schema;
              },
            })
        : null,
    sextant:
      planPermissions.create && (odonto || ceo)
        ? Yup.object().shape({
            s1: Yup.object().validateSextant(notify, setPlanStepColor),
            s2: Yup.object().validateSextant(notify, setPlanStepColor),
            s3: Yup.object().validateSextant(notify, setPlanStepColor),
            s4: Yup.object().validateSextant(notify, setPlanStepColor),
            s5: Yup.object().validateSextant(notify, setPlanStepColor),
            s6: Yup.object().validateSextant(notify, setPlanStepColor),
          })
        : null,
    admeasurement_objective: Yup.object().shape({}),
    conducts: Yup.array(),
    ampi_classification: Yup.string()
      .nullable()
      .test({
        name: "ampi_classification",
        test: function (value) {
          if (attendanceIsAMPI && !!recordsAMPIQuery.data?.items.length) {
            if (!value) {
              setObjectiveStepColor((curr) => ({
                ...curr,
                ampi_classification: true,
              }));
              notify("A classificação do AMPI não foi preenchida", "error");

              return this.createError({
                message: "A classificação do AMPI não foi preenchida",
                path: "ampi_classification",
              });
            }
            setObjectiveStepColor((curr) => ({
              ...curr,
              ampi_classification: false,
            }));
            return true;
          }

          return true;
        },
      }),
    ampi_last_classification: Yup.date()
      .nullable()
      .test({
        name: "ampi_last_classification",
        test: function (value) {
          if (attendanceIsAMPI && !!recordsAMPIQuery.data?.items.length) {
            if (!value) {
              setObjectiveStepColor((curr) => ({
                ...curr,
                ampi_last_classification: true,
              }));

              notify("A data da última classificação do AMPI é necessária", "error");

              return this.createError({
                message: "A data da última classifação é necessária",
                path: "ampi_last_classification",
              });
            }

            setObjectiveStepColor((curr) => ({
              ...curr,
              ampi_last_classification: false,
            }));
            return true;
          }

          return true;
        },
      }),
  });

  const [initialValues, setInitialValues] = useState(() => {
    if (!dontHasStoragedSoap && soapValuesByAttendance) {
      const storagedSoapData = {
        ...soapValuesByAttendance,
        interventions: soapValuesByAttendance.interventions || [],
        risk_stratification_status: soapValuesByAttendance.risk_stratification_status,
        risk_stratification: soapValuesByAttendance.risk_stratification,
        ...(odonto || ceo ? soapOdonto : {}),
      };

      if (storagedSoapData?.pregnant) {
        const previousPregnanciesKeys = Object.keys(soapValuesByAttendance).filter((key) => {
          if (key.includes("previous_pregnancies")) {
            return key;
          }
          return false;
        });

        previousPregnanciesKeys.forEach((previousPregnancyKey) => {
          storagedSoapData[previousPregnancyKey] = soapValuesByAttendance[previousPregnancyKey];
        });

        setPreviousPregnancies(previousPregnanciesKeys.length || 1);
        setAdmeasurementFilters((filters) => ({
          ...filters,
          pregnant: true,
        }));
      }

      handleBloodPressureValidation(storagedSoapData.doencasPreexistentes);

      return storagedSoapData;
    }

    return {
      ...initialValuesForm,
      last_soap_load_data: { ...initialValuesForm },
    };
  });

  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const {
    ModelDataSOAP,
    postSOAP,
    signSoap,
    getSOAP,
    getAdministrativeProcedureByAttendance,
    patchSoap,
  } = useSOAP(subjectivePermissions, objectvePermissions, assessmentPermissions, planPermissions);
  const { getDiabeticFootByPatient } = useDiabeticFoot();

  const patchSoapMutation = useMutation(patchSoap);
  const postSOAPMutation = useMutation(postSOAP);
  const signSoapMutation = useMutation(signSoap);

  const { getAdmeasurement } = useAdmeasurement();
  const { putActiveProblems, getPatientsPreillness, getPatient, getPatientAllergies } =
    usePatient();
  const { getAttendanceById, updateAttendanceStatus, verifyHasPendencies } = useAttendaces();
  const { getPreillness } = usePreillness();
  const { getCiaps } = useCiaps();
  const { getPrograms } = useProgram();
  const { getRiskStratificationStatus } = useRiskStratification();

  const attendanceStatusMutation = useMutation({
    mutationFn: updateAttendanceStatus,
    mutationKey: "update-attendance-status",
  });

  const validateSignature = useSignaturePassword();
  const activeProblemsMutation = useMutation(putActiveProblems);

  const [gestationalResult, setGestationalResult] = useState({
    DUM: null,
    USG: null,
  });

  const formRef = useRef(null);
  const [blockNavigationMessage, setBlockNavigationBlockMessage] = useState(false);

  const pendenciesQuery = useQuery("verify-has-pendencies", () => verifyHasPendencies(), {
    onSuccess(response) {
      if (!response) return;

      let alert = attendanceAlerts["soapPendency"];
      let message = "Finalize o documento SOAP para poder sair dessa página.";

      if (response.type === "soap_complement") {
        alert = attendanceAlerts["complementPendency"];
        message = "Finalize o complemento do SOAP para poder sair dessa página.";
        setDisableFields(true);
      }

      setBlockNavigationBlockMessage(message);
      notify(alert, "warning");
    },
    onError() {
      queryClient.setQueryData("verify-has-pendencies", null);
      setBlockNavigationBlockMessage(null);
    },
  });

  usePrompt(blockNavigationMessage, !!blockNavigationMessage, null, false);

  useQuery(
    ["administrative-procedure-by-attendance", idAttendance, dontHasStoragedSoap],
    () => getAdministrativeProcedureByAttendance(idAttendance),
    {
      enabled: !!idAttendance && !historyStorage,
      onSuccess(response) {
        setInitialValues((initialValues) => {
          if (
            (initialValues.confirm_procedure && initialValues.plan_procedure) ||
            dontHasStoragedSoap
          ) {
            return initialValues;
          }

          let procedureData = {
            confirm_procedure: true,
            plan_procedure: response,
          };

          return {
            ...initialValues,
            ...procedureData,
            last_soap_load_data: {
              ...initialValues.last_soap_load_data,
              ...procedureData,
            },
          };
        });

        setDisabledFieldNames((disabledFieldNames) =>
          disabledFieldNames.concat(["confirm_procedure", "plan_procedure"])
        );
      },
    }
  );

  const attendanceQuery = useQuery(
    ["attendance", idAttendance],
    () => getAttendanceById(idAttendance),
    {
      retry: false,
      refetchOnWindowFocus: false,
      onSuccess(response) {
        if (historyStorage) {
          activeInitAttendance.current = false;
          return;
        }

        if (!response.id_scheduling && activeInitAttendance.current) {
          addCallbackToQueue(() => {
            setOpenConfirm({
              open: true,
              text: "Deseja iniciar o atendimento ?",
              onConfirm: () => initializeAttendance(idAttendance, true),
            });
          });
        }

        activeInitAttendance.current = false;
        const isNotWomanDiagnostic = !userData?.company.type.CEM;

        let newValidations = {
          radioStatusAlergia:
            objectvePermissions.create && isNotWomanDiagnostic
              ? Yup.string().test({
                  name: "allergy-status-requirement",
                  test: function (value) {
                    if (!value) {
                      setObjectiveStepColor((curr) => ({
                        ...curr,
                        radioStatusAlergia: true,
                      }));
                      notify("Preencha todos os campos obrigatórios da alergia", "error");
                      return this.createError({
                        message: "Selecione o status de alergia",
                        path: "radioStatusAlergia",
                      });
                    } else {
                      setObjectiveStepColor((curr) => ({
                        ...curr,
                        radioStatusAlergia: false,
                      }));
                      return true;
                    }
                  },
                })
              : null,
          radioVacina:
            objectvePermissions.create && isNotWomanDiagnostic
              ? Yup.string()
                  .nullable()
                  .test({
                    name: "radioVacina-requirement",
                    test: function (value) {
                      if (
                        !userData?.company?.type?.CAPS &&
                        !odonto &&
                        !ceo &&
                        value === null &&
                        convertedBirthDate?.years < 3
                      ) {
                        setObjectiveStepColor((curr) => ({
                          ...curr,
                          radioVacina: true,
                        }));
                        notify("Selecione a opção no campo de vacina", "error");
                        return this.createError({
                          message: "É requerido",
                          path: "radioVacina",
                        });
                      } else {
                        setObjectiveStepColor((curr) => ({
                          ...curr,
                          radioVacina: false,
                        }));
                        return true;
                      }
                    },
                  })
              : null,
          physical_exams:
            userData.employee_type.higher_education &&
            objectvePermissions.create &&
            isNotWomanDiagnostic
              ? Yup.string().test({
                  name: "physical_exams-requirement",
                  test: function (value) {
                    if (!value) {
                      setObjectiveStepColor((curr) => ({
                        ...curr,
                        physical_exams: true,
                      }));
                      notify("Preencha todos os campos obrigatórios", "error");
                      return this.createError({
                        message: "É requerido",
                        path: "physical_exams",
                      });
                    } else {
                      setObjectiveStepColor((curr) => ({
                        ...curr,
                        physical_exams: false,
                      }));
                      return true;
                    }
                  },
                })
              : null,
          cid:
            assessmentPermissions.create && isNotWomanDiagnostic
              ? Yup.array().test({
                  name: "cid-required",
                  test: function (value) {
                    if (userData.employee_type.use_cids && !value?.length) {
                      setEvaluationStepColor((curr) => ({
                        ...curr,
                        cid: true,
                      }));
                      return this.createError({
                        message: "É requerido",
                        path: "cid",
                      });
                    } else {
                      setEvaluationStepColor((curr) => ({
                        ...curr,
                        cid: false,
                      }));
                      return true;
                    }
                  },
                })
              : null,
          ciapEvaluation:
            assessmentPermissions.create && isNotWomanDiagnostic
              ? Yup.array().test({
                  name: "ciapEvaluation-required",
                  test: function (value) {
                    if (
                      (!userData.employee_type.use_cids ||
                        [
                          "a384c778-b1d4-445e-9482-5ed440dac913",
                          "66dc5b43-bfba-4773-8ce4-a6df6c838368",
                        ].includes(userData?.employee_type?.id)) &&
                      !value?.length
                    ) {
                      setEvaluationStepColor((curr) => ({
                        ...curr,
                        ciapEvaluation: true,
                      }));
                      return this.createError({
                        message: "É requerido",
                        path: "ciapEvaluation",
                      });
                    } else {
                      setEvaluationStepColor((curr) => ({
                        ...curr,
                        ciapEvaluation: false,
                      }));
                      return true;
                    }
                  },
                })
              : null,
          oral_health_surveillance:
            assessmentPermissions.create && odonto && isNotWomanDiagnostic
              ? Yup.array().test({
                  name: "oral_health_surveillance",
                  test: function (value) {
                    if (!value?.length) {
                      setEvaluationStepColor((curr) => ({
                        ...curr,
                        oral_health_surveillance: true,
                      }));

                      return this.createError({
                        message: "É requerido",
                        path: "oral_health_surveillance",
                      });
                    } else {
                      setEvaluationStepColor((curr) => ({
                        ...curr,
                        oral_health_surveillance: false,
                      }));
                      return true;
                    }
                  },
                })
              : null,
          doencasPreexistentes:
            objectvePermissions.create && isNotWomanDiagnostic
              ? Yup.array().test({
                  name: "doencasPreexistentes",
                  test: function (value) {
                    if (!value.length) {
                      setObjectiveStepColor((curr) => ({
                        ...curr,
                        doencasPreexistentes: true,
                      }));
                      notify("Problema / Condição avaliada não informadas.", "error");

                      return this.createError({
                        message: "É requerido",
                        path: "doencasPreexistentes",
                      });
                    } else {
                      setObjectiveStepColor((curr) => ({
                        ...curr,
                        doencasPreexistentes: false,
                      }));
                      return true;
                    }
                  },
                })
              : null,
        };

        const requiredAttendanceType =
          response.service?.mab && userData.employee_type.higher_education && !response.scheduling;

        if (isNotWomanDiagnostic && (requiredAttendanceType || odonto)) {
          if (planPermissions.create) {
            newValidations = {
              ...newValidations,
              attendance_type: Yup.string().test({
                name: "attendance_type",
                test: function (value) {
                  if (!value) {
                    setPlanStepColor((curr) => ({
                      ...curr,
                      attendance_type: true,
                    }));
                    notify("È necessário informar o tipo de atendimento.", "error");

                    return this.createError({
                      message: "Confirmação necessária",
                      path: "attendance_type",
                    });
                  } else {
                    setPlanStepColor((curr) => ({
                      ...curr,
                      attendance_type: false,
                    }));
                    return true;
                  }
                },
              }),
            };
          }
        }

        if (!response.service.vaccine) {
          newValidations = {
            ...newValidations,
            confirm_procedure: Yup.boolean().test({
              name: "confirm_procedure",
              test: function (value) {
                if (!value) {
                  setPlanStepColor((curr) => ({
                    ...curr,
                    confirm_procedure: true,
                  }));
                  notify("Confirmação do procedimento é necessária", "error");

                  return this.createError({
                    message: "Confirmação necessária",
                    path: "confirm_procedure",
                  });
                } else {
                  setPlanStepColor((curr) => ({
                    ...curr,
                    confirm_procedure: false,
                  }));
                  return true;
                }
              },
            }),
            plan_procedure: Yup.object()
              .when("confirm_procedure", {
                is: (confirm_procedure) => confirm_procedure,
                then: (schema) => {
                  return schema.test({
                    name: "plan_procedure_required",
                    test: (value) => {
                      if (!value) {
                        setPlanStepColor((curr) => ({
                          ...curr,
                          plan_procedure: true,
                        }));
                        notify("Necessário informar um procedimento para confirmação", "error");
                      } else {
                        setPlanStepColor((curr) => ({
                          ...curr,
                          plan_procedure: false,
                        }));
                        return true;
                      }
                    },
                    message: "Procedimento deve ser informado.",
                  });
                },
              })
              .nullable(),
          };
        }

        if (dontHasStoragedSoap) {
          let attendanceData = {
            attendance_type: response.scheduling ? "2" : "",
          };

          if (!response.has_soap && !response.service.vaccine) {
            attendanceData.confirm_procedure = !!response.confirmed_procedure;

            if (response.risk_classification_id) {
              attendanceData.plan_procedure = {
                id: 1186,
                name: "ATENDIMENTO DE URGÊNCIA EM ATENÇÃO BÁSICA",
                tuss_code: "0301060037",
              };
            } else {
              attendanceData.plan_procedure = response.procedure || response.scheduling?.procedure;
            }
          }

          setInitialValues((initialValues) => ({
            ...initialValues,
            ...attendanceData,
            last_soap_load_data: {
              ...initialValues.last_soap_load_data,
              ...attendanceData,
            },
          }));
        }

        setValidations((validations) => ({
          ...validations,
          ...newValidations,
        }));

        if (response.patientDischarge) {
          setDisableFields(true);
          notify(
            "Não é possível realizar um SOAP / Odonto para um atendimento com alta realizada.",
            "info"
          );
        }
      },
    }
  );

  function initializeAttendance(attendanceId, in_attendance) {
    attendanceStatusMutation.mutate(
      { id: attendanceId, in_attendance },
      {
        onError(error) {
          if (in_attendance) {
            notify(error.message, "error");
          }
        },
      }
    );
  }

  useEffect(() => {
    return () => {
      initializeAttendance(idAttendance, false);
    };
  }, []);

  const pregnancyTerminationQuery = useQuery(
    ["pregnancy-termination", patientData],
    () =>
      getRecords({
        patientId: patientData.id,
        documentId: "6464cdcaf6c0f3cd46f279c6",
        params: { page: 0, limit: 1, status: "finished" },
      }),
    {
      enabled: !!patientData?.pregnant,
    }
  );

  useQuery(
    ["last-soap", lastSoapId, patientData, pregnancyTerminationQuery.data],
    () => getSOAP(lastSoapId),
    {
      retry: false,
      refetchOnWindowFocus: false,
      enabled:
        dontHasStoragedSoap &&
        !!lastSoapId &&
        !!patientData &&
        (pregnancyTerminationQuery.isIdle || pregnancyTerminationQuery.isFetched),
      onSuccess(response) {
        setInitialValues((initialValues) => {
          let newInitialValues = {
            ...initialValues,
            cid: response?.assessment?.cid ?? [],
            radioStatusAlergia: response.objective?.allergy_status,
            allergy_substances:
              Object.keys(response.objective?.allergy_substances || {}).filter(
                (key) => response.objective?.allergy_substances[key]
              ) || [],
            allergy_description: response.objective?.allergy_description,
          };

          if (odonto || ceo) {
            newInitialValues.teeth = response.plan.teeth?.map((i) => ({
              ...i,
              crown: { ...i.crown, procedures: [], notes: "" },
              root: { ...i.root, procedures: [], notes: "" },
            }));
            newInitialValues.upper_denture = response?.plan?.upper_denture || false;
            newInitialValues.lower_denture = response?.plan?.lower_denture || false;
            newInitialValues.braces = response?.plan?.braces;
            newInitialValues.retainer = response?.plan?.retainer;
          }

          const pregnancyTermination = pregnancyTerminationQuery.data?.items?.[0];

          if (pregnancyTermination && !pregnancyTermination.objective_id && !historyStorage) {
            newInitialValues = {
              ...newInitialValues,
              pregnant: false,
              last_menstrual_period: null,
              obstetric_usg_date: null,
              obstetric_usg_weeks: null,
              obstetric_usg_days: null,
              gravidity: null,
              parity: null,
              vaginal_deliveries_number: null,
              cesarean_deliveries_number: null,
              abortions_number: null,
              planned_pregnancy: null,
            };
          } else if (patientData.pregnant) {
            setAdmeasurementFilters((filters) => ({
              ...filters,
              pregnant: true,
            }));

            newInitialValues = {
              ...newInitialValues,
              pregnant: true,
              last_menstrual_period: response?.objective?.pregnancy_record?.last_menstrual_period
                ? parseISO(response?.objective?.pregnancy_record?.last_menstrual_period)
                : null,
              obstetric_usg_date: response?.objective?.pregnancy_record?.obstetric_usg_date
                ? parseISO(response?.objective?.pregnancy_record?.obstetric_usg_date)
                : null,
              obstetric_usg_weeks: response?.objective?.pregnancy_record?.obstetric_usg_weeks,
              obstetric_usg_days: response?.objective?.pregnancy_record?.obstetric_usg_days,
              gravidity: response?.objective?.pregnancy_record?.gravidity,
              parity: response?.objective?.pregnancy_record?.parity,
              vaginal_deliveries_number:
                response?.objective?.pregnancy_record?.vaginal_deliveries_number,
              cesarean_deliveries_number:
                response?.objective?.pregnancy_record?.cesarean_deliveries_number,
              abortions_number: response?.objective?.pregnancy_record?.abortions_number,
              planned_pregnancy: response?.objective?.pregnancy_record?.planned_pregnancy,
            };

            response.objective?.pregnancy_record?.previous_pregnancies?.forEach((value, index) => {
              newInitialValues["previous_pregnancies-" + index] = parseISO(value);
            });

            setPreviousPregnancies(
              response.objective?.pregnancy_record?.previous_pregnancies?.length || 1
            );

            setDisabledFieldNames((disabledFields) => [...disabledFields, "pregnant"]);
          }

          if (response.objective.child_record && convertedBirthDate?.years <= 6) {
            newInitialValues = {
              ...newInitialValues,
              ortolani: response.objective.child_record.ortolani,
              neuropsychomotor_development:
                response.objective.child_record.neuropsychomotor_development,
              red_reflex_exam: response.objective.child_record.red_reflex_exam,
              breastfeeding: response.objective.child_record?.breastfeeding,
            };
          }

          if (attendanceIsAMPI) {
            const currentDate = new Date();
            const lastClassificationDate = parseISO(response.objective?.ampi_last_classification);
            const monthsSinceLastClassification = differenceInMonths(
              currentDate,
              lastClassificationDate
            );
            const currentClassification = response.objective?.ampi_classification;

            if (
              ((monthsSinceLastClassification >= 5 &&
                (currentClassification === "pre-fragile" || currentClassification === "fragile")) ||
                (monthsSinceLastClassification >= 11 && currentClassification === "healthy")) &&
              userData.employee_type.higher_education
            ) {
              addCallbackToQueue(() =>
                setOpenConfirm({
                  open: true,
                  text: "A data da última Classificação AMPI venceu, por favor preencha o formulário de Elaboração do PTS",
                })
              );
            }
          }

          if (attendanceQuery.data?.reevaluation === "Reavaliar") {
            newInitialValues = {
              ...newInitialValues,
              reason: response.subjective?.reason || "",
              ciapSubjective: response.subjective?.ciaps || [],
              doencasPreexistentes: response.objective?.preExistIllnesses || [],
              radioVacina: response.objective?.vaccine_up_to_date,
              radioStatusAlergia: response.objective?.allergy_status,
              allergy_substances: response.objective?.allergy_substances
                ? Object.entries(response.objective.allergy_substances)
                    .filter(([, value]) => value)
                    .map(([key]) => key)
                : [],
              physical_exams: response.objective?.physical_exams || "",
              allergy_description: response.objective?.allergy_description || "",
              complementary_exams: response.objective?.complementary_exams || "",
              cid: response.assessment?.cids || [],
              ciapEvaluation: response.assessment?.ciaps || [],
              consult: response.assessment?.query_assessment || "",
              plan: response.plan?.query_plan,
              ciapPlan: response.plan?.ciaps || [],
              programs: response.plan?.programs || [],
              conducts: response.conducts?.map(({ id }) => id) || [],
            };
          }

          newInitialValues.last_soap_load_data = { ...newInitialValues };

          return newInitialValues;
        });
      },
    }
  );

  useQuery(
    ["diabetic-foot", idPatient],
    () =>
      getDiabeticFootByPatient(idPatient, {
        page: 0,
        limit: 1,
        order_by: "most-recent",
      }),
    {
      enabled: !historyStorage,
      onSuccess(response) {
        const diabeticFoot = response.items[0];

        setInitialValues((initialValues) => ({
          ...initialValues,
          diabetic_foot: diabeticFoot.objective.diabetic_foot,
          date_diabetic_foot_assessment: diabeticFoot.objective.date_diabetic_foot_assessment,
          new_evaluation_scheduled_date: diabeticFoot.objective.new_evaluation_scheduled_date,
        }));

        setDiabeticFeetEvaluation({
          amg: diabeticFoot.amg,
          hypertensive: diabeticFoot.hypertensive,
          insulin_dependent: diabeticFoot.insulin_dependent,
          diabetic_type: diabeticFoot.diabetic_type,
          last_amg_report_date: diabeticFoot.last_amg_report_date,
          glycated_hemoglobin_result: diabeticFoot.glycated_hemoglobin_result,
          glycated_hemoglobin_date: diabeticFoot.glycated_hemoglobin_date,
          left_foot: diabeticFoot.left_foot,
          right_foot: diabeticFoot.right_foot,
          result: diabeticFoot.result,
        });
      },
    }
  );

  const patientQuery = useQuery(["patientQuery", idPatient], () => getPatient(idPatient), {
    retry: true,
    refetchOnWindowFocus: false,
    keepPreviousData: false,
    onSuccess(response) {
      setPatientData(response);
    },
  });

  function setRiskStratificationValidations() {
    const requirement = Yup.boolean().test({
      test(value, field) {
        if (typeof value !== "boolean") {
          notify(
            "Todas as perguntas relacionadas a Estratificação RCV devem ser respondidas.",
            "error"
          );

          setObjectiveStepColor((curr) => ({
            ...curr,
            [field.path]: true,
          }));

          return this.createError({
            message: "Deve ser informado.",
          });
        }
        setObjectiveStepColor((curr) => ({
          ...curr,
          [field.path]: false,
        }));
        return true;
      },
    });

    setValidations((curr) => ({
      ...curr,
      risk_stratification: Yup.object().nullable().shape({
        diabetes: requirement,
        left_ventricular_hypertrophy: requirement,
        albuminuria: requirement,
        glomerular_filtration_rate: requirement,
        ischemic_stroke: requirement,
        cerebral_hemorrhage: requirement,
        transient_ischemic_attack: requirement,
        stable_or_unstable_angina: requirement,
        myocardial_infarction: requirement,
        myocardial_revascularization: requirement,
        advanced_retinopathy: requirement,
        cvd_family_history: requirement,
        smoking: requirement,
        dyslipidemia: requirement,
        obesity: requirement,
        heart_failure_or_reduced: requirement,
        artery_disease_lower_limbs: requirement,
        stage_four_chronic_kidney_disease: requirement,
      }),
    }));
  }

  function enableRiskStratification(initialResponses = {}) {
    setInitialValues((initialValues) => ({
      ...initialValues,
      risk_stratification: initialResponses,
      risk_stratification_status: true,
      last_soap_load_data: {
        ...initialValues.last_soap_load_data,
        risk_stratification_status: true,
      },
    }));
    setRiskStratificationValidations();
    setAdmeasurementFilters((curr) => ({
      ...curr,
      risk_stratification: true,
    }));
  }

  useQuery(
    ["get-risks-stratification-status"],
    () => getRiskStratificationStatus(patientQuery.data?.id),
    {
      enabled: !!patientQuery.data?.id,
      onSuccess(data) {
        if (data) {
          const { active, message, ...risk_stratification } = data;

          if (active) {
            enableRiskStratification(risk_stratification);
            setDisabledFieldNames((disabledFields) => [
              ...disabledFields,
              "risk_stratification_status",
            ]);
          } else {
            addCallbackToQueue(() =>
              setOpenConfirm({
                open: true,
                text: message,
              })
            );
            enableRiskStratification();
          }
        }
      },
    }
  );

  const allergiesQuery = useQuery(
    ["get-patient-allergies", patientQuery.data?.id],
    () => getPatientAllergies(patientQuery.data.id, { limit: 1 }),
    {
      enabled: !historyStorage && !!patientQuery.data?.id,
    }
  );

  function handleBloodPressureValidation(value) {
    const hasHypertension = value?.some(
      (item) => item.id === HYPERTENSION_PRE_EXISTING_ILLNESSES_ID
    );

    const bloodPressureId = [
      DIASTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID,
      SYSTOLIC_BLOOD_PRESSURE_ADMEASUREMENT_ID,
    ];

    if (
      hasHypertension &&
      !odonto &&
      !ceo &&
      employeeTypes.includes(userData?.employee_type?.id) &&
      !userData?.company.type.CEM
    ) {
      const regex = /^\d*(\,\d*)?(\.\d*)?(x\d*)?(\/\d*)?(\,\d*)?(\.\d*)?$/i;

      setValidations((validations) => {
        const newValidations = {};

        bloodPressureId.forEach((id) => {
          newValidations[id] = Yup.string().test({
            name: "admeasurement-requirement",
            test: function (value) {
              if (!value?.length) {
                setObjectiveStepColor((curr) => ({
                  ...curr,
                  [`admeasurement_objective_${id}`]: true,
                }));
                notify("Algumas aferições não foram informadas", "error");
                return this.createError({
                  message: "É requerido",
                  path: `admeasurement_objective.${id}`,
                });
              }

              if (!value?.match(regex)) {
                setObjectiveStepColor((curr) => ({
                  ...curr,
                  [`admeasurement_objective_${id}`]: true,
                }));
                notify("Algumas aferições não estão válidas", "error");
                return this.createError({
                  message: "Aferição inválida",
                  path: `admeasurement_objective.${id}`,
                });
              }

              setObjectiveStepColor((curr) => ({
                ...curr,
                [`admeasurement_objective_${id}`]: false,
              }));
              return true;
            },
          });
        });

        return {
          ...validations,
          admeasurement_objective: Yup.object().shape({
            ...validations.admeasurement_objective.fields,
            ...newValidations,
          }),
        };
      });
    } else {
      setValidations((validations) => {
        const newValidations = {
          ...validations.admeasurement_objective.fields,
        };
        bloodPressureId.forEach((id) => delete newValidations[id]);

        return {
          ...validations,
          admeasurement_objective: Yup.object().shape(newValidations),
        };
      });
    }
  }

  useQuery("patientPreillness", () => getPatientsPreillness(idPatient), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: !historyStorage && dontHasStoragedSoap,
    onSuccess(response) {
      setInitialValues((values) => ({
        ...values,
        doencasPreexistentes: response,
        last_soap_load_data: {
          ...values.last_soap_load_data,
          doencasPreexistentes: response,
        },
      }));

      handleBloodPressureValidation(response);
    },
  });

  const preIllnessQuery = useQuery(
    "preIllnes",
    () => getPreillness({ filter: "active", patient_id: idPatient }),
    {
      enabled: !historyStorage,
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
    }
  );

  const subjectiveCiapsQuery = useQuery(
    "subjective-ciaps",
    () => getCiaps({ filter: "active", type: "S" }),
    {
      enabled: !historyStorage,
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
    }
  );

  const evaluationCiapsQuery = useQuery(
    "evaluation-ciaps",
    () => getCiaps({ filter: "active", type: "A" }),
    {
      enabled: !historyStorage,
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
    }
  );

  const planCiapsQuery = useQuery("plan-ciaps", () => getCiaps({ filter: "active", type: "P" }), {
    enabled: !historyStorage,
    retry: false,
    refetchOnWindowFocus: false,
    initialData: [],
  });

  const programsQuery = useQuery("programs", getPrograms, {
    enabled: !historyStorage,
    retry: false,
    refetchOnWindowFocus: false,
    initialData: [],
  });

  const admeasurmentQuery = useQuery(
    ["admeasurement", attendanceQuery.data, gestationalResult, admeasurementFilters],
    () => getAdmeasurement(admeasurementFilters),
    {
      enabled: attendanceQuery.data?.id === idAttendance || historyStorage,
      retry: false,
      refetchOnWindowFocus: false,
      initialData: [],
      onSuccess(response) {
        const adValidations = {};
        const admeasurement_objective = {};

        response.forEach((admeasurement) => {
          const admeasurementOption = admeasurement.describe.toUpperCase();
          const pregnantAdmeasurements = ["ALTURA UTERINA"];
          const childAdmeasurements = ["PESO", "ALTURA"];

          const admeasurementName = `admeasurement_objective.${admeasurement.id}`;

          admeasurement_objective[admeasurement.id] = "";

          const admeasurementValidation =
            validations?.admeasurement_objective?.fields?.[admeasurement.id];
          let required = admeasurementValidation?.tests?.some(
            (test) => test.OPTIONS.name === "admeasurement-requirement"
          );

          const allowedAttendance = employeeTypes.includes(userData?.employee_type?.id);

          if (
            allowedAttendance &&
            (admeasurementFilters.pregnant || admeasurementFilters.risk_stratification) &&
            admeasurementOption.includes("PRESSÃO ARTERIAL")
          ) {
            required = true;
          } else if (
            allowedAttendance &&
            pregnantAdmeasurements.includes(admeasurementOption) &&
            (gestationalResult.DUM?.weeks >= 12 || gestationalResult.USG?.weeks >= 12)
          ) {
            required = true;
          } else if (
            userData?.company?.type?.UBS &&
            !attendanceQuery?.data?.risk_classification_id &&
            allowedAttendance &&
            childAdmeasurements.includes(admeasurementOption) &&
            patientData?.birth_date &&
            convertedBirthDate?.years <= 5
          ) {
            required = true;
          } else if (
            admeasurementOption === "PERIMETRO CEFALICO" &&
            convertedBirthDate?.years <= 2
          ) {
            required = true;
          }

          if (userData?.company.type.CEM || userData?.company.type.CAPS || odonto || ceo) {
            required = false;
          }

          const regex =
            admeasurementOption === "ALTURA"
              ? /^\d(,\d+)?(\.\d+)?$/
              : admeasurementOption === "PESO"
              ? /^\d{1,3}(,\d+)?(\.\d+)?$/
              : /^\d*(\,\d*)?(\.\d*)?(x\d*)?(\/\d*)?(\,\d*)?(\.\d*)?$/i;

          adValidations[admeasurement.id] = required
            ? Yup.string().test({
                name: "admeasurement-requirement",
                test: function (value) {
                  if (!value?.length) {
                    setObjectiveStepColor((curr) => ({
                      ...curr,
                      [admeasurementName]: true,
                    }));
                    notify("Algumas aferições não foram informadas", "error");
                    return this.createError({
                      message: "É requerido",
                      path: `admeasurement_objective.${admeasurement.id}`,
                    });
                  }

                  if (!value?.match(regex)) {
                    setObjectiveStepColor((curr) => ({
                      ...curr,
                      [admeasurementName]: true,
                    }));
                    notify("Algumas aferições não estão válidas", "error");
                    return this.createError({
                      message: "Aferição inválida",
                      path: `admeasurement_objective.${admeasurement.id}`,
                    });
                  }

                  setObjectiveStepColor((curr) => ({
                    ...curr,
                    [admeasurementName]: false,
                  }));
                  return true;
                },
              })
            : Yup.string()
                .nullable()
                .test({
                  name: "admeasurement-validate",
                  test: function (value) {
                    if (value && !value?.match(regex)) {
                      setObjectiveStepColor((curr) => ({
                        ...curr,
                        [admeasurementName]: true,
                      }));
                      notify("Algumas aferições não estão válidas", "error");
                      return this.createError({
                        message: "Aferição inválida",
                        path: `admeasurement_objective.${admeasurement.id}`,
                      });
                    }

                    setObjectiveStepColor((curr) => ({
                      ...curr,
                      [admeasurementName]: false,
                    }));
                    return true;
                  },
                });
        });

        if (dontHasStoragedSoap) {
          setInitialValues((initialValues) => ({
            ...initialValues,
            admeasurement_objective,
            last_soap_load_data: {
              ...initialValues.last_soap_load_data,
              admeasurement_objective,
            },
          }));
        }

        if (subjectivePermissions.create) {
          setValidations((validations) => ({
            ...validations,
            admeasurement_objective: Yup.object().shape(adValidations),
          }));
        }
      },
    }
  );

  const isConducstValid =
    !userData?.company.type.CEM &&
    !userData?.company?.type?.CAPS &&
    !ceo &&
    (hasConducts ||
      odonto ||
      ceo ||
      (planPermissions.create && !!attendanceQuery.data?.scheduling) ||
      (planPermissions.create &&
        attendanceQuery.data?.service?.describe.toUpperCase() === "ACOLHIMENTO"));

  const conductQuery = useQuery(
    ["conducts", hasConducts, attendanceQuery.data?.id],
    () => {
      if (isConducstValid) {
        return getConducts({
          filter: odonto ? "odontology" : "attendance",
        });
      }
      return [];
    },
    {
      retry: false,
      refetchOnWindowFocus: false,
      keepPreviousData: false,
      initialData: [],
      enabled: attendanceQuery.data?.id === idAttendance,
      onSuccess: (response) => {
        if (response.length > 0 && isConducstValid) {
          setValidations((validations) => ({
            ...validations,
            conducts: Yup.array().test({
              name: "conducts",
              test: function (value) {
                if (value.length === 0) {
                  setPlanStepColor((curr) => ({
                    ...curr,
                    conducts: true,
                  }));
                  notify("Nenhuma Conduta informada", "error");

                  return this.createError({
                    message: "É requerido",
                    path: "conducts",
                  });
                } else {
                  setPlanStepColor((curr) => ({
                    ...curr,
                    conducts: false,
                  }));
                  return true;
                }
              },
            }),
          }));
        }
      },
    }
  );

  const recordsAMPIQuery = useQuery(
    ["records-ampi-query", idPatient],
    () =>
      getRecords({
        patientId: idPatient,
        documentId: "65b16154388e341d1fcbb7a7",
        params: { page: 0, limit: 1, status: "finished" },
      }),
    {
      enabled: !!attendanceIsAMPI,
      initialData: { totalItems: 0, items: [] },
      onSuccess: ({ items }) => {
        setInitialValues((initialValues) => {
          const classificationField = items[0]?.fields?.find(
            (field) => field?.field_id === AMPI_CLASSIFICATION_FIELD_ID
          );

          let keyFound = null;

          for (const key in ampiOptions) {
            if (ampiOptions[key] === classificationField?.value) {
              keyFound = key;
            }
          }

          let ampiData = {
            ...initialValues,
            ampi_last_classification: !!keyFound ? parseISO(items[0]?.updated_at) : null,
            ampi_classification: keyFound,
          };

          if (soapValuesByAttendance) {
            ampiData = {
              ...ampiData,
              ...soapValuesByAttendance,
            };
          }

          return {
            ...ampiData,
            last_soap_load_data: {
              ...ampiData,
            },
          };
        });
      },
    }
  );

  useEffect(() => {
    if (subjectivePermissions.create) {
      setActive(0);
      return;
    }
    if (objectvePermissions.create) {
      setActive(1);
      return;
    }
    if (assessmentPermissions.create) {
      setActive(2);
      return;
    }
    if (planPermissions.create) {
      setActive(3);
      return;
    }
  }, [
    subjectivePermissions.create,
    objectvePermissions.create,
    assessmentPermissions.create,
    planPermissions.create,
  ]);

  React.useEffect(() => {
    return () => {
      setIsRenderInterventions(false);
      setHistoryStorage(false);
      clearAppContext();
    };
  }, []);

  function openConfirmContinueEditing() {
    addCallbackToQueue(() =>
      setOpenConfirm({
        open: true,
        text: "Notamos que você iniciou um SOAP para este atendimento, deseja *continuar* editando?",
        onConfirm: handleConfirmDialogContinueEditing,
        onCancel: () => {
          setSoapValues((previousData) => {
            return {
              ...previousData,
              [idAttendance]: null,
            };
          });
          setInitialValues((initialValues) => ({
            ...initialValues.last_soap_load_data,
            last_soap_load_data: { ...initialValues.last_soap_load_data },
          }));
          setTouched(true);
        },
      })
    );
  }

  useEffect(() => {
    const storagedSoapValues = JSON.parse(localStorage.getItem("attendance"));
    activeHandleValues.current = false;

    if (
      storagedSoapValues &&
      storagedSoapValues[idAttendance] &&
      !attendanceQuery.data?.patientDischarge &&
      !historyStorage
    ) {
      openConfirmContinueEditing();
    }

    if (historyStorage) {
      setDisableFields(true);
      setTouched(false);
    } else {
      setTouched(true);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyStorage]);

  const steps = [
    {
      describe: "S",
      color: Object.values(subjectiveStepColor).includes(true)
        ? "#e5cbcb"
        : theme.palette.secondary.medium,
    },
    {
      describe: "O",
      color: Object.values(objectiveStepColor).includes(true)
        ? "#e5cbcb"
        : theme.palette.secondary.medium,
    },
    {
      describe: "A",
      color: Object.values(evaluationStepColor).includes(true)
        ? "#e5cbcb"
        : theme.palette.secondary.medium,
    },
    {
      describe: "P",
      color: Object.values(planStepColor).includes(true)
        ? "#e5cbcb"
        : theme.palette.secondary.medium,
    },
  ];

  const titles = ["Subjetivo", "Objetivo", "Avaliação", "Plano"];
  const [shouldShowButtons, setShouldShowButtons] = useState(true);
  function stepperPages(value) {
    switch (value) {
      case 0:
        setShouldShowButtons(subjectivePermissions.create);
        return subjectivePermissions.create ? (
          <Subjective ciaps={subjectiveCiapsQuery.data} />
        ) : (
          <HasNoPermission />
        );
      case 1:
        setShouldShowButtons(objectvePermissions.create);
        return objectvePermissions.create ? (
          <Objective
            company={userData}
            attendance={attendanceQuery.data}
            admeasurement={admeasurmentQuery.data}
            validations={validations}
            setValidations={setValidations}
            preIllness={preIllnessQuery.data}
            setAdmeasurementFilters={setAdmeasurementFilters}
            setRiskStratificationValidations={setRiskStratificationValidations}
            previousPregnancies={previousPregnancies}
            setPreviousPregnancies={setPreviousPregnancies}
            gestationalResult={gestationalResult}
            setGestationalResult={setGestationalResult}
            recordAMPIQuery={recordsAMPIQuery.data}
            odonto={odonto}
            ceo={ceo}
            handleBloodPressureValidation={handleBloodPressureValidation}
          />
        ) : (
          <HasNoPermission />
        );
      case 2:
        setShouldShowButtons(assessmentPermissions.create);
        return assessmentPermissions.create ? (
          <Evaluation
            ciapsEvaluation={evaluationCiapsQuery.data}
            attendance={attendanceQuery.data}
            odonto={odonto}
            patientID={idPatient}
          />
        ) : (
          <HasNoPermission />
        );
      case 3:
        setShouldShowButtons(planPermissions.create);
        return planPermissions.create ? (
          odonto || ceo ? (
            <PlanOdonto
              conducts={conductQuery.data}
              odonto={odonto}
              ceo={ceo}
              attendance={attendanceQuery.data}
              allergies={allergiesQuery.data?.items[0]}
            />
          ) : (
            <Plan
              ciaps={planCiapsQuery.data}
              programs={programsQuery.data}
              attendance={attendanceQuery.data}
              conducts={conductQuery.data}
              allergies={allergiesQuery.data?.items[0]}
              admeasurment={admeasurmentQuery.data}
            />
          )
        ) : (
          <HasNoPermission />
        );
      default:
        return null;
    }
  }

  async function submitSOAP(values) {
    setLoading(true);

    validateSignature(
      () => {
        activeProblemsToInactive.forEach((activeProblem) => {
          if (!!activeProblem) {
            activeProblemsMutation.mutate({
              idPatient,
              activeProblemId: activeProblem.activeProblemId,
            });
          }
        });

        values.idAttendance = idAttendance;
        values.interventions = interventions;

        const diabeticFootIsEnabled = employeeTypes
          .concat(PHYSIOTHERAPIST_EMPLOYEE_TYPE_ID)
          .includes(userData.employee_type.id);

        if (diabeticFootIsEnabled) {
          values.diabetic_foot_assessment = diabeticFeetEvaluation;
        } else {
          values.diabetic_foot = null;
          values.date_diabetic_foot_assessment = null;
          values.date_diabetic_foot_assessment = null;
          values.new_evaluation_scheduled_date = null;
        }

        if (values.pregnant) {
          values.obstetric_usg_date = values.obstetric_usg_date
            ? new Date(values.obstetric_usg_date)
            : null;
          values.last_menstrual_period = values.obstetric_usg_date
            ? new Date(values.last_menstrual_period)
            : null;
        }

        const soapPayload = ModelDataSOAP(values, convertedBirthDate?.years);

        postSOAPMutation.mutate(soapPayload, {
          onSuccess: async (response) => {
            setTouched(false);
            setBlockNavigationBlockMessage(null);

            const keysOfSoapValues = Object.keys(soapValues);

            if (keysOfSoapValues.length > 1) {
              setSoapValues((current) => {
                const currentValues = { ...current };
                delete currentValues[idAttendance];
                return currentValues;
              });
            } else {
              localStorage.removeItem("attendance");
            }

            if (Cookies.get("signer-auth-token") && Cookies.get("signer-auth-token") !== "null") {
              let pdfToSign;

              if (odonto || ceo) {
                const rps = formatOdontoRps(response.plan.sextant);
                const odontoProcedures = formatOdontoProcedures(response.plan);

                pdfToSign = soapPDF(response, patientQuery.data, null, odontoProcedures, rps);
              } else {
                pdfToSign = soapPDF(response, patientQuery.data);
              }

              const data = {
                id: pdfToSign.name.replace(".pdf", ""),
                original_file_name: pdfToSign.name,
                data: await pdfToBase64(pdfToSign),
                signature_settings: {
                  visible_sign_x: 170,
                  visible_sign_y: 600,
                  visible_sign_page: "*",
                },
              };

              signSoapMutation.mutate(
                { id: response.id, data },
                {
                  onError: (error) => {
                    setLoading(false);
                    notify(error.message, "error");
                  },
                  onSettled: () => {
                    goBack();
                  },
                }
              );
            } else {
              goBack();
            }
          },
          onError(error) {
            setLoading(false);
            notify(error.message, "error");
          },
        });
      },
      false,
      () => {
        setLoading(false);
      }
    );
  }

  function handleConfirmDialogContinueEditing() {
    setInterventions(soapValuesByAttendance?.interventions);
  }

  function goBack() {
    setIsRenderInterventions(false);
    setHistoryStorage(false);
    clearAppContext();

    navigate(location.state?.backTo || (historyStorage ? "/patient" : "/attendance/initial"), {
      state: {
        menuServiceOpen: true,
        use: idAttendance,
      },
      replace: true,
    });
  }

  async function onEditSoap(values) {
    setLoading(true);

    validateSignature(
      () => {
        patchSoapMutation.mutate(
          {
            id: soapToEdit.id,
            data: {
              complement: values.complement,
            },
          },
          {
            onSuccess: async (response) => {
              queryClient.setQueryData("verify-has-pendencies", null);
              setBlockNavigationBlockMessage(null);
              localStorage.removeItem("attendance");

              if (Cookies.get("signer-auth-token") && Cookies.get("signer-auth-token") !== "null") {
                response.pts = values.pts;
                response.plan["interventions"] = interventions;

                let pdfToSign;

                if (odonto || ceo) {
                  const rps = formatOdontoRps(response.plan.sextant);
                  const odontoProcedures = formatOdontoProcedures(response.plan);

                  pdfToSign = soapPDF(response, patientQuery.data, null, odontoProcedures, rps);
                } else {
                  pdfToSign = soapPDF(response, patientQuery.data);
                }

                const data = {
                  id: pdfToSign.name.replace(".pdf", ""),
                  original_file_name: pdfToSign.name,
                  data: await pdfToBase64(pdfToSign),
                  signature_settings: {
                    visible_sign_x: 170,
                    visible_sign_y: 600,
                    visible_sign_page: "*",
                  },
                };

                signSoapMutation.mutate(
                  { id: response.id, data },
                  {
                    onError: (error) => {
                      notify(error.message, "error");
                    },
                    onSettled: () => {
                      setLoading(false);
                      goBack();
                    },
                  }
                );
              } else {
                goBack();
              }
            },
            onError(error) {
              setLoading(false);
              notify(error.message, "error");
            },
          }
        );
      },
      false,
      () => {
        setLoading(false);
      }
    );
  }

  function handleStorageValues(previousData, values) {
    if (!activeHandleValues.current) {
      const newValues = {
        ...previousData,
        [idAttendance]: {
          ...previousData[idAttendance],
          ...values,
          interventions,
        },
      };

      if (!newValues[idAttendance].last_soap_load_data) {
        newValues[idAttendance].last_soap_load_data = initialValuesForm;
      }

      return newValues;
    }

    return previousData;
  }

  const confirmOptions = {
    "pending-medications": {
      message:
        "Paciente com medicação assistida, certifique-se sobre a existência de pendência de administração de medicação e direcione-o ao setor de medicação",
    },
    "woman-diagnostic": {
      message: "Ao menos uma intervenção e/ou procedimento clínico deve ser registrada.",
    },
    "interventions-nurse": {
      message:
        "Você realizou algum procedimento durante este atendimento? Por favor, confirme para garantir que todos os procedimentos sejam registrados adequadamente.",
    },
  };

  if (!patientQuery.data?.id && !historyStorage) {
    return (
      <Box
        sx={{
          minHeight: "80vh",
        }}
      >
        {patientQuery.isFetching ? (
          <Loading message={"Carregando dados do paciente..."} />
        ) : (
          <>
            <Typography
              sx={{
                fontSize: 16,
                color: theme.palette.error.light,
              }}
              marginBottom={2}
            >
              Não foi possível buscar os dados do paciente, por favor, tente novamente.
            </Typography>
            <Button
              variant="contained"
              onClick={() => {
                goBack();
              }}
            >
              Tentar Novamente
            </Button>
          </>
        )}
      </Box>
    );
  }

  return (
    <>
      <Helmet>
        <title>
          {TITLE_NAME} SOAP - {params.id}{" "}
        </title>
      </Helmet>
      {loading && <Loading />}
      <ConfirmDialog
        message={openConfirm.text}
        hideBackDrop={false}
        open={openConfirm.open}
        maxWidth="sm"
        fullWidth={true}
        handleClose={() => {
          if (openConfirm.onClose instanceof Function) openConfirm.onClose();
          setOpenConfirm({
            open: false,
          });
          next();
        }}
        actions
        handleConfirm={openConfirm.onConfirm}
        handleCancel={openConfirm.onCancel}
      />
      <Grid
        container
        spacing={2}
        sx={{
          display: "flex",
          flexGrow: 1,
          mt: -5,
          flexWrap: "nowrap",
        }}
      >
        <Grid
          item
          sx={{
            mt: 3,
            mr: 0,
            ml: 0,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "570px",
            maxHeight: "570px",
            width: "120px",
          }}
        >
          <Stepper
            activeStep={active}
            nonLinear
            orientation="vertical"
            sx={{
              "& .MuiStepConnector-line": {
                marginLeft: "38px",
                fill: "red",
                marginTop: "-12px",
                marginBottom: "-12px",
                borderColor: theme.palette.secondary.medium,
                borderWidth: "2px",
              },
            }}
          >
            {steps.map((label, key) => (
              <Step key={label.describe + "-" + key} onClick={() => setActive(key)}>
                <StepLabel
                  icon={label.describe}
                  sx={{
                    "& .Mui-active": {
                      "&.MuiSvgIcon-root": {
                        color: theme.palette.primary.light,
                        borderColor: "white",
                        borderWidth: "3px",
                        boxShadow: `0 0 0 1.5px ${theme.palette.primary.light}`,
                        zIndex: 50,
                        position: "relative",
                      },
                    },
                    "& .MuiStepIcon-text": {
                      fill: "white",
                      fontSize: 10,
                    },
                    "&.MuiStepLabel-vertical": {
                      "& svg": {
                        border: "3px solid transparent",
                        fontSize: 100,
                        borderRadius: 20,
                        justifySelf: "center",
                        color: label.color,
                        "&:hover": {
                          border: "1px solid transparent",
                          cursor: "pointer",
                        },
                      },
                    },
                  }}
                />
              </Step>
            ))}
          </Stepper>
        </Grid>

        <Grid item xs={10.95} sx={{ maxWidth: 1280 }}>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={Yup.object().shape(validations, [["cid", "ciapEvaluation"]])}
            validateOnChange={false}
            validateOnBlur={false}
            onSubmit={soapToEdit ? onEditSoap : submitSOAP}
            innerRef={formRef}
          >
            {({ setFieldValue, submitForm, values }) => (
              <Form>
                <SoapContainer
                  title={titles?.[active]}
                  patient={patientData}
                  attendance={attendanceQuery.data}
                  allergies={allergiesQuery.data?.items[0]}
                  history={
                    <SoapHistory
                      patient={patientQuery.data}
                      patientDischarge={attendanceQuery?.data?.patientDischarge}
                      setLastSoapId={setLastSoapId}
                      setIdAttendance={setIdAttendance}
                      previousPregnancies={previousPregnancies}
                      setPreviousPregnancies={setPreviousPregnancies}
                      setHasConducts={setHasConducts}
                      setTouchedValues={setTouched}
                      validations={validations}
                      setValidations={setValidations}
                      odonto={odonto}
                      ceo={ceo}
                      pendencies={pendenciesQuery.data}
                    />
                  }
                >
                  <HandleChange
                    data={(previousData) => handleStorageValues(previousData, values)}
                    touched={touched}
                    setLocalStorage={setSoapValues}
                    dependencies={[values, interventions]}
                  />
                  {stepperPages(active, setFieldValue)}
                  {shouldShowButtons ? (
                    <Grid container xs={12} mt={4} display="flex" columnGap={4}>
                      {soapToEdit && (
                        <>
                          <Button variant="contained" disabled={loading} onClick={submitForm}>
                            Finalizar
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            disabled={loading}
                            onClick={() => {
                              setSoapToEdit(null);
                            }}
                          >
                            Cancelar
                          </Button>
                        </>
                      )}
                      {!soapToEdit && (
                        <Button
                          variant="contained"
                          disabled={disableFields || loading}
                          onClick={() => {
                            if (userData?.company.type.CEM && !interventions.length) {
                              return addCallbackToQueue(() => {
                                setOpenConfirm({
                                  open: true,
                                  text: confirmOptions["woman-diagnostic"]?.message,
                                });
                              });
                            }

                            if (patientData?.pending_medication) {
                              return addCallbackToQueue(() => {
                                setOpenConfirm({
                                  open: true,
                                  text: confirmOptions["pending-medications"]?.message,
                                  onConfirm: submitForm,
                                });
                              });
                            }

                            if (
                              !interventions.length &&
                              userData?.employee_type?.id === NURSING_ASSISTANT_EMPLOYEE_TYPE_ID
                            ) {
                              return setOpenConfirm({
                                open: true,
                                text: confirmOptions["interventions-nurse"]?.message,
                                onConfirm: submitForm,
                              });
                            }

                            submitForm();
                          }}
                        >
                          Finalizar
                        </Button>
                      )}
                      {(!!attendanceQuery.data?.risk_classification_id ||
                        (employeeTypes.includes(userData?.employee_type?.id) &&
                          (userData?.company?.type.CAPS || userData?.company?.type.UBS))) &&
                        attendanceQuery.data?.reevaluation !== "Em monitoramento" &&
                        !soapToEdit && (
                          <Button
                            variant="contained"
                            onClick={() => {
                              values.reevaluate = true;
                              submitForm();
                            }}
                            disabled={disableFields || loading}
                          >
                            Reavaliação
                          </Button>
                        )}
                      {!soapToEdit && (
                        <Button
                          onClick={() => {
                            addCallbackToQueue(() => {
                              setOpenConfirm({
                                open: true,
                                text: "Deseja *SAIR* do SOAP ?",
                                onConfirm: () => {
                                  setHistoryStorage(false);
                                  goBack();
                                },
                              });
                            });
                          }}
                          variant="text"
                        >
                          Voltar
                        </Button>
                      )}
                    </Grid>
                  ) : null}
                </SoapContainer>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
    </>
  );
}

export default Soap;
